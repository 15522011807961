import {Action} from 'platform/components';
import {Box, Heading, HStack, Icon, Show, ThemeIconKey, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always, filter, find, head, uniq} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {EntityResourceIds, OrderDiscriminator, useGetParticipationQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {Main, usePermissions} from '@omnetic-dms/shared';
import {OrderDiscriminatorEnum} from '@omnetic-dms/teas';

import {buildArray, convertStringToCamelCase, suffixTestId, TestIdProps, useToggle} from 'shared';

import {CheckoutCreditSettlement} from './CheckoutCreditSettlement';
import {CheckoutOrderCard} from './CheckoutOrderCard';
import {CheckoutProps} from './types/CheckoutProps';

interface OrderStatus {
  icon: ThemeIconKey;
  text: string;
}

export function Checkout(props: CheckoutProps & TestIdProps) {
  const [isCreditSettlementDialogOpen, toggleCreditSettlementDialog] = useToggle(false);

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: props.businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const [canViewBusinessCaseBrokerageFees] = usePermissions({
    permissionKeys: ['businessCaseViewBrokerageFees'],
    scopes: {
      businessCaseViewBrokerageFees: businessCaseParticipation,
    },
  });
  const orderTypes = uniq(
    props.checkoutInfo.orders
      .filter((order) => isNotNilOrEmpty(order.items))
      .map((order) => order.orderDiscriminator)
  );

  const checkoutActions = buildArray<Action>([]);
  // TODO - T20-39108 - SALE_VEHICLE_STATES - 12
  checkoutActions.when(
    orderTypes.includes(OrderDiscriminatorEnum.PURCHASE_BROKERAGE_SALE) &&
      !props.isUnsuccessfulBrokeragePurchase,
    {
      type: 'button',
      title: i18n.t('entity.checkout.labels.createCheckoutOffsettingOfLiabilitiesAndReceivables'),
      variant: 'secondary',
      onClick: toggleCreditSettlementDialog,
    }
  );

  const isDeductibleBrokerage = head(
    find(
      (order) => order.orderDiscriminator === OrderDiscriminatorEnum.PURCHASE_BROKERAGE_SALE,
      props.checkoutInfo.orders
    )?.items ?? []
  )?.deductible;

  return (
    <Main actions={checkoutActions}>
      <HStack justify="center" width="100%">
        <Box maxWidth={320} flexGrow={1} paddingTop={3}>
          <VStack spacing={4}>
            {props.alert}
            <VStack spacing={7} data-testid={suffixTestId('checkout', props)}>
              {orderTypes.map((orderType) => {
                // TODO - T20-39108 - SALE_VEHICLE_STATES - 12
                if (
                  props.isUnsuccessfulBrokeragePurchase &&
                  orderType === OrderDiscriminatorEnum.PURCHASE_BROKERAGE_SALE
                ) {
                  return null;
                }

                const orders = filter(
                  (order) => order.orderDiscriminator === orderType,
                  props.checkoutInfo.orders
                );

                const orderStatus = match<OrderDiscriminator, OrderStatus | undefined>(orderType)
                  .with('SALE', () => ({
                    icon: 'maps/directions_car',
                    text: i18n.t('entity.checkout.labels.sellVehicle'),
                  }))
                  .with('PURCHASE', () => ({
                    icon: 'maps/directions_car',
                    text: i18n.t('entity.checkout.labels.purchaseVehicle'),
                  }))
                  .with('PURCHASE_BROKERAGE_SALE', () => ({
                    icon: 'maps/directions_car',
                    text: i18n.t('entity.checkout.labels.purchaseVehicle'),
                  }))
                  .with('PURCHASE_BROKERAGE_FEES', () => ({
                    icon: 'editor/monetization_on',
                    text: i18n.t('entity.checkout.labels.brokerageFeesPayment'),
                  }))
                  .otherwise(always(undefined));

                return (
                  <Box key={orderType}>
                    <Show when={orderStatus}>
                      <Box paddingBottom={3}>
                        <HStack spacing={2} align="center">
                          <Icon value={orderStatus?.icon} />
                          <Heading data-testid="label-checkout.purchaseVehicle" size={2}>
                            {orderStatus?.text}
                          </Heading>
                        </HStack>
                      </Box>
                    </Show>
                    <CheckoutOrderCard
                      orders={orders}
                      orderType={orderType}
                      documentContextId={props.documentContextId}
                      documentContextTarget={props.documentContextTarget}
                      documentBulkContext={props.documentBulkContext}
                      isDeductibleBrokerage={isDeductibleBrokerage}
                      data-testid={suffixTestId('checkout', props)}
                      shouldHidePaymentTab={
                        !canViewBusinessCaseBrokerageFees &&
                        orderType === OrderDiscriminatorEnum.PURCHASE_BROKERAGE_FEES
                      }
                      {...props}
                    />
                  </Box>
                );
              })}
            </VStack>
          </VStack>
        </Box>
      </HStack>
      <Show when={orderTypes[0] === OrderDiscriminatorEnum.PURCHASE_BROKERAGE_SALE}>
        <CheckoutCreditSettlement
          checkoutId={props.checkoutInfo.id}
          orderId={props.checkoutInfo.orders[0]?.id}
          isOpen={isCreditSettlementDialogOpen}
          toggleOpen={toggleCreditSettlementDialog}
          data-testid={suffixTestId(
            `checkout-${convertStringToCamelCase(orderTypes[0])}-creditSettlement`,
            props
          )}
        />
      </Show>
    </Main>
  );
}
