import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {MyProfileTemplate} from '../../components/MyProfileTemplate/MyProfileTemplate';
import {PersonalInformationForm} from './components/PersonalInformationForm';

export function PersonalInformation() {
  return (
    <MyProfileTemplate
      data-testid={testIds.myProfile.personalInformation('header')}
      header={{
        title: i18n.t('entity.user.labels.personalInformation'),
        breadcrumbs: [
          {label: i18n.t('entity.user.labels.myProfile'), isCurrentPage: true},
          {label: i18n.t('entity.user.labels.personalInformation'), isCurrentPage: true},
        ],
      }}
    >
      <PersonalInformationForm />
    </MyProfileTemplate>
  );
}
