import {
  ButtonGroup,
  Card,
  DataStatus,
  FormButton,
  Form,
  FormField,
  FormSubmitHandler,
} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';

import {UseFormReturn} from 'react-hook-form';

import {isNotNil} from 'ramda-adjunct';

import {
  UserDashboardSettingsResponseBody,
  useGetUserDashboardSettingsQuery,
  usePutUserDashboardSettingsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {ProfileLayout} from '../../../components/ProfileLayout/ProfileLayout';

type DashboardSettingsFormType = UserDashboardSettingsResponseBody & {
  hasSalesFunnel: boolean;
};

export function DashboardSettings() {
  const {
    data: dashboardSettings,
    isLoading: isDashboardSettingsLoading,
    isError: isDashboardSettingsError,
  } = useGetUserDashboardSettingsQuery();

  const [updateUserDashboardSettings] = usePutUserDashboardSettingsMutation();

  let defaultValues: DashboardSettingsFormType | undefined = undefined;
  if (isNotNil(dashboardSettings)) {
    defaultValues = {
      hasSalesFunnel:
        dashboardSettings.salesFunnelInterests || dashboardSettings.salesFunnelBusinessCases,
      salesFunnelInterests: dashboardSettings.salesFunnelInterests,
      salesFunnelBusinessCases: dashboardSettings.salesFunnelBusinessCases,
      vehicleStock: dashboardSettings.vehicleStock,
      latestVehicles: dashboardSettings.latestVehicles,
      latestBusinessCases: dashboardSettings.latestBusinessCases,
      latestInterests: dashboardSettings.latestInterests,
      notifications: dashboardSettings.notifications,
      myTasks: dashboardSettings.myTasks,
    };
  }

  const onSubmit: FormSubmitHandler<DashboardSettingsFormType> = async (values) => {
    await updateUserDashboardSettings({
      salesFunnelInterests: values.salesFunnelInterests,
      salesFunnelBusinessCases: values.salesFunnelBusinessCases,
      vehicleStock: values.vehicleStock,
      latestVehicles: values.latestVehicles,
      latestBusinessCases: values.latestBusinessCases,
      latestInterests: values.latestInterests,
      notifications: values.notifications,
      myTasks: values.myTasks,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const onChangeHasSalesFunnel = (
    hasSalesFunnel: boolean,
    formApi: UseFormReturn<DashboardSettingsFormType>
  ) => {
    if (hasSalesFunnel) {
      formApi.setValue('salesFunnelInterests', true);
      formApi.setValue('salesFunnelBusinessCases', true);
    } else {
      formApi.setValue('salesFunnelInterests', false);
      formApi.setValue('salesFunnelBusinessCases', false);
    }
  };

  const onChangeSalesFunnelInterests = (
    salesFunnelInterests: boolean,
    formApi: UseFormReturn<DashboardSettingsFormType>
  ) => {
    const {salesFunnelBusinessCases} = formApi.getValues();

    if (!salesFunnelInterests && !salesFunnelBusinessCases) {
      formApi.setValue('hasSalesFunnel', false);
    }
  };

  const onChangeSalesFunnelBusinessCases = (
    salesFunnelBusinessCases: boolean,
    formApi: UseFormReturn<DashboardSettingsFormType>
  ) => {
    const {salesFunnelInterests} = formApi.getValues();

    if (!salesFunnelInterests && !salesFunnelBusinessCases) {
      formApi.setValue('hasSalesFunnel', false);
    }
  };

  return (
    <ProfileLayout data-testid={testIds.myProfile.dashboard('wrapper')}>
      <Card
        title={i18n.t('page.myProfile.dashboard.title')}
        data-testid={testIds.myProfile.dashboard('wrapper')}
      >
        <DataStatus isLoading={isDashboardSettingsLoading} isError={isDashboardSettingsError}>
          <Form<DashboardSettingsFormType> defaultValues={defaultValues} onSubmit={onSubmit}>
            {(control, formApi) => {
              const hasSalesFunnel = formApi.watch('hasSalesFunnel');

              return (
                <VStack spacing={4}>
                  <FormField
                    control={control}
                    name="hasSalesFunnel"
                    label={i18n.t('page.myProfile.dashboard.labels.opportunitiesFunnel')}
                    type="switch"
                    onChange={(value) => onChangeHasSalesFunnel(value, formApi)}
                  />

                  <HStack grow={1}>
                    <Box width={12} />
                    <FormField
                      control={control}
                      name="salesFunnelInterests"
                      label={i18n.t('page.myProfile.dashboard.labels.interests')}
                      type="switch"
                      isDisabled={!hasSalesFunnel}
                      onChange={(value) => onChangeSalesFunnelInterests(value, formApi)}
                    />
                  </HStack>
                  <HStack grow={1}>
                    <Box width={12} />
                    <FormField
                      control={control}
                      name="salesFunnelBusinessCases"
                      label={i18n.t('page.myProfile.dashboard.labels.businessCases')}
                      type="switch"
                      isDisabled={!hasSalesFunnel}
                      onChange={(value) => onChangeSalesFunnelBusinessCases(value, formApi)}
                    />
                  </HStack>
                  <FormField
                    control={control}
                    name="vehicleStock"
                    label={i18n.t('page.myProfile.dashboard.labels.vehicleStockOverview')}
                    type="switch"
                  />
                  <FormField
                    control={control}
                    name="latestVehicles"
                    label={i18n.t('page.myProfile.dashboard.labels.latestVehicles')}
                    type="switch"
                  />
                  <FormField
                    control={control}
                    name="latestBusinessCases"
                    label={i18n.t('page.myProfile.dashboard.labels.latestBusinessCases')}
                    type="switch"
                  />
                  <FormField
                    control={control}
                    name="latestInterests"
                    label={i18n.t('page.myProfile.dashboard.labels.latestInterests')}
                    type="switch"
                  />
                  {/* TOBE implemented later
                  <FormField
                    control={control}
                    name="hasRecentActivity"
                    label={i18n.t('page.myProfile.dashboard.labels.recentActivity')}
                    type="switch"
                  /> */}
                  <FormField
                    control={control}
                    name="notifications"
                    label={i18n.t('page.myProfile.dashboard.labels.notifications')}
                    type="switch"
                  />
                  <FormField
                    control={control}
                    name="myTasks"
                    label={i18n.t('page.myProfile.dashboard.labels.showMyTasks')}
                    type="switch"
                  />
                  <ButtonGroup align="right">
                    <FormButton
                      title={i18n.t('general.actions.save')}
                      type="submit"
                      control={control}
                    />
                  </ButtonGroup>
                </VStack>
              );
            }}
          </Form>
        </DataStatus>
      </Card>
    </ProfileLayout>
  );
}
