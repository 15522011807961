import {BaseFlagProps, openDeleteDialog, showNotification} from 'platform/components';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {always, defaultTo, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useDeleteServiceOrderReturnMutation,
  useGetServiceOrderReturnQuery,
  useGetUserQuery,
  useGetWarehouseHeaderDynamicActionsQuery,
  usePutWarehouseHeaderDynamicActionsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {
  DEFAULT_CURRENCY,
  DetailTemplate,
  DetailTemplateHeader,
  getUserName,
  handleApiError,
  NavigationItem,
} from '@omnetic-dms/shared';

import {
  composePath,
  EMPTY_PLACEHOLDER,
  generateHashFromObjects,
  Nullish,
  parseDate,
  useNavigate,
} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {createHeaderActions} from '../../utils/createHeaderActions';
import {Documents} from './(sections)/Documents/Documents';
import {Overview} from './(sections)/Overview/Overview';

export function ServiceOrderReturnsDetail() {
  const {serviceOrderReturnId} = useWarehouseParams();

  const navigate = useNavigate();

  const currencyFormatter = useFormatCurrency();

  const formatDate = useDateTimeFormatter();

  const {
    data: serviceOrderReturn,
    isLoading: isServiceOrderReturnLoading,
    isError: hasServiceOrderReturnError,
  } = useGetServiceOrderReturnQuery({serviceOrderReturnId});

  const {data: createdByUser, isLoading: isCreatedByUserLoading} = useGetUserQuery(
    {
      id: serviceOrderReturn?.serviceOrderReturn?.createdBy as string,
    },
    {skip: isNilOrEmpty(serviceOrderReturn?.serviceOrderReturn?.createdBy)}
  );

  const {data: completedByUser, isLoading: isCompletedByUserLoading} = useGetUserQuery(
    {
      id: serviceOrderReturn?.serviceOrderReturn?.updatedBy as string,
    },
    {
      skip:
        serviceOrderReturn?.serviceOrderReturn?.state !== 'COMPLETED' &&
        isNilOrEmpty(serviceOrderReturn?.serviceOrderReturn?.updatedBy),
    }
  );

  const {
    data: serviceOrderReturnActions,
    isLoading: isServiceOrderReturnActionsLoading,
    isError: hasServiceOrderReturnActionsError,
  } = useGetWarehouseHeaderDynamicActionsQuery(
    {id: serviceOrderReturnId, resource: 'service-order-return'},
    {skip: isNilOrEmpty(serviceOrderReturnId)}
  );

  const [putServiceOrderReturnAction, {isLoading: isPutServiceOrderReturnActionLoading}] =
    usePutWarehouseHeaderDynamicActionsMutation();

  const [deleteServiceOrderReturn] = useDeleteServiceOrderReturnMutation();

  const isLoading =
    isServiceOrderReturnLoading ||
    isCreatedByUserLoading ||
    isCompletedByUserLoading ||
    isServiceOrderReturnActionsLoading;

  const isError = hasServiceOrderReturnError || hasServiceOrderReturnActionsError;

  const serviceOrderReturnTitle = `${i18n.t('page.warehouse.labels.serviceOrderReturn')} ${defaultTo(
    '',
    serviceOrderReturn?.serviceOrderReturn?.number
  )}`;

  const priceWithVat = currencyFormatter(
    defaultTo(0, serviceOrderReturn?.sparePartsTotalPrice?.totalPurchasePrice?.withVat?.amount),
    defaultTo(
      DEFAULT_CURRENCY,
      serviceOrderReturn?.sparePartsTotalPrice?.totalPurchasePrice?.withVat?.currency
    ),
    2
  );

  const priceWithoutVat = currencyFormatter(
    defaultTo(0, serviceOrderReturn?.sparePartsTotalPrice?.totalPurchasePrice?.withoutVat?.amount),
    defaultTo(
      DEFAULT_CURRENCY,
      serviceOrderReturn?.sparePartsTotalPrice?.totalPurchasePrice?.withoutVat?.currency
    ),
    2
  );

  const formatToShortDate = (date: string | Nullish) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return formatDate('dateShort', parseDate(date));
  };

  const createdAt = `${i18n.t('general.labels.dateCreated')}: ${formatToShortDate(
    serviceOrderReturn?.serviceOrderReturn?.createdAt
  )}`;

  const createdBy = `${i18n.t('general.labels.createdBy')}: ${getUserName(createdByUser)}`;

  const completedAt = match(serviceOrderReturn?.serviceOrderReturn?.state)
    .returnType<string | null>()
    .with(
      'COMPLETED',
      always(
        `${i18n.t('general.labels.dateCompleted')}: ${formatToShortDate(
          serviceOrderReturn?.serviceOrderReturn?.updatedAt
        )}`
      )
    )
    .otherwise(always(null));

  const completedBy = match(serviceOrderReturn?.serviceOrderReturn?.state)
    .returnType<string | null>()
    .with(
      'COMPLETED',
      always(`${i18n.t('general.labels.completedBy')}: ${getUserName(completedByUser)}`)
    )
    .otherwise(always(null));

  const headerFlags = match(serviceOrderReturn?.serviceOrderReturn?.state)
    .returnType<Omit<BaseFlagProps, 'size'>[]>()
    .with('COMPLETED', always([{colorScheme: 'green', label: i18n.t('general.labels.completed')}]))
    .otherwise(always([{colorScheme: 'blue', label: i18n.t('general.labels.pending')}]));

  const handleActionClick = (actionKey: string) => {
    match(actionKey)
      .with('delete', () => {
        openDeleteDialog({
          text: i18n.t('entity.warehouse.actions.deleteEntity'),
          onConfirm: () =>
            deleteServiceOrderReturn({body: {serviceOrderReturnId: [serviceOrderReturnId]}})
              .unwrap()
              .then(() => showNotification.success())
              .then(() => navigate(warehouseRoutes.serviceOrderReturns))
              .catch(handleApiError),
        });
      })
      .otherwise(() => {
        putServiceOrderReturnAction({
          id: serviceOrderReturnId,
          resource: 'service-order-return',
          body: {actionKey, originEntityHeaderType: 'service-order-return'},
        })
          .unwrap()
          .then(() => showNotification.success())
          .catch(handleApiError);
      });
  };

  const headerActions = createHeaderActions({
    actions: serviceOrderReturnActions?.actions,
    callback: handleActionClick,
    isLoading: isPutServiceOrderReturnActionLoading,
  });

  const header: DetailTemplateHeader = {
    title: serviceOrderReturnTitle,
    icon: 'custom/warehouse',
    parameters: [
      defaultTo('', serviceOrderReturn?.serviceOrderReturn?.number),
      serviceOrderReturn?.serviceOrder?.variant,
      createdAt,
      createdBy,
      completedAt,
      completedBy,
    ],
    recordId: serviceOrderReturn?.serviceOrderReturn?.serviceOrderReturnId,
    resourceId: EntityResourceIds.serviceOrderReturn,
    controls: ['ASSIGNEE'],
    flags: headerFlags,
    actions: headerActions,
    primaryParameter: defaultTo(undefined, priceWithVat),
    secondaryParameter: `${priceWithoutVat} ${i18n.t('general.labels.w/oVat')}`,
  };

  const navigation: NavigationItem[] = [
    {
      id: 'serviceOrderReturn',
      label: i18n.t('page.warehouse.labels.overview'),
      href: composePath(warehouseRoutes.serviceOrderReturnsDetailOverview, {
        params: {id: serviceOrderReturnId},
      }),
      content: (
        <Overview
          serviceOrderReturn={serviceOrderReturn}
          isServiceOrderReturnLoading={isServiceOrderReturnLoading}
          data-testid={testIds.warehouse.serviceOrderReturnsDetailOverview('section.overview')}
        />
      ),
      'data-testid': testIds.warehouse.serviceOrderReturnsDetailOverview('navigation.overview'),
    },
    {
      id: 'serviceOrderReturnDocuments',
      label: i18n.t('page.warehouse.labels.documents'),
      href: composePath(warehouseRoutes.serviceOrderReturnsDetailDocuments, {
        params: {id: serviceOrderReturnId},
      }),
      content: (
        <Documents
          serviceOrderReturnId={serviceOrderReturnId}
          data-testid={testIds.warehouse.serviceOrderReturnsDetailOverview('section.documents')}
        />
      ),
      'data-testid': testIds.warehouse.serviceOrderReturnsDetailOverview('navigation.documents'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('page.warehouse.labels.serviceOrderReturn')} />
      <DetailTemplate
        key={generateHashFromObjects(serviceOrderReturn)}
        isLoading={isLoading}
        isError={isError}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.serviceOrderReturnsDetailOverview('page')}
      />
    </>
  );
}
