import {DataStatus} from 'platform/components';
import {Hide, VStack} from 'platform/foundation';
import {Nullish} from 'utility-types';

import {useState} from 'react';

import {descend, isEmpty, isNotNil} from 'ramda';

import {
  PartialVehiclePhotosRoutes,
  useLazyGetAuditDataQuery,
  useLazyGetMetadaServiceCaseQuery,
  useLazyGetServiceAlbumListQuery,
  useLazyGetServiceAlbumPhotoListQuery,
  useLazyGetServiceCheckInQuery,
  useLazyGetServiceHandOverQuery,
} from '@omnetic-dms/api';

import {useOnMount} from 'shared';

import {useBatchDownload} from '../../hooks/useBatchDownload';
import {getGridPhotosFromAudit, getGridServiceAlbumPhotos} from '../../utils/getGridPhotos';
import {DownloadDialog} from './components/DownloadDialog';
import {
  ServiceCasePhotosCard,
  ServiceCasePhotosCardProps,
} from './components/ServiceCasePhotosCard';

interface ServiceCasePhotosProps {
  vehicleId: string;
  ignoredServiceCaseId?: string | Nullish;
  hideWhenEmpty?: boolean;
}

export function ServiceCasePhotos(props: ServiceCasePhotosProps) {
  const [serviceCaseCards, setServiceCaseCards] = useState<ServiceCasePhotosCardProps[]>([]);
  const [isPhotosLoading, setIsPhotosLoading] = useState(true);
  const [isPhotosError, setIsPhotosError] = useState(false);

  const {handleDownload, eventData, isLoading, isStatePending, handleServiceAlbumDownload} =
    useBatchDownload();

  const [lazyGetServiceAlbumList] = useLazyGetServiceAlbumListQuery();

  const [lazyGetServiceAlbumPhotoList] = useLazyGetServiceAlbumPhotoListQuery();

  const [lazyGetMetadaServiceCase] = useLazyGetMetadaServiceCaseQuery();
  const [lazyGetServiceCheckin] = useLazyGetServiceCheckInQuery();
  const [lazyGetServiceHandover] = useLazyGetServiceHandOverQuery();
  const [lazyGetAuditData] = useLazyGetAuditDataQuery();

  const handleBatchDownload = (albumId: string) =>
    handleServiceAlbumDownload({
      vehicleId: props.vehicleId,
      serviceAlbumId: albumId,
    });

  const handleInspectionGalleryBatchDownload = (auditId?: string) =>
    handleDownload({
      vehiclePhotoLocation: PartialVehiclePhotosRoutes.inspections,
      vehicleId: props.vehicleId,
      auditId,
    });

  useOnMount(() => {
    (async () => {
      if (!props.vehicleId) {
        return;
      }

      const serviceCaseAlbums = await lazyGetServiceAlbumList({
        vehicleId: props.vehicleId,
      }).unwrap();

      const albums = serviceCaseAlbums
        ?.filter((item) => item.serviceCaseId !== props.ignoredServiceCaseId)
        .toSorted(descend((album) => album.createdAt));

      const promises = albums?.map(async (album) => {
        if (!album.serviceCaseId) {
          return;
        }

        const serviceCase = await lazyGetMetadaServiceCase({
          serviceCaseId: album.serviceCaseId,
        }).unwrap();

        const checkin = await lazyGetServiceCheckin({
          serviceCaseId: album.serviceCaseId,
        }).unwrap();

        const handover = await lazyGetServiceHandover({
          serviceCaseId: album.serviceCaseId,
        }).unwrap();

        const checkinAudit = checkin?.inspectionId?.[0]
          ? await lazyGetAuditData({auditId: checkin.inspectionId?.[0]}).unwrap()
          : null;

        const handoverAudit = handover?.inspectionId?.[0]
          ? await lazyGetAuditData({
              auditId: handover.inspectionId?.[0],
            }).unwrap()
          : null;

        const photos = await lazyGetServiceAlbumPhotoList({
          vehicleId: props.vehicleId,
          serviceAlbumId: album.id,
        }).unwrap();

        const serviceCasePhotos = getGridServiceAlbumPhotos(photos);
        const checkinPhotos = getGridPhotosFromAudit(checkinAudit?.[0]?.categories);
        const handoverPhotos = getGridPhotosFromAudit(handoverAudit?.[0]?.categories);

        return isEmpty(serviceCasePhotos) && isEmpty(checkinPhotos) && isEmpty(handoverPhotos)
          ? null
          : {
              serviceCase,
              serviceCasePhotos,
              checkinPhotos,
              handoverPhotos,
              onDownloadAll: () => handleBatchDownload(album.id),
              onDownloadCheckin: () => handleInspectionGalleryBatchDownload(checkinAudit?.[0]?.id),
              onDownloadHandover: () =>
                handleInspectionGalleryBatchDownload(handoverAudit?.[0]?.id),
            };
      });

      Promise.allSettled(promises ?? [])
        .then((data) => {
          const cards = data.reduce<ServiceCasePhotosCardProps[]>((acc, card) => {
            if (card.status === 'fulfilled' && isNotNil(card.value)) {
              return [...acc, card.value];
            }
            return acc;
          }, []);

          setServiceCaseCards(cards);
          setIsPhotosLoading(false);
        })
        .catch(() => {
          setIsPhotosError(true);
        });
    })();
  });

  return (
    <Hide when={props.hideWhenEmpty && !isPhotosLoading && isEmpty(serviceCaseCards)}>
      <DataStatus
        isLoading={isPhotosLoading}
        isError={isPhotosError}
        isEmpty={isEmpty(serviceCaseCards)}
      >
        <VStack spacing={4}>
          {serviceCaseCards.map((card) => (
            <ServiceCasePhotosCard key={card.serviceCase?.id} {...card} />
          ))}
        </VStack>
      </DataStatus>
      <DownloadDialog
        withWrapper
        isOpen={isLoading || isStatePending}
        fileName={eventData?.data?.bulkName}
        fileCount={eventData?.data?.objects.length}
      />
    </Hide>
  );
}
