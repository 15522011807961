import {DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {AdditionalCustomers} from './components/AdditionalCustomers';
import {AllowedCustomerSelector} from './components/AllowedCustomerSelector';
import {BillingInformationList} from './components/BillingInformationList';
import {useBillingInformation} from './hooks/useBillingInformation';

interface AftersalesCheckoutBillingInformationProps extends RequiredTestIdProps {
  checkoutId: string;
  recordId?: string;
  resourceId?: string;
}

export function AftersalesCheckoutBillingInformation(
  props: AftersalesCheckoutBillingInformationProps
) {
  const {billingInformation, isLoading, isError, handleChangeContractInformation} =
    useBillingInformation(props.checkoutId);

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      {billingInformation && (
        <VStack spacing={4}>
          <AllowedCustomerSelector
            checkoutId={props.checkoutId}
            selectedRecipient={billingInformation.billingRecipient}
            data-testid={suffixTestId('allowedCustomer', props)}
          />
          <BillingInformationList
            customerId={billingInformation.billingInformation.customerInformation.id}
            checkoutId={props.checkoutId}
            recordId={props.recordId}
            resourceId={props.resourceId}
            onAddContract={handleChangeContractInformation}
            selectedContractId={billingInformation.billingInformation.contractInformation?.id}
            data-testid={suffixTestId('billingList', props)}
          />
          <AdditionalCustomers
            checkoutId={props.checkoutId}
            recordId={props.recordId}
            resourceId={props.resourceId}
            data-testid={suffixTestId('additionalCustomer', props)}
          />
        </VStack>
      )}
    </DataStatus>
  );
}
