import {showNotification, Upload, UploadState, useTranslationContext} from 'platform/components';
import {Box} from 'platform/foundation';
import {RcFile} from 'rc-upload/lib/interface';

import {CSSProperties, useState} from 'react';

import {randomLowerCaseUUID, suffixTestId, TestIdProps} from 'shared';

import {QueueVideoTask, RawFile, useVideoUploadQueueAction} from 'features/video-upload-queue';

import {useConditionContext} from '../hooks/useConditionContext';
import {PendingVideo} from '../types/PendingVideo';
import {isFileVideo} from '../utils/isFileVideo';

interface UploadVideoProps extends TestIdProps {
  ratio: CSSProperties['aspectRatio'];
  isMandatory?: boolean;
  onVideoUploadTriggered: () => void;
  onVideoUploadStarted: () => void;
  onVideoUploadFinished: (video: PendingVideo) => void;
}

export function UploadVideo(props: UploadVideoProps) {
  const [uploadState, setUploadState] = useState<UploadState>(UploadState.Idle);
  const {handleMultiMultiPartUpload} = useVideoUploadQueueAction();
  const {isDisabledForUser} = useConditionContext();
  const t = useTranslationContext();

  const handleStartUpload = async (file: RcFile) => {
    if (!isFileVideo(file)) {
      showNotification.error(t('entity.inspection.errors.invalidFileType'));
      return;
    }

    setUploadState(UploadState.Uploading);
    props.onVideoUploadTriggered();

    const rawFile: RawFile = {
      id: randomLowerCaseUUID(),
      uri: URL.createObjectURL(file),
      name: file.name,
      size: file.size,
      mimeType: file.type,
      connectionFileId: null,
    };

    await handleMultiMultiPartUpload(rawFile, handleUploadFinished);
    setUploadState(UploadState.Success);
    props.onVideoUploadStarted();
  };

  const handleUploadFinished = (params: QueueVideoTask) => {
    const pendingVideo = {
      videoId: params.connectionFileId,
      vehicleAuditVideoAssetId: params.id,
      updatedAt: new Date().toISOString(),
      filename: params.name,
    };

    props.onVideoUploadFinished(pendingVideo);
  };

  return (
    <Box width="100%" height="100%" ratio={props.ratio} position="relative" overflow="hidden">
      <Upload
        size="default"
        accept="video/*"
        type="card"
        uploadIcon="image/video_call"
        uploadText={`${props.isMandatory ? '*' : ''} ${t('entity.video.labels.add')}`}
        errorIcon="navigation/cancel"
        customRequest={({onSuccess}) => {
          onSuccess?.('', new XMLHttpRequest());
        }}
        onStart={handleStartUpload}
        uploadState={uploadState}
        isMultiple
        isDisabled={isDisabledForUser}
        data-testid={suffixTestId('uploadControl', props)}
      />
    </Box>
  );
}
