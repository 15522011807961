import {Box} from 'platform/foundation';

import {useState} from 'react';

import {useOnMount} from 'shared';

import {Avatar} from '../../Avatar/Avatar';
import {DataStatus} from '../../DataStatus/DataStatus';
import {NotificationItemData} from '../NotificationItemData';

type AvatarState =
  | {status: 'loading'}
  | {status: 'idle'}
  | {status: 'loaded'; url: string | undefined}
  | {status: 'error'};

export function NotificationAvatar(props: NotificationItemData) {
  const [avatarState, setAvatarState] = useState<AvatarState>({status: 'idle'});

  useOnMount(() => {
    if (!props.getAvatarUrl) {
      return;
    }

    setAvatarState({status: 'loading'});

    props
      .getAvatarUrl()
      .then((url) => setAvatarState({status: 'loaded', url}))
      .catch(() => setAvatarState({status: 'error'}));
  });

  return (
    <Box height={10} width={10}>
      <DataStatus isLoading={avatarState.status === 'loading'}>
        <Avatar
          icon={props.icon}
          name={props.userName}
          imageUrl={avatarState.status === 'loaded' ? avatarState.url : undefined}
        />
      </DataStatus>
    </Box>
  );
}
