import {Button, Dropdown} from 'platform/components';
import {Box} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {suffixTestId} from 'shared';

import {PredefinedNotesDropdownBody} from './PredefinedNotesDropdownBody';
import {PredefinedNotesProps} from './types/PredefinedNotesProps';

export function PredefinedNotes(props: PredefinedNotesProps) {
  return (
    <Box>
      <Dropdown
        dropdownControl={
          <Button
            title={i18n.t('entity.vehicle.labels.templates')}
            variant={props.isLinkVariant ? 'link' : 'ghostLink'}
            leftIcon="content/add_circle"
            size="small"
            data-testid={suffixTestId('templates', props)}
          />
        }
        data-testid={suffixTestId('predefinedNotes', props)}
      >
        <PredefinedNotesDropdownBody {...props} />
      </Dropdown>
    </Box>
  );
}
