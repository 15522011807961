import {isTrue} from 'ramda-adjunct';

import {MinisaleResponseBody} from '@omnetic-dms/api';

import {Nullish} from 'shared';

import {BuyingFormType} from '../types/BuyingFormType';

export const transformMinisaleToBuyingForm = (
  minisale: MinisaleResponseBody | Nullish,
  defaultCurrency: string
): BuyingFormType => ({
  id: minisale?.id ?? null,
  customerBuyingStatement: minisale?.customerBuyingStatement ?? false,
  createdBy: minisale?.createdBy ?? null,
  createdAt: minisale?.createdAt ?? null,
  priceRequested: minisale?.priceRequested ?? {
    amount: '0',
    currency: defaultCurrency,
  },
  priceOfferedBase: minisale?.priceOfferedBase ?? {
    amount: '0',
    currency: defaultCurrency,
  },
  priceOfferedWithVat: minisale?.priceOfferedWithVat ?? {
    amount: '0',
    currency: defaultCurrency,
  },
  priceOfferedWithoutVat: minisale?.priceOfferedWithoutVat ?? {
    amount: '0',
    currency: defaultCurrency,
  },
  expectedCosts: minisale?.expectedCosts ?? {
    amount: '0',
    currency: defaultCurrency,
  },
  reason: minisale?.reason ?? null,
  costReason: minisale?.costReason ?? null,
  interestRate: minisale?.interestRate ?? null,
  isVatDeductible: isTrue(minisale?.isVatDeductible) ? ['true'] : ['false'],
  vehicleData: {
    type: minisale?.vehicleData?.type ? [minisale.vehicleData.type] : ['VEHICLETYPE_PASSENGER_CAR'],
    vin: minisale?.vehicleData?.vin ?? null,
    make: minisale?.vehicleData?.make ?? null,
    note: minisale?.vehicleData?.note ?? null,
    modelFamily: minisale?.vehicleData?.modelFamily ?? null,
    trim: minisale?.vehicleData?.trim ?? null,
    variant: minisale?.vehicleData?.variant ?? null,
    fuelType: minisale?.vehicleData?.fuelType ? [minisale.vehicleData.fuelType] : null,
    secondaryFuelType: minisale?.vehicleData?.secondaryFuelType
      ? [minisale?.vehicleData?.secondaryFuelType]
      : null,
    transmission: minisale?.vehicleData?.transmission ? [minisale.vehicleData.transmission] : null,
    bodyStyle: minisale?.vehicleData?.bodyStyle ? [minisale.vehicleData.bodyStyle] : null,
    drive: minisale?.vehicleData?.drive ? [minisale.vehicleData.drive] : null,
    firstRegistration: minisale?.vehicleData?.firstRegistration ?? null,
    realProductionYear: minisale?.vehicleData?.realProductionYear ?? null,
    mileage: minisale?.vehicleData?.mileage ?? null,
    licencePlate: minisale?.vehicleData?.licencePlate ?? null,
    features: minisale?.vehicleData?.features ?? [],
    power: minisale?.vehicleData?.power ?? null,
    engineVolume: minisale?.vehicleData?.engineVolume ?? null,
    maximalPower: minisale?.vehicleData?.maximalPower ?? null,
  },
  vatType: minisale?.vatType ?? 'S',
  vatDirection: minisale?.vatDirection ?? null,
  updatedBy: minisale?.updatedBy ?? null,
  pricedBy: minisale?.pricedBy ?? null,
  updatedAt: minisale?.updatedAt ?? null,
  pricedAt: minisale?.pricedAt ?? null,
});
