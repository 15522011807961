import {z} from 'zod';

import {BaseVoidResponseSchema} from './base';

export const PostIntegrationsForXentryLinkApiResponseSchema = BaseVoidResponseSchema;
export type PostIntegrationsForXentryLinkApiResponse = z.infer<
  typeof PostIntegrationsForXentryLinkApiResponseSchema
>;

export const PostIntegrationsForXentryLinkApiArgSchema = z.object({
  serviceCaseId: z.string(),
  body: z
    .object({
      xentryOrderId: z.string(),
    })
    .nullable(),
});

export type PostIntegrationsForXentryLinkApiArg = z.infer<
  typeof PostIntegrationsForXentryLinkApiArgSchema
>;

export const PostIntegrationsForXentryUnlinkApiResponseSchema = BaseVoidResponseSchema;
export type PostIntegrationsForXentryUnlinkApiResponse = z.infer<
  typeof PostIntegrationsForXentryUnlinkApiResponseSchema
>;

export const PostIntegrationsForXentryUnlinkApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type PostIntegrationsForXentryUnlinkApiArg = z.infer<
  typeof PostIntegrationsForXentryUnlinkApiArgSchema
>;

export const XentryJobSchema = z.object({
  id: z.string().nullish(),
  createdAt: z.string().nullish(),
  url: z.string().nullish(),
  isCurrentlyLinked: z.boolean().nullish(),
  linkedAt: z.string().nullish(),
});

export type XentryJob = z.infer<typeof XentryJobSchema>;

export const PostAvailableXentryOrdersApiResponseSchema = z
  .object({
    xentryJob: z.array(XentryJobSchema).optional(),
  })
  .nullable();

export type PostAvailableXentryOrdersApiResponse = z.infer<
  typeof PostAvailableXentryOrdersApiResponseSchema
>;

export const PostAvailableXentryOrdersApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type PostAvailableXentryOrdersApiArg = z.infer<typeof PostAvailableXentryOrdersApiArgSchema>;

const IntegrationActionSchema = z.object({
  key: z.string().nullish(),
  style: z.string().nullish(),
  position: z.number().nullish(),
});

const IntegrationSchema = z.object({
  code: z.string().nullish(),
  serviceCode: z.string().nullish(),
  viewDetailsLink: z.string().nullish(),
  createdAt: z.string().nullish(),
  updatedAt: z.string().nullish(),
  featureCode: z.string().nullish(),
  externalId: z.string().nullish(),
});

export type XentryIntegrationAction = z.infer<typeof IntegrationActionSchema>;

export const GetServiceCaseIntegrationsApiResponseSchema = z
  .object({
    caseIntegration: z
      .array(
        z
          .object({
            integration: IntegrationSchema.nullish(),
            action: z.array(IntegrationActionSchema.nullable()).optional(),
            position: z.number().nullish(),
          })
          .nullable()
      )
      .optional(),
  })
  .nullable();

export type GetServiceCaseIntegrationsApiResponse = z.infer<
  typeof GetServiceCaseIntegrationsApiResponseSchema
>;

export const GetServiceCaseIntegrationsApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type GetServiceCaseIntegrationsApiArg = z.infer<
  typeof GetServiceCaseIntegrationsApiArgSchema
>;

export const PostServiceOrderAudatexTaskCalculationsApiResponseSchema = BaseVoidResponseSchema;
export type PostServiceOrderAudatexTaskCalculationsApiResponse = z.infer<
  typeof PostServiceOrderAudatexTaskCalculationsApiResponseSchema
>;

export const PostServiceOrderAudatexTaskCalculationsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: z.object({
    caseId: z.string(),
    taskId: z.string(),
  }),
});

export type PostServiceOrderAudatexTaskCalculationsApiArg = z.infer<
  typeof PostServiceOrderAudatexTaskCalculationsApiArgSchema
>;

export const PostServiceOrderMyClaimCalculationsApiResponseSchema = BaseVoidResponseSchema;
export type PostServiceOrderMyClaimCalculationsApiResponse = z.infer<
  typeof PostServiceOrderMyClaimCalculationsApiResponseSchema
>;

export const PostServiceOrderMyClaimCalculationsApiArgSchema = z.object({
  serviceCaseId: z.string(),
  serviceOrderId: z.string(),
  body: z.object({
    myClaimId: z.string(),
  }),
});

export type PostServiceOrderMyClaimCalculationsApiArg = z.infer<
  typeof PostServiceOrderMyClaimCalculationsApiArgSchema
>;
export const PostServiceCaseIntegrationsXentryOrderManualCreationApiResponseSchema =
  BaseVoidResponseSchema;
export type PostServiceCaseIntegrationsXentryOrderManualCreationApiResponse = z.infer<
  typeof PostServiceCaseIntegrationsXentryOrderManualCreationApiResponseSchema
>;
export const PostServiceCaseIntegrationsXentryOrderManualCreationApiArgSchema = z.object({
  serviceCaseId: z.string(),
});
export type PostServiceCaseIntegrationsXentryOrderManualCreationApiArg = z.infer<
  typeof PostServiceCaseIntegrationsXentryOrderManualCreationApiArgSchema
>;

const ServiceCaseIntegrationsXentryOrderSynchronizationGroupSchema = z.object({
  type: z.string().nullable(),
  name: z.string().nullable().optional(),
  selectedSource: z.string().nullable().optional(),
  isVisibleForUser: z.boolean().nullable().optional(),
  data: z
    .array(
      z
        .object({
          labelName: z.string().nullable(),
          valueOmnetic: z.string().nullable().optional(),
          valueXentry: z.string().nullable().optional(),
          valueType: z.string().nullable().optional(),
          unit: z.string().nullable().optional(),
        })
        .nullable()
    )
    .optional(),
  groups: z
    .array(
      z
        .object({
          type: z.string().nullable(),
          name: z.string().nullable().optional(),
          selectedSource: z.string().nullable().optional(),
          isVisibleForUser: z.boolean().nullable().optional(),
          data: z
            .array(
              z
                .object({
                  labelName: z.string().nullable(),
                  valueOmnetic: z.string().nullable().optional(),
                  valueXentry: z.string().nullable().optional(),
                  valueType: z.string().nullable().optional(),
                  unit: z.string().nullable().optional(),
                })
                .nullable()
            )
            .optional(),
          groups: z
            .array(
              z
                .object({
                  type: z.string().nullable().optional(),
                  name: z.string().nullable().optional(),
                  selectedSource: z.string().nullable().optional(),
                  isVisibleForUser: z.boolean().nullable().optional(),
                  data: z
                    .array(
                      z
                        .object({
                          labelName: z.string().nullable(),
                          valueOmnetic: z.string().nullable().optional(),
                          valueXentry: z.string().nullable().optional(),
                          valueType: z.string().nullable().optional(),
                          unit: z.string().nullable().optional(),
                        })
                        .nullable()
                    )
                    .optional(),
                })
                .nullable()
            )
            .optional(),
        })
        .nullable()
    )
    .optional(),
});

export type ServiceCaseIntegrationsXentryOrderSynchronizationGroup = z.infer<
  typeof ServiceCaseIntegrationsXentryOrderSynchronizationGroupSchema
>;

export const GetServiceCaseIntegrationsXentryOrderSynchronizationApiResponseSchema = z.union([
  z
    .object({
      groups: z
        .array(ServiceCaseIntegrationsXentryOrderSynchronizationGroupSchema.nullable())
        .optional(),
    })
    .nullable(),
  z.void(),
]);

export type GetServiceCaseIntegrationsXentryOrderSynchronizationApiResponse = z.infer<
  typeof GetServiceCaseIntegrationsXentryOrderSynchronizationApiResponseSchema
>;

export const GetServiceCaseIntegrationsXentryOrderSynchronizationApiArgSchema = z.object({
  serviceCaseId: z.string(),
});

export type GetServiceCaseIntegrationsXentryOrderSynchronizationApiArg = z.infer<
  typeof GetServiceCaseIntegrationsXentryOrderSynchronizationApiArgSchema
>;

export const PostServiceCaseIntegrationsXentryOrderSynchronizationApiResponseSchema = z.void();

export type PostServiceCaseIntegrationsXentryOrderSynchronizationApiResponse = z.infer<
  typeof PostServiceCaseIntegrationsXentryOrderSynchronizationApiResponseSchema
>;

export const PostServiceCaseIntegrationsXentryOrderSynchronizationApiArgSchema = z.object({
  serviceCaseId: z.string(),
  body: z
    .object({
      groups: z
        .array(
          z
            .object({
              type: z.string().nullable(),
              id: z.string().nullable().optional(),
              selectedSource: z.string(),
            })
            .nullable()
        )
        .optional(),
    })
    .nullable(),
});

export type PostServiceCaseIntegrationsXentryOrderSynchronizationApiArg = z.infer<
  typeof PostServiceCaseIntegrationsXentryOrderSynchronizationApiArgSchema
>;
