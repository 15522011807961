import {addDays, compareAsc, subDays} from 'date-fns';
import {
  Button,
  Card,
  Choice,
  FormButton,
  FormControl,
  FormField,
  Label,
  openConfirmDialog,
  Tooltip,
} from 'platform/components';
import {Grid, GridItem, HStack, Heading, Right, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {ReactNode, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {head, isNil, isNotNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {GetServiceOrderVariantPaymentTypeApiResponse, useGetTenantQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {CurrencyCodeType, Nullish, RequiredTestIdProps, suffixTestId, useBoolean} from 'shared';

import {useBank} from '../../hooks/useBank';
import {useCashRegister} from '../../hooks/useCashRegister';
import {CheckoutPaymentFormType} from '../../types/CheckoutPaymentFormType';
import {getOrderPaymentOptions} from '../../utils/getOrderPaymentOptions';
import {PredefinedNotes} from '../PredefinedNotes/PredefinedNotes';
import {ForeignCurrencyPayment} from './ForeignCurrencyPayment';

interface CheckoutPaymentFormBodyProps extends RequiredTestIdProps {
  control: FormControl<CheckoutPaymentFormType>;
  formApi: UseFormReturn<CheckoutPaymentFormType>;
  orderVariantPaymentType: GetServiceOrderVariantPaymentTypeApiResponse | Nullish;
  isSubmitButtonDisabled: boolean;
  isIssueButtonDisabled?: boolean;
  issueButtonTooltip?: ReactNode;
  onSaveChanges: (data: CheckoutPaymentFormType) => Promise<unknown>;
}

export function CheckoutPaymentFormBody(props: CheckoutPaymentFormBodyProps) {
  const {control, formApi} = props;

  const [oldPaymentMethod, setOldPaymentMethod] = useState<string | Nullish>(
    formApi.getValues('paymentMethod')
  );

  const {bankAccountOptions} = useBank();
  const {cashRegisterOptions} = useCashRegister();
  const {data} = useGetTenantQuery();
  const formatCurrency = useFormatCurrency();

  const [isSaveLoading, startSaveLoading, stopSaveLoading] = useBoolean();

  const [amount, exchangeRate, ratio, currency, issuedOnDate, paymentMethod, note] = formApi.watch([
    'amount',
    'exchangeRate',
    'ratio',
    'currency',
    'issuedOn',
    'paymentMethod',
    'note',
  ]);

  const minTaxableEventDate = issuedOnDate ? subDays(issuedOnDate, 15) : new Date();
  const maxTaxableEventDate = issuedOnDate ? issuedOnDate : new Date();

  const currencyData = data?.currency as CurrencyCodeType | undefined;

  const foreignAmount = isNotNil(exchangeRate) ? (amount / exchangeRate) * ratio : 0;

  const afterPaymentChange = (newValue: string | Nullish) => {
    const issuedOn = formApi.getValues('issuedOn');

    if (newValue === 'BANK_TRANSFER' && isNotNil(issuedOn)) {
      const DEFAULT_DUE_DATE = 14;

      formApi.setValue('due', DEFAULT_DUE_DATE, {shouldValidate: true});
      formApi.setValue('dueDate', addDays(issuedOn, DEFAULT_DUE_DATE), {shouldValidate: true});

      if (isNilOrEmpty(formApi.getValues('bankAccount'))) {
        formApi.setValue('bankAccount', head(bankAccountOptions)?.value ?? '', {
          shouldValidate: true,
        });
      }

      return;
    }

    if (newValue === 'CASH' && isNilOrEmpty(formApi.getValues('cashRegisterId'))) {
      formApi.setValue('cashRegisterId', head(cashRegisterOptions)?.value ?? '', {
        shouldValidate: true,
      });
    }

    if (isNotNil(issuedOn)) {
      formApi.setValue('due', 0, {shouldValidate: true});
      formApi.setValue('dueDate', formApi.getValues('issuedOn'), {shouldValidate: true});
    }
  };

  const handlePaymentMethodChange = (newValue: string | Nullish) => {
    if (isNil(newValue)) {
      return;
    }

    if (newValue === 'INTERNAL') {
      openConfirmDialog({
        onConfirm: () => {
          formApi.setValue('paymentMethod', 'INTERNAL');
          afterPaymentChange('INTERNAL');
          handleSaveChanges();
          setOldPaymentMethod(newValue);
        },
        text: i18n.t('entity.checkout.labels.freeOfChargeHelperText'),
      });
      return;
    }

    if (oldPaymentMethod === 'INTERNAL') {
      openConfirmDialog({
        onConfirm: () => {
          formApi.setValue('paymentMethod', newValue);
          afterPaymentChange(newValue);
          handleSaveChanges();
          setOldPaymentMethod(newValue);
        },
        text: i18n.t('entity.invoice.notifications.freeOfChargeChangeHelperText'),
      });
      return;
    }

    setOldPaymentMethod(newValue);
    formApi.setValue('paymentMethod', newValue);
    afterPaymentChange(newValue);
  };

  const handleIssuedOnChange = () => (issueDate: Date | Nullish) => {
    if (isNil(issueDate)) {
      return;
    }

    const due = formApi.getValues('due') ?? 0;
    formApi.setValue('dueDate', addDays(issueDate, due), {shouldValidate: true});

    const taxableEventDate = formApi.getValues('taxableEventDate');

    const minTaxableEventDate = subDays(issueDate, 15);
    const maxTaxableEventDate = issueDate;

    if (!taxableEventDate) {
      return formApi.setValue('taxableEventDate', issueDate, {shouldValidate: true});
    }

    if (compareAsc(taxableEventDate, minTaxableEventDate) < 0) {
      return formApi.setValue('taxableEventDate', minTaxableEventDate, {shouldValidate: true});
    }

    if (compareAsc(taxableEventDate, maxTaxableEventDate) > 0) {
      return formApi.setValue('taxableEventDate', maxTaxableEventDate, {shouldValidate: true});
    }
  };

  const handleDueChange = () => (due: number | Nullish) => {
    if (isNil(due)) {
      return;
    }

    const issuedOn = formApi.getValues('issuedOn');

    if (isNotNil(issuedOn)) {
      formApi.setValue('dueDate', addDays(issuedOn, due), {shouldValidate: true});
    }
  };

  const handleDueDateChange = () => (date: Date | Nullish) => {
    if (isNil(date)) {
      return;
    }

    const due = formApi.getValues('due') ?? 0;
    formApi.setValue('issuedOn', subDays(date, due), {shouldValidate: true});
  };

  const handleSaveChanges = () => {
    startSaveLoading();
    props.onSaveChanges(formApi.getValues()).finally(stopSaveLoading);
  };

  return (
    <VStack spacing={4}>
      <ForeignCurrencyPayment
        control={props.control}
        formApi={props.formApi}
        data-testid={suffixTestId('foreginPayment', props)}
      />
      <Card variant="inlineGrey">
        <VStack spacing={4}>
          <Heading size={4}>{i18n.t('entity.checkout.labels.totalPayment')}</Heading>
          <Grid spacing={4} columns={4}>
            <FormField
              control={control}
              name="amount"
              type="currency"
              currency={currencyData}
              label={i18n.t('general.labels.amount')}
              isDisabled
              isRequired
              data-testid={suffixTestId('amount', props)}
            />
            <Show when={isNotNil(currency)}>
              <FormField
                control={control}
                name="exchangeRate"
                type="currency"
                currency={currencyData}
                label={i18n.t('general.labels.exchangeRate')}
                data-testid={suffixTestId('exchangeRate', props)}
                isRequired
              />
              <VStack spacing={2}>
                <Text color="secondary" size="xSmall">
                  {i18n.t('entity.checkout.labels.amountInForeignCurrency')}
                </Text>
                <Text alternative>{formatCurrency(foreignAmount, currency || '', 2)}</Text>
              </VStack>
            </Show>
          </Grid>
          <Grid spacing={4} columns={4}>
            <FormField
              control={control}
              name="issuedOn"
              type="date"
              isRequired
              isRelativeDatesHidden
              label={i18n.t('entity.checkout.labels.issuedOn')}
              onChange={handleIssuedOnChange()}
              data-testid={suffixTestId('issuedOn', props)}
            />
            <FormField
              control={control}
              name="due"
              type="number"
              label={i18n.t('entity.checkout.labels.due')}
              onChange={handleDueChange()}
              data-testid={suffixTestId('issuedOn', props)}
              minStepperValue={0}
              isStepperVisible
              isRequired
            />
            <FormField
              control={control}
              name="dueDate"
              type="date"
              isRelativeDatesHidden
              label={i18n.t('entity.checkout.labels.dueDate')}
              onChange={handleDueDateChange()}
              data-testid={suffixTestId('issuedOn', props)}
              isRequired
            />
            <FormField
              control={control}
              name="taxableEventDate"
              type="date"
              isRelativeDatesHidden
              label={i18n.t('entity.checkout.labels.taxableEventDate')}
              data-testid={suffixTestId('taxableEventDate', props)}
              maxDate={maxTaxableEventDate}
              minDate={minTaxableEventDate}
              isRequired
            />
            <GridItem span={2}>
              <Choice
                name="paymentMethod"
                value={formApi.getValues('paymentMethod')}
                label={i18n.t('entity.checkout.labels.paymentMethod')}
                options={getOrderPaymentOptions(props.orderVariantPaymentType)}
                onChange={(value) => handlePaymentMethodChange(value)}
                isNotClearable
                data-testid={suffixTestId('paymentMethod', props)}
              />
            </GridItem>
            <GridItem span={2}>
              <Show when={paymentMethod === 'BANK_TRANSFER'}>
                <FormField
                  control={control}
                  name="bankAccount"
                  type="choice"
                  options={bankAccountOptions}
                  label={i18n.t('entity.checkout.labels.incomingBankAccount')}
                  isRequired
                  isNotClearable
                  data-testid={suffixTestId('incomingBankAccount', props)}
                />
              </Show>
              <Show when={paymentMethod === 'CASH'}>
                <FormField
                  control={control}
                  name="cashRegisterId"
                  type="choice"
                  options={cashRegisterOptions}
                  label={i18n.t('entity.checkout.labels.cashRegister')}
                  isNotClearable
                  data-testid={suffixTestId('incomingBankAccount', props)}
                />
              </Show>
            </GridItem>
          </Grid>
          <VStack spacing={1}>
            <HStack justify="space-between" align="flex-end">
              <Label>{i18n.t('general.labels.note')}</Label>
              <PredefinedNotes
                note={note ?? null}
                onPrefill={(note) => props.formApi.setValue('note', note)}
                resource="SERVICE_CASE"
                context="service_checkout"
                isLinkVariant
                data-testid={suffixTestId('predefinedNotes', props)}
              />
            </HStack>
            <FormField
              control={control}
              name="note"
              type="textarea"
              data-testid={suffixTestId('note', props)}
            />
          </VStack>
          <Right>
            <Tooltip label={props.issueButtonTooltip} placement="top-end" hasAutoWidth>
              <FormButton
                control={control}
                type="submit"
                isDisabled={props.isSubmitButtonDisabled || props.isIssueButtonDisabled}
                title={i18n.t('entity.checkout.actions.issuePayment')}
                data-testid={suffixTestId('issuePayment', props)}
              />
            </Tooltip>
          </Right>
        </VStack>
      </Card>
      <Right>
        <Button
          title={i18n.t('general.actions.saveChanges')}
          variant="secondary"
          onClick={handleSaveChanges}
          isDisabled={isSaveLoading || props.isSubmitButtonDisabled}
          isLoading={isSaveLoading}
          data-testid={suffixTestId('saveChanges', props)}
        />
      </Right>
    </VStack>
  );
}
