import {Dropdown, Action, closeCurrentDialog, TabProps, Tabs} from 'platform/components';
import {Box} from 'platform/foundation';

import {GetUserApiResponse, useGetMyTasksQuery, useGetTasksByMeQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {employeeRoutes, taskManagerRoutes} from '@omnetic-dms/routes';

import {
  RequiredTestIdProps,
  useBoolean,
  buildArray,
  useNavigate,
  suffixTestId,
  composePath,
} from 'shared';

import {openCreateTaskFormDialog} from '../../utils/openCreateTaskFormDialog';
import {openEditTaskFormDialog} from '../../utils/openEditTaskFormDialog';
import {TaskCenterList} from '../TaskCenterList/TaskCenterList';
import {TaskCenterHeaderButton} from './components/TaskCenterHeaderButton';

interface TaskCenterPopupProps extends RequiredTestIdProps {}

export function TaskCenterPopup(props: TaskCenterPopupProps) {
  const [isOpen, setOpen, setClosed] = useBoolean();

  const navigate = useNavigate();

  const {
    data: myTasks,
    isLoading: areMyTasksLoading,
    isError: hasMyTasksError,
  } = useGetMyTasksQuery();

  const {
    data: tasksByMe,
    isLoading: areTasksByMeLoading,
    isError: hasTasksByMeError,
  } = useGetTasksByMeQuery();

  const TabActions = buildArray<Action>().add({
    type: 'button',
    title: i18n.t('entity.task.actions.createTask'),
    variant: 'link',
    leftIcon: 'content/add_circle',
    onClick: () => {
      closeCurrentDialog();
      openCreateTaskFormDialog({
        'data-testid': 'task-center-create-task',
      });
      setClosed();
    },
  });

  const handleShowAllClick = () => {
    navigate(taskManagerRoutes.taskList);
    setClosed();
  };

  const handleAssigneeClick = (user: GetUserApiResponse) => {
    navigate(
      composePath(employeeRoutes.detail, {
        params: {id: user.id},
      })
    );
  };

  const tabs = buildArray<TabProps>()
    .add({
      id: 'my-tasks',
      title: i18n.t('entity.task.labels.myTasks'),
      content: (
        <TaskCenterList
          data-testid={suffixTestId('myTasks', props)}
          list={myTasks ?? []}
          isLoading={areMyTasksLoading}
          isError={hasMyTasksError}
          onItemClick={(task) => {
            closeCurrentDialog();
            openEditTaskFormDialog({
              id: task.id,
              'data-testid': 'task-center-myTasks',
            });
            setClosed();
          }}
          shouldRenderShowAllButton
          onShowAllClick={handleShowAllClick}
        />
      ),
      actions: TabActions,
    })
    .add({
      id: 'by-me-tasks',
      title: i18n.t('entity.task.labels.byMeTasks'),
      content: (
        <TaskCenterList
          data-testid={suffixTestId('byMeTasks', props)}
          list={tasksByMe ?? []}
          isLoading={areTasksByMeLoading}
          isError={hasTasksByMeError}
          onItemClick={(task) => {
            closeCurrentDialog();
            openEditTaskFormDialog({
              id: task.id,
              'data-testid': 'task-center-byMeTasks',
            });
            setClosed();
          }}
          shouldRenderShowAllButton
          onAssigneeClick={handleAssigneeClick}
          onShowAllClick={handleShowAllClick}
        />
      ),
      actions: TabActions,
    });
  return (
    <Dropdown
      closeOnSelect
      closeOnBlur
      onOpen={setOpen}
      onClose={setClosed}
      isOpen={isOpen}
      placement="bottom-end"
      dropdownControl={
        <TaskCenterHeaderButton isOpen={isOpen} data-testid={props['data-testid']} />
      }
    >
      <Box minWidth={120} paddingHorizontal={2}>
        <Tabs tabs={tabs} />
      </Box>
    </Dropdown>
  );
}
