import {Alert, Card, Separator} from 'platform/components';
import {Box, Hide, Show, Space, VStack} from 'platform/foundation';

import {isNil} from 'ramda';

import {
  useAllowDepositMutation,
  useCreateDepositPaymentMutation,
  useForbidDepositMutation,
  useGetOrderQuery,
  usePatchCheckoutOrderForeignCurrencyPaymentMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {useGetShowPaymentForm} from '../../hooks/useGetShowPaymentForm';
import {getPaymentsByDiscriminator} from '../../utils/getPaymentsByDiscriminator';
import {IssuePaymentForm} from '../PaymentForm/IssuePaymentForm';
import {DepositPaymentDetail} from './components/DepositPaymentDetail';

interface DepositPaymentCardProps extends TestIdProps {
  orderId: string;
  checkoutId: string;
  refreshBusinessCaseCheckoutInfo: (customerId: string | Nullish) => void;
}

export function DepositPaymentList(props: DepositPaymentCardProps) {
  const [allowDeposit, {isLoading: isCreatingDeposit}] = useAllowDepositMutation();
  const [forbidDeposit, {isLoading: isForbiddingDeposit}] = useForbidDepositMutation();
  const [createDeposit, {isLoading: isAddPaymentPending}] = useCreateDepositPaymentMutation();
  const [_, {isLoading: isPatchingForeignCurrency}] =
    usePatchCheckoutOrderForeignCurrencyPaymentMutation({
      fixedCacheKey: 'foreignCurrencyPayment',
    });

  const isTogglingDeposit = isForbiddingDeposit || isCreatingDeposit;

  const {data: order, isFetching: isFetchingOrder} = useGetOrderQuery({
    checkoutId: props.checkoutId,
    orderId: props.orderId,
  });

  const [getShowPaymentForm] = useGetShowPaymentForm({
    checkoutId: props.checkoutId,
    order,
  });

  const isDepositNotToggleable = order?.payments.some(
    (payment) => payment.paymentState !== 'CONCEPT'
  );

  const isDepositAllowed = order?.depositAllowed || false;

  const onControlChange = () => {
    if (isDepositNotToggleable || isNil(order)) {
      return;
    }

    const toggleAction = isDepositAllowed ? forbidDeposit : allowDeposit;

    toggleAction({checkoutId: props.checkoutId, orderId: props.orderId})
      .unwrap()
      .catch(handleApiError);
  };

  const {deposits, balanceAndPurchases} = getPaymentsByDiscriminator(order);

  const isBalanceOrPurchasePaid = balanceAndPurchases.some(
    (payment) => payment.paymentState !== 'CONCEPT'
  );

  const handleCreateAnotherDepositPayment = () =>
    createDeposit({checkoutId: props.checkoutId, orderId: props.orderId})
      .unwrap()
      .catch(handleApiError);

  const paymentProps = {
    order: order!,
    checkoutId: props.checkoutId,
    refreshBusinessCaseCheckoutInfo: props.refreshBusinessCaseCheckoutInfo,
    handleAddAnotherPayment: handleCreateAnotherDepositPayment,
    isAddPaymentPending,
    isFetchingOrder,
  };

  return (
    <Card
      title={i18n.t('entity.checkout.deposit')}
      control={{
        type: 'switch',
        'data-testid': suffixTestId('depositPaymentAllowed', props),
        value: isDepositAllowed,
        isDisabled:
          isDepositNotToggleable ||
          isPatchingForeignCurrency ||
          isTogglingDeposit ||
          isFetchingOrder,
        onChange: onControlChange,
      }}
      data-testid={suffixTestId('depositPayment', props)}
      variant="inlineGrey"
      isExpanded={isDepositAllowed}
      isExpandable
      onExpandButtonClick={isDepositNotToggleable ? undefined : onControlChange}
    >
      <VStack spacing={4} width="100%">
        {deposits.map((depositPayment, index) => {
          const shouldShowForm = getShowPaymentForm(depositPayment.paymentState);

          return (
            <Box key={depositPayment.id}>
              <Hide when={index === 0}>
                <Separator />
              </Hide>

              <Show when={depositPayment.paymentState === 'PAID'}>
                <Alert
                  message={i18n.t('entity.checkout.labels.allPaymentsPaid')}
                  variant="success"
                  data-testid={testIds.businessCase.checkout(`allPaymentsPaid-alert-${index}`)}
                />
                <Space vertical={4} />
              </Show>

              <Show when={shouldShowForm}>
                <IssuePaymentForm
                  {...paymentProps}
                  payment={depositPayment}
                  data-testid={testIds.businessCase.checkout(
                    `issuePaymentForm-${depositPayment.paymentDiscriminator}-${index}`
                  )}
                  canAddAnotherPayment={
                    index === deposits.length - 1 ? !isBalanceOrPurchasePaid : false
                  }
                />
              </Show>
              <Hide when={shouldShowForm}>
                <DepositPaymentDetail
                  {...paymentProps}
                  payment={depositPayment}
                  data-testid={testIds.businessCase.checkout(
                    `issuePaymentDetail-${depositPayment.paymentDiscriminator}-${index}`
                  )}
                  canAddAnotherPayment={
                    index === deposits.length - 1 ? !isBalanceOrPurchasePaid : false
                  }
                />
              </Hide>
            </Box>
          );
        })}
      </VStack>
    </Card>
  );
}
