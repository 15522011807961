import {useSubscription} from '@apollo/client';
import {match} from 'ts-pattern';

import {useEffect, useState} from 'react';

import {always, isNil} from 'ramda';

import {
  Module,
  ModuleItem,
  Register,
  SEND_NOTIFICATION_SUBSCRIPTION,
  useLazyGetSentBackgroundNotificationQuery,
  useListPermissionExpressionQuery,
} from '@omnetic-dms/api';
import {handleApiError, notificationTypes, register} from '@omnetic-dms/shared';

import {Navigator} from '../../components/Navigator/Navigator';
import {isTestEnvironment} from '../../utils/isTestEnvironment';
import {resolveFeatureFlag} from './utils/resolveFeatureFlag';
import {resolvePermission} from './utils/resolvePermission';

/**
 * This hook needs to be called after correctly initializing Flagsmith via useInitFlagsmith() hook.
 * Flagsmith init is in Initialize.tsx since we need to initialize feature flags in the uppermost
 * boundary where possible. This hook will not work correctly if flagsmith is not initialized
 * previously. If encountering errors, please double-check if flagsmith is initialized before
 * calling this hook.
 */
export function useRegisterModules(modules: Module[]) {
  const {data: userPermissions, refetch: refetchUserPermissions} =
    useListPermissionExpressionQuery();
  const [getBackgroundNotification] = useLazyGetSentBackgroundNotificationQuery();

  useSubscription(SEND_NOTIFICATION_SUBSCRIPTION, {
    onData: ({data: subscription}) => {
      const backgroundNotificationId: string | null =
        subscription.data?.onSendNotification?.backgroundNotificationId;

      if (isNil(backgroundNotificationId)) {
        return;
      }

      getBackgroundNotification({
        id: backgroundNotificationId,
      })
        .unwrap()
        .then((notification) => {
          if (notification.type === notificationTypes.PERMISSION_CHANGED) {
            refetchUserPermissions();
          }
        })
        .catch(handleApiError);
    },
  });

  const isAccessible = (module: Module | ModuleItem) => {
    const permission = module.requiredPermission;
    const featureFlag = module.requiredFeatureFlag;

    const hasPermission = resolvePermission(permission, userPermissions);
    const isFeatureFlagEnabled = resolveFeatureFlag(featureFlag);

    const isModuleAccessible = match([hasPermission, isFeatureFlagEnabled])
      .with([true, true], always(true))
      .otherwise(always(false));

    return isModuleAccessible;
  };

  const [modulesCache, cache] = useState<Register | null>(null);

  useEffect(() => {
    if (!modules || !userPermissions) {
      return;
    }

    Promise.all(modules).then(
      (result) => {
        cache(register(result, isAccessible, Navigator(), isTestEnvironment));
      },
      () => {
        throw Error('Application cannot load modules. Please check the validity of each module.');
      }
    );

    return () => {
      cache(null);
    };
  }, [userPermissions]);

  return modulesCache;
}
