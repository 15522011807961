import {ThemeIconKey} from 'platform/foundation';
import {match} from 'ts-pattern';

import {TaskPriorityEnum, TaskPriorityEnumSchema} from '@omnetic-dms/api';

export function getIconKeyByPriority(priority: TaskPriorityEnum) {
  return match(priority)
    .returnType<ThemeIconKey>()
    .with(TaskPriorityEnumSchema.enum.LOW, () => 'hardware/keyboard_arrow_down')
    .with(TaskPriorityEnumSchema.enum.MEDIUM, () => 'content/remove')
    .with(TaskPriorityEnumSchema.enum.HIGH, () => 'hardware/keyboard_arrow_up')
    .exhaustive();
}
