import {ProgressBar} from 'platform/components';
import {Box, Center} from 'platform/foundation';

import {CSSProperties} from 'react';

import {isNil} from 'ramda';

interface VideoPlaceholderProps {
  progress?: number;
  ratio?: CSSProperties['aspectRatio'];
}

export function VideoPlaceholder(props: VideoPlaceholderProps) {
  return (
    <Box
      width="100%"
      height="100%"
      ratio={props.ratio}
      padding={2}
      border="1px dashed"
      borderColor="palettes.neutral.80.100"
      borderRadius="xSmall"
    >
      <Center height="100%">
        <ProgressBar
          percentage={props.progress || undefined}
          indeterminate={isNil(props.progress) || props.progress === 0}
          small
        />
      </Center>
    </Box>
  );
}
