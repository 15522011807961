import DOMPurify from 'dompurify';
import {Box, Clickable, Hide, HStack, Show, Text, VStack} from 'platform/foundation';
import {DateTimeFormat} from 'platform/locale';
import styled from 'styled-components';

import {isNil, not} from 'ramda';
import {isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';

import {TestIdProps} from 'shared';

import {NotificationItemData} from '../NotificationItemData';
import {NotificationAvatar} from './NotificationAvatar';

type NotificationItemProps = NotificationItemData &
  TestIdProps & {
    onNavigate: (url: string) => void;
    onMarkAsRead?: (id: string) => void;
  };

export function NotificationItem(props: NotificationItemProps) {
  const onClick = () => {
    not(props.isRead) && props.onMarkAsRead?.(props.id);
    props.url && props.onNavigate(props.url);
    props.onClick?.();
  };

  const isMarkAsReadEnabled = isNotNil(props.onMarkAsRead) && !!props.isRead;

  return (
    <Clickable
      isDisabled={isNil(props.resource) && not(isMarkAsReadEnabled)}
      onClick={onClick}
      data-testid={props['data-testid']}
    >
      <LayoutNotificationBackgroud $isRead={props.isRead}>
        <Box minHeight={18} padding={2} overflow="hidden">
          <HStack spacing={2} justify="stretch">
            <NotificationAvatar {...props} />
            <VStack spacing={1} grow={1}>
              <HStack spacing={2} align="baseline" justify="space-between">
                <Text size="xSmall" color="secondary" inline>
                  {props.title}
                </Text>
                <Hide when={props.isRead}>
                  <Box
                    width={2}
                    height={2}
                    flexGrow={0}
                    flexShrink={0}
                    borderRadius="circular"
                    backgroundColor="general.accent"
                  />
                </Hide>
              </HStack>
              <Show when={props.resource}>
                <Text size="xSmall" color="link">
                  {props.resource}
                </Text>
              </Show>
              {props.created && (
                <Text size="xSmall" color="secondary">
                  <DateTimeFormat format="dateTimeMedium" value={props.created} />
                </Text>
              )}
              <Show when={isNotNilOrEmpty(props.message)}>
                <Box
                  border="1px solid"
                  borderColor="general.separator"
                  borderRadius="small"
                  padding={2}
                >
                  <MessageText
                    // eslint-disable-next-line no-restricted-syntax -- Data is sanitized so it's safe to use dangerouslySetInnerHTML
                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.message ?? '')}}
                  />
                </Box>
              </Show>
            </VStack>
          </HStack>
        </Box>
      </LayoutNotificationBackgroud>
    </Clickable>
  );
}

export const LayoutNotificationBackgroud = styled.div<{$isRead?: boolean}>`
  background-color: ${({theme, $isRead}) =>
    $isRead || $isRead === undefined
      ? theme.colors.general.white
      : theme.colors.palettes.blue[10][100]};
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[10][100]};
  }
  border-radius: ${({theme}) => theme.radii.small};
`;

// Text component doesn't support line-clamp
// eslint-disable-next-line eag/no-css-property
const MessageText = styled.div`
  font-size: ${({theme}) => theme.fontSizes.text.xSmall};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
