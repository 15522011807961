import {DataStatus, Form, FormSubmitHandler, showNotification} from 'platform/components';
import {match} from 'ts-pattern';
import {DeepPartial} from 'utility-types';
import {boolean, object} from 'yup';

import {FieldErrors} from 'react-hook-form';
import {useParams} from 'react-router-dom';

import {always, defaultTo, isNil} from 'ramda';
import {isNilOrEmpty, isNotNil} from 'ramda-adjunct';

import {
  DirectSaleVariantDetail,
  useGetAuthorizationProfileQuery,
  useGetAuthorizationProfilesQuery,
  useGetBranchListQuery,
  useGetDirectSaleTypesQuery,
  useGetDirectSaleVariantQuery,
  useGetMaterialPriceTypesQuery,
  useGetSeriesListQuery,
  useGetTemplatesQuery,
  useGetTenantQuery,
  useGetWorkRateTypesQuery,
  useLazyGetAuthorizationProfileQuery,
  usePatchDirectSaleVariantMutation,
  usePostDirectSaleVariantMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError, queryParams, useDuplicateErrorHandler} from '@omnetic-dms/shared';

import {useNavigate, useQueryState, yupNumber, yupString} from 'shared';

import {SettingsTemplate} from '../../components/SettingsTemplate/SettingsTemplate';
import {SettingsTemplateHeader} from '../../types';
import {DocumentsTab} from './(sections)/DocumentTab/DocumentsTab';
import {GeneralTab} from './(sections)/GeneralTab/GeneralTab';
import {DirectSaleVariantForm} from './types/DirectSaleVariantForm';

export function WarehouseDirectSaleVariantsDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const {duplicateErrorHandler} = useDuplicateErrorHandler();
  const [, setActiveTab] = useQueryState(queryParams.COMPONENT_SECTIONS_TAB);

  const {
    data: directSaleVariant,
    isLoading: isDirectSaleVariantLoading,
    isError: hasDirectSaleVariantError,
  } = useGetDirectSaleVariantQuery(
    {directSaleVariantId: params.id as string},
    {skip: isNilOrEmpty(params.id)}
  );

  const {data: tenant, isLoading: isTenantLoading, isError: hasTenantError} = useGetTenantQuery();

  const {
    data: authorizationProfile,
    isLoading: isAuthorizationProfileLoading,
    isError: hasAuthorizationProfileError,
  } = useGetAuthorizationProfileQuery(
    {
      'x-tenant': tenant?.id as string,
      authorizationProfileId: directSaleVariant?.general?.authorizationProfileId as string,
    },
    {skip: isNil(tenant) || isNilOrEmpty(directSaleVariant?.general?.authorizationProfileId)}
  );

  const [lazyGetAuthorizationProfile, {isFetching: isLazyGetAuthorizationProfileFetching}] =
    useLazyGetAuthorizationProfileQuery();

  const {
    data: branchList,
    isLoading: isBranchListLoading,
    isError: hasBranchListError,
  } = useGetBranchListQuery();

  const {
    data: authorizationProfiles,
    isLoading: isAuthorizationProfilesLoading,
    isError: hasAuthorizationProfilesError,
  } = useGetAuthorizationProfilesQuery({'x-tenant': tenant?.id as string}, {skip: isNil(tenant)});

  const {
    data: directSaleTypes,
    isLoading: isDirectSaleTypesLoading,
    isError: hasDirectSaleTypesError,
  } = useGetDirectSaleTypesQuery();

  const {
    data: workRateTypes,
    isLoading: isWorkRateTypesLoading,
    isError: hasWorkRateTypesError,
  } = useGetWorkRateTypesQuery();

  const {
    data: materialPriceTypes,
    isLoading: isMaterialPriceTypesLoading,
    isError: hasMaterialPriceTypesError,
  } = useGetMaterialPriceTypesQuery();

  const {
    data: seriesList,
    isLoading: isSeriesListLoading,
    isError: hasSeriesListError,
  } = useGetSeriesListQuery({
    type: ['warehouse/direct-sale', 'warehouse/offer', 'accounting/invoice'],
  });

  const {
    data: invoiceTemplates,
    isLoading: isInvoiceTemplatesLoading,
    isError: hasInvoiceTemplatesError,
  } = useGetTemplatesQuery({documentKindCode: 'invoice'});

  const {
    data: offerTemplates,
    isLoading: isOfferTemplatesLoading,
    isError: hasOfferTemplatesError,
  } = useGetTemplatesQuery({documentKindCode: 'warehouse-offer'});

  const [createDirectSaleVariant] = usePostDirectSaleVariantMutation();
  const [updateDirectSaleVariant] = usePatchDirectSaleVariantMutation();

  const isLoading =
    isDirectSaleVariantLoading ||
    isTenantLoading ||
    isAuthorizationProfilesLoading ||
    isAuthorizationProfileLoading ||
    isBranchListLoading ||
    isDirectSaleTypesLoading ||
    isWorkRateTypesLoading ||
    isMaterialPriceTypesLoading ||
    isSeriesListLoading ||
    isInvoiceTemplatesLoading ||
    isOfferTemplatesLoading;

  const isError =
    hasDirectSaleVariantError ||
    hasTenantError ||
    hasAuthorizationProfilesError ||
    hasAuthorizationProfileError ||
    hasBranchListError ||
    hasDirectSaleTypesError ||
    hasWorkRateTypesError ||
    hasMaterialPriceTypesError ||
    hasSeriesListError ||
    hasInvoiceTemplatesError ||
    hasOfferTemplatesError;

  const handleSubmit: FormSubmitHandler<DirectSaleVariantForm> = async (formValues) => {
    // Since branchId is read-only, we don't want to send it back to server
    const {branchId: _branchId, ...generalWithoutBranchId} = formValues.general;

    const payloadBody = {
      general: generalWithoutBranchId,
      documents: formValues.documents,
    };

    if (isNotNil(params.id)) {
      return await updateDirectSaleVariant({
        body: payloadBody,
        directSaleVariantId: params.id,
      })
        .unwrap()
        .then(() => {
          showNotification.success(
            i18n.t('entity.warehouse.notifications.directSaleVariantUpdated')
          );
        })
        .then(() => handleNavigateBack())
        .catch(handleApiError);
    }

    await createDirectSaleVariant(payloadBody)
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('entity.warehouse.notifications.directSaleVariantCreated'));
      })
      .then(() => handleNavigateBack())
      .catch(duplicateErrorHandler);
  };

  const handleNavigateBack = () => {
    navigate(settingsRoutes.warehousesDirectSaleVariants);
  };

  const handleNavigateToInvalidTab = (errors: FieldErrors<DirectSaleVariantDetail>) => {
    const isGeneralTabValid = isNil(errors.general);
    const isDocumentsTabValid = isNil(errors.documents);

    match([isGeneralTabValid, isDocumentsTabValid])
      .with([false, true], () => setActiveTab('general'))
      .with([true, false], () => setActiveTab('documents'))
      .otherwise(always(null));
  };

  const header: SettingsTemplateHeader = {
    title: isNil(params.id)
      ? i18n.t('entity.warehouse.labels.newDirectSaleVariant')
      : (directSaleVariant?.general.name ?? ''),
    breadcrumbs: [
      {
        label: i18n.t('entity.warehouse.labels.directSaleVariants'),
        href: settingsRoutes.warehousesDirectSaleVariants,
      },
    ],
  };

  const defaultValues: DeepPartial<DirectSaleVariantForm> = {
    general: {
      ...directSaleVariant?.general,
      branchId: authorizationProfile?.branchId,
      priceTypeRatio: defaultTo(1, directSaleVariant?.general.priceTypeRatio),
      rateTypeRatio: defaultTo(1, directSaleVariant?.general.rateTypeRatio),
    },
    documents: {
      ...directSaleVariant?.documents,
    },
  };

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={60}>
      <Form<DirectSaleVariantForm>
        schema={formSchema}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        onInvalidSubmit={(errors) => handleNavigateToInvalidTab(errors)}
        isFullHeight
      >
        {(control, formApi) => {
          const handleAuthorizationProfileChange = (
            authorizationProfileId: string | string[] | number | null
          ) => {
            if (isNil(tenant) || isNilOrEmpty(authorizationProfileId)) {
              return;
            }

            lazyGetAuthorizationProfile({
              'x-tenant': tenant?.id,
              authorizationProfileId: authorizationProfileId as string,
            })
              .unwrap()
              .then((authorizationProfile) =>
                formApi.setValue('general.branchId', authorizationProfile.branchId, {
                  shouldValidate: true,
                })
              )
              .catch(handleApiError);
          };

          return (
            <SettingsTemplate
              header={header}
              tabs={[
                {
                  title: i18n.t('entity.warehouse.labels.general'),
                  queryId: 'general',
                  content: (
                    <GeneralTab
                      control={control}
                      formApi={formApi}
                      isFetchingAuthorizationProfile={isLazyGetAuthorizationProfileFetching}
                      onDiscard={handleNavigateBack}
                      onAuthorizationProfileChange={handleAuthorizationProfileChange}
                      directSaleVariantId={params.id}
                      authorizationProfiles={authorizationProfiles}
                      branchList={branchList}
                      directSaleTypes={directSaleTypes}
                      workRateTypes={workRateTypes}
                      materialPriceTypes={materialPriceTypes}
                      seriesList={seriesList}
                      data-testid={testIds.settings.warehousesDirectSaleVariantsDetail(
                        'tab.general'
                      )}
                    />
                  ),
                },
                {
                  title: i18n.t('entity.warehouse.labels.documents'),
                  queryId: 'documents',
                  content: (
                    <DocumentsTab
                      control={control}
                      formApi={formApi}
                      onDiscard={handleNavigateBack}
                      directSaleVariantId={params.id}
                      offerTemplates={offerTemplates}
                      seriesList={seriesList}
                      invoiceTemplates={invoiceTemplates}
                      data-testid={testIds.settings.warehousesDirectSaleVariantsDetail(
                        'tab.documents'
                      )}
                    />
                  ),
                },
              ]}
              data-testid={testIds.settings.warehousesDirectSaleVariantsDetail('template')}
            />
          );
        }}
      </Form>
    </DataStatus>
  );
}

const requiredIfEnabled = yupString.when('isEnable', {
  is: true,
  then: yupString.required(),
  otherwise: yupString,
});

const formSchema = object({
  general: object({
    directSaleTypeId: yupString.required(),
    name: yupString.required(),
    note: yupString,
    priceType: yupString.required(),
    rateType: yupString.required(),
    priceTypeRatio: yupNumber.positive(),
    rateTypeRatio: yupNumber.positive(),
    minimalMaterialMargin: yupNumber,
    minimalWorkMargin: yupNumber,
    defaultCustomerId: yupString,
    sufix: yupString.required(),
    documentTemplates: yupString,
    authorizationProfileId: yupString.required(),
    docSeriesId: yupString.required(),
    isUnitPriceWithVat: boolean().default(false),
    isChangeableDefaultCustomer: boolean().default(false),
  }),
  documents: object({
    defaultPaymentType: yupString,
    orderOffer: object({
      docSeriesId: yupString,
      templateId: yupString,
    }),
    cashPayment: object({
      docSeriesId: requiredIfEnabled,
      invoiceTemplateId: requiredIfEnabled,
      isEnable: boolean().default(false),
    }),
    cardPayment: object({
      docSeriesId: requiredIfEnabled,
      invoiceTemplateId: requiredIfEnabled,
      isEnable: boolean().default(false),
    }),
    bankPayment: object({
      docSeriesId: requiredIfEnabled,
      invoiceTemplateId: requiredIfEnabled,
      isEnable: boolean().default(false),
    }),
    internalPayment: object({
      docSeriesId: requiredIfEnabled,
      invoiceTemplateId: requiredIfEnabled,
      isEnable: boolean().default(false),
    }),
  }),
});
