import {closeCurrentDialog, showNotification} from 'platform/components';

import {useCreateTaskMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {getApiDateString, parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import type {TaskForm as TaskFormType} from '../../types/TaskForm';
import {castTimeToDateISOString} from '../../utils/castTimeToDateISOString';
import {TaskForm} from '../TaskForm/TaskForm';

interface CreateTaskFormProps extends RequiredTestIdProps {
  onCreate?: () => void;
}

export function CreateTask(props: CreateTaskFormProps) {
  const [createTask] = useCreateTaskMutation();

  const onCreateTask = (data: TaskFormType) =>
    createTask({
      createTaskRequestBody: {
        ...data,
        dueDate: data?.dueDate ? getApiDateString(parseDate(data.dueDate)) : null,
        dueFrom:
          data?.dueDate && data?.dueFrom
            ? castTimeToDateISOString(data.dueDate, data.dueFrom)
            : null,
        dueTo:
          data?.dueDate && data?.dueTo ? castTimeToDateISOString(data.dueDate, data.dueTo) : null,
        relatedRecordId: null,
      },
    })
      .unwrap()
      .then(() => {
        closeCurrentDialog();
        props.onCreate?.();
        showNotification.success(i18n.t('general.notifications.successfullyCreated'));
      })
      .catch(handleApiError);

  return <TaskForm onSubmit={onCreateTask} data-testid={suffixTestId('createTask', props)} />;
}
