import {openConfirmDialog, showNotification} from 'platform/components';
import {match} from 'ts-pattern';

import {RefObject} from 'react';

import {useDeleteTaskMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {RequiredTestIdProps} from 'shared';

import {ActionCallback, DataGrid, DataGridRef} from 'features/datagrid';

import {openEditTaskFormDialog} from '../../utils/openEditTaskFormDialog';

interface TasksDataGridProps extends RequiredTestIdProps {
  dataGridRef: RefObject<DataGridRef>;
}

export function TasksDataGrid(props: TasksDataGridProps) {
  const [deleteTask] = useDeleteTaskMutation();

  const dataGridActionsHandler: ActionCallback = ({actionKey, rowId, refreshData}) => {
    match(actionKey)
      .with('edit', () => {
        if (Array.isArray(rowId)) {
          // This should never happen, but just in case
          throw new Error('Cannot delete multiple tasks at once');
        }
        openEditTaskFormDialog({
          id: rowId,
          onEdit: refreshData,
          onDelete: refreshData,
          'data-testid': testIds.taskManager.taskList('editTaskDialog'),
        });
      })
      .with('delete', () => {
        openConfirmDialog({
          onConfirm: () => {
            if (Array.isArray(rowId)) {
              // This should never happen, but just in case
              throw new Error('Cannot delete multiple tasks at once');
            }
            deleteTask({id: rowId})
              .unwrap()
              .then(() => {
                refreshData();
                showNotification.success(i18n.t('general.notifications.successfullyDeleted'));
              })
              .catch(handleApiError);
          },
        });
      });
  };

  return (
    <DataGrid
      ref={props.dataGridRef}
      data-testid={props['data-testid']}
      actionCallback={dataGridActionsHandler}
      gridCode="task"
      emptyState={{
        headline: i18n.t('general.labels.noData'),
      }}
    />
  );
}
