import type {PermissionsRecordType} from '../permissions';

export const settingsPermissions = {
  readSettings: {
    actionId: 'READ',
    resourceId: 'SETTINGS',
    scopes: [],
  },
  configureBackgroundRemoval: {
    actionId: 'CONFIGURE_BACKGROUND_REMOVAL',
    resourceId: 'SALES_SETTINGS',
    scopes: [],
  },
  enableWarehouseManagement: {
    actionId: 'ENABLE_WAREHOUSE_MANAGEMENT',
    resourceId: 'SALES_SETTINGS',
    scopes: [],
  },
  disableWarehouseManagement: {
    actionId: 'DISABLE_WAREHOUSE_MANAGEMENT',
    resourceId: 'SALES_SETTINGS',
    scopes: [],
  },
  readCustomFieldsDefinitions: {
    resourceId: 'FIELD_DEFINITION',
    actionId: 'READ',
    scopes: [],
  },
  removeCustomFieldsDefinitions: {
    resourceId: 'FIELD_DEFINITION',
    actionId: 'REMOVE_TENANT_FIELD_DEFINITION',
    scopes: [],
  },
  updateCustomFieldsDefinitions: {
    resourceId: 'FIELD_DEFINITION',
    actionId: 'UPDATE_TENANT_FIELD_DEFINITION',
    scopes: [],
  },
  addCustomFieldsDefinitions: {
    resourceId: 'FIELD_DEFINITION',
    actionId: 'ADD_TENANT_FIELD_DEFINITION',
    scopes: [],
  },
  createMessageTemplate: {
    resourceId: 'CONTEXT_TEMPLATE',
    actionId: 'CREATE',
    scopes: [],
  },
  patchMessageTemplate: {
    resourceId: 'CONTEXT_TEMPLATE',
    actionId: 'PATCH',
    scopes: [],
  },
} satisfies PermissionsRecordType;
