import {FlagProps, Parameter, showNotification} from 'platform/components';
import {useDateTimeFormatter} from 'platform/locale';
import {match} from 'ts-pattern';

import {Helmet} from 'react-helmet-async';

import {defaultTo, isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  useGetReceiveNoteCorrectionQuery,
  useGetReceiveNoteQuery,
  useGetWarehouseHeaderDynamicActionsQuery,
  usePutWarehouseHeaderDynamicActionsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {
  DetailTemplate,
  DetailTemplateHeader,
  handleApiError,
  NavigationItem,
} from '@omnetic-dms/shared';

import {composePath, EMPTY_PLACEHOLDER, generateHashFromObjects, Nullish, parseDate} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {createHeaderActions} from '../../utils/createHeaderActions';
import {Documents} from './(sections)/Documents/Documents';
import {Overview} from './(sections)/Overview/Overview';

export function ReceiveNoteCorrectionDetail() {
  const {receiveNoteId, receiveNoteCorrectionId} = useWarehouseParams();

  const dateTimeFormatter = useDateTimeFormatter();

  const {
    data: receiveNote,
    isLoading: isReceiveNoteLoading,
    isError: hasReceiveNoteError,
  } = useGetReceiveNoteQuery({receiveNoteId}, {skip: isNilOrEmpty(receiveNoteId)});

  const {
    data: receiveNoteCorrectionAction,
    isFetching: isReceiveNoteCorrectionActionFetching,
    isError: hasReceiveNoteCorrectionActionError,
  } = useGetWarehouseHeaderDynamicActionsQuery(
    {id: receiveNoteCorrectionId, resource: 'credit-note'},
    {skip: isNilOrEmpty(receiveNoteCorrectionId)}
  );

  const {
    data: receiveNoteCorrection,
    isLoading: isReceiveNoteCorrectionLoading,
    isError: hasReceiveNoteCorrectionError,
  } = useGetReceiveNoteCorrectionQuery(
    {creditNoteId: receiveNoteCorrectionId},
    {skip: isNilOrEmpty(receiveNoteCorrectionId)}
  );

  const [putReceiveNoteCorrectionAction, {isLoading: isReceiveNoteCorrectionActionLoading}] =
    usePutWarehouseHeaderDynamicActionsMutation();

  // TODO https://carvago.atlassian.net/browse/T20-55731
  // const {data: createdByUser, isLoading: isCreatedByUserLoading} = useGetUserQuery(
  //   {id: receiveNoteCorrection?.creditNote?.createdBy!},
  //   {skip: isNil(receiveNoteCorrection?.creditNote?.createdBy)}
  // );

  // TODO https://carvago.atlassian.net/browse/T20-55731
  // const {data: updatedByUser, isLoading: isUpdatedByUserLoading} = useGetUserQuery(
  //   {id: receiveNoteCorrection?.creditNote?.updatedBy!},
  //   {skip: isNil(receiveNoteCorrection?.creditNote?.updatedBy)}
  // );

  // TODO https://carvago.atlassian.net/browse/T20-55731
  const isLoading =
    isReceiveNoteLoading || isReceiveNoteCorrectionLoading; /*|| isCreatedByUserLoading*/
  // TODO https://carvago.atlassian.net/browse/T20-55731
  const hasError =
    hasReceiveNoteError || hasReceiveNoteCorrectionError; /*|| isUpdatedByUserLoading*/

  const isDraftReceiveNoteCorrection = isNil(receiveNoteCorrection);
  const isCompletedReceiveNoteCorrection = receiveNoteCorrection?.creditNote?.state === 'COMPLETED';

  const formatToShortDate = (date: string | Nullish) => {
    if (isNil(date)) {
      return EMPTY_PLACEHOLDER;
    }
    return dateTimeFormatter('dateShort', parseDate(date));
  };
  // TODO https://carvago.atlassian.net/browse/T20-55731
  // const createdBy = `${i18n.t('general.labels.createdBy')}: ${getUserName(createdByUser) ?? EMPTY_PLACEHOLDER}`;
  const createdAt = `${i18n.t('general.labels.createdAt')}: ${formatToShortDate(receiveNoteCorrection?.creditNote?.created)}`;
  // TODO https://carvago.atlassian.net/browse/T20-55731
  // const updatedBy = `${i18n.t('general.labels.updatedBy')}: ${getUserName(updatedByUser) ?? EMPTY_PLACEHOLDER}`;
  const updatedAt = `${i18n.t('general.labels.updatedAt')}: ${formatToShortDate(receiveNoteCorrection?.creditNote?.updated)}`;
  // TODO https://carvago.atlassian.net/browse/T20-55731
  // const completedBy = `${i18n.t('general.labels.completedBy')}: ${isCompletedReceiveNoteCorrection ? getUserName(updatedByUser) : EMPTY_PLACEHOLDER}`;
  const completedAt = `${i18n.t('general.labels.completedAt')}: ${isCompletedReceiveNoteCorrection ? formatToShortDate(receiveNoteCorrection?.creditNote?.updated) : EMPTY_PLACEHOLDER}`;

  const headerTitle = isNilOrEmpty(receiveNoteCorrectionId)
    ? i18n.t('entity.warehouse.labels.newReceiveNoteCorrection')
    : `${i18n.t('entity.warehouse.labels.receiveNoteCorrection')} ${defaultTo('', receiveNoteCorrection?.creditNote?.creditNoteNumber)}`;

  const headerParameters = match(isDraftReceiveNoteCorrection)
    .returnType<Parameter[]>()
    .with(true, () => [])
    // TODO https://carvago.atlassian.net/browse/T20-55731
    .otherwise(() => [
      /*createdBy,*/ createdAt,
      /*updatedBy,*/ updatedAt,
      /*completedBy,*/ completedAt,
    ]);

  const headerFlags = match(receiveNoteCorrection?.creditNote?.state)
    .returnType<FlagProps[]>()
    .with('PENDING', () => [{colorScheme: 'blue', label: i18n.t('general.labels.pending')}])
    .with('COMPLETED', () => [{colorScheme: 'green', label: i18n.t('general.labels.completed')}])
    .otherwise(() => [{colorScheme: 'neutral', label: i18n.t('entity.warehouse.labels.draft')}]);

  const handleActionClick = (actionKey: string) => {
    putReceiveNoteCorrectionAction({
      id: receiveNoteCorrection?.creditNote.creditNoteId as string,
      resource: 'credit-note',
      body: {actionKey},
    })
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);
  };

  const headerActions = createHeaderActions({
    actions: receiveNoteCorrectionAction?.actions,
    callback: handleActionClick,
    isLoading: isReceiveNoteCorrectionActionLoading,
  });

  const header: DetailTemplateHeader = {
    title: headerTitle,
    icon: 'action/deployed',
    parameters: headerParameters,
    recordId: receiveNoteCorrection?.creditNote?.creditNoteId,
    resourceId: EntityResourceIds.receiveNoteCorrection,
    flags: headerFlags,
    actions: headerActions,
    controls: ['ASSIGNEE'],
  };

  const navigation: NavigationItem[] = [
    {
      id: 'receiveNoteCorrectionDetail',
      label: i18n.t('page.warehouse.labels.overview'),
      href: composePath(warehouseRoutes.receiveNoteCorrectionDetailOverview, {
        params: {id: receiveNoteCorrectionId},
      }),
      content: (
        <Overview
          receiveNote={receiveNote}
          receiveNoteCorrection={receiveNoteCorrection}
          isReceiveNoteCorrectionLoading={isReceiveNoteCorrectionLoading}
          data-testid={testIds.warehouse.receiveNoteCorrectionDetailOverview('sections.overview')}
        />
      ),
      'data-testid': testIds.warehouse.receiveNoteCorrectionDetailOverview('navigation.overview'),
    },
    {
      id: 'receiveNoteCorrectionDocuments',
      label: i18n.t('page.warehouse.labels.documents'),
      href: composePath(warehouseRoutes.receiveNoteCorrectionDetailDocuments, {
        params: {id: receiveNoteCorrectionId},
      }),
      content: (
        <Documents
          creditNoteId={receiveNoteCorrectionId}
          data-testid={testIds.warehouse.receiveNoteCorrectionDetailOverview('sections.documents')}
        />
      ),
      'data-testid': testIds.warehouse.receiveNoteCorrectionDetailOverview('navigation.documents'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('entity.warehouse.labels.receiveNoteCorrection')} />
      <DetailTemplate
        key={generateHashFromObjects(receiveNoteCorrection)}
        isLoading={isLoading}
        isError={hasError}
        header={header}
        navigation={navigation}
        data-testid={testIds.warehouse.receiveNoteCorrectionDetailOverview('page')}
      />
    </>
  );
}
