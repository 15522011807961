import {useEffect} from 'react';

export function useFormValueChange<T>(
  formValue: T,
  dataValue: T,
  onValueChange: (value: T) => void
) {
  useEffect(() => {
    if (dataValue !== formValue) {
      onValueChange(dataValue);
    }
  }, [dataValue]);
}
