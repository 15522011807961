import {match} from 'ts-pattern';

import {always} from 'ramda';

import {RedirectView} from '../types/RedirectView';

export const getViewFromQueryString = (query: string): RedirectView | null =>
  match<string | null, RedirectView | null>(new URLSearchParams(query).get('view'))
    .with('detail', always('detail'))
    .with('emailUnsubscribe', always('emailUnsubscribe'))
    .otherwise(always(null));
