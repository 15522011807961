import {AppLanguage} from 'platform/locale';

import {UrlBuilderConfig, getWorkspaceFromUri, urlBuilder} from '@omnetic-dms/api';
import {environment} from '@omnetic-dms/environment';

import {createSearchParamsString} from '../../../utils/createSearchParamsString';

const {workspace} = getWorkspaceFromUri();

const QUERY_KEY = {
  THEME_ID: 'theme',
  LANGUAGE: 'lang',
};

export function openDigitalCertificate({
  inspectionId,
  language,
  themeId,
}: {
  inspectionId: string;
  language: AppLanguage;
  themeId?: string;
}) {
  const builderConfig: UrlBuilderConfig = {
    workspace,
    protocol: window.location.protocol,
    domain: environment.DIGITAL_CERTIFICATE_DOMAIN,
    route: `/${inspectionId}`,
    searchParam: createSearchParamsString({
      [QUERY_KEY.LANGUAGE]: language,
      [QUERY_KEY.THEME_ID]: themeId,
    }),
  };

  const digitalCertificateUrl = urlBuilder(builderConfig);

  window.open(digitalCertificateUrl, '_blank')?.focus();
}
