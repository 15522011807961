import {
  Attributes,
  Button,
  ButtonGroup,
  DataStatus,
  openDialog,
  Segment,
  Separator,
} from 'platform/components';
import {Box, Right, Show, VStack} from 'platform/foundation';

import {useState} from 'react';

import {isNil, last} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  EntityResourceIds,
  OrderPaymentResponseBody,
  OrderResponseBody,
  useGetParticipationQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {usePermissions} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps, useRequiredParams} from 'shared';

import {PaymentForm} from '../../PaymentForm/components/PaymentForm';
import {PaymentPriceBox} from '../../PaymentForm/components/PaymentPriceBox';
import {PaymentsTable} from '../../PaymentList/components/PaymentsTable';
import {useGetPaymentDetailAttributes} from '../../PaymentList/hooks/useGetPaymentDetailAttributes';
import {PaymentSegmentValue} from '../../PaymentList/types/PaymentSegmentValue';
import {isSegmentValue} from '../../PaymentList/utils/isSegmentValue';

const segmentOptions = [
  {value: 'detail', label: i18n.t('general.labels.detail')},
  {value: 'payments', label: i18n.t('page.accounting.invoiceDetailPayments.title')},
];

interface DepositPaymentDetailProps extends TestIdProps {
  payment: OrderPaymentResponseBody;
  checkoutId: string;
  order: OrderResponseBody;
  refreshBusinessCaseCheckoutInfo: (customerId: string | Nullish) => void;
  handleAddAnotherPayment: () => void;
  canAddAnotherPayment: boolean;
  isAddPaymentPending: boolean;
  isFetchingOrder: boolean;
}

export function DepositPaymentDetail(props: DepositPaymentDetailProps) {
  const isPaymentPending = props.payment.paymentState === 'PENDING';
  const [segmentValue, setSegmentValue] = useState<PaymentSegmentValue>('detail');
  const {id: businessCaseId} = useRequiredParams();

  const [detailRows] = useGetPaymentDetailAttributes(props);

  const {data: businessCaseParticipation} = useGetParticipationQuery({
    recordId: businessCaseId,
    resourceId: EntityResourceIds.businessCase,
  });

  const [hasConfirmBusinessCaseSellingPaymentManuallyPermission] = usePermissions({
    permissionKeys: ['confirmBusinessCaseSellingPaymentManually'],
    scopes: {
      confirmBusinessCaseSellingPaymentManually: businessCaseParticipation,
    },
  });

  const canConfirmPaymentManually =
    props.order.orderDiscriminator === 'PURCHASE' ||
    props.order.orderDiscriminator === 'PURCHASE_BROKERAGE_SALE' ||
    props.order.orderDiscriminator === 'PURCHASE_BROKERAGE_FEES'
      ? true
      : hasConfirmBusinessCaseSellingPaymentManuallyPermission;

  const handleUpdateSegment = (val: string) => {
    if (!isSegmentValue(val)) {
      return;
    }

    setSegmentValue(val);
  };

  const handlePayment = () => {
    if (isNil(props.payment.proformaInvoiceId)) {
      return;
    }

    openDialog(
      <PaymentForm
        invoiceId={props.payment.proformaInvoiceId}
        documentType="invoice_proforma"
        checkoutId={props.checkoutId}
        orderId={props.order.id}
        isPartialPaymentEnabled
        payment={props.payment}
        data-testid={suffixTestId('paymentForm', props)}
        businessCaseId={businessCaseId}
      />,
      {
        title: i18n.t('entity.invoice.labels.paymentOfInvoice', {
          number: last(props.payment.proformaInvoices)?.name,
        }),
        scrollBehavior: 'outside',
      }
    );
  };

  return (
    <VStack spacing={4}>
      <PaymentPriceBox payment={props.payment} />
      <Separator spacing={0} />
      <Box maxWidth={73}>
        <Segment value={segmentValue} options={segmentOptions} onChange={handleUpdateSegment} />
      </Box>

      <Show when={segmentValue === 'detail'}>
        <DataStatus isLoading={isNilOrEmpty(detailRows)}>
          <Attributes
            size="quarter"
            rows={detailRows}
            data-testid={suffixTestId('paymentDetailAttributes', props)}
          />
        </DataStatus>
      </Show>

      <Show when={segmentValue === 'payments'}>
        <PaymentsTable
          payment={props.payment}
          orderId={props.order.id}
          checkoutId={props.checkoutId}
          orderDiscriminator={props.order.orderDiscriminator}
        />
      </Show>

      <Right>
        <Show when={props.canAddAnotherPayment}>
          <Box flexGrow={1}>
            <Button
              variant="link"
              leftIcon="content/add_circle"
              onClick={props.handleAddAnotherPayment}
              isLoading={props.isAddPaymentPending}
              isDisabled={props.isFetchingOrder}
              title={i18n.t('entity.checkout.addAnotherPayment')}
              data-testid={testIds.businessCase.checkout('addAnotherPayment')}
            />
          </Box>
        </Show>

        <Show when={isPaymentPending}>
          <ButtonGroup
            align="right"
            buttons={[
              {
                title: i18n.t('entity.order.actions.pay'),
                onClick: handlePayment,
                isDisabled: !canConfirmPaymentManually,
                'data-testid': suffixTestId('pay', props),
              },
            ]}
          />
        </Show>
      </Right>
    </VStack>
  );
}
