import {closeCurrentDialog, openDialog} from 'platform/components';

import {
  useGetCheckoutAllowedCustomerQuery,
  useGetCheckoutBillingInformationQuery,
  usePutCheckoutSetBillingInformationMutation,
  usePutCheckoutSetContractInformationMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {Nullish} from 'shared';

import {handleApiError} from '../../../utils/handleApiError';
import {CustomerMatchOrCreate} from '../../CustomerWidgetCard/components/CustomerMatchOrCreate';
import {getAllowedCustomerLabel} from '../utils/getAllowedCustomerLabel';

export function useBillingInformation(checkoutId: string) {
  const {
    data: allowedCustomers,
    isLoading: areAllowedCustomersLoading,
    isError: areAllowedCustomersError,
  } = useGetCheckoutAllowedCustomerQuery({checkoutId});

  const {
    data: billingInformation,
    isLoading: isBillingInformationLoading,
    isError: isBillingInformationError,
  } = useGetCheckoutBillingInformationQuery({checkoutId});

  const [putCheckoutSetBillingInformation, {isLoading: isSettingBillingInformationLoading}] =
    usePutCheckoutSetBillingInformationMutation();
  const [putCheckoutSetContractInformation] = usePutCheckoutSetContractInformationMutation();

  const handleChangeContractInformation = (customerContractInformationId: string) =>
    putCheckoutSetContractInformation({
      checkoutId,
      body: {customerContractInformationId},
    })
      .unwrap()
      .catch(handleApiError);

  const handleChangeBillingRecipient = (values: string[] | Nullish) => {
    if (values?.includes('OTHER')) {
      return openDialog(
        <CustomerMatchOrCreate
          secondStepComponentType="BUSINESS_CASE"
          onCustomer={(customer) =>
            putCheckoutSetBillingInformation({
              checkoutId,
              body: {billingRecipient: 'OTHER', customerId: customer.id},
            })
              .unwrap()
              .then(closeCurrentDialog)
              .catch(handleApiError)
          }
        />,
        {title: i18n.t('entity.customer.labels.customer')}
      );
    }

    const selectedCustomer = allowedCustomers?.find((customer) =>
      values?.includes(customer.billingRecipient)
    );

    if (selectedCustomer) {
      putCheckoutSetBillingInformation({checkoutId, body: selectedCustomer})
        .unwrap()
        .catch(handleApiError);
    }
  };

  const allowedCustomerOptions =
    allowedCustomers?.map((customer) => ({
      label: getAllowedCustomerLabel(customer.billingRecipient),
      value: customer.billingRecipient,
    })) ?? [];

  const isLoading = isBillingInformationLoading || areAllowedCustomersLoading;

  const isError = isBillingInformationError || areAllowedCustomersError;

  return {
    isLoading,
    isError,
    allowedCustomerOptions,
    billingInformation,
    isSettingBillingInformationLoading,
    handleChangeBillingRecipient,
    handleChangeContractInformation,
  };
}
