import {Form, FormButton, FormField, showNotification} from 'platform/components';
import {Box, Grid, GridItem, VStack} from 'platform/foundation';
import {object, ref} from 'yup';

import {useChangeCurrentUserPasswordMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {yupString} from 'shared';

type ChangePasswordFormType = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export function ChangePasswordForm() {
  const [changePassword] = useChangeCurrentUserPasswordMutation();

  const handleSubmit = async ({currentPassword, newPassword}: ChangePasswordFormType) => {
    await changePassword({
      changeCurrentUserPasswordRequestBody: {
        currentPassword: currentPassword,
        newPassword: newPassword,
      },
    })
      .unwrap()
      .then(() => showNotification.success(i18n.t(`general.notifications.passwordChanged`)))
      .catch(handleApiError);
  };

  return (
    <Form<ChangePasswordFormType>
      shouldWatchForUnsavedChanges
      defaultValues={{
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      }}
      schema={schema}
      onSubmit={handleSubmit}
    >
      {(control, formApi) => (
        <VStack spacing={6}>
          <FormField
            control={control}
            isRequired
            type="password"
            name="currentPassword"
            label={i18n.t('page.myProfile.labels.currentPassword')}
            data-testid={testIds.myProfile.security('currentPassword')}
          />

          <Grid columns={2} spacing={4}>
            <GridItem>
              <FormField
                control={control}
                type="password"
                isRequired
                name="newPassword"
                label={i18n.t('page.myProfile.labels.newPassword')}
                data-testid={testIds.myProfile.security('newPassword')}
              />
            </GridItem>
            <GridItem>
              <FormField
                control={control}
                isRequired
                type="password"
                name="confirmPassword"
                label={i18n.t('page.myProfile.labels.confirmPassword')}
                data-testid={testIds.myProfile.security('confirmPassword')}
              />
            </GridItem>
          </Grid>

          <Box maxWidth={18}>
            <FormButton
              type="submit"
              isDisabled={!formApi.formState.isValid}
              title={i18n.t('general.actions.change')}
              data-testid={testIds.myProfile.security('changePassword')}
              control={control}
            />
          </Box>
        </VStack>
      )}
    </Form>
  );
}

const passwordValidation = yupString
  .required(i18n.t('general.notifications.errorPasswordsRequired'))
  .min(8, i18n.t('general.notifications.errorPasswordsLength'))
  .matches(/^(?:[A-Z]|[a-z]|[0-9]|.){8,}$/, {
    message: i18n.t('general.notifications.errorPasswordsFormat'),
  })
  .matches(/[A-Z]/, {
    message: i18n.t('general.notifications.errorPasswordsUppercase'),
  })
  .matches(/[a-z]/, {
    message: i18n.t('general.notifications.errorPasswordsLowercase'),
  })
  .matches(/[0-9]/, {
    message: i18n.t('general.notifications.errorPasswordsDigit'),
  });

const schema = object().shape({
  currentPassword: yupString.required(),
  newPassword: passwordValidation,
  confirmPassword: yupString
    .required()
    .oneOf([ref('newPassword'), null], i18n.t('general.notifications.errorPasswordsNotMatch')),
});
