import {Card, useDialog} from 'platform/components';

import {useDispatch} from 'react-redux';

import {mergeAll} from 'ramda';

import {documentContextApi, useGetTireOrderQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {Section, UploadDocumentsDialog, useGetDocumentDatagridActions} from '@omnetic-dms/shared';

import {DataGrid, QueryFilterObject, useRefreshDataGrid} from 'features/datagrid';

import {useTiresInventoryUrl} from '../../hooks/useTiresInventoryUrl';

export function TireOrderDocuments() {
  const {orderId} = useTiresInventoryUrl();

  const [isUploadDialogOpen, openUploadDialog, closeUploadDialog] = useDialog();

  const dispatch = useDispatch();

  const {data: tireOrder} = useGetTireOrderQuery({orderId});

  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const [actionCallback] = useGetDocumentDatagridActions(refreshDataGrid, tireOrder?.customerId);

  const queryModifier = (filter: QueryFilterObject) => mergeAll([filter, {tireOrderId: orderId}]);

  const onDocumentCreated = () => {
    refreshDataGrid();

    dispatch(documentContextApi.util.invalidateTags([{type: 'documentsCount', id: orderId ?? ''}]));
  };

  return (
    <Section data-testid={testIds.tiresInventory.tireOrderDetail('documents')}>
      <Card
        title={i18n.t('page.customer.documents.title')}
        actions={[
          {
            variant: 'link',
            type: 'button',
            leftIcon: 'action/backup',
            onClick: openUploadDialog,
            title: i18n.t('entity.document.actions.uploadDocument'),
          },
        ]}
      >
        <DataGrid
          autoHeight
          ref={dataGridRef}
          gridCode="document-context-list-tire-order"
          actionCallback={actionCallback}
          data-testid={testIds.tiresInventory.tireOrderDetail('documents')}
          emptyState={{
            headline: i18n.t('page.customer.notifications.noDocuments'),
            subheadline: i18n.t('page.customer.notifications.documentListWillAppearHere'),
          }}
          queryModifier={queryModifier}
        />
      </Card>
      <UploadDocumentsDialog
        isOpen={isUploadDialogOpen}
        handleClose={closeUploadDialog}
        onDocumentCreated={onDocumentCreated}
        contextTarget="tire-order"
        contextId={orderId}
      />
    </Section>
  );
}
