import '@ag-grid-enterprise/core';
import {IconButton, Tooltip, useLocalStorage, useTranslationContext} from 'platform/components';
import {HStack} from 'platform/foundation';

import {useMemo} from 'react';

import {Box} from '@chakra-ui/react';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {QUICK_FILTER_VALUE_STORAGE_KEY} from '../const/localStorage';
import {useDataGridConfig} from '../context/useDatagridConfig';
import {useDataGridFiltersModel} from '../context/useDataGridFiltersModel';
import {useGridTheme} from '../hooks/useGridTheme';
import {usePresets} from '../hooks/usePresets';
import {useResetFilterByUser} from '../hooks/useResetFilterByUser';
import {ColumnApi, GridApi} from '../types/AgGridTypes';
import {FeGridSettings, GetDataQueryResponse, TransformedDefinitionApiResponse} from '../types/Api';
import {DataGridProps} from '../types/DataGridProps';
import {DefaultResetFilterButtonRenderer} from './DefaultResetFilterButtonRenderer';
import {ExportButton} from './ExportButton/ExportButton';
import {PresetsPanelRenderer} from './PresetPanel/PresetsPanelRenderer';
import {QuickFilterWrapper} from './QuickFilterWrapper';
import {SharePresetButton} from './SharePresetButton/SharePresetButton';

export type DataGridHeaderProps = {
  dataGridSettings: FeGridSettings;
  dataGridProps: DataGridProps;
  gridApi: GridApi;
  columnApi: ColumnApi;
  definition: TransformedDefinitionApiResponse;
  dataQuery: GetDataQueryResponse | Nullish;
  setSettings: (settings: FeGridSettings) => void;
  refreshData: () => void;
} & TestIdProps;

export function DataGridHeader(props: DataGridHeaderProps) {
  const {
    dataGridSettings,
    setSettings,
    dataGridProps,
    gridApi,
    columnApi,
    definition,
    refreshData,
  } = props;

  const {buildAbsoluteUrl} = useDataGridConfig();
  const {headerTheme} = useGridTheme(dataGridSettings, definition);
  const {isFilterActive} = useDataGridFiltersModel();
  const presetsPanelProps = usePresets(gridApi, dataGridSettings, props.dataQuery, setSettings);

  const showHeaderPanel = useMemo(
    () => definition.behavior.smartSearchEnabled || definition.behavior.presetsEnabled,
    [definition.behavior.smartSearchEnabled, definition.behavior.presetsEnabled]
  );
  const [quickFilterValue, _] = useLocalStorage<string | null>(
    `${QUICK_FILTER_VALUE_STORAGE_KEY}${dataGridProps.gridCode}`,
    ''
  );

  const resetFilter = useResetFilterByUser(gridApi, columnApi);
  const t = useTranslationContext();

  if (!showHeaderPanel) {
    return null;
  }

  return (
    <Box __css={headerTheme} data-testid={suffixTestId('headerWrapper', props)}>
      <Box className="ag-custom-header-panel">
        {definition.behavior.smartSearchEnabled && (
          <Box flexShrink={0}>
            <QuickFilterWrapper
              gridProps={dataGridProps}
              gridApi={gridApi}
              data-testid={props['data-testid']}
            />
          </Box>
        )}
        {definition.behavior.presetsEnabled && (
          <PresetsPanelRenderer
            {...presetsPanelProps}
            data-testid={props['data-testid']}
            gridApi={props.gridApi}
          />
        )}
        {buildAbsoluteUrl && (
          <HStack grow={1} justify="flex-end">
            <Tooltip label={t('page.datagrid.button.refreshData')}>
              <IconButton
                aria-label={t('page.datagrid.button.refreshData')}
                icon="navigation/refresh"
                onClick={refreshData}
                data-testid={suffixTestId('refreshDataIconButton', props)}
              />
            </Tooltip>
            <SharePresetButton data-testid={props['data-testid']} />
          </HStack>
        )}
        <ExportButton gridApi={gridApi} data-testid={suffixTestId('exportButton', props)} />
      </Box>
      <Box>
        {(quickFilterValue || isFilterActive) && (
          <DefaultResetFilterButtonRenderer
            resetFilter={resetFilter}
            data-testid={props['data-testid']}
          />
        )}
      </Box>
    </Box>
  );
}
