import {
  Button,
  ButtonGroup,
  Card,
  Flags,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Label,
  openDeleteDialog,
  showNotification,
} from 'platform/components';
import {Grid, Show, VStack} from 'platform/foundation';
import {useCurrencySymbolFormatter} from 'platform/locale';
import {boolean, object} from 'yup';

import {isNotEmpty} from 'ramda';

import {
  MakeFieldDefinitionResponseBody,
  useDeleteFieldDefinitionMutation,
  useGetTenantQuery,
  useUpdateFieldDefinitionMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';
import {NoPermissionTooltip, handleApiError, usePermissions} from '@omnetic-dms/shared';
import {TextField} from '@omnetic-dms/teas';

import {RequiredTestIdProps, suffixTestId, yupString} from 'shared';

import {DefinitionFormValues} from '../types/DefinitionFormValues';
import {getAllowedValuesFlagProps} from '../utils/getAllowedValuesFlagProps';
import {getValueTypeLabel} from '../utils/getValueTypeLabel';

interface DefinitionFormRowProps extends RequiredTestIdProps {
  definition: MakeFieldDefinitionResponseBody;
  handleMoveItem: (direction: 'up' | 'down') => void;
  isFirstDefinition: boolean;
  isLastDefinition: boolean;
}

export function DefinitionFormRow(props: DefinitionFormRowProps) {
  const formatCurrencySymbol = useCurrencySymbolFormatter();

  const [canUpdateDefinition, canRemoveDefinition] = usePermissions({
    permissionKeys: ['updateCustomFieldsDefinitions', 'removeCustomFieldsDefinitions'],
  });

  const {data: tenant} = useGetTenantQuery();
  const currencySymbol = formatCurrencySymbol(tenant?.currency);

  const [deleteDefinition] = useDeleteFieldDefinitionMutation();
  const [updateDefinition] = useUpdateFieldDefinitionMutation();

  const handleDeleteDefinition = () =>
    openDeleteDialog({
      text: i18n.t('page.generalSettings.labels.deleteDefinitionDialogText', {
        name: props.definition.name,
      }),
      'data-testid': testIds.settings.customFields('deleteRoleConfirmDialog'),
      onConfirm: async () => {
        await deleteDefinition({
          definitionId: props.definition.id,
          resourceId: props.definition.resource.id,
        })
          .unwrap()
          .catch(handleApiError);
      },
    });

  const handleUpdateDefinition: FormSubmitHandler<DefinitionFormValues> = async (submitData) => {
    await updateDefinition({
      body: {
        hint: submitData.hint,
        name: submitData.name,
        isRequired: submitData.isRequired,
      },
      resourceId: props.definition.resource.id,
      definitionId: props.definition.id,
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .catch(handleApiError);
  };

  const hasAllowedValues = isNotEmpty(props.definition.allowedValues);
  const valueTypeLabel = getValueTypeLabel({...props.definition, currencySymbol});

  return (
    <Form<DefinitionFormValues>
      schema={schema}
      onSubmit={handleUpdateDefinition}
      defaultValues={{
        hint: props.definition.hint,
        name: props.definition.name,
        valueType: props.definition.valueType,
        allowedValues: props.definition.allowedValues.map((value) => value.value?.toString()),
        isRequired: props.definition.isRequired,
      }}
    >
      {(control) => (
        <Card
          variant="inlineGrey"
          isExpandable
          isClosedByDefault
          title={props.definition.name}
          subtitle={valueTypeLabel}
          data-testid={testIds.settings.customFields(`card-${props.definition.resource.id}`)}
          actions={[
            {
              type: 'iconButton',
              icon: 'navigation/arrow_upward',
              onClick: () => props.handleMoveItem('up'),
              isDisabled: props.isFirstDefinition,
            },
            {
              type: 'iconButton',
              icon: 'navigation/arrow_downward',
              onClick: () => props.handleMoveItem('down'),
              isDisabled: props.isLastDefinition,
            },
          ]}
        >
          <VStack spacing={4}>
            <Grid columns={3}>
              <TextField
                label={i18n.t('page.generalSettings.labels.valueType')}
                value={valueTypeLabel}
                data-testid={suffixTestId('valueType', props)}
                disabled
                required
              />

              <FormField
                type="text"
                control={control}
                name="name"
                label={i18n.t('page.generalSettings.labels.name')}
                data-testid={suffixTestId('name', props)}
                isRequired
              />

              <FormField
                type="text"
                control={control}
                name="hint"
                label={i18n.t('page.generalSettings.labels.hint')}
                data-testid={suffixTestId('hint', props)}
                isRequired
              />

              <FormField
                type="checkbox"
                control={control}
                name="isRequired"
                label={i18n.t('page.generalSettings.labels.isFieldRequired')}
                data-testid={suffixTestId('isFieldRequired', props)}
              />
            </Grid>

            <Show when={hasAllowedValues}>
              <VStack>
                <Label>{i18n.t('page.generalSettings.labels.allowedValues')}</Label>
                <Flags
                  colorScheme="black"
                  flags={getAllowedValuesFlagProps(props.definition.allowedValues)}
                  data-testid={suffixTestId('flags', props)}
                  shouldWrap
                />
              </VStack>
            </Show>

            <ButtonGroup align="right">
              <Show when={canRemoveDefinition}>
                <Button
                  leftIcon="action/delete"
                  title={i18n.t('general.labels.delete')}
                  variant="dangerGhost"
                  onClick={handleDeleteDefinition}
                  data-testid={suffixTestId('delete', props)}
                />
              </Show>

              <NoPermissionTooltip shouldShowTooltip={!canUpdateDefinition}>
                <FormButton
                  leftIcon="content/save"
                  title={i18n.t('general.actions.saveChanges')}
                  data-testid={suffixTestId('saveChanges', props)}
                  control={control}
                  type="submit"
                  isDisabled={!canUpdateDefinition}
                />
              </NoPermissionTooltip>
            </ButtonGroup>
          </VStack>
        </Card>
      )}
    </Form>
  );
}

const schema = object({
  name: yupString.required(),
  hint: yupString.required(),
  isRequired: boolean().default(false),
});
