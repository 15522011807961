import {DropdownItem, FormControl, showNotification} from 'platform/components';

import {UseFormReturn} from 'react-hook-form';

import {isEmpty} from 'ramda';

import {
  ContextTemplateResponseBody,
  GetContextTemplateListApiResponse,
  useRenderCustomerEmailTemplateMutation,
} from '@omnetic-dms/api';
import {i18n} from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';

import {composePath, useNavigate} from 'shared';

import {VARIABLE_REGEX} from '../../../utils/getVariables';
import {handleApiError} from '../../../utils/handleApiError';
import {replaceVariables} from '../../../utils/replaceVariables';
import {SendEmailFormValues} from '../types/SendEmailFormValues';

type Props = {
  formApi: UseFormReturn<SendEmailFormValues>;
  control: FormControl<SendEmailFormValues>;
  customerId?: string | null;
  renderTemplate: ReturnType<typeof useRenderCustomerEmailTemplateMutation>[0];
  templates: GetContextTemplateListApiResponse | undefined;
};

export const TemplatesEmailDropdownContent = (props: Props) => {
  const navigate = useNavigate();

  const onTemplateClick = (template: ContextTemplateResponseBody) =>
    props
      .renderTemplate({
        renderCustomerEmailTemplateRequestBody: {
          template: {
            subject: template.subject ?? '',
            body: template.body,
          },
          customerId: props.customerId ?? '',
        },
      })
      .unwrap()
      .then((response) => {
        props.formApi.setValue('subject', replaceVariables(response.subject));
        props.formApi.setValue('body', replaceVariables(response.body));

        if (VARIABLE_REGEX.test(response.subject) || VARIABLE_REGEX.test(response.body)) {
          showNotification.warning(i18n.t('general.notifications.notAllPlaceholdersFilled'));
        }
      })
      .catch(handleApiError);

  if (isEmpty(props.templates)) {
    return (
      <DropdownItem
        label={i18n.t('general.actions.addNewTemplate')}
        onClick={() =>
          navigate(
            composePath(settingsRoutes.messageTemplatesNew, {
              params: {type: 'email'},
            })
          )
        }
      />
    );
  }

  return (
    <>
      {props.templates?.map((template) => (
        <DropdownItem
          label={template.name}
          key={template.id}
          onClick={() => onTemplateClick(template)}
        />
      ))}
    </>
  );
};
