import validatePhone from 'phone';
import {
  Form,
  FormField,
  FormSubmitHandler,
  Label,
  TextInput,
  showNotification,
  PhoneNumber,
  DataStatus,
} from 'platform/components';
import {VStack, Text, Box, Grid, GridItem} from 'platform/foundation';
import {mixed, object, string} from 'yup';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetCurrentUserInfoQuery,
  useGetTenantQuery,
  usePatchCurrentUserMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {myProfileRoutes, testIds} from '@omnetic-dms/routes';
import {usePhoneNumbers} from '@omnetic-dms/shared';

import {Nullish, useNavigate} from 'shared';

import {MyProfileFooter} from '../../../components/MyProfileFooter';

type PersonalInformationFormType = {
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumber;
};

export function PersonalInformationForm() {
  const navigate = useNavigate();
  const {countriesOptions} = usePhoneNumbers();
  const {
    data: user,
    isLoading: isUserLoading,
    isError: hasUserError,
  } = useGetCurrentUserInfoQuery();
  const [patchUser] = usePatchCurrentUserMutation();
  const {data: tenant, isLoading: isTenantLoading, isError: hasTenantError} = useGetTenantQuery();

  const handleSubmit: FormSubmitHandler<PersonalInformationFormType> = async ({
    firstName,
    lastName,
    phoneNumber,
  }) => {
    const args = {
      updateCurrentUserRequestBody: {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
      },
    };

    const res = await patchUser(args).unwrap();

    if (res && res.id) {
      showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      return;
    }

    showNotification.error(i18n.t('general.labels.somethingWentWrong'));
  };

  const isLoading = isUserLoading || isTenantLoading;
  const hasError = hasUserError || hasTenantError;

  return (
    <DataStatus isLoading={isLoading} isError={hasError} minHeight={50}>
      <Box maxWidth={200}>
        <VStack spacing={6}>
          <Form<PersonalInformationFormType>
            shouldWatchForUnsavedChanges
            defaultValues={{
              firstName: user?.firstName ?? undefined,
              lastName: user?.lastName ?? undefined,
              phoneNumber: (user?.phoneNumber as PhoneNumber) ?? {
                prefix: '',
                number: '',
                countryCode: tenant?.country,
              },
            }}
            schema={schema}
            onSubmit={handleSubmit}
          >
            {(control) => (
              <>
                <Grid columns={2} spacing={6}>
                  <GridItem>
                    <FormField
                      control={control}
                      isRequired
                      type="text"
                      name="firstName"
                      label={i18n.t('entity.person.labels.firstName')}
                      data-testid={testIds.myProfile.personalInformation('firstName')}
                    />
                  </GridItem>
                  <GridItem>
                    <FormField
                      control={control}
                      isRequired
                      type="text"
                      name="lastName"
                      label={i18n.t('entity.person.labels.lastName')}
                      data-testid={testIds.myProfile.personalInformation('lastName')}
                    />
                  </GridItem>

                  <GridItem>
                    <TextInput
                      label={i18n.t('general.labels.email')}
                      value={user?.email ?? ''}
                      data-testid={testIds.myProfile.personalInformation('email')}
                      isDisabled
                    />
                  </GridItem>
                  <GridItem>
                    <FormField
                      type="phone"
                      control={control}
                      name="phoneNumber"
                      label={i18n.t('entity.person.labels.phoneNumber')}
                      countries={countriesOptions}
                      data-testid={testIds.myProfile.personalInformation('phoneNumber')}
                    />
                  </GridItem>
                </Grid>

                <MyProfileFooter
                  actions={[
                    {
                      type: 'button',
                      onClick: () => navigate(myProfileRoutes.personalInformation),
                      title: i18n.t('general.actions.discardChanges'),
                      variant: 'secondary',
                      'data-testid': testIds.myProfile.personalInformation('discard'),
                    },
                    {
                      type: 'form-button',
                      control,
                      buttonType: 'submit',
                      title: i18n.t('general.actions.saveAndClose'),
                      'data-testid': testIds.myProfile.personalInformation('save'),
                    },
                  ]}
                />
              </>
            )}
          </Form>

          <VStack spacing={6}>
            <VStack spacing={1} data-testid={testIds.myProfile.personalInformation('roles')}>
              <Label>{`${i18n.t('page.settings.labels.assignedRole')}}:`}</Label>
              <Text>
                {user?.roles?.length
                  ? user.roles
                      .map((role) => role.title)
                      .join(', ')
                      .toString()
                  : '-'}
              </Text>
            </VStack>

            <VStack spacing={1} data-testid={testIds.myProfile.personalInformation('branches')}>
              <Label>{`${i18n.t('page.settings.labels.assignedBranches')}:`}</Label>
              <Text>
                {user?.branches?.length
                  ? user.branches
                      .map((branch) => branch.name)
                      .join(', ')
                      .toString()
                  : '-'}
              </Text>
            </VStack>
          </VStack>
        </VStack>
      </Box>
    </DataStatus>
  );
}

const schema = object({
  firstName: string().required().nullable(),
  lastName: string().required().nullable(),
  phoneNumber: mixed()
    .test(
      'isPhone',
      i18n.t('general.validations.invalidPhoneNumber'),
      (phone: PhoneNumber | Nullish) => {
        if (isNilOrEmpty(phone?.number)) {
          return true;
        }

        return validatePhone(phone?.number || '', {
          country: phone?.countryCode,
          validateMobilePrefix: false,
        }).isValid;
      }
    )
    .nullable(),
});
