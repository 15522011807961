import {openDialog} from 'platform/components';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps} from 'shared';

import {EditTask} from '../components/EditTask/EditTask';

interface OpenEditTaskFormDialogProps extends RequiredTestIdProps {
  id: string;
  onEdit?: () => void;
  onDelete?: () => void;
}

export function openEditTaskFormDialog(props: OpenEditTaskFormDialogProps) {
  openDialog(
    <EditTask
      onEdit={props.onEdit}
      onDelete={props.onDelete}
      id={props.id}
      data-testid={props['data-testid']}
    />,
    {
      'data-testid': props['data-testid'],
      title: i18n.t('entity.task.actions.editTask'),
      size: 'large',
      scrollBehavior: 'outside',
      withAdditionalFooter: true,
    }
  );
}
