import {Button, Textarea} from 'platform/components';
import {Box, HStack} from 'platform/foundation';

import {useState} from 'react';

import {isNilOrEmpty} from 'ramda-adjunct';

import {useSourcingCreateCommentMutation} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface NewCommentProps extends RequiredTestIdProps {
  readonly vehicleId: string;
}

export function NewComment(props: NewCommentProps) {
  const [message, setMessage] = useState<string | null>('');
  const [createComment, {isLoading}] = useSourcingCreateCommentMutation();

  const handleClick = () => {
    createComment({
      externalId: props.vehicleId,
      body: {
        message: message ?? '',
        parentCommentUuid: null,
      },
    })
      .unwrap()
      .then(() => {
        setMessage('');
      });
  };

  return (
    <HStack spacing={4} justify="space-between">
      <Box flex={1}>
        <Textarea
          value={message}
          onChange={setMessage}
          placeholder={i18n.t('page.comments.actions.writeAComment')}
          data-testid={suffixTestId('newComment', props)}
        />
      </Box>
      <Button
        title={i18n.t('general.actions.send')}
        onClick={handleClick}
        isLoading={isLoading}
        isDisabled={isNilOrEmpty(message)}
        data-testid={suffixTestId('sendComment', props)}
      />
    </HStack>
  );
}
