import {useSubscription} from '@apollo/client';

import {isNotNil} from 'ramda';

import {
  AuditDataVideoAssetsFilesBody,
  SEND_NOTIFICATION_SUBSCRIPTION,
  useLazyGetSentBackgroundNotificationQuery,
} from '@omnetic-dms/api';
import {handleApiError, notificationTypes} from '@omnetic-dms/shared';

import {PendingVideo} from '../types/PendingVideo';

interface UseVideoNotificationSubscriptionProps {
  pendingVideos: PendingVideo[];
  videoAssets: AuditDataVideoAssetsFilesBody[];
  onVideoProcessed: (pendingVideo: PendingVideo, fileStatus: string, fileType: string) => void;
  onVideoVariantGenerated: (videoAssetId: string) => void;
}

export function useVideoNotificationSubscription(props: UseVideoNotificationSubscriptionProps) {
  const [getBackgroundNotification] = useLazyGetSentBackgroundNotificationQuery();

  useSubscription(SEND_NOTIFICATION_SUBSCRIPTION, {
    onData: ({data: subscription}) => {
      const backgroundNotificationId: string | null =
        subscription.data?.onSendNotification?.backgroundNotificationId;

      if (isNotNil(backgroundNotificationId)) {
        getBackgroundNotification({id: backgroundNotificationId})
          .unwrap()
          .then((notification) => {
            // Detect video processed successfully, so we can assign it to the audit
            if (notification.type === notificationTypes.FILE_STORAGE_FILE_UPLOADED) {
              const {fileId, fileStatus, fileType} = notification.payload;
              const pendingVideo = props.pendingVideos?.find(
                (pendingVideo) => pendingVideo.videoId === fileId
              );

              if (isNotNil(pendingVideo)) {
                props.onVideoProcessed(pendingVideo, fileStatus, fileType);
              }
            }

            // Detect a new video variant was generated, so we can update the cover and/or the preview
            if (notification.type === notificationTypes.FILE_STORAGE_FILE_VARIANT_UPLOADED) {
              const videoAssetId = props.videoAssets?.find(
                (asset) => asset.videoId === notification.payload.originalFileId
              )?.id;

              if (isNotNil(videoAssetId) && notification.payload.fileStatus === 'success') {
                props.onVideoVariantGenerated(videoAssetId);
              }
            }
          })
          .catch(handleApiError);
      }
    },
  });
}
