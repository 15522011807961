import {Breadcrumbs, BreadcrumbType, Separator} from 'platform/components';
import {Box, Heading, HStack, Show, Space, VStack} from 'platform/foundation';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {suffixTestId, TestIdProps} from 'shared';

export interface MyProfileHeaderProps extends TestIdProps {
  title: string;
  breadcrumbs: BreadcrumbType[];
}

export function MyProfileHeader(props: MyProfileHeaderProps) {
  return (
    <VStack>
      <Box paddingTop={6} paddingHorizontal={6} paddingBottom={6}>
        <Show when={isNotNilOrEmpty(props.breadcrumbs)}>
          <Breadcrumbs
            breadcrumbs={props.breadcrumbs}
            data-testid={suffixTestId('settingsHeader-breadcrumbs', props)}
          />
          <Space vertical={8} />
        </Show>
        <HStack align="center" spacing={3} justify="space-between">
          <Heading size={1} data-testid={suffixTestId('settingsHeader-title', props)}>
            {props.title}
          </Heading>
        </HStack>
      </Box>
      <Separator spacing={0} />
    </VStack>
  );
}
