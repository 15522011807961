import {captureException} from '@sentry/browser';

import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  branchSlice,
  cachedApi,
  fileStorageApi,
  metadaApi,
  omneticApi,
  publicApi,
} from '@omnetic-dms/api';
import {teasReducers} from '@omnetic-dms/teas';

import {duplicateKeysCheckTransform} from '../utils/duplicateKeysCheckTransform';
import {getLocalStorageValuesSize} from '../utils/getLocalStorageValuesSize';
import {languageCheckTransform} from '../utils/languageCheckTransform';

const handleSetStorageItemError = (error: Error) => {
  const localStorageValueSizes = getLocalStorageValuesSize();
  captureException(error + '\n' + localStorageValueSizes);
};

export const reducers = combineReducers({
  ...teasReducers,
  // RTK Query
  [publicApi.reducerPath]: publicApi.reducer,
  [omneticApi.reducerPath]: omneticApi.reducer,
  [metadaApi.reducerPath]: metadaApi.reducer,
  [fileStorageApi.reducerPath]: fileStorageApi.reducer,
  [cachedApi.reducerPath]: persistReducer(
    {
      key: cachedApi.reducerPath,
      writeFailHandler: handleSetStorageItemError,
      storage,
      transforms: [languageCheckTransform, duplicateKeysCheckTransform],
    },
    cachedApi.reducer
  ),
  [branchSlice.name]: branchSlice.reducer,
});

export type RootState = ReturnType<typeof reducers>;
