import {DataStatus} from 'platform/components';
import {match} from 'ts-pattern';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  AftersalesPaymentMethod,
  EntityResourceIds,
  useGetCheckoutLinkForServiceOrderQuery,
  useGetServiceOrderQuery,
  useGetServiceOrderVariantPaymentTypeQuery,
} from '@omnetic-dms/api';
import {AftersalesCheckout} from '@omnetic-dms/shared';

import {buildArray, RequiredTestIdProps} from 'shared';

import {useWorkshopUrl} from '../../../../../hooks/useWorkshopUrl';

interface CheckoutProps extends RequiredTestIdProps {}

export function CheckoutV2(props: CheckoutProps) {
  const {orderId, serviceCaseId} = useWorkshopUrl();

  const {
    data: checkout,
    isLoading: isCheckoutLoading,
    isError: isCheckoutError,
  } = useGetCheckoutLinkForServiceOrderQuery(
    {serviceOrderId: orderId},
    {refetchOnMountOrArgChange: true}
  );

  const {
    data: serviceOrder,
    isLoading: isServiceOrderLoading,
    isError: isServiceOrderError,
  } = useGetServiceOrderQuery({
    serviceCaseId,
    serviceOrderId: orderId,
  });

  const {
    data: orderVariantPaymentType,
    isLoading: isOrderVariantPaymentTypeLoading,
    isError: isOrderVariantPaymentTypeError,
  } = useGetServiceOrderVariantPaymentTypeQuery(
    {serviceOrderVariantId: serviceOrder?.serviceOrderVariantId ?? ''},
    {skip: isNilOrEmpty(serviceOrder?.serviceOrderVariantId)}
  );

  const disallowedPaymentMethods = buildArray<AftersalesPaymentMethod>()
    .whenNot(orderVariantPaymentType?.bankPayment?.isAllowed, 'BANK_TRANSFER')
    .whenNot(orderVariantPaymentType?.cardPayment?.isAllowed, 'CARD')
    .whenNot(orderVariantPaymentType?.cashPayment?.isAllowed, 'CASH')
    .whenNot(orderVariantPaymentType?.internalPayment?.isAllowed, 'INTERNAL');

  const orderVariantDefaultPaymentType = orderVariantPaymentType?.defaultPaymentType;

  const defaultPaymentType = match<
    typeof orderVariantDefaultPaymentType,
    AftersalesPaymentMethod | undefined
  >(orderVariantDefaultPaymentType)
    .with('CARD', 'CASH', 'BANK_TRANSFER', (paymentType) => paymentType)
    .with('CASH_ON_DELIVERY', () => 'INTERNAL')
    .when(isNil, () => undefined)
    .exhaustive();

  const isLoading = isCheckoutLoading || isServiceOrderLoading || isOrderVariantPaymentTypeLoading;

  const isError = isCheckoutError || isServiceOrderError || isOrderVariantPaymentTypeError;

  return (
    <DataStatus isLoading={isLoading} isError={isError} isEmpty={isNilOrEmpty(checkout)}>
      <AftersalesCheckout
        checkoutId={checkout?.checkoutId ?? ''}
        defaultPaymentType={defaultPaymentType}
        disallowedPaymentMethods={disallowedPaymentMethods}
        recordId={orderId}
        resourceId={EntityResourceIds.serviceOrder}
        data-testid={props['data-testid']}
      />
    </DataStatus>
  );
}
