import {isFeatureEnabled} from 'feature-flags';
import {DataStatus, FormControl, FormField, getApiYearOptions} from 'platform/components';
import {Grid, Show, VStack} from 'platform/foundation';

import {UseFormReturn, useWatch} from 'react-hook-form';

import {reverse} from 'ramda';

import {VinDecoderResponseBody} from '@omnetic-dms/api';
import featureFlags from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';
import {
  hasSecondaryFuelType,
  MakeSelect,
  ModelSelect,
  SecondaryFuelTypeField,
  useVehicleCatalogue,
  VinDecoderField,
} from '@omnetic-dms/shared';

import {getApiDateString, handleFormReset, RequiredTestIdProps, suffixTestId} from 'shared';

import {BuyingFormType} from '../types/BuyingFormType';

interface BuyingVehicleFormProps extends RequiredTestIdProps {
  control: FormControl<BuyingFormType>;
  formApi: UseFormReturn<BuyingFormType>;
}

export function BuyingVehicleForm(props: BuyingVehicleFormProps) {
  const [vehicleTypes, make, fuelTypes] = useWatch({
    control: props.control,
    name: ['vehicleData.type', 'vehicleData.make', 'vehicleData.fuelType'],
  });

  const vehicleType = vehicleTypes?.[0] || 'VEHICLETYPE_PASSENGER_CAR';
  const fuelType = fuelTypes?.[0];

  const [vehicleUtils, {isLoading, isError}] = useVehicleCatalogue(vehicleType);

  const handleDecodeVin =
    (formApi: UseFormReturn<BuyingFormType>) => (decodedData?: VinDecoderResponseBody) => {
      if (decodedData?.vehicleType) {
        formApi.setValue('vehicleData.type', [decodedData.vehicleType], {shouldDirty: true});
      }
      if (decodedData?.make) {
        formApi.setValue('vehicleData.make', decodedData.make, {shouldDirty: true});
      }
      if (decodedData?.modelFamily) {
        formApi.setValue('vehicleData.modelFamily', decodedData.modelFamily, {shouldDirty: true});
      }
      if (decodedData?.trim) {
        formApi.setValue('vehicleData.trim', decodedData.trim, {shouldDirty: true});
      }
      if (decodedData?.variant) {
        formApi.setValue('vehicleData.variant', decodedData.variant, {shouldDirty: true});
      }
      if (decodedData?.manufacturedOnYear) {
        formApi.setValue('vehicleData.realProductionYear', decodedData.manufacturedOnYear, {
          shouldDirty: true,
        });
      }
      if (
        decodedData?.firstRegistrationOnYear &&
        decodedData?.firstRegistrationOnMonth &&
        decodedData?.firstRegistrationOnDay
      ) {
        formApi.setValue(
          'vehicleData.firstRegistration',
          getApiDateString(
            new Date(
              parseInt(decodedData.firstRegistrationOnYear, 10),
              parseInt(decodedData.firstRegistrationOnMonth, 10),
              parseInt(decodedData.firstRegistrationOnDay, 10)
            )
          ),
          {shouldDirty: true}
        );
      }

      if (decodedData?.otherRecords) {
        formApi.setValue('vehicleData.note', decodedData.otherRecords, {shouldDirty: true});
      }
      if (decodedData?.bodyStyle) {
        formApi.setValue('vehicleData.bodyStyle', [decodedData.bodyStyle], {shouldDirty: true});
      }
      if (decodedData?.fuelType) {
        formApi.setValue('vehicleData.fuelType', [decodedData.fuelType], {shouldDirty: true});
      }
      if (decodedData?.secondaryFuelType) {
        formApi.setValue('vehicleData.secondaryFuelType', [decodedData.secondaryFuelType], {
          shouldDirty: true,
        });
      }
      if (decodedData?.transmission) {
        formApi.setValue('vehicleData.transmission', [decodedData.transmission], {
          shouldDirty: true,
        });
      }
      if (decodedData?.drive) {
        formApi.setValue('vehicleData.drive', [decodedData.drive], {shouldDirty: true});
      }
      if (decodedData?.power) {
        formApi.setValue('vehicleData.power', parseInt(decodedData.power), {shouldDirty: true});
      }
      if (decodedData?.engineVolume) {
        formApi.setValue('vehicleData.engineVolume', parseInt(decodedData.engineVolume), {
          shouldDirty: true,
        });
      }
      if (decodedData?.maximalPower && isFeatureEnabled(featureFlags.CORE_MAX_POWER)) {
        formApi.setValue('vehicleData.maximalPower', parseInt(decodedData.maximalPower), {
          shouldDirty: true,
        });
      }
      if (decodedData?.features) {
        formApi.setValue('vehicleData.features', decodedData.features, {shouldDirty: true});
      }
      props.control.onChange();
    };

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={100}>
      <VStack spacing={4}>
        <VinDecoderField<BuyingFormType>
          control={props.control}
          formApi={props.formApi}
          registrationPlateField={{
            name: 'vehicleData.licencePlate',
            label: i18n.t('entity.vehicle.labels.licensePlate'),
          }}
          vinField={{name: 'vehicleData.vin', label: i18n.t('entity.vehicle.labels.vin')}}
          isCebiaFeatureDecoderEnabled
          onDecodedData={handleDecodeVin(props.formApi)}
          data-testid={suffixTestId('vinDecoder', props)}
        />
        <FormField
          control={props.control}
          type="chips"
          name="vehicleData.type"
          options={vehicleUtils.vehicleTypeOptions ?? []}
          label={i18n.t('entity.vehicle.labels.vehicleType')}
          isRequired
          onChange={() =>
            handleFormReset<BuyingFormType>(props.formApi, [
              {name: 'vehicleData.make', value: null},
              {name: 'vehicleData.modelFamily', value: null},
              {name: 'vehicleData.trim', value: null},
              {name: 'vehicleData.variant', value: null},
              {name: 'vehicleData.bodyStyle', value: null},
              {name: 'vehicleData.transmission', value: null},
              {name: 'vehicleData.drive', value: null},
              {name: 'vehicleData.fuelType', value: null},
              {name: 'vehicleData.secondaryFuelType', value: null},
              {name: 'vehicleData.power', value: null},
              {name: 'vehicleData.maximalPower', value: null},
              {name: 'vehicleData.engineVolume', value: null},
            ])
          }
          data-testid={suffixTestId('buyingVehicleForm-vehicleType', props)}
        />
        <Grid columns={4}>
          <MakeSelect<BuyingFormType>
            vehicleType={vehicleType}
            control={props.control}
            isRequired
            name="vehicleData.make"
            label={i18n.t('entity.vehicle.labels.make')}
            data-testid={suffixTestId('serviceVehicleForm', props)}
            onChange={() =>
              handleFormReset<BuyingFormType>(props.formApi, [
                {name: 'vehicleData.modelFamily', value: null},
                {name: 'vehicleData.trim', value: null},
                {name: 'vehicleData.variant', value: null},
                {name: 'vehicleData.power', value: null},
                {name: 'vehicleData.maximalPower', value: null},
                {name: 'vehicleData.engineVolume', value: null},
              ])
            }
          />
          <ModelSelect<BuyingFormType>
            vehicleType={vehicleType}
            makes={make}
            control={props.control}
            isRequired
            name="vehicleData.modelFamily"
            label={i18n.t('entity.vehicle.labels.model')}
            data-testid={suffixTestId('serviceVehicleForm', props)}
          />
          <FormField
            control={props.control}
            type="text"
            name="vehicleData.trim"
            label={i18n.t('entity.vehicle.labels.trimLevel')}
            data-testid={suffixTestId('buyingVehicleForm-trim', props)}
          />
          <FormField
            control={props.control}
            type="text"
            name="vehicleData.variant"
            label={i18n.t('entity.vehicle.labels.variantName')}
            data-testid={suffixTestId('buyingVehicleForm-variant', props)}
          />
        </Grid>
        <Grid columns={4}>
          <FormField
            control={props.control}
            type="choice"
            name="vehicleData.realProductionYear"
            options={reverse(getApiYearOptions(null, null))}
            label={i18n.t('entity.vehicle.labels.realProductionYear')}
            data-testid={suffixTestId('buyingVehicleForm-realProductionYear', props)}
          />
          <FormField
            control={props.control}
            type="apiDate"
            name="vehicleData.firstRegistration"
            label={i18n.t('entity.vehicle.labels.firstRegistration')}
            data-testid={suffixTestId('buyingVehicleForm-firstRegistrationOn', props)}
          />
          <FormField
            control={props.control}
            type="number"
            name="vehicleData.mileage"
            label={i18n.t('entity.vehicle.labels.mileage')}
            suffix={i18n.t('general.metric.km')}
            data-testid={suffixTestId('buyingVehicleForm-mileage', props)}
          />
        </Grid>
        <Grid columns={4}>
          <FormField
            control={props.control}
            type="number"
            name="vehicleData.power"
            suffix={i18n.t('general.metric.kW')}
            label={i18n.t('entity.vehicle.labels.power')}
            data-testid={suffixTestId('buyingVehicleForm-power', props)}
          />
          <Show whenFeatureEnabled={featureFlags.CORE_MAX_POWER}>
            <FormField
              control={props.control}
              type="integer"
              name="vehicleData.maximalPower"
              label={i18n.t(`entity.vehicle.labels.maximalPower`)}
              suffix={i18n.t('general.metric.kW')}
            />
          </Show>
          <FormField
            control={props.control}
            type="number"
            name="vehicleData.engineVolume"
            suffix={i18n.t('general.metric.ccm')}
            label={i18n.t('entity.vehicle.labels.engineCapacity')}
            data-testid={suffixTestId('buyingVehicleForm-engineCapacity', props)}
          />
        </Grid>
        <FormField
          control={props.control}
          type="chips"
          name="vehicleData.bodyStyle"
          options={vehicleUtils.vehicleStyleOptions ?? []}
          label={i18n.t('entity.vehicle.labels.body')}
          data-testid={suffixTestId('buyingVehicleForm-bodyStyle', props)}
        />
        <FormField
          control={props.control}
          type="chips"
          name="vehicleData.fuelType"
          options={vehicleUtils.fuelTypeOptions ?? []}
          label={i18n.t('entity.vehicle.labels.fuel')}
          data-testid={suffixTestId('buyingVehicleForm-fuelType', props)}
          onChange={() =>
            handleFormReset<BuyingFormType>(props.formApi, [
              {name: 'vehicleData.secondaryFuelType', value: null},
              // {name: 'vehicleData.power', value: null},
              // {name: 'vehicleData.engineVolume', value: null},
            ])
          }
        />
        <Show when={hasSecondaryFuelType(fuelType)}>
          <SecondaryFuelTypeField<BuyingFormType>
            fuelType={fuelType}
            vehicleType={vehicleType}
            control={props.control}
            name="vehicleData.secondaryFuelType"
            label={i18n.t('entity.vehicle.labels.secondaryFuelType')}
            data-testid={suffixTestId('buyingVehicleForm', props)}
            isDeselectable
          />
        </Show>
        <Grid columns={2}>
          <FormField
            control={props.control}
            type="chips"
            name="vehicleData.transmission"
            options={vehicleUtils.transmissionOptions ?? []}
            label={i18n.t('entity.vehicle.labels.transmission')}
            data-testid={suffixTestId('buyingVehicleForm-transmission', props)}
          />
          <FormField
            control={props.control}
            type="chips"
            name="vehicleData.drive"
            options={vehicleUtils.driveOptions ?? []}
            label={i18n.t('entity.vehicle.labels.drive')}
            data-testid={suffixTestId('buyingVehicleForm-drive', props)}
          />
        </Grid>
        <FormField
          control={props.control}
          type="textarea"
          name="vehicleData.note"
          label={i18n.t('entity.vehicle.labels.additionalInformation')}
          data-testid={suffixTestId('buyingVehicleForm-note', props)}
        />
      </VStack>
    </DataStatus>
  );
}
