import {Box, Center, Icon, Show, VStack, Text} from 'platform/foundation';
import styled from 'styled-components';

import {useGetMyTasksCountQuery} from '@omnetic-dms/api';

import {RequiredTestIdProps, suffixTestId} from 'shared';

interface TaskCenterHeaderButtonProps extends RequiredTestIdProps {
  isOpen: boolean;
}

export function TaskCenterHeaderButton(props: TaskCenterHeaderButtonProps) {
  const {data: tasksCount} = useGetMyTasksCountQuery();

  return (
    <HeaderButtonWrapper $isOpen={props.isOpen}>
      <VStack
        justify="center"
        align="center"
        width={12}
        height={12}
        data-testid={suffixTestId('headerButton', props)}
      >
        <Box
          position="relative"
          width={6}
          height={6}
          backgroundColor="palettes.neutral.30.100"
          borderRadius="circular"
        >
          <Center height="100%">
            <Icon size={3} value="action/check_circle" cursor="pointer" />
            <Show when={(tasksCount?.count ?? 0) > 0}>
              <Box
                position="absolute"
                bottom={3}
                left={3}
                backgroundColor="severity.danger"
                width={4}
                height={4}
                borderRadius="circular"
              >
                <Center>
                  <Text size="xSmall" alternative color="white">
                    {tasksCount?.count}
                  </Text>
                </Center>
              </Box>
            </Show>
          </Center>
        </Box>
      </VStack>
    </HeaderButtonWrapper>
  );
}

const HeaderButtonWrapper = styled.div<{$isOpen?: boolean}>`
  cursor: pointer;
  background-color: ${({theme, $isOpen}) => $isOpen && theme.colors.palettes.neutral[30][100]};
  &:hover {
    background-color: ${({theme}) => theme.colors.palettes.neutral[30][100]};
  }
`;
