import {DataStatus, TabProps, Tabs} from 'platform/components';

import {AftersalesPaymentMethod, useGetCheckoutPaymentsQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId, useQueryState} from 'shared';

import {queryParams} from '../../utils/queryParams';
import {AftersalesCheckoutBillingInformation} from '../AftersalesCheckoutBillingInformation/AftersalesCheckoutBillingInformation';
import {AftersalesCheckoutPayment} from '../AftersalesCheckoutPayment/AftersalesCheckoutPayment';

interface CheckoutProps extends RequiredTestIdProps {
  checkoutId: string;
  defaultPaymentType?: AftersalesPaymentMethod;
  disallowedPaymentMethods?: AftersalesPaymentMethod[];
  recordId?: string;
  resourceId?: string;
}

export function AftersalesCheckout(props: CheckoutProps) {
  const [checkoutTabId, setCheckoutTabId] = useQueryState(queryParams.AFTERSALES_CHECKOUT_TAB);

  const {data, isLoading, isError} = useGetCheckoutPaymentsQuery({checkoutId: props.checkoutId});

  const areAllPaymentsPaid = data?.every(({paymentState}) => paymentState === 'PAID');

  const tabs: TabProps[] = [
    {
      id: 'billingInformation',
      content: (
        <AftersalesCheckoutBillingInformation
          checkoutId={props.checkoutId}
          recordId={props.recordId}
          resourceId={props.resourceId}
          data-testid={props['data-testid']}
        />
      ),
      title: i18n.t('entity.checkout.labels.billingInformation'),
      icon: 'success',
    },
    {
      id: 'payment',
      content: (
        <AftersalesCheckoutPayment
          checkoutId={props.checkoutId}
          data-testid={props['data-testid']}
          defaultPaymentType={props.defaultPaymentType}
          disallowedPaymentMethods={props.disallowedPaymentMethods}
        />
      ),
      title: i18n.t('entity.checkout.labels.payment'),
      icon: areAllPaymentsPaid ? 'success' : 'pending',
    },
  ];

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <Tabs
        onChange={setCheckoutTabId}
        activeTabId={checkoutTabId}
        tabs={tabs}
        variant="condensed"
        data-testid={suffixTestId('checkoutTabs', props)}
        isLazy
        lazyBehavior="unmount"
      />
    </DataStatus>
  );
}
