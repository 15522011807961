import {isNotNil} from 'ramda';

type AuditDataVideoAssetsFilesBody = {
  id: string;
  videoId: string;
  url: string;
  originUrl: string | null;
  previewUrl: string | null;
  coverImageUrl: string | null;
  variants: {type: string; url?: string | null}[] | null;
};

export const isVideoPlayable = (videoAsset: AuditDataVideoAssetsFilesBody) =>
  // Has original file with a URL
  isNotNil(videoAsset.originUrl) ||
  // Or has at least one variant with a URL
  (videoAsset.variants?.filter((variant) => isNotNil(variant.url)).length ?? 0) > 0;
