import {Button, ButtonGroup, Dialog, useTranslationContext} from 'platform/components';
import {Space, Text} from 'platform/foundation';

import {Blocker} from 'react-router-dom';

export function PossibleUploadInterruptionDialog(props: {blocker: Blocker}) {
  const t = useTranslationContext();

  const handleLeave = () => {
    props.blocker.proceed?.();
  };

  const handleStayOnPage = () => {
    props.blocker.reset?.();
  };

  return (
    <Dialog
      isOpen={props.blocker.state === 'blocked'}
      onClose={handleStayOnPage}
      size="small"
      title={t('general.notifications.videoStillUploading.title')}
      data-testid="formSaver-dialog"
    >
      <Text size="small">{t('general.notifications.videoStillUploading.description')}</Text>

      <Space vertical={4} />

      <ButtonGroup align="right">
        <Button
          variant="secondary"
          title={t('general.labels.leavePage')}
          onClick={handleLeave}
          data-testid="formSaverModal-button-yes"
        />
        <Button
          title={t('general.labels.stayOnPage')}
          onClick={handleStayOnPage}
          data-testid="formSaverModal-button-no"
        />
      </ButtonGroup>
    </Dialog>
  );
}
