import {useState} from 'react';

import {useDataGridConfig} from '../../../context/useDatagridConfig';
import {useDataGridContext} from '../../../context/useDataGridContext';
import {useHttpCalls} from '../../../hooks/useHttpCalls';
import {GridApi} from '../../../types/AgGridTypes';
import {ExportState} from '../../../types/ExportOptionsRenderer';
import {getSearchParamsByGridCode} from '../../../utils/url/getSearchParamsByGridCode';
import {ExportOptions} from '../types/ExportOptions';

type UseExportProps = {
  gridApi?: GridApi;
  onClose: () => void;
};

export const useExport = (props: UseExportProps) => {
  const http = useHttpCalls();
  const {gridCode} = useDataGridContext();
  const {handleExportSuccessfullyStarted} = useDataGridConfig();
  const [exportState, setExportState] = useState<ExportState>('idle');

  const onExport = async (options: ExportOptions) => {
    const searchParams = getSearchParamsByGridCode(gridCode);
    setExportState('exporting');
    const response = await http.postExport({
      dataQueryId: searchParams.queryId,
      columns:
        props.gridApi
          ?.getColumns()
          ?.filter((column) => column.isVisible() && !column.getId().startsWith('eag-col'))
          .map((column) => column.getId()) || [],
      ...options,
    });
    if (response) {
      handleExportSuccessfullyStarted?.({
        exportId: response.exportId,
        gridCode,
      });
    }
    setExportState('idle');
    props.onClose();
  };

  return {
    exportState,
    onExport,
  };
};
