import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {MyProfileTemplate} from '../../components/MyProfileTemplate/MyProfileTemplate';
import {MyProfileModuleProps} from '../../types/MyProfileModuleProps';
import {LanguageForm} from './components/LanguageForm';

export function Language(props: MyProfileModuleProps) {
  return (
    <MyProfileTemplate
      header={{
        title: i18n.t('entity.user.labels.langAndTime'),
        breadcrumbs: [
          {label: i18n.t('entity.user.labels.myProfile'), isCurrentPage: true},
          {label: i18n.t('entity.user.labels.langAndTime'), isCurrentPage: true},
        ],
      }}
      data-testid={testIds.myProfile.language('header')}
    >
      <LanguageForm persistor={props.persistor} />
    </MyProfileTemplate>
  );
}
