import {diff} from 'deep-object-diff';
import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  DialogFooter,
  Form,
  FormButton,
  FormSubmitHandler,
  Separator,
  showNotification,
  TextInput,
} from 'platform/components';
import {Grid, GridItem, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';
import {DeepPartial} from 'utility-types';
import {object, ValidationError} from 'yup';

import {useRef} from 'react';
import {Path, UseFormReturn} from 'react-hook-form';

import {
  always,
  assocPath,
  clone,
  concat,
  defaultTo,
  flip,
  head,
  isEmpty,
  last,
  not,
  path,
  pipe,
} from 'ramda';
import {isFunction, isNilOrEmpty, isString} from 'ramda-adjunct';

import {
  PatchReceiveNoteCorrectionItemRequest,
  ReceiveNoteCorrectionItemChangedField,
  useGetHandlingUnitQuery,
  useGetHandlingUnitsQuery,
  useGetManufacturerQuery,
  useGetReceiveNoteCorrectionItemQuery,
  useGetSuppliersQuery,
  usePatchReceiveNoteCorrectionItemMutation,
  usePutReceiveNoteCorrectionItemCalculationMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {
  generateHashFromObjects,
  Nullish,
  RequiredTestIdProps,
  sanitizeObject,
  suffixTestId,
  yupNumber,
} from 'shared';

import {FALLBACK_HANDLING_UNIT} from '../../../../../constants/fallbackHandlingUnit';
import {filterElementsBySuffixMatch} from '../../../../../utils/filterElementsBySuffixMatch';
import {objectPaths} from '../../../../../utils/objectPaths';
import {removeKeys} from '../../../../../utils/removeKeys';
import {ReceiveNoteCorrectionItemForm} from '../types/ReceiveNoteCorrectionItemForm';
import {ArticleDetailCard} from './ArticleDetailCard';
import {DeliveryNoteCorrectionItemDetailCard} from './DeliveryNoteCorrectionItemDetailCard';
import {DeliveryNoteItemForm} from './DeliveryNoteItemForm';
import {ReceiveNoteItemForm} from './ReceiveNoteItemForm';
import {SupplierArticleForm} from './SupplierArticleForm';

interface ReceiveNoteCorrectionItemEditProps extends RequiredTestIdProps {
  receiveNoteCorrectionId: string;
  receiveNoteCorrectionItemId: string;
  onAfterSubmit: VoidFunction;
}

export function ReceiveNoteCorrectionItemEdit(props: ReceiveNoteCorrectionItemEditProps) {
  const {
    data: receiveNoteCorrectionItem,
    isLoading: isReceiveNoteCorrectionItemLoading,
    isError: hasReceiveNoteCorrectionItemError,
  } = useGetReceiveNoteCorrectionItemQuery({
    creditNoteId: props.receiveNoteCorrectionId,
    creditNoteItemId: props.receiveNoteCorrectionItemId,
  });

  const {
    data: suppliers,
    isLoading: areSuppliersLoading,
    isError: hasSuppliersError,
  } = useGetSuppliersQuery();

  const {
    data: handlingUnits,
    isLoading: areHandlingUnitsLoading,
    isError: hasHandlingUnitsError,
  } = useGetHandlingUnitsQuery();

  const {
    data: receiveNoteCorrectionItemHandlingUnit,
    isLoading: isReceiveNoteCorrectionItemHandlingUnitLoading,
    isError: hasReceiveNoteCorrectionItemHandlingUnitError,
  } = useGetHandlingUnitQuery(
    {id: receiveNoteCorrectionItem?.article?.handlingUnit as string},
    {skip: isNilOrEmpty(receiveNoteCorrectionItem?.article?.handlingUnit)}
  );

  const {
    data: deliveryNoteItemHandlingUnit,
    isLoading: isDeliveryNoteItemHandlingUnitLoading,
    isError: hasDeliveryNoteItemHandlingUnitError,
  } = useGetHandlingUnitQuery(
    {id: receiveNoteCorrectionItem?.supplierArticle?.supplierUnitId as string},
    {skip: isNilOrEmpty(receiveNoteCorrectionItem?.supplierArticle?.supplierUnitId)}
  );

  const {
    data: manufacturerByReceiveNoteItemCorrection,
    isLoading: isManufacturerByReceiveNoteItemCorrectionLoading,
    isError: hasManufacturerByReceiveNoteItemCorrectionError,
  } = useGetManufacturerQuery(
    {manufacturerId: receiveNoteCorrectionItem?.article?.manufacturerId as string},
    {skip: isNilOrEmpty(receiveNoteCorrectionItem?.article?.manufacturerId)}
  );

  const [patchReceiveNoteCorrectionItem] = usePatchReceiveNoteCorrectionItemMutation();

  const [calculatePrices] = usePutReceiveNoteCorrectionItemCalculationMutation();

  const isLoading =
    isReceiveNoteCorrectionItemLoading ||
    isReceiveNoteCorrectionItemHandlingUnitLoading ||
    areSuppliersLoading ||
    isDeliveryNoteItemHandlingUnitLoading ||
    isManufacturerByReceiveNoteItemCorrectionLoading;

  const hasError =
    hasReceiveNoteCorrectionItemError ||
    hasSuppliersError ||
    hasHandlingUnitsError ||
    hasReceiveNoteCorrectionItemHandlingUnitError ||
    hasDeliveryNoteItemHandlingUnitError ||
    hasManufacturerByReceiveNoteItemCorrectionError;

  const manufacturerNumber = defaultTo(
    null,
    receiveNoteCorrectionItem?.article?.manufacturerNumber
  );

  const defaultValues: DeepPartial<ReceiveNoteCorrectionItemForm> = {
    receiveItem: receiveNoteCorrectionItem?.receiveItem,
    deliveryItem: receiveNoteCorrectionItem?.deliveryItem,
    supplierArticle: {
      supplierQuantity: receiveNoteCorrectionItem?.supplierArticle?.supplierQuantity,
      supplierUnitId: receiveNoteCorrectionItem?.supplierArticle?.supplierUnitId,
      warehouseQuantity: receiveNoteCorrectionItem?.supplierArticle?.warehouseQuantity,
      warehouseUnitId: receiveNoteCorrectionItem?.article?.handlingUnit,
      supplierOrderingNumber: receiveNoteCorrectionItem?.supplierArticle?.supplierOrderingNumber,
      supplierBulkPackageQuantity:
        receiveNoteCorrectionItem?.supplierArticle?.supplierBulkPackageQuantity,
      supplier: suppliers?.find(
        (supplier) => supplier.id === receiveNoteCorrectionItem?.supplierArticle?.supplierId
      )?.name,
    },
  };

  // Needed for proper recalculations
  const prevFormValues = useRef<DeepPartial<ReceiveNoteCorrectionItemForm>>(defaultValues);
  // Needed for cancelling recalculating requests from the form onChange event
  const abortControllerInstance = useRef<AbortController | Nullish>();

  const receiveUnit = defaultTo(
    FALLBACK_HANDLING_UNIT,
    receiveNoteCorrectionItemHandlingUnit?.name
  );

  const deliveryUnit = defaultTo(FALLBACK_HANDLING_UNIT, deliveryNoteItemHandlingUnit?.name);

  const receiveBeforeQuantity = receiveNoteCorrectionItem?.receiveItem?.receiveBeforeQuantity ?? 0;

  const deliveryBeforeQuantity =
    receiveNoteCorrectionItem?.deliveryItem?.deliveryBeforeQuantity ?? 0;

  const handleSubmit: FormSubmitHandler<ReceiveNoteCorrectionItemForm> = async (formValues) => {
    const requestBody: PatchReceiveNoteCorrectionItemRequest['body'] = {
      receiveItem: {
        receiveCorrectionQuantity: formValues.receiveItem.receiveCorrectionQuantity,
        receiveCorrectionUnitPrice: formValues.receiveItem.receiveCorrectionUnitPrice,
        receiveCorrectionStockValue: formValues.receiveItem.receiveCorrectionStockValue,
        receiveAfterQuantity: formValues.receiveItem.receiveAfterQuantity,
        receiveAfterUnitPrice: formValues.receiveItem.receiveAfterUnitPrice,
        receiveAfterStockValue: formValues.receiveItem.receiveAfterStockValue,
      },
      deliveryItem: {
        deliveryCorrectionQuantity: formValues.deliveryItem.deliveryCorrectionQuantity,
        deliveryCorrectionUnitPrice: formValues.deliveryItem.deliveryCorrectionUnitPrice,
        deliveryCorrectionStockValue: formValues.deliveryItem.deliveryCorrectionStockValue,
        deliveryAfterQuantity: formValues.deliveryItem.deliveryAfterQuantity,
        deliveryAfterUnitPrice: formValues.deliveryItem.deliveryAfterUnitPrice,
        deliveryAfterStockValue: formValues.deliveryItem.deliveryAfterStockValue,
      },
    };

    await patchReceiveNoteCorrectionItem({
      creditNoteItemId: props.receiveNoteCorrectionItemId,
      body: requestBody,
    })
      .unwrap()
      .then(() =>
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'))
      )
      .then(closeCurrentDialog)
      .then(props.onAfterSubmit)
      .catch(handleApiError);
  };

  const handleChange = (
    values: DeepPartial<ReceiveNoteCorrectionItemForm>,
    // We don't know the correct type here
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: any,
    formApi: UseFormReturn<ReceiveNoteCorrectionItemForm>
  ) => {
    const copiedValues = JSON.parse(JSON.stringify(values));
    const {asString} = pipe(
      defaultTo({}),
      flip(diff)(sanitizeObject(copiedValues)),
      objectPaths
    )(prevFormValues.current);

    // Name of changed field
    const changedField = last<string>(change.name?.split('.') ?? []);
    const changedFieldWithFullPath = change?.name;
    // Indicate if the changed field is allowed to trigger recalculation
    const shouldTriggerRecalculation = ALLOWED_VALUES.includes(changedField);
    // Get the difference between the previous and current form values
    const difference = diff(sanitizeObject(prevFormValues.current), sanitizeObject(copiedValues));
    // Difference that contains only allowed values
    const allowedDifference = removeKeys(
      difference,
      concat(ALLOWED_VALUES, ALLOWED_VALUES_IN_OBJECT)
    );

    const isReceiveNoteItemPricesDiffEmpty = allowedDifference?.receiveItem
      ? isEmpty(allowedDifference?.receiveItem)
      : false;
    const isDeliveryItemPricesDiffEmpty = allowedDifference?.deliveryItem
      ? isEmpty(allowedDifference?.deliveryItem)
      : false;

    const isDiffEmpty = isReceiveNoteItemPricesDiffEmpty && isDeliveryItemPricesDiffEmpty;

    const valueToValidate = match(changedField)
      .with('receiveAfterQuantity', always(values.receiveItem?.receiveAfterQuantity))
      .with('deliveryAfterQuantity', always(values.deliveryItem?.deliveryAfterQuantity))
      .otherwise(always(null));

    const isValueValid = validateQuantity(valueToValidate, changedFieldWithFullPath, formApi);

    if (
      not(shouldTriggerRecalculation) ||
      isNilOrEmpty(allowedDifference) ||
      isDiffEmpty ||
      not(isValueValid)
    ) {
      return;
    }

    const arrayOfChangedValues = asString.includes('.') ? asString.split(',') : asString;

    const pathToChangedValues = head(
      filterElementsBySuffixMatch(arrayOfChangedValues, ALLOWED_VALUES)
    )?.split('.') as string[];

    const changedValue = path(pathToChangedValues ?? [], values);

    const newPrevFormValues = assocPath(
      pathToChangedValues ?? [],
      changedValue,
      prevFormValues.current
    );

    prevFormValues.current = newPrevFormValues;

    if (abortControllerInstance.current && isFunction(abortControllerInstance.current.abort)) {
      abortControllerInstance.current.abort();
    }

    const receiveNoteCorrectionItemCalculateController = new AbortController();

    abortControllerInstance.current = receiveNoteCorrectionItemCalculateController;

    calculatePrices({
      body: {
        changedFieldValue: {field: changedField as ReceiveNoteCorrectionItemChangedField},
        currentFeFieldsValues: {
          receiveItem: {
            receiveAfterQuantity: values.receiveItem?.receiveAfterQuantity ?? 0,
            receiveAfterUnitPrice: values.receiveItem?.receiveAfterUnitPrice ?? 0,
            receiveAfterStockValue: values.receiveItem?.receiveAfterStockValue ?? 0,
            receiveBeforeQuantity: values.receiveItem?.receiveBeforeQuantity ?? 0,
            receiveBeforeUnitPrice: values.receiveItem?.receiveBeforeUnitPrice ?? 0,
            receiveBeforeStockValue: values.receiveItem?.receiveBeforeStockValue ?? 0,
          },
          deliveryItem: {
            deliveryAfterQuantity: values.deliveryItem?.deliveryAfterQuantity ?? 0,
            deliveryAfterUnitPrice: values.deliveryItem?.deliveryAfterUnitPrice ?? 0,
            deliveryAfterStockValue: values.deliveryItem?.deliveryAfterStockValue ?? 0,
            deliveryBeforeQuantity: values.deliveryItem?.deliveryBeforeQuantity ?? 0,
            deliveryBeforeUnitPrice: values.deliveryItem?.deliveryBeforeUnitPrice ?? 0,
            deliveryBeforeStockValue: values.deliveryItem?.deliveryBeforeStockValue ?? 0,
          },
          supplierArticle: {
            supplierQuantity: values.supplierArticle?.supplierQuantity ?? 0,
            warehouseQuantity: values.supplierArticle?.warehouseQuantity ?? 0,
          },
        },
      },
      signal: receiveNoteCorrectionItemCalculateController.signal,
    })
      .unwrap()
      .then((calculatedPrices) => {
        const newValues = clone(
          sanitizeObject({
            ...values,
            receiveItem: calculatedPrices.receiveItem,
            deliveryItem: calculatedPrices.deliveryItem,
            supplierArticle: {
              supplierQuantity: calculatedPrices?.supplierArticle?.supplierQuantity,
              supplierUnitId: values?.supplierArticle?.supplierUnitId,
              warehouseQuantity: calculatedPrices?.supplierArticle?.warehouseQuantity,
              warehouseUnitId: values?.supplierArticle?.warehouseUnitId,
              supplierOrderingNumber: values?.supplierArticle?.supplierOrderingNumber,
              supplierBulkPackageQuantity: values?.supplierArticle?.supplierBulkPackageQuantity,
              supplier: values?.supplierArticle?.supplier,
            },
          })
        );

        prevFormValues.current = newValues;
        formApi.reset(newValues);
      })
      .catch((e) => {
        // If the request is aborted, then we don't want to show the error notification
        if (e && e.error && isString(e.error) && e.error.includes(ABORT_ERROR)) {
          return;
        }
        handleApiError(e);
      });
  };

  const validateQuantity = (
    value: number | Nullish,
    changedField: Path<ReceiveNoteCorrectionItemForm>,
    formApi: UseFormReturn<ReceiveNoteCorrectionItemForm>
  ) => {
    const valueToValidate = defaultTo(0, value);

    try {
      formApi.clearErrors(changedField);

      const beforeQuantityValue = match(changedField)
        .returnType<number>()
        .with('receiveItem.receiveAfterQuantity', always(receiveBeforeQuantity))
        .with('deliveryItem.deliveryAfterQuantity', always(deliveryBeforeQuantity))
        .otherwise(always(0));

      quantitySchema(beforeQuantityValue).validateSync(valueToValidate);

      return true;
    } catch (error) {
      if (error instanceof ValidationError) {
        formApi.setError(changedField, {message: error.errors.join(' ')});
      }

      return false;
    }
  };

  return (
    <Form<ReceiveNoteCorrectionItemForm>
      key={generateHashFromObjects(receiveNoteCorrectionItem)}
      schema={formSchema(receiveBeforeQuantity, deliveryBeforeQuantity)}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      {(control, formApi) => {
        // For controlling recalculations of prices
        formApi.watch((data, change) => handleChange(data, change, formApi));

        return (
          <DataStatus isLoading={isLoading} isError={hasError} minHeight={100}>
            <VStack data-testid={props['data-testid']}>
              <VStack spacing={4}>
                <Grid columns={4} spacing={4}>
                  <GridItem span={2}>
                    <TextInput
                      value={manufacturerNumber}
                      label={i18n.t('entity.warehouse.labels.catalogueNumber')}
                      isRequired
                      isDisabled
                      data-testid={suffixTestId('inputs.manufacturerNumber', props)}
                    />
                  </GridItem>
                </Grid>
              </VStack>

              <Separator />

              <VStack spacing={4}>
                <ReceiveNoteItemForm
                  control={control}
                  receiveUnit={receiveUnit}
                  currency={receiveNoteCorrectionItem?.currency}
                  onQuantityChange={(value, formContext) =>
                    validateQuantity(value, formContext, formApi)
                  }
                  data-testid={suffixTestId('forms.receiveNoteItem', props)}
                />
                <Separator spacing={0} />
                <DeliveryNoteItemForm
                  control={control}
                  deliveryUnit={deliveryUnit}
                  currency={receiveNoteCorrectionItem?.currency}
                  onQuantityChange={(value, formContext) =>
                    validateQuantity(value, formContext, formApi)
                  }
                  data-testid={suffixTestId('forms.deliveryNoteItem', props)}
                />
                <Separator spacing={0} />
                <SupplierArticleForm
                  control={control}
                  handlingUnits={handlingUnits}
                  areHandlingUnitsLoading={areHandlingUnitsLoading}
                  data-testid={suffixTestId('forms.supplierArticle', props)}
                />
                <DeliveryNoteCorrectionItemDetailCard
                  deliveryNoteCorrectionItemDetails={{
                    manufacturerNumber: receiveNoteCorrectionItem?.article?.manufacturerNumber,
                    manufacturerName: manufacturerByReceiveNoteItemCorrection?.name,
                    supplierOrderingNumber:
                      receiveNoteCorrectionItem?.supplierArticle?.supplierOrderingNumber,
                    articleName: receiveNoteCorrectionItem?.article?.name,
                    articleDescription: receiveNoteCorrectionItem?.article?.description,
                  }}
                  data-testid={suffixTestId('cards.deliveryNoteCorrectionItemDetail', props)}
                />
                <ArticleDetailCard
                  warehouseId={receiveNoteCorrectionItem?.article?.warehouseId}
                  articleId={receiveNoteCorrectionItem?.article?.id}
                  data-testid={suffixTestId('cards.articleDetail', props)}
                />

                <DialogFooter>
                  <ButtonGroup align="right">
                    <Button
                      variant="secondary"
                      title={i18n.t('general.actions.discard')}
                      onClick={closeCurrentDialog}
                      data-testid={suffixTestId('actions.discard', props)}
                    />
                    <FormButton
                      control={control}
                      type="submit"
                      variant="primary"
                      title={i18n.t('general.actions.saveChanges')}
                      data-testid={suffixTestId('actions.submit', props)}
                    />
                  </ButtonGroup>
                </DialogFooter>
              </VStack>
            </VStack>
          </DataStatus>
        );
      }}
    </Form>
  );
}

const quantitySchema = (beforeQuantity: number) =>
  yupNumber
    .min(0, (params) => i18n.t('general.errors.number.greaterOrEqual', {min: params.min}))
    .test(
      'isAfterQuantityLessThanBeforeQuantity',
      `${i18n.t('entity.warehouse.labels.mustBeLessThanBeforeCorrection')} (<${beforeQuantity})`,
      (value) => defaultTo(0, value) < beforeQuantity
    );

const formSchema = (receiveBeforeQuantity: number, deliveryBeforeQuantity: number) =>
  object({
    receiveItem: object({
      receiveAfterQuantity: quantitySchema(receiveBeforeQuantity),
    }),
    deliveryItem: object({
      deliveryAfterQuantity: quantitySchema(deliveryBeforeQuantity),
    }),
  });

const ALLOWED_VALUES_IN_OBJECT = ['receiveItem', 'deliveryItem'];

// nested keys from ALLOWED_VALUES_IN_OBJECT
const ALLOWED_VALUES = ['receiveAfterQuantity', 'deliveryAfterQuantity'];

const ABORT_ERROR = 'AbortError';
