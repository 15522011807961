import {
  DataStatus,
  Form,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {VStack, Text, Heading} from 'platform/foundation';
import {object, string} from 'yup';

import {
  InactivityPeriodType,
  useGetCurrentUserInfoQuery,
  useSetUserSettingsItemMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {myProfileRoutes, testIds} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {useNavigate} from 'shared';

import {MyProfileFooter} from '../../../components/MyProfileFooter';

type AutoLogoutFormType = {
  period: NonNullable<InactivityPeriodType>;
};

type InactivityOption = {label: string; value: NonNullable<InactivityPeriodType>};

const predefinedOptions: InactivityOption[] = [
  {label: i18n.t('entity.user.labels.never'), value: 'INACTIVITY_PERIOD_NEVER'},
  {label: i18n.t('entity.user.labels.minutes', {val: 15}), value: 'INACTIVITY_PERIOD_15_MINUTES'},
  {label: i18n.t('entity.user.labels.minutes', {val: 30}), value: 'INACTIVITY_PERIOD_30_MINUTES'},
  {label: i18n.t('entity.user.labels.oneHour'), value: 'INACTIVITY_PERIOD_1_HOUR'},
  {label: i18n.t('entity.user.labels.hours', {val: 2}), value: 'INACTIVITY_PERIOD_2_HOURS'},
  {label: i18n.t('entity.user.labels.hours', {val: 6}), value: 'INACTIVITY_PERIOD_6_HOURS'},
  {label: i18n.t('entity.user.labels.hours', {val: 24}), value: 'INACTIVITY_PERIOD_24_HOURS'},
  {label: i18n.t('entity.user.labels.hours', {val: 48}), value: 'INACTIVITY_PERIOD_48_HOURS'},
];

export function AutoLogoutForm() {
  const navigate = useNavigate();
  const {data: userInfo, isLoading, isError} = useGetCurrentUserInfoQuery();
  const [updateUserSettings] = useSetUserSettingsItemMutation();

  const handleSubmit: FormSubmitHandler<AutoLogoutFormType> = async (values, _, reset) => {
    await updateUserSettings({
      settingKey: 'inactivity_period',
      userSettingRequestBody: {value: values.period},
    })
      .unwrap()
      .then(() => {
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
        reset(undefined, {keepValues: true});
      })
      .catch(handleApiError);
  };

  const defaultValues = {
    period:
      predefinedOptions.find((option) => option.value === userInfo?.settings.inactivityPeriod)
        ?.value || 'INACTIVITY_PERIOD_NEVER',
  };

  return (
    <VStack spacing={6}>
      <Heading size={4}>{i18n.t('entity.user.labels.autoLogout')}</Heading>
      <Text color="tertiary" size="small">
        {i18n.t('entity.user.labels.autoLogoutTooltip')}
      </Text>

      <DataStatus isLoading={isLoading} isError={isError}>
        <Form<AutoLogoutFormType>
          shouldWatchForUnsavedChanges
          defaultValues={defaultValues}
          schema={schema}
          onSubmit={handleSubmit}
        >
          {(control, formApi) => (
            <>
              <FormField
                control={control}
                isRequired
                type="choice"
                name="period"
                isNotClearable
                options={predefinedOptions}
                label={i18n.t('entity.user.labels.period')}
                data-testid={testIds.myProfile.security('period')}
              />

              <MyProfileFooter
                actions={[
                  {
                    type: 'button',
                    onClick: () => navigate(myProfileRoutes.security),

                    title: i18n.t('general.actions.discardChanges'),
                    variant: 'secondary',
                    'data-testid': testIds.myProfile.security('discard'),
                  },
                  {
                    type: 'form-button',
                    control,
                    isDisabled: !formApi.formState.isDirty,
                    buttonType: 'submit',
                    title: i18n.t('general.actions.saveAndClose'),
                    'data-testid': testIds.myProfile.security('save'),
                  },
                ]}
              />
            </>
          )}
        </Form>
      </DataStatus>
    </VStack>
  );
}

const schema = object().shape({
  period: string().required(),
});
