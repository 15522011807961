import {Blocker, useBlocker, useInRouterContext} from 'react-router-dom';

const UNBLOCKED_STATE: Blocker = {
  state: 'unblocked',
  reset: undefined,
  proceed: undefined,
  location: undefined,
};

export function useBlockNavigating(
  shouldBlock: boolean,
  unblockForNextLocationSearchParam: string
) {
  const isWithinRouterContext = useInRouterContext();

  const getUnblockedState = () => UNBLOCKED_STATE;
  const useAppropriateBlocker = isWithinRouterContext ? useBlocker : getUnblockedState;
  const blocker = useAppropriateBlocker((args) => {
    if (args.nextLocation.search.includes(unblockForNextLocationSearchParam)) {
      return false;
    }
    return shouldBlock;
  });

  return {blocker};
}
