import {DataStatus, Parameter} from 'platform/components';
import {useFormatCurrency, useDateTimeFormatter} from 'platform/locale';

import {Helmet} from 'react-helmet-async';

import {defaultTo} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useGetArticleQuery,
  useGetHandlingUnitsQuery,
  useGetTenantQuery,
  useGetVatRatesQuery,
  useGetWarehouseAccountQuery,
  useGetWarehouseQuery,
  useGetWarehousesQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {testIds, warehouseRoutes} from '@omnetic-dms/routes';
import {DetailTemplate, DetailTemplateHeader, NavigationItem} from '@omnetic-dms/shared';

import {parseDate, buildArray, composePath, generateHashFromObjects, useNavigate} from 'shared';

import {useWarehouseParams} from '../../hooks/useWarehouseParams';
import {getHandlingUnitLabel} from '../../utils/getHandlingUnitLabel';
import {Availability} from './(sections)/Availability/Availability';
import {Inventories} from './(sections)/Inventories/Inventories';
import {Movements} from './(sections)/Movements/Movements';
import {Overview} from './(sections)/Overview/Overview';
import {Reservations} from './(sections)/Reservations/Reservations';
import {SupplierArticles} from './(sections)/SupplierArticles/SupplierArticles';

export function ArticleDetail() {
  const params = useWarehouseParams();
  const currencyFormatter = useFormatCurrency();
  const navigate = useNavigate();
  const dateFormatter = useDateTimeFormatter();

  /*
   *  Queries and mutations
   *
   * */

  const {
    data: article,
    isLoading: isArticleLoading,
    isError: isArticleError,
  } = useGetArticleQuery({
    articleId: params.articleId,
    warehouseId: params.warehouseId,
  });

  const {
    data: warehouse,
    isLoading: isWarehouseLoading,
    isError: isWarehouseError,
  } = useGetWarehouseQuery(
    {warehouseId: article?.warehouseId as string},
    {skip: isNilOrEmpty(article)}
  );

  const {
    data: warehouseAccount,
    isLoading: isWarehouseAccountLoading,
    isError: isWarehouseAccountError,
  } = useGetWarehouseAccountQuery(
    {warehouseAccountId: warehouse?.defaultAccountId as string},
    {skip: isNilOrEmpty(warehouse)}
  );

  const {
    data: warehouses,
    isLoading: isWarehousesLoading,
    isError: isWarehousesError,
  } = useGetWarehousesQuery();

  const {data: tenant, isLoading: isTenantLoading, isError: isTenantError} = useGetTenantQuery();
  const {
    data: vatRates,
    isLoading: isVatRatesLoading,
    isError: isVatRatesError,
  } = useGetVatRatesQuery();

  const {
    data: handlingUnits,
    isLoading: isHandlingUnitsLoading,
    isError: isHandlingUnitsError,
  } = useGetHandlingUnitsQuery();

  if (
    !article ||
    !warehouses ||
    !tenant ||
    !vatRates ||
    !warehouse ||
    !warehouseAccount ||
    !handlingUnits
  ) {
    return (
      <DataStatus
        isLoading={
          isArticleLoading ||
          isWarehousesLoading ||
          isTenantLoading ||
          isVatRatesLoading ||
          isWarehouseLoading ||
          isWarehouseAccountLoading ||
          isHandlingUnitsLoading
        }
        isError={
          isArticleError ||
          isWarehousesError ||
          isTenantError ||
          isVatRatesError ||
          isWarehouseError ||
          isWarehouseAccountError ||
          isHandlingUnitsError
        }
        minHeight="100vh"
      />
    );
  }

  const priceWithoutVat = defaultTo(0, article?.dispensingPrices?.saleBasePriceWithoutVat);
  const priceWithVat = defaultTo(0, article?.dispensingPrices?.saleBasePriceWithVat);
  const currencyWithVat = currencyFormatter(priceWithVat, tenant.currency, 2);
  const currencyWithoutVat = currencyFormatter(priceWithoutVat, tenant.currency, 2);
  const createdDate = dateFormatter('dateTimeMedium', parseDate(article.created));
  const updatedDate = dateFormatter('dateTimeMedium', parseDate(article.updated));

  const handlingUnitLabel = getHandlingUnitLabel(article.handlingUnit, handlingUnits);

  const handleReserve = () =>
    navigate(
      composePath(warehouseRoutes.articleDetailReservations, {
        params: {warehouseId: params.warehouseId, id: params.articleId},
      })
    );

  const getHeader: DetailTemplateHeader = {
    title: article.name ?? '',
    icon: 'custom/warehouse',
    parameters: buildArray<Parameter>()
      .add(article.manufacturerNumber)
      .add(`${i18n.t('general.labels.created')}: ${createdDate}`)
      .when(article.updated, `${i18n.t('general.labels.lastUpdate')}: ${updatedDate}`)
      .add(warehouse?.name)
      .when(article.storageLocation, article.storageLocation),
    actions: [
      {
        type: 'button',
        title: i18n.t('entity.warehouse.actions.reserve'),
        variant: 'primary',
        onClick: handleReserve,
        'data-testid': testIds.warehouse.articleDetailOverview('headerActions.reserve'),
      },
    ],
    primaryParameter: defaultTo(undefined, currencyWithVat),
    secondaryParameter: `${currencyWithoutVat} ${i18n.t('general.labels.w/oVat')}`,
  };

  const navigation: NavigationItem[] = [
    {
      id: 'article',
      label: i18n.t('page.warehouse.labels.overview'),
      href: composePath(warehouseRoutes.articleDetailOverview, {
        params: {id: params.articleId, warehouseId: params.warehouseId},
      }),
      content: (
        <Overview
          article={article}
          warehouse={warehouse}
          warehouses={warehouses}
          vatRates={vatRates}
          tenant={tenant}
          warehouseAccount={warehouseAccount}
          data-testid={testIds.warehouse.articleDetailOverview('sections.article')}
        />
      ),
      hasSeparator: true,
      'data-testid': testIds.warehouse.articleDetailOverview('article'),
    },
    {
      id: 'availability',
      label: i18n.t('entity.warehouse.labels.availability'),
      href: composePath(warehouseRoutes.articleDetailAvailability, {
        params: {id: params.articleId, warehouseId: params.warehouseId},
      }),
      content: (
        <Availability
          article={article}
          handlingUnitLabel={handlingUnitLabel as string}
          data-testid={testIds.warehouse.articleDetailAvailability('sections.availability')}
        />
      ),
      'data-testid': testIds.warehouse.articleDetailAvailability('availability'),
    },
    {
      id: 'inventories',
      label: i18n.t('entity.warehouse.labels.inventories'),
      href: composePath(warehouseRoutes.articleDetailInventories, {
        params: {id: params.articleId, warehouseId: params.warehouseId},
      }),
      content: (
        <Inventories
          article={article}
          tenant={tenant}
          handlingUnitLabel={handlingUnitLabel as string}
          data-testid={testIds.warehouse.articleDetailInventories('sections.inventories')}
        />
      ),
      'data-testid': testIds.warehouse.articleDetailInventories('inventories'),
    },
    {
      id: 'movements',
      label: i18n.t('entity.warehouse.labels.movements'),
      href: composePath(warehouseRoutes.articleDetailMovements, {
        params: {id: params.articleId, warehouseId: params.warehouseId},
      }),
      content: (
        <Movements data-testid={testIds.warehouse.articleDetailMovements('sections.movements')} />
      ),
      'data-testid': testIds.warehouse.articleDetailMovements('movements'),
    },
    {
      id: 'reservations',
      label: i18n.t('entity.warehouse.labels.reservations'),
      href: composePath(warehouseRoutes.articleDetailReservations, {
        params: {id: params.articleId, warehouseId: params.warehouseId},
      }),
      content: (
        <Reservations
          article={article}
          handlingUnitLabel={handlingUnitLabel}
          data-testid={testIds.warehouse.articleDetailReservations('sections.reservations')}
        />
      ),
      'data-testid': testIds.warehouse.articleDetailReservations('reservations'),
    },
    {
      id: 'supplierArticles',
      label: i18n.t('entity.warehouse.labels.supplierArticles'),
      href: composePath(warehouseRoutes.articleDetailSupplierArticles, {
        params: {id: params.articleId, warehouseId: params.warehouseId},
      }),
      content: (
        <SupplierArticles
          data-testid={testIds.warehouse.articleDetailSupplierArticles('sections.supplierArticles')}
        />
      ),
      'data-testid': testIds.warehouse.articleDetailSupplierArticles('supplierArticles'),
    },
  ];

  return (
    <>
      <Helmet title={i18n.t('entity.warehouse.labels.articleDetail')} />
      <DetailTemplate
        key={generateHashFromObjects(article)}
        isLoading={false}
        isError={false}
        header={getHeader}
        navigation={navigation}
        data-testid={testIds.warehouse.articleDetailOverview('page')}
      />
    </>
  );
}
