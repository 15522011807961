import {FormControl, FormField} from 'platform/components';
import {Grid, GridItem, Heading, VStack} from 'platform/foundation';

import {
  GetCodeListResponse,
  GetCurrenciesApiResponse,
  GetWarehouseAccountsResponse,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {getOptionsFromCurrencies, getOptionsFromWarehouseAccounts} from '@omnetic-dms/shared';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {WarehouseSettingsForm} from '../types/WarehouseSettingsForm';
import {getOptionsFromCodeList} from '../utils/getOptionsFromCodeList';

interface AccountingFormProps extends TestIdProps {
  control: FormControl<WarehouseSettingsForm>;
  warehousePricing: GetCodeListResponse | Nullish;
  currencies: GetCurrenciesApiResponse | Nullish;
  warehouseAccounts: GetWarehouseAccountsResponse | Nullish;
}

export function AccountingForm(props: AccountingFormProps) {
  return (
    <VStack spacing={6}>
      <Heading size={3}>{i18n.t('entity.warehouse.labels.accounting')}</Heading>
      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            name="warehousePricing"
            type="chips"
            label={i18n.t('entity.warehouse.labels.valuation')}
            options={getOptionsFromCodeList(props.warehousePricing)}
            control={props.control}
            isRequired
            data-testid={suffixTestId('inputs.warehousePricing', props)}
          />
        </GridItem>
      </Grid>

      <Grid columns={4}>
        <GridItem span={1}>
          <FormField
            name="currency"
            type="choice"
            label={i18n.t('general.labels.currency')}
            options={getOptionsFromCurrencies(props.currencies)}
            control={props.control}
            isRequired
            isDisabled
            data-testid={suffixTestId('inputs.currency', props)}
          />
        </GridItem>
        <GridItem span={1}>
          <FormField
            name="defaultAccountId"
            type="choice"
            label={i18n.t('entity.warehouse.labels.defaultWarehouseAccount')}
            options={getOptionsFromWarehouseAccounts(props.warehouseAccounts)}
            control={props.control}
            isRequired
            isNotClearable
            data-testid={suffixTestId('inputs.defaultAccountId', props)}
          />
        </GridItem>
      </Grid>

      <FormField
        name="dispatchToMinus"
        type="checkbox"
        label={i18n.t('entity.warehouse.labels.enableDispatchToMinus')}
        control={props.control}
        data-testid={suffixTestId('inputs.dispatchToMinus', props)}
      />

      <FormField
        name="dummyWarehouse"
        type="checkbox"
        label={i18n.t('entity.warehouse.labels.dummyWarehouse')}
        control={props.control}
        data-testid={suffixTestId('inputs.dummyWarehouse', props)}
      />
    </VStack>
  );
}
