import styled from 'styled-components';

interface HeaderButtonWrapperProps {
  $isOpen?: boolean;
}

export const HeaderButtonWrapper = styled.div<HeaderButtonWrapperProps>`
  position: relative;
  & > button {
    background-color: ${({theme, $isOpen}) => $isOpen && theme.colors.palettes.neutral[30][100]};
  }
`;
