import {closeCurrentDialog, showNotification} from 'platform/components';
import {Show, Spinner} from 'platform/foundation';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {useDeleteTaskMutation, useEditTaskMutation, useGetTaskQuery} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {getApiDateString, noop, parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import type {TaskForm as TaskFormType} from '../../types/TaskForm';
import {castTimeToDateISOString} from '../../utils/castTimeToDateISOString';
import {TaskForm} from '../TaskForm/TaskForm';

interface EditTaskProps extends RequiredTestIdProps {
  id: string;
  onEdit?: () => void;
  onDelete?: () => void;
}

export function EditTask(props: EditTaskProps) {
  const {
    data: task,
    isLoading: isTaskLoading,
    isError: hasTaskError,
  } = useGetTaskQuery({id: props.id});
  const [editTask] = useEditTaskMutation();
  const [deleteTask, {isLoading: isDeleteTaskLoading}] = useDeleteTaskMutation();

  if (hasTaskError) {
    showNotification.error(i18n.t('general.labels.somethingWentWrong'));
    closeCurrentDialog();
    return null;
  }

  const onEditTask = (data: TaskFormType) =>
    editTask({
      id: props.id,
      editTaskRequestBody: {
        ...data,
        dueDate: data?.dueDate ? getApiDateString(parseDate(data.dueDate)) : null,
        dueFrom:
          data?.dueDate && data?.dueFrom
            ? castTimeToDateISOString(data.dueDate, data.dueFrom)
            : null,
        dueTo:
          data?.dueDate && data?.dueTo ? castTimeToDateISOString(data.dueDate, data.dueTo) : null,
        relatedRecordId: null,
      },
    })
      .unwrap()
      .then(() => {
        closeCurrentDialog();
        props.onEdit?.();
        showNotification.success(i18n.t('general.notifications.changesSuccessfullySaved'));
      })
      .catch(handleApiError);

  const onDeleteTask = () =>
    deleteTask({id: props.id})
      .unwrap()
      .then(() => {
        closeCurrentDialog();
        props.onDelete?.();
        showNotification.success(i18n.t('general.notifications.successfullyDeleted'));
      })
      .catch(handleApiError);

  return (
    <>
      <Show when={isNotNilOrEmpty(task)}>
        <TaskForm
          values={task}
          onSubmit={onEditTask}
          onDelete={onDeleteTask}
          isLoading={isDeleteTaskLoading}
          data-testid={suffixTestId('editTask', props)}
        />
      </Show>
      <Show when={isTaskLoading}>
        <TaskForm onSubmit={noop} data-testid={suffixTestId('skeletonTask', props)} />
        <Spinner variant="overlay" />
      </Show>
    </>
  );
}
