import {z} from 'zod';

export const BasePriceSchema = z.object({
  amount: z.number(),
  currency: z.string(),
});

export type BasePrice = z.infer<typeof BasePriceSchema>;

export const PriceWithAndWithoutVatSchema = z.object({
  withoutVat: BasePriceSchema,
  withVat: BasePriceSchema,
});

export type PriceWithAndWithoutVat = z.infer<typeof PriceWithAndWithoutVatSchema>;

export const MechanicSchema = z.object({
  id: z.string(),
  name: z.string(),
  isDefault: z.boolean(),
  costCenterId: z.string().optional(),
});

export type Mechanic = z.infer<typeof MechanicSchema>;

export const AssignedMechanicSchema = z.object({
  id: z.string(),
  isDefault: z.boolean(),
  costCenterId: z.string().nullish(),
});

export type AssignedMechanic = z.infer<typeof AssignedMechanicSchema>;

export const BasketTooltipSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export type BasketTooltip = z.infer<typeof BasketTooltipSchema>;

export const MinMaxQuantitySchema = z.object({
  minQuantity: z.number(),
  maxQuantity: z.number(),
});
export type MinMaxQuantity = z.infer<typeof MinMaxQuantitySchema>;

/**
 * Defines the type of the entity being interacted with.
 *
 * @example
 * To add a spare part from the warehouse to the basket, use the type 'wrh_sale_item'.
 * For example: originEntityType = 'wrh_sale_item'.
 */
export const OriginEntityTypeSchema = z.enum([
  'svc_job_item',
  'wrh_sale_item',
  'warehouse',
  'customer',
  'other',
  'supplier_order_item',
  'receive_note_item',
]);
export type OriginEntityType = z.infer<typeof OriginEntityTypeSchema>;

/**
 * Specifies the origin type of the entity, such as service cases, direct sales,
 * service order issues, or service order returns.
 *
 * This is used by the backend to identify the origin of the request,
 * as the same operation is reused for returning parts across multiple entity types.
 *
 * @example
 * We might want to return a spare part from direct sales. This is done through Direct Sale Returns entity.
 * The origin entity for that spare part was direct sale, therefore the originEntityHeaderType should be set as 'direct-sale'.
 *
 */
export const OriginEntityHeaderTypeSchema = z
  .enum(['service-case-order', 'direct-sale', 'service-order-issue-note', 'service-order-return'])
  .optional();
export type OriginEntityHeaderType = z.infer<typeof OriginEntityHeaderTypeSchema>;
