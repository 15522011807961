import {Card, Action, DataStatus} from 'platform/components';
import {VStack} from 'platform/foundation';

import {FullVehicleResponseBody, GetVehicleCustomerApiResponse} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {buildArray, suffixTestId, TestIdProps} from 'shared';

import {SuggestedVehicle} from './SuggestedVehicle';

interface SuggestedVehiclesProps extends TestIdProps {
  selectedVehicle: FullVehicleResponseBody | null;
  suggestedVehicles: GetVehicleCustomerApiResponse;
  onVehicleChange: (vehicleId: FullVehicleResponseBody | null) => void;
  onAddNew: () => void;
  isLoading: boolean;
  isError: boolean;
}

export function SuggestedVehicles(props: SuggestedVehiclesProps) {
  return (
    <Card
      actions={buildArray<Action>().add({
        type: 'button',
        variant: 'link',
        leftIcon: 'content/add_circle',
        title: i18n.t('general.actions.addNew'),
        onClick: props.onAddNew,
      })}
      title={i18n.t('entity.vehicle.labels.vehicle')}
      data-testid={suffixTestId('header', props)}
    >
      <DataStatus isLoading={props.isLoading} isError={props.isError} minHeight={80}>
        <VStack spacing={4}>
          {props.suggestedVehicles?.map(
            (suggested) =>
              suggested?.vehicleId && (
                <SuggestedVehicle
                  key={suggested.id}
                  type={suggested?.customerType}
                  vehicleId={suggested.vehicleId}
                  isSelected={props.selectedVehicle?.id === suggested?.vehicleId}
                  onSelect={props.onVehicleChange}
                />
              )
          )}
        </VStack>
      </DataStatus>
    </Card>
  );
}
