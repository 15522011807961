import {useEffect} from 'react';

import {isNil} from 'ramda';

import {useNavigate} from 'shared';

import {useAdditionalLogic} from './hooks/useAdditionalLogic';
import {getIdFromQueryString} from './utils/getIdFromQueryString';
import {getModuleFromQueryString} from './utils/getModuleFromQueryString';
import {getPathToRedirect} from './utils/getPathToRedirect';
import {getSubmoduleFromQueryString} from './utils/getSubmoduleFromQueryString';
import {getSubmoduleIdFromQueryString} from './utils/getSubmoduleIdFromQueryString';
import {getViewFromQueryString} from './utils/getViewFromQueryString';

export function Redirect() {
  const navigate = useNavigate();
  const urlSearchString = window.location.search;

  const module = getModuleFromQueryString(urlSearchString);
  const view = getViewFromQueryString(urlSearchString);
  const id = getIdFromQueryString(urlSearchString);
  const submodule = getSubmoduleFromQueryString(urlSearchString);
  const submoduleId = getSubmoduleIdFromQueryString(urlSearchString);

  const redirectProps = {
    module,
    view,
    id,
    submodule,
    submoduleId,
  };

  const [additionalModuleLogic] = useAdditionalLogic(redirectProps);
  const path = getPathToRedirect(redirectProps);

  useEffect(() => {
    if (isNil(path)) {
      return;
    }

    additionalModuleLogic();
    navigate(path, {replace: true});
    // adding dependencies would cause unwanted re-runs of this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, path]);

  return null;
}
