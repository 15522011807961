import {parseDate} from 'shared';

export const castTimeToDateISOString = (date: string, time: string) => {
  const parsedDate = parseDate(date);
  const [hours, minutes] = time.split(':') ?? [];
  if (!hours || !minutes) {
    return parsedDate.toISOString();
  }
  parsedDate.setHours(Number(hours));
  parsedDate.setMinutes(Number(minutes));
  return parsedDate.toISOString();
};
