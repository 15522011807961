import {Card} from 'platform/components';
import {VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {CommentsList} from './components/CommentsList';
import {NewComment} from './components/NewComment';

interface CommentsTabProps extends RequiredTestIdProps {
  vehicleId: string;
}

export function CommentsTab(props: CommentsTabProps) {
  return (
    <Card title={i18n.t('page.comments.labels.comments')}>
      <VStack spacing={4}>
        <CommentsList
          vehicleId={props.vehicleId}
          data-testid={suffixTestId('commentsList', props)}
        />
        <NewComment vehicleId={props.vehicleId} data-testid={suffixTestId('newComment', props)} />
      </VStack>
    </Card>
  );
}
