import axios from 'axios';
import {match} from 'ts-pattern';

import {downloadBlobFile} from './downloadBlobFile';
import {handleApiError} from './handleApiError';

type DownloadDataGridExportProps = {
  exportId: string;
  gridCode: string;
};

export const downloadDataGridExport = async (props: DownloadDataGridExportProps) => {
  const blob = await axios
    .get(`/v6/data-grid/${props.gridCode}/export/${props.exportId}/download`, {
      responseType: 'arraybuffer',
    })
    .catch(handleApiError);
  if (!blob) {
    return;
  }
  const downloadParams = match(blob.headers['content-type'] as string)
    .with('text/csv; charset=UTF-8', () => ({
      fileName: 'export',
      fileType: 'csv' as const,
    }))
    .otherwise(() => ({
      fileName: 'export',
      fileType: 'xlsx' as const,
    }));
  downloadBlobFile(blob.data, downloadParams);
};
