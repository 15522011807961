import {ThemeIconKey} from 'platform/foundation';
import {match} from 'ts-pattern';

import {TaskTypeEnum, TaskTypeEnumSchema} from '@omnetic-dms/api';

export function getIconKeyByType(type: TaskTypeEnum) {
  return match(type)
    .returnType<ThemeIconKey>()
    .with(TaskTypeEnumSchema.enum.TASK, () => 'content/paste')
    .with(TaskTypeEnumSchema.enum.MEETING, () => 'action/calendar_today')
    .with(TaskTypeEnumSchema.enum.CALL, () => 'communication/phone')
    .exhaustive();
}
