import {showNotification} from 'platform/components';
import {Box, HStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {equals, not} from 'ramda';
import {isArray, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  DeletePartsRequestReturnItemsRequest,
  HeaderDynamicActionsEventRequest,
  PartsRequestReturnBasketItem,
  PostPartsRequestReturnItemRequest,
  RequestItem,
  useDeletePartsRequestReturnItemsMutation,
  useGetPartsRequestReturnBasketQuery,
  useGetWarehouseHeaderDynamicActionsQuery,
  usePatchPartsRequestReturnBasketItemQuantityMutation,
  usePostPartsRequestReturnItemMutation,
  usePutWarehouseHeaderDynamicActionsMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {
  AfterSalesMaterialReturnBasket,
  EitherQuantityOrError,
  FullScreenModal,
  handleApiError,
  useBasketMechanic,
  useInvalidBasketItemsIds,
} from '@omnetic-dms/shared';

import {noop, Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {useRefreshDataGrid} from 'features/datagrid';

import {createHeaderActions} from '../../../utils/createHeaderActions';
import {DirectSaleMaterialReturnList} from './DirectSaleMaterialReturnList';

interface DirectSaleMaterialReturnModalProps extends RequiredTestIdProps {
  directSaleId: string;
  authorizationProfileId: string | Nullish;
  onClose: VoidFunction;
}

export function DirectSaleMaterialReturnModal(props: DirectSaleMaterialReturnModalProps) {
  const [dataGridRef, refreshDataGrid] = useRefreshDataGrid();

  const {
    data: basket,
    isLoading: isBasketLoading,
    isError: isBasketError,
  } = useGetPartsRequestReturnBasketQuery({originEntityHeaderId: props.directSaleId});

  const {
    data: partsRequestActions,
    isLoading: isPartsRequestActionsLoading,
    isError: isPartsRequestActionsError,
  } = useGetWarehouseHeaderDynamicActionsQuery({
    id: props.directSaleId,
    resource: 'parts-request-return',
  });

  const [putPartsRequestAction, {isLoading: isPutPartsRequestActionLoading}] =
    usePutWarehouseHeaderDynamicActionsMutation();

  const [addItemsToBasket] = usePostPartsRequestReturnItemMutation();

  const [patchBasketItemQuantity] = usePatchPartsRequestReturnBasketItemQuantityMutation();

  const [deleteBasketItems, {isLoading: isDeletingBasketItems}] =
    useDeletePartsRequestReturnItemsMutation();

  const isLoading = isBasketLoading || isPartsRequestActionsLoading;
  const isError = isBasketError || isPartsRequestActionsError;

  const basketItems = basket?.requestReturnBasketItem ?? [];
  const basketTotalPrice = basket?.requestReturnBasketTotalPrice;
  const basketMechanicId = basket?.assignMechanicId;

  const {setInvalidBasketItemId, invalidBasketItemsIds} = useInvalidBasketItemsIds(basketItems);

  const {mechanics, areMechanicsLoading, mechanicPayload, inputMechanicId, handleMechanicChange} =
    useBasketMechanic({
      authorizationProfileId: props.authorizationProfileId,
      assignedMechanicId: basketMechanicId,
    });

  const handleAddToBasket = async (selectedItems: RequestItem[]) => {
    const requestBody: PostPartsRequestReturnItemRequest['body'] = {
      originEntityHeaderId: props.directSaleId,
      originEntityHeaderType: 'direct-sale',
      originEntityType: 'wrh_sale_item',
      requestItems: selectedItems,
    };

    await addItemsToBasket({
      body: requestBody,
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleQuantityChange = async (itemId: string, quantity: EitherQuantityOrError) => {
    setInvalidBasketItemId(itemId, quantity);

    const basketItem = basketItems.find((item) => equals(item.id, itemId));
    const hasQuantityChanged = not(equals(basketItem?.quantity, quantity.newQuantity));

    if (quantity.hasError || not(hasQuantityChanged)) {
      return;
    }

    await patchBasketItemQuantity({
      originEntityHeaderId: props.directSaleId,
      requestItemId: itemId,
      body: {quantity: quantity.newQuantity!},
    })
      .unwrap()
      .catch(handleApiError);
  };

  const handleDelete = async (itemsIds: string | string[]) => {
    const ids = isArray(itemsIds) ? itemsIds : [itemsIds];

    const requestBody: DeletePartsRequestReturnItemsRequest['body'] = {
      requestItemId: ids,
    };

    await deleteBasketItems({
      originEntityHeaderId: props.directSaleId,
      body: requestBody,
    })
      .unwrap()
      .then(refreshDataGrid)
      .catch(handleApiError);
  };

  const handleActionClick = (actionKey: string) => {
    const requestAction = (body: HeaderDynamicActionsEventRequest['body']) =>
      putPartsRequestAction({
        id: props.directSaleId,
        resource: 'parts-request-return',
        body,
      })
        .unwrap()
        .then(() =>
          actionKey === 'complete'
            ? showNotification.success(i18n.t('entity.warehouse.notifications.returnCompleted'))
            : noop
        )
        .then(props.onClose)
        .catch(handleApiError);

    match(actionKey)
      .with('complete', () => {
        requestAction({
          actionKey,
          returnedBy: mechanicPayload?.id,
          originEntityHeaderType: 'direct-sale',
        });
      })
      .otherwise(() => {
        requestAction({
          actionKey,
          originEntityHeaderType: 'direct-sale',
        });
      });
  };

  const dynamicActions = createHeaderActions({
    actions: partsRequestActions?.actions,
    callback: handleActionClick,
    isLoading: isPutPartsRequestActionLoading,
  });

  return (
    <FullScreenModal
      headline={i18n.t('general.actions.returnMaterial')}
      headerActions={dynamicActions}
    >
      <Box padding={4} height="100%">
        <HStack spacing={4} height="100%">
          <Box flex={5}>
            <DirectSaleMaterialReturnList
              directSaleId={props.directSaleId}
              dataGridRef={dataGridRef}
              onAddToBasket={handleAddToBasket}
              data-testid={suffixTestId('list', props)}
            />
          </Box>
          <Box flex={1} minWidth={86}>
            <AfterSalesMaterialReturnBasket<PartsRequestReturnBasketItem>
              basket={{
                items: basketItems,
                totalPrice: basketTotalPrice,
                isLoading,
                hasError: isError,
                hasInvalidItems: isNotNilOrEmpty(invalidBasketItemsIds),
                isDeletingItems: isDeletingBasketItems,
              }}
              mechanic={{
                mechanics,
                areMechanicsLoading,
                selectedMechanicId: inputMechanicId,
                onMechanicChange: handleMechanicChange,
              }}
              onQuantityChange={handleQuantityChange}
              onDelete={handleDelete}
              data-testid={suffixTestId('basket', props)}
            />
          </Box>
        </HStack>
      </Box>
    </FullScreenModal>
  );
}
