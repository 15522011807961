import {isEmpty, isNil, isNotEmpty, isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

import {InspectionType, useCreateAuditMutation} from '@omnetic-dms/api';

/**
 * Hook to get or create a vehicle condition inspection.
 */
export function useGetOrCreateInspection() {
  const [createInspection] = useCreateAuditMutation();

  const getOrCreateInspection = async (
    vehicleId: string | undefined,
    inspectionId: string | undefined
  ) => {
    if (isNotNil(inspectionId) && isNotEmpty(inspectionId)) {
      return inspectionId;
    }

    if (isNil(vehicleId) || isEmpty(vehicleId)) {
      throw new Error('Vehicle ID is required to ensure inspection exists');
    }

    // In some rare cases the vehicle condition inspection is not created along the vehicle creation.
    // Handle this case by creating the inspection before using it.
    const inspection = await createInspection({
      vehicleId,
      body: {
        inspectionType: InspectionType.VEHICLE_CONDITION,
      },
    }).unwrap();

    if (isNotNilOrEmpty(inspection.id)) {
      return inspection.id;
    }

    throw new Error('Failed to create inspection');
  };

  return getOrCreateInspection;
}
