import {Flag, FormControl, FormField} from 'platform/components';
import {Box, Grid, GridItem, HStack, Icon} from 'platform/foundation';
import {match} from 'ts-pattern';

import {Path} from 'react-hook-form';

import {always} from 'ramda';

import i18n from '@omnetic-dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ReceiveNoteCorrectionItemForm} from '../types/ReceiveNoteCorrectionItemForm';
import {ReceiveNoteCorrectionItemFormContext} from '../types/ReceiveNoteCorrectionItemFormContext';

interface ReceiveNoteCorrectionItemDifferenceRowProps extends RequiredTestIdProps {
  control: FormControl<ReceiveNoteCorrectionItemForm>;
  formContext: ReceiveNoteCorrectionItemFormContext;
  unit: string;
  currency: string | Nullish;
}

export function ReceiveNoteCorrectionItemDifferenceRow(
  props: ReceiveNoteCorrectionItemDifferenceRowProps
) {
  const quantity = match(props.formContext)
    .with('receiveItem', always('receiveCorrectionQuantity'))
    .with('deliveryItem', always('deliveryCorrectionQuantity'))
    .otherwise(always(null));

  const unitPrice = match(props.formContext)
    .with('receiveItem', always('receiveCorrectionUnitPrice'))
    .with('deliveryItem', always('deliveryCorrectionUnitPrice'))
    .otherwise(always(null));

  const totalPrice = match(props.formContext)
    .with('receiveItem', always('receiveCorrectionStockValue'))
    .with('deliveryItem', always('deliveryCorrectionStockValue'))
    .otherwise(always(null));

  return (
    <Grid columns={4} spacing={4} align="flex-end" data-testid={props['data-testid']}>
      <GridItem span={1}>
        <FormField
          control={props.control}
          type="number"
          name={`${props.formContext}.${quantity}` as Path<ReceiveNoteCorrectionItemForm>}
          label={i18n.t('entity.warehouse.labels.quantity')}
          suffix={props.unit}
          isDisabled
          data-testid={suffixTestId('inputs.differenceQuantity', props)}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          control={props.control}
          type="number"
          name={`${props.formContext}.${unitPrice}` as Path<ReceiveNoteCorrectionItemForm>}
          label={i18n.t('entity.warehouse.labels.unitPrice')}
          suffix={props.currency}
          isDisabled
          data-testid={suffixTestId('inputs.differenceUnitPrice', props)}
        />
      </GridItem>
      <GridItem span={1}>
        <FormField
          control={props.control}
          type="number"
          name={`${props.formContext}.${totalPrice}` as Path<ReceiveNoteCorrectionItemForm>}
          label={i18n.t('entity.warehouse.labels.totalPrice')}
          suffix={props.currency}
          isDisabled
          data-testid={suffixTestId('inputs.differenceStockValue', props)}
        />
      </GridItem>
      <GridItem span={1}>
        <HStack spacing={2} align="center">
          <Icon value="navigation/chevron_left" color="palettes.neutral.800" size={4} />
          <Box width="100%">
            <Flag
              label={i18n.t('entity.warehouse.labels.difference')}
              colorScheme="blue"
              size="large"
              isSubtle
              isFullWidth
              data-testid={suffixTestId('rowFlag', props)}
            />
          </Box>
        </HStack>
      </GridItem>
    </Grid>
  );
}
