import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query';
import {showNotification} from 'platform/components';
import {Grid} from 'platform/foundation';
import {platformLightboxQueryParams, useLightbox} from 'platform/lightbox';

import {CSSProperties, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {isNil} from 'ramda';

import {
  useDeleteInspectionMutation,
  useDeleteVideoAssetMutation,
  useLazyGetVideoAssetQuery,
  useUploadVideoAssetMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {useVideoUploadQueueState} from 'features/video-upload-queue';

import {useThunkDispatch} from '../../../hooks/useThunkDispatch';
import {
  addVideoAssets,
  deleteVideoAssets,
  updateAuditState,
  updateVideoAssetData,
} from '../../../store/carAudit/reducer';
import {selectActiveAuditId} from '../../../store/carAudit/selectors';
import {LoadAuditDataResponseItemBody} from '../../../types/LoadAuditDataResponseItemBody';
import {useAuditVideos} from '../hooks/useAuditVideos';
import {useBlockNavigating} from '../hooks/useBlockNavigating';
import {useConditionContext} from '../hooks/useConditionContext';
import {useVideoNotificationSubscription} from '../hooks/useVideoNotificationSubscription';
import {PendingVideo} from '../types/PendingVideo';
import {getPrecedingPlayableVideos} from '../utils/getPrecedingPlayableVideos';
import {isVideoPlayable} from '../utils/isVideoPlayable';
import {AuditVideo} from './AuditVideo';
import {AuditVideoLightbox} from './AuditVideoLightbox';
import {PossibleUploadInterruptionDialog} from './PossibleUploadInterruptionDialog';
import {UploadVideo} from './UploadVideo';
import {VideoPlaceholder} from './VideoPlaceholder';

interface AuditVideosProps extends TestIdProps {
  paramDefinitionId: string;
  categoryId: string;
  ratio?: CSSProperties['aspectRatio'];
  isMandatory?: boolean;
}

export function AuditVideos(props: AuditVideosProps) {
  const auditId = useSelector(selectActiveAuditId);
  const dispatch = useThunkDispatch();
  const {videoAssets} = useAuditVideos(props.categoryId, props.paramDefinitionId);
  const {isDisabledForUser, handleChangeCategory, setIsUploadInProgress} = useConditionContext();
  const [lightboxUploadImagesGalleryControls, {onOpen: onGalleryOpen, isOpen: isGalleryOpen}] =
    useLightbox(`auditVideos-${props.categoryId}-${props.paramDefinitionId}`);
  const [, {isSuccess: isDeleteInspectionSuccess}] = useDeleteInspectionMutation({
    fixedCacheKey: 'deleteInspection_auditVideos',
  });
  const [isUploadStarting, startUploadStarting, stopUploadStarting] = useBoolean();
  const {tasks, uploadProgress} = useVideoUploadQueueState();
  const [pendingVideos, setPendingVideos] = useState<PendingVideo[]>([]);
  /*
   Video upload phases – handled by:
    1. Upload is triggered (upload button is disabled) – isUploadStarting
    2. Multipart upload is in progress – tasks.length > 0
    3. Video is being processed by the backend – pendingVideos.length > 0
    4. Video is processed by BE and assigned to the audit

    Exceptions from blocking – exempt by:
    - Opening the lightbox – unblockForNextLocationSearchParam = platformLightboxQueryParams.LIGHTBOX_ID
    - Closing the lightbox – !isGalleryOpen (unblockForCurrentLocationSearchParam would not block the navigation, but would still show dialog)
    - Deleting inspection – isDeleteInspectionSuccess

    Unhandled blocking (should be handled, but it's not):
    - Condition refresh button click – being disabled by setting isUploadInProgress from context
   */
  const isUploadInProgress = isUploadStarting || tasks.length + pendingVideos.length > 0;
  const {blocker} = useBlockNavigating(
    isUploadInProgress && !isGalleryOpen && !isDeleteInspectionSuccess,
    platformLightboxQueryParams.LIGHTBOX_ID
  );

  useEffect(() => {
    setIsUploadInProgress(isUploadInProgress);
  }, [isUploadInProgress, setIsUploadInProgress]);

  const [getVideoAsset] = useLazyGetVideoAssetQuery();
  const [uploadVideoAsset] = useUploadVideoAssetMutation();
  const [deleteVideoAsset] = useDeleteVideoAssetMutation();

  const onVideoProcessed = (pendingVideo: PendingVideo, fileStatus: string, fileType: string) => {
    if (isNil(auditId)) {
      throw new Error('Audit ID is not defined');
    }

    if (!(fileStatus === 'success' && fileType === 'video')) {
      showNotification.error(
        i18n.t('entity.inspection.errors.processingFailed', {
          filename: pendingVideo.filename,
        })
      );
      setPendingVideos((videos) =>
        videos.filter((video) => video.videoId !== pendingVideo.videoId)
      );
      return;
    }

    const options = {
      auditId,
      categoryId: props.categoryId,
      paramDefinitionId: props.paramDefinitionId,
      updatedAt: pendingVideo.updatedAt,
      vehicleAuditVideoAssetId: pendingVideo.vehicleAuditVideoAssetId,
      videoId: pendingVideo.videoId,
    };

    // Assign video to the audit
    uploadVideoAsset({auditId, body: options})
      .unwrap()
      .then((response) => {
        // Update redux state
        dispatch(
          addVideoAssets({
            categoryId: props.categoryId,
            paramDefinitionId: props.paramDefinitionId,
            assets: [{id: response.id, videoId: pendingVideo.videoId}],
          })
        );
        onVideoAssignedOrDeleted();
        showNotification.success(
          i18n.t('entity.inspection.labels.uploadCompleted', {
            filename: pendingVideo.filename,
          })
        );
      })
      .catch((error: FetchBaseQueryError) => {
        showNotification.error(
          i18n.t('entity.inspection.errors.uploadFailed', {
            filename: pendingVideo.filename,
          })
        );
        handleApiError(error, {silent: true});
      })
      .finally(() => {
        setPendingVideos((videos) =>
          videos.filter((video) => video.videoId !== pendingVideo.videoId)
        );
      });
  };

  const onVideoVariantGenerated = (videoAssetId: string) => {
    if (isNil(auditId)) {
      throw new Error('Audit ID is not defined');
    }

    getVideoAsset({auditId, videoAssetId})
      .unwrap()
      .then((response) => {
        dispatch(
          updateVideoAssetData({
            categoryId: props.categoryId,
            paramDefinitionId: props.paramDefinitionId,
            videoId: response.videoId,
            asset: response,
          })
        );
      })
      .catch(handleApiError);
  };

  // Subscribe to notifications to know when to assign videos to audits and fetch generated variants of uploaded videos
  useVideoNotificationSubscription({
    pendingVideos,
    videoAssets,
    onVideoProcessed,
    onVideoVariantGenerated,
  });

  const handleDeleteVideo = (videoAsset: {id: string; videoId: string}) => {
    if (isNil(auditId)) {
      throw new Error('Audit ID is not defined');
    }

    deleteVideoAsset({auditId, videoAssetId: videoAsset.id})
      .unwrap()
      .then(() => {
        dispatch(
          deleteVideoAssets({
            categoryId: props.categoryId,
            paramDefinitionId: props.paramDefinitionId,
            videoIds: [videoAsset.videoId],
          })
        );
        onVideoAssignedOrDeleted();
        showNotification.success(i18n.t('entity.inspection.labels.deleteVideoAssetCompleted'));
      })
      .catch(handleApiError);
  };

  // Assigning or deleting the video the status of the audit should be changed to in progress
  const onVideoAssignedOrDeleted = () => {
    // This is not RTK Query – unwrap() is not available
    // eslint-disable-next-line no-restricted-syntax
    handleChangeCategory()
      .then(() => {
        dispatch(
          updateAuditState({
            state: LoadAuditDataResponseItemBody.state.IN_PROGRESS,
          })
        );
      })
      .catch(handleApiError);
  };

  return (
    <Grid columns={8}>
      <AuditVideoLightbox
        videoAssets={videoAssets}
        controls={lightboxUploadImagesGalleryControls}
        onDeleteVideo={handleDeleteVideo}
      />

      {videoAssets?.map((videoAsset, index) => (
        <AuditVideo
          key={videoAsset.id}
          videoAsset={videoAsset}
          ratio={props.ratio}
          isLoading={!isVideoPlayable(videoAsset)}
          isDisabled={isDisabledForUser}
          onOpenPreview={() => onGalleryOpen(getPrecedingPlayableVideos(videoAssets, index))}
          onDeleteVideo={handleDeleteVideo}
          data-testid={suffixTestId('auditVideo', props)}
        />
      ))}

      {tasks.map((task) => {
        const progress =
          (uploadProgress.find((progress) => (progress.id = task.id))?.progress ?? 0) / 100;

        return <VideoPlaceholder key={task.id} ratio={props.ratio} progress={progress} />;
      })}

      {pendingVideos.map((pendingVideo) => (
        <VideoPlaceholder key={pendingVideo.videoId} ratio={props.ratio} />
      ))}

      <UploadVideo
        ratio={props.ratio}
        isMandatory={props.isMandatory}
        onVideoUploadTriggered={startUploadStarting}
        onVideoUploadStarted={stopUploadStarting}
        onVideoUploadFinished={(pendingVideo) =>
          // We must wait for success notification from BE to be able to assign it to the audit
          setPendingVideos((videos) => [...videos, pendingVideo])
        }
      />

      <PossibleUploadInterruptionDialog blocker={blocker} />
    </Grid>
  );
}
