import {Separator} from 'platform/components';
import {Box, HStack, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useCallback} from 'react';

import {head, isNil, mergeAll} from 'ramda';
import {isArray} from 'ramda-adjunct';

import {usePostWarehouseArticleItemMutation} from '@omnetic-dms/api';
import {
  handleApiError,
  useWarehouseDataGridTreeFolder,
  ArticleFolderTree,
  catchUnhandledDataGridActions,
} from '@omnetic-dms/shared';

import {Nullish, TestIdProps, suffixTestId} from 'shared';

import {ActionCallback, DataGrid, QueryFilterObject} from 'features/datagrid';

import {MaterialArticleTabExternalFilters} from './WarehouseArticlesTabExternalFilters';

interface WarehouseArticlesTabProps extends TestIdProps {
  serviceOrderIssueNoteId: string | Nullish;
  serviceOrderId: string | Nullish;
  serviceOrderVariantId: string | Nullish;
}

const WAREHOUSE_ARTICLES_EXTERNAL_FILTER_ID = 'wharticles-external-filter-id';

export function WarehouseArticlesTab(props: WarehouseArticlesTabProps) {
  const [addToBasket] = usePostWarehouseArticleItemMutation();
  const [folderTreeRef, dataGridModifier] = useWarehouseDataGridTreeFolder();

  const queryModifier = useCallback(
    (filter: QueryFilterObject) => {
      const modifiedFilter = mergeAll([filter, {serviceOrderId: props.serviceOrderId}]);

      return dataGridModifier ? dataGridModifier?.queryModifier(modifiedFilter) : modifiedFilter;
    },
    [props.serviceOrderId, dataGridModifier]
  );

  const actionCallback: ActionCallback = ({actionKey, rowId, refreshData}) => {
    const id = isArray(rowId) ? head(rowId) : rowId;

    if (isNil(id)) {
      throw Error('Row id must be specified');
    }

    match(actionKey)
      .with('dispatch', () => {
        if (props.serviceOrderIssueNoteId) {
          addToBasket({
            serviceOrderIssueNoteId: props.serviceOrderIssueNoteId,
            body: {articleId: id},
          })
            .unwrap()
            .then(refreshData)
            .catch(handleApiError);
        }
      })
      .otherwise(() => catchUnhandledDataGridActions(actionKey));
  };

  return (
    <HStack height="100%">
      <Box flex={1}>
        <VStack height="100%">
          <Box minHeight={40}>
            <div id={WAREHOUSE_ARTICLES_EXTERNAL_FILTER_ID}></div>
          </Box>
          <Separator orientation="horizontal" spacing={4} />
          <ArticleFolderTree
            ref={folderTreeRef}
            data-testid={suffixTestId('articleFolderTree', props)}
          />
        </VStack>
      </Box>
      <Separator orientation="vertical" />
      <Box flex={3}>
        <DataGrid
          // DG must be re-rendered on folder change to update the query modifier
          // eslint-disable-next-line no-restricted-syntax
          key={dataGridModifier?.key}
          gridCode="issue-note-service-order-warehouse-articles"
          externalFilterId={WAREHOUSE_ARTICLES_EXTERNAL_FILTER_ID}
          _useAsLastResort_definitionBehaviorOverrides={{filterMode: 'NATIVE'}}
          filterComponent={(datagridProps) => (
            <MaterialArticleTabExternalFilters
              serviceOrderVariantId={props.serviceOrderVariantId}
              {...datagridProps}
            />
          )}
          actionCallback={actionCallback}
          queryModifier={queryModifier}
          data-testid="issueNoteServiceOrderWarehouseArticles"
        />
      </Box>
    </HStack>
  );
}
