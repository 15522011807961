import {HStack, Space} from 'platform/foundation';

import {DigitalCertificateLink} from '@omnetic-dms/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {InspectionIdForAuditId} from '../../../types/InspectionIdForAuditId';
import {AutoFillTemplates} from './AutoFillTemplates';
import {ExportPDF} from './ExportPDF';
import {VerifyCondition} from './VerifyCondition';

interface Props extends TestIdProps, Omit<InspectionIdForAuditId, 'auditId'> {}

export function ConditionHeader(props: Props) {
  return (
    <>
      <HStack spacing={2} justify="flex-end">
        <DigitalCertificateLink />
        <ExportPDF data-testid={suffixTestId('exportPdf', props)} />
        <AutoFillTemplates
          data-testid={suffixTestId('conditionHeader', props)}
          shouldUseAuditIdForUpdateTemplate={props.shouldUseAuditIdForUpdateTemplate}
        />
        <VerifyCondition data-testid={suffixTestId('conditionHeader', props)} />
      </HStack>
      <Space vertical={5} />
    </>
  );
}
