import {z} from 'zod';

import {AbortSignalSchema, BaseVoidResponseSchema} from './base';
import {GetArticleResponseSchema} from './metadaWarehouseArticle';

/**
 * GET
 */
export const GetReceiveNoteCorrectionItemRequestSchema = z.object({
  creditNoteId: z.string(),
  creditNoteItemId: z.string(),
});
export type GetReceiveNoteCorrectionItemRequest = z.infer<
  typeof GetReceiveNoteCorrectionItemRequestSchema
>;

export const GetReceiveNoteCorrectionItemResponseSchema = z.object({
  creditNoteItemId: z.string(),
  creditNoteId: z.string(),
  receiveNoteItemId: z.string(),
  deliveryNoteItemId: z.string(),
  correctedCreditNoteId: z.string().optional(),
  // TODO https://carvago.atlassian.net/browse/T20-67057
  currency: z.string().optional(),
  receiveItem: z.object({
    receiveCorrectionQuantity: z.number(),
    receiveCorrectionUnitPrice: z.number(),
    receiveCorrectionStockValue: z.number(),
    receiveAfterQuantity: z.number(),
    receiveAfterUnitPrice: z.number(),
    receiveAfterStockValue: z.number(),
    receiveBeforeQuantity: z.number(),
    receiveBeforeUnitPrice: z.number(),
    receiveBeforeStockValue: z.number(),
  }),
  deliveryItem: z.object({
    deliveryCorrectionQuantity: z.number(),
    deliveryCorrectionUnitPrice: z.number(),
    deliveryCorrectionStockValue: z.number(),
    deliveryAfterQuantity: z.number(),
    deliveryAfterUnitPrice: z.number(),
    deliveryAfterStockValue: z.number(),
    deliveryBeforeQuantity: z.number(),
    deliveryBeforeUnitPrice: z.number(),
    deliveryBeforeStockValue: z.number(),
  }),
  supplierArticle: z.object({
    supplierQuantity: z.number(),
    supplierUnitId: z.string(),
    warehouseQuantity: z.number(),
    supplierOrderingNumber: z.string().optional(),
    supplierBulkPackageQuantity: z.number().optional(),
    supplierId: z.string(),
  }),
  article: GetArticleResponseSchema,
});
export type GetReceiveNoteCorrectionItemResponse = z.infer<
  typeof GetReceiveNoteCorrectionItemResponseSchema
>;

/**
 * POST
 */
export const BulkPostReceiveNoteCorrectionItemsRequestSchema = z.object({
  creditNoteId: z.string(),
  body: z.object({
    receiveNoteItemId: z.array(z.string()),
  }),
});
export type BulkPostReceiveNoteCorrectionItemsRequest = z.infer<
  typeof BulkPostReceiveNoteCorrectionItemsRequestSchema
>;

export const BulkPostReceiveNoteCorrectionItemsResponseSchema = z.object({
  creditNoteItemId: z.array(z.string()),
});
export type BulkPostReceiveNoteCorrectionItemsResponse = z.infer<
  typeof BulkPostReceiveNoteCorrectionItemsResponseSchema
>;

export const PatchReceiveNoteCorrectionItemsDeleteRequestSchema = z.object({
  creditNoteId: z.string(),
  body: z.object({
    state: z.enum(['BASKET', 'ADDED']),
    creditNoteItemId: z.array(z.string()).optional(),
  }),
});
export type PatchReceiveNoteCorrectionItemsDeleteRequest = z.infer<
  typeof PatchReceiveNoteCorrectionItemsDeleteRequestSchema
>;

export const PatchReceiveNoteCorrectionItemsDeleteResponseSchema = BaseVoidResponseSchema;
export type PatchReceiveNoteCorrectionItemsDeleteResponse = z.infer<
  typeof PatchReceiveNoteCorrectionItemsDeleteResponseSchema
>;

/**
 * PATCH
 */
export const PatchReceiveNoteCorrectionItemRequestSchema = z.object({
  creditNoteItemId: z.string(),
  body: z.object({
    receiveItem: z.object({
      receiveCorrectionQuantity: z.number(),
      receiveCorrectionUnitPrice: z.number(),
      receiveCorrectionStockValue: z.number(),
      receiveAfterQuantity: z.number(),
      receiveAfterUnitPrice: z.number(),
      receiveAfterStockValue: z.number(),
    }),
    deliveryItem: z.object({
      deliveryCorrectionQuantity: z.number(),
      deliveryCorrectionUnitPrice: z.number(),
      deliveryCorrectionStockValue: z.number(),
      deliveryAfterQuantity: z.number(),
      deliveryAfterUnitPrice: z.number(),
      deliveryAfterStockValue: z.number(),
    }),
  }),
});
export type PatchReceiveNoteCorrectionItemRequest = z.infer<
  typeof PatchReceiveNoteCorrectionItemRequestSchema
>;

export const PatchReceiveNoteCorrectionItemResponseSchema = BaseVoidResponseSchema;
export type PatchReceiveNoteCorrectionItemResponse = z.infer<
  typeof PatchReceiveNoteCorrectionItemResponseSchema
>;

/**
 * DELETE
 */

/**
 * PUT
 */
const ReceiveNoteCorrectionItemChangedFieldSchema = z.enum([
  'receiveAfterQuantity',
  'deliveryAfterQuantity',
]);
export type ReceiveNoteCorrectionItemChangedField = z.infer<
  typeof ReceiveNoteCorrectionItemChangedFieldSchema
>;

export const PutReceiveNoteCorrectionItemCalculationRequestSchema = z.object({
  body: z.object({
    changedFieldValue: z.object({
      field: ReceiveNoteCorrectionItemChangedFieldSchema,
    }),
    currentFeFieldsValues: z.object({
      receiveItem: z.object({
        receiveAfterQuantity: z.number(),
        receiveAfterUnitPrice: z.number(),
        receiveAfterStockValue: z.number(),
        receiveBeforeQuantity: z.number(),
        receiveBeforeUnitPrice: z.number(),
        receiveBeforeStockValue: z.number(),
      }),
      deliveryItem: z.object({
        deliveryAfterQuantity: z.number(),
        deliveryAfterUnitPrice: z.number(),
        deliveryAfterStockValue: z.number(),
        deliveryBeforeQuantity: z.number(),
        deliveryBeforeUnitPrice: z.number(),
        deliveryBeforeStockValue: z.number(),
      }),
      supplierArticle: z.object({
        supplierQuantity: z.number(),
        warehouseQuantity: z.number(),
      }),
    }),
  }),
  signal: AbortSignalSchema,
});
export type PutReceiveNoteCorrectionItemCalculationRequest = z.infer<
  typeof PutReceiveNoteCorrectionItemCalculationRequestSchema
>;

export const PutReceiveNoteCorrectionItemCalculationResponseSchema = z.object({
  receiveItem: z.object({
    receiveCorrectionQuantity: z.number(),
    receiveCorrectionUnitPrice: z.number(),
    receiveCorrectionStockValue: z.number(),
    receiveAfterQuantity: z.number(),
    receiveAfterUnitPrice: z.number(),
    receiveAfterStockValue: z.number(),
    receiveBeforeQuantity: z.number(),
    receiveBeforeUnitPrice: z.number(),
    receiveBeforeStockValue: z.number(),
  }),
  deliveryItem: z.object({
    deliveryCorrectionQuantity: z.number(),
    deliveryCorrectionUnitPrice: z.number(),
    deliveryCorrectionStockValue: z.number(),
    deliveryAfterQuantity: z.number(),
    deliveryAfterUnitPrice: z.number(),
    deliveryAfterStockValue: z.number(),
    deliveryBeforeQuantity: z.number(),
    deliveryBeforeUnitPrice: z.number(),
    deliveryBeforeStockValue: z.number(),
  }),
  supplierArticle: z.object({
    supplierQuantity: z.number(),
    warehouseQuantity: z.number(),
  }),
});
export type PutReceiveNoteCorrectionItemCalculationResponse = z.infer<
  typeof PutReceiveNoteCorrectionItemCalculationResponseSchema
>;
