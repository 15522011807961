import {FormControl, FormField, Button, Separator, FormButton} from 'platform/components';
import {VStack, HStack, Space} from 'platform/foundation';
import {match} from 'ts-pattern';

import {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {FormContactPerson} from '../../../hooks/useContactPersons';
import {
  EmailSmsNotificationForm,
  NotificationCategory,
  ServiceCaseState,
} from '../types/EmailSmsNotificationForm';
import {EmailSection} from './EmailSection';
import {SmsSection} from './SmsSection';

interface EmailSmsNotificationFormBodyProps extends RequiredTestIdProps {
  control: FormControl<EmailSmsNotificationForm>;
  formApi: UseFormReturn<EmailSmsNotificationForm>;
  orderId?: string;
  serviceCaseId: string;
  serviceCaseState?: ServiceCaseState;
  isCheckinFinished: boolean;
  isHandoverFinished: boolean;
  contactPersons: FormContactPerson[];
  onCloseDialog: () => void;
}

export function EmailSmsNotificationFormBody(props: EmailSmsNotificationFormBodyProps) {
  const {watch, setValue} = props.formApi;

  const selectedCategory = watch('category');
  const isCategoryCustom = selectedCategory[0] === NotificationCategory.CUSTOM;

  const isChipDisabled = () =>
    match(props.serviceCaseState)
      .with('CREATED', () => [false, true, true])
      .with('DRAFT', () => [false, true, true])
      .with('DEMAND', () => [false, true, true])
      .with('PLANNED', () => [false, true, true])
      .with('CHECKED_IN', () => [false, false, true])
      .with('HANDED_OVER', () => [false, false, false])
      .otherwise(() => [true, true, true]);

  useEffect(() => {
    setValue('sms.isEnabled', isCategoryCustom);
  }, [isCategoryCustom, setValue]);

  return (
    <VStack spacing={4}>
      <FormField
        control={props.control}
        type="chips"
        name="category"
        options={[
          {
            isDisabled: isChipDisabled()[0],
            label: i18n.t('general.labels.custom'),
            value: NotificationCategory.CUSTOM,
          },
          {
            isDisabled: isChipDisabled()[1] || !props.isCheckinFinished,
            label: i18n.t('entity.workshop.labels.checkIn'),
            value: NotificationCategory.CHECKIN,
          },
          {
            isDisabled: isChipDisabled()[2] || !props.isHandoverFinished,
            label: i18n.t('entity.workshop.labels.handover'),
            value: NotificationCategory.HANDOVER,
          },
        ]}
        data-testid={suffixTestId('category', props)}
      />
      <Separator spacing={0} />
      <EmailSection
        formApi={props.formApi}
        control={props.control}
        orderId={props.orderId}
        serviceCaseId={props.serviceCaseId}
        personsWithEmails={props.contactPersons}
        isCategoryCustom={isCategoryCustom}
        data-testid={suffixTestId('EmailSection', props)}
      />
      <Separator spacing={0} />
      <SmsSection
        formApi={props.formApi}
        control={props.control}
        personsWithPhones={props.contactPersons}
        data-testid={suffixTestId('SmsSection', props)}
        serviceCaseId={props.serviceCaseId}
        orderId={props.orderId}
      />
      <Space vertical={2} />
      <HStack spacing={2} justify="flex-end">
        <Button
          type="button"
          variant="secondary"
          title={i18n.t('general.labels.discard')}
          onClick={props.onCloseDialog}
          data-testid={suffixTestId('general.labels.discard', props)}
        />
        <FormButton
          control={props.control}
          type="submit"
          variant="primary"
          title={i18n.t('general.actions.send')}
          data-testid={suffixTestId('general.actions.send', props)}
        />
      </HStack>
    </VStack>
  );
}
