import {Card, DataStatus} from 'platform/components';
import {Heading, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  AftersalesPaymentMethod,
  useGetCheckoutByIdQuery,
  useGetCheckoutPaymentsQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {DEFAULT_CURRENCY} from '../../constants/currency';
import {CheckoutPaymentForm} from './components/CheckoutPaymentForm';
import {ForeginCurrencyPaymentCard} from './components/ForeginCurrencyPaymentCard';

interface AftersalesCheckoutPaymentProps extends RequiredTestIdProps {
  checkoutId: string;
  disallowedPaymentMethods?: AftersalesPaymentMethod[];
  defaultPaymentType?: AftersalesPaymentMethod;
}

export function AftersalesCheckoutPayment(props: AftersalesCheckoutPaymentProps) {
  const {
    data: checkoutData,
    isLoading: isCheckoutLoading,
    isError: isCheckoutError,
  } = useGetCheckoutByIdQuery({checkoutId: props.checkoutId});
  const {
    data: paymentsData,
    isLoading: isPaymentsLoading,
    isError: isPaymentsError,
  } = useGetCheckoutPaymentsQuery(
    {checkoutId: props.checkoutId},
    {refetchOnMountOrArgChange: true}
  );

  const formatCurrency = useFormatCurrency();

  const isLoading = isCheckoutLoading || isPaymentsLoading;
  const isError = isCheckoutError || isPaymentsError;

  const areAllPaymentsConcept = !!paymentsData?.every(
    ({paymentState}) => paymentState === 'CONCEPT'
  );

  return (
    <DataStatus isLoading={isLoading} isError={isError}>
      <VStack spacing={4}>
        {checkoutData && (
          <Card variant="inlineGrey">
            <VStack spacing={1}>
              <Text size="xSmall" color="secondary">
                {i18n.t('entity.order.labels.totalAmount')}
              </Text>
              <Heading size={3}>
                {formatCurrency(
                  checkoutData?.totalPrice?.withVat?.amount ?? 0,
                  checkoutData?.totalPrice?.withVat?.currency ?? DEFAULT_CURRENCY,
                  2
                )}
              </Heading>
              <Text size="xSmall" color="secondary">
                {`${formatCurrency(
                  checkoutData?.totalPrice?.withoutVat?.amount ?? 0,
                  checkoutData?.totalPrice?.withoutVat?.currency ?? DEFAULT_CURRENCY,
                  2
                )} ${i18n.t('entity.order.labels.withoutVat')}`}
              </Text>
            </VStack>
          </Card>
        )}
        <ForeginCurrencyPaymentCard
          checkoutId={props.checkoutId}
          areAllPaymentsConcept={areAllPaymentsConcept}
          data-testid={suffixTestId('foreginCurrency', props)}
        />
        {paymentsData?.map((payment) => (
          <CheckoutPaymentForm
            key={payment.checkoutPaymentId}
            paymentId={payment.checkoutPaymentId}
            checkoutId={props.checkoutId}
            isSubmitButtonDisabled={
              isNilOrEmpty(checkoutData?.billingInformation?.customerInformation.id) ||
              isNilOrEmpty(checkoutData?.billingInformation?.contractInformation?.id)
            }
            customerId={checkoutData?.billingInformation?.customerInformation.id ?? ''}
            disallowedPaymentMethods={props.disallowedPaymentMethods}
            defaultPaymentType={props.defaultPaymentType}
            isForeignCurrencyAllowed={checkoutData?.isForeignCurrencyPayment}
            data-testid={suffixTestId('payment', props)}
          />
        ))}
      </VStack>
    </DataStatus>
  );
}
