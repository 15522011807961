import {openDialog} from 'platform/components';

import i18n from '@omnetic-dms/i18n';

import {RequiredTestIdProps} from 'shared';

import {CreateTask} from '../components/CreateTask/CreateTask';

interface OpenTaskFormDialogProps extends RequiredTestIdProps {
  onCreate?: () => void;
}

export function openCreateTaskFormDialog(props: OpenTaskFormDialogProps) {
  openDialog(<CreateTask onCreate={props.onCreate} data-testid={props['data-testid']} />, {
    'data-testid': props['data-testid'],
    title: i18n.t('entity.task.actions.createTask'),
    size: 'large',
    scrollBehavior: 'outside',
    withAdditionalFooter: true,
  });
}
