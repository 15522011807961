import {OptionType} from 'platform/components';

import {applySpec, map} from 'ramda';

import {GetUsersApiResponse} from '@omnetic-dms/api';

type User = GetUsersApiResponse[number];

export const mapUsersToSelectOptions = map<User, OptionType>(
  applySpec<OptionType>({
    label: (user: User) => user.email,
    value: (user: User) => user.id,
  })
);
