import {match} from 'ts-pattern';

import {always, isNotNil} from 'ramda';

import {ContractInformationResponseBodyV2} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {useAddress} from './useAddress';

export function useCustomerContractAddress() {
  const {composeAddress} = useAddress();

  const getContractAddress = (contract: ContractInformationResponseBodyV2) =>
    match([isNotNil(contract.person?.permanentAddress), isNotNil(contract.businessInfo?.address)])
      .with([true, false], always(composeAddress(contract.person?.permanentAddress?.address)))
      .with([false, true], always(composeAddress(contract.businessInfo?.address?.address)))
      .otherwise(always(i18n.t('entity.contract.labels.doesNotContainAnAddress')));

  return [getContractAddress];
}
