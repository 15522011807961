import {testIds} from '@omnetic-dms/routes';

import {DataGrid} from 'features/datagrid';

import {useVehiclesActionCallback} from '../../hooks/useVehiclesActionCallback';

export function OSServiceVehicles() {
  const [actionCallback] = useVehiclesActionCallback();

  return (
    <DataGrid
      gridCode="service-vehicle-os"
      data-testid={testIds.vehicles.serviceVehicleOpenSearch()}
      // eslint-disable-next-line no-restricted-syntax
      key="service-vehicle-opensearch"
      actionCallback={actionCallback}
    />
  );
}
