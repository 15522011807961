import {
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  Label,
  openDialog,
  Separator,
  showNotification,
} from 'platform/components';
import {Box, Grid, Heading, HStack, Space, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';
import {boolean, object} from 'yup';

import {isNil} from 'ramda';
import {isFalse, isNilOrEmpty, isNotNilOrEmpty} from 'ramda-adjunct';

import {
  PatchServiceOrderApiArg,
  PatchServiceOrderInsuranceApiArg,
  useGetCustomersV2Query,
  useGetInsuranceTypesQuery,
  useGetServiceOrderGroupQuery,
  useGetServiceOrderInsuranceQuery,
  useGetServiceOrderQuery,
  usePatchServiceOrderInsuranceMutation,
  usePatchServiceOrderMutation,
  useAccM2StrediskoGetCentresQuery,
  useGetServiceOrderVariantAccountingQuery,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError, PredefinedNotes} from '@omnetic-dms/shared';

import {
  parseDate,
  PartialWithNull,
  suffixTestId,
  TestIdProps,
  yupDate,
  yupNumber,
  yupString,
} from 'shared';

import {useContactPersons} from '../../../../../hooks/useContactPersons';
import {MileageValidationDialog} from '../../../components/MileageValidationDialog';
import {relativeDates} from '../../../constants/relativeDates';
import {useOrderMandatoryFields} from '../hooks/useOrderMandatoryFields';
import {getCompletionAt} from '../utils/getCompletionAt';
import {getDefaultCompletionAtTime} from '../utils/getDefaultCompletionAtTime';
import {hasObjectNonEmptyValue} from '../utils/hasObjectNonEmptyValue';
import {DeactivateContractDialog} from './DeactivateContractDialog';

const sliderTicks = [
  {value: 0, label: 'E'},
  {value: 0.25, label: '1/4'},
  {value: 0.5, label: '1/2'},
  {value: 0.75, label: '3/4'},
  {value: 1, label: 'F'},
];

interface OrderInsuranceFormProps extends TestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  isReadOnly?: boolean;
}

type FormType = PartialWithNull<{
  completionAtDate: Date;
  completionAtTime: string;
  totalPriceEstimated: number;
  note: string;
  insuranceCompany: string;
  insuranceType: string;
  insuranceEventNumber: string;
  insuranceClaimNumber: string;
  initialInspectionPerformed: boolean;
  vatDeducatible: boolean;
  unblockingOfPayment: boolean;
  coverLetter: boolean;
  returnUsedPart: boolean;
  contactPerson: string;
  email: string;
  phone: string;
  mileage: number;
  fuelTank: number;
  serviceOrderGroupId?: string;
  serviceCenterId?: string;
}>;

export function OrderInsuranceForm(props: OrderInsuranceFormProps) {
  const {
    data: serviceOrderInsurance,
    isFetching: isServiceOrderInsuranceFetching,
    isError: isServiceOrderInsuranceError,
  } = useGetServiceOrderInsuranceQuery(props);
  const {
    data: serviceOrder,
    isFetching: isServiceOrderFetching,
    isError: isServiceOrderError,
  } = useGetServiceOrderQuery(props);
  const {data: insuranceCompanies, isLoading: isInsuranceCompaniesLoading} = useGetCustomersV2Query(
    {
      institutions: ['INSURANCE'],
    }
  );
  const {data: insuranceTypesOptions, isLoading: isInsuranceTypesLoading} =
    useGetInsuranceTypesQuery();
  const {
    isFieldRequired,
    isLoading: isServiceOrderVariantMandatoryFieldsLoading,
    isError: isServiceOrderVariantMandatoryFieldsError,
  } = useOrderMandatoryFields(props.serviceOrderId);
  const {
    contactPersons,
    isLoading: isContactPersonsLoading,
    isError: isContactPersonsError,
  } = useContactPersons(props.serviceCaseId);

  const {
    data: orderVariantAccounting,
    isFetching: isOrderVariantAccountingFetching,
    isError: isOrderVariantAccountingError,
  } = useGetServiceOrderVariantAccountingQuery(
    {serviceOrderVariantId: serviceOrder?.serviceOrderVariantId ?? ''},
    {skip: isNilOrEmpty(serviceOrder)}
  );

  const {
    data: serviceCentres,
    isLoading: isServiceCentresLoading,
    isError: isServiceCentresError,
  } = useAccM2StrediskoGetCentresQuery();

  const {
    data: orderGroups,
    isLoading: isOrderGroupsLoading,
    isError: isOrderGroupsError,
  } = useGetServiceOrderGroupQuery();

  const [patchServiceOrder] = usePatchServiceOrderMutation();
  const [patchServiceOrderInsurance] = usePatchServiceOrderInsuranceMutation();

  const handleSaveData = async (
    serviceOrderData: PatchServiceOrderApiArg['body'],
    orderInsuranceData: PatchServiceOrderInsuranceApiArg['body']
  ) => {
    if (isNil(serviceOrderData) || isNil(orderInsuranceData)) {
      return;
    }

    const patchServiceOrderMutation = hasObjectNonEmptyValue(serviceOrderData)
      ? patchServiceOrder({
          serviceCaseId: props.serviceCaseId,
          serviceOrderId: props.serviceOrderId,
          body: serviceOrderData,
        })
          .unwrap()
          .catch(handleApiError)
      : null;
    const patchServiceOrderInsuranceMutation = hasObjectNonEmptyValue(orderInsuranceData)
      ? patchServiceOrderInsurance({
          serviceCaseId: props.serviceCaseId,
          serviceOrderId: props.serviceOrderId,
          body: orderInsuranceData,
        })
          .unwrap()
          .catch(handleApiError)
      : null;
    await Promise.all([patchServiceOrderMutation, patchServiceOrderInsuranceMutation]).then(
      ([orderResponse]) => {
        if (isFalse(orderResponse?.isMileageValid)) {
          openDialog(
            <MileageValidationDialog
              highestMileage={orderResponse?.highestMileage}
              data-testid={suffixTestId('mileageValidation', props)}
            />,
            {size: 'small'}
          );
        }
        showNotification.success();
      }
    );
  };

  const onSubmit: FormSubmitHandler<FormType> = async (data) => {
    const phoneNumber = contactPersons
      ?.find((c) => c.value === data.contactPerson)
      ?.phoneNumbers?.find((phone) => phone.value === data.phone)?.phone;

    const serviceOrderData: PatchServiceOrderApiArg['body'] = {
      completionAt: getCompletionAt(data.completionAtDate, data.completionAtTime),
      recipientId: serviceOrder?.recipientId,
      technicanId: serviceOrder?.technicanId,
      totalPriceEstimated: data.totalPriceEstimated,
      note: data.note,
      serviceCenterId: data.serviceCenterId,
      serviceOrderGroupId: data.serviceOrderGroupId,
      contactInformation: {
        id: data.contactPerson,
        email: data.email,
        phoneNumber,
      },
      mileage: data.mileage,
      fuelTank: data.fuelTank,
    };

    const orderInsuranceData: PatchServiceOrderInsuranceApiArg['body'] = {
      coverLetter: data.coverLetter,
      initialInspectionPerformed: data.initialInspectionPerformed,
      insuranceClaimNumber: data.insuranceClaimNumber,
      insuranceCompany: data.insuranceCompany,
      insuranceEventNumber: data.insuranceEventNumber,
      insuranceType: data.insuranceType,
      returnUsedPart: data.returnUsedPart,
      unblockingOfPayment: data.unblockingOfPayment,
      vatDeducatible: data.vatDeducatible,
    };

    await match([serviceOrderInsurance?.insuranceCompany, orderInsuranceData.insuranceCompany])
      .when(
        ([oldValue, newValue]) => isNotNilOrEmpty(oldValue) && isNilOrEmpty(newValue),
        () =>
          openDialog(
            <DeactivateContractDialog
              onClose={closeCurrentDialog}
              onDeactivate={(isDiscountRemove) =>
                handleSaveData(serviceOrderData, {...orderInsuranceData, isDiscountRemove})
              }
              data-testid={suffixTestId('deactivateDialog', props)}
            />,
            {title: i18n.t('entity.order.labels.deactivateContract'), size: 'small'}
          )
      )
      .when(
        ([oldValue, newValue]) =>
          isNotNilOrEmpty(oldValue) && isNotNilOrEmpty(newValue) && oldValue !== newValue,
        () =>
          openDialog(
            <DeactivateContractDialog
              onClose={closeCurrentDialog}
              onDeactivate={(isDiscountApply) =>
                handleSaveData(serviceOrderData, {...orderInsuranceData, isDiscountApply})
              }
              data-testid={suffixTestId('deactivateDialog', props)}
            />,
            {title: i18n.t('entity.order.labels.deactivateContract'), size: 'small'}
          )
      )
      .otherwise(() => handleSaveData(serviceOrderData, orderInsuranceData));
  };

  // TODO: T20-26316
  const insuranceCompaniesOptions = insuranceCompanies?.map((insurance) => ({
    value: insurance.id,
    label: insurance.businessInfo?.businessInfo?.tradeName,
  }));

  const filteredServiceCentresOptions = serviceCentres?.m2centres
    ?.filter(
      (center) => !orderVariantAccounting?.excludedServiceCenterIds?.includes(center?.code ?? '')
    )
    .map((center) => ({
      label: center?.name,
      value: center?.code,
    }));

  const filteredOrderGroupsOptions = orderGroups
    ?.filter(
      (group) =>
        !orderVariantAccounting?.excludedServiceOrderGroupIds?.includes(group?.serviceOrderGroupId)
    )
    .map((group) => ({
      label: group?.name,
      value: group?.serviceOrderGroupId,
    }));

  const defaultValues: Partial<FormType> = {
    completionAtDate: serviceOrder?.completionAt
      ? parseDate(serviceOrder?.completionAt)
      : undefined,
    completionAtTime: getDefaultCompletionAtTime(serviceOrder?.completionAt),
    note: serviceOrder?.note,
    totalPriceEstimated: serviceOrder?.totalPriceEstimated?.amount,
    coverLetter: serviceOrderInsurance?.coverLetter,
    initialInspectionPerformed: serviceOrderInsurance?.initialInspectionPerformed,
    insuranceClaimNumber: serviceOrderInsurance?.insuranceClaimNumber,
    insuranceCompany: serviceOrderInsurance?.insuranceCompany,
    insuranceEventNumber: serviceOrderInsurance?.insuranceEventNumber,
    insuranceType: serviceOrderInsurance?.insuranceType,
    returnUsedPart: serviceOrderInsurance?.returnUsedPart,
    unblockingOfPayment: serviceOrderInsurance?.unblockingOfPayment,
    vatDeducatible: serviceOrderInsurance?.vatDeducatible,
    contactPerson: serviceOrder?.contactInformation?.id,
    email: serviceOrder?.contactInformation?.email,
    phone: serviceOrder?.contactInformation?.phoneNumber?.number,
    mileage: serviceOrder?.mileage,
    fuelTank: serviceOrder?.fuelTank,
    serviceOrderGroupId:
      serviceOrder?.serviceOrderGroupId || orderVariantAccounting?.defaultServiceOrderGroupId,
    serviceCenterId:
      serviceOrder?.serviceCenterId || orderVariantAccounting?.defaultServiceCenterId,
  };

  const isLoading =
    isOrderGroupsLoading ||
    isServiceCentresLoading ||
    isOrderVariantAccountingFetching ||
    isServiceOrderFetching ||
    isServiceOrderInsuranceFetching ||
    isServiceOrderVariantMandatoryFieldsLoading ||
    isContactPersonsLoading;

  const isError =
    isOrderGroupsError ||
    isServiceCentresError ||
    isOrderVariantAccountingError ||
    isServiceOrderError ||
    isServiceOrderInsuranceError ||
    isServiceOrderVariantMandatoryFieldsError ||
    isContactPersonsError;

  return (
    <DataStatus isLoading={isLoading} isError={isError} minHeight={144}>
      <Form<FormType>
        onSubmit={onSubmit}
        schema={formSchema(isFieldRequired)}
        defaultValues={defaultValues}
        shouldWatchForUnsavedChanges
      >
        {(control, formApi) => {
          const contactEmailOptions =
            contactPersons?.find((c) => c.value === formApi.watch('contactPerson'))?.emails || [];
          const contactPhoneOptions =
            contactPersons?.find((c) => c.value === formApi.watch('contactPerson'))?.phoneNumbers ||
            [];

          return (
            <>
              <VStack spacing={4}>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="completionAtDate"
                      type="date"
                      relativeDates={relativeDates}
                      label={i18n.t('entity.order.labels.completionDate')}
                      isDisabled={props.isReadOnly}
                      isRequired={isFieldRequired('completionAtDate')}
                      data-testid={suffixTestId('completionAtDate', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="completionAtTime"
                      type="time"
                      label={i18n.t('entity.order.labels.completionTime')}
                      isDisabled={props.isReadOnly}
                      isRequired={isFieldRequired('completionAtTime')}
                      data-testid={suffixTestId('completionAtTime', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="totalPriceEstimated"
                      type="number"
                      label={i18n.t('entity.order.labels.estimatedPrice')}
                      isDisabled={props.isReadOnly}
                      isRequired={isFieldRequired('totalPriceEstimated')}
                      data-testid={suffixTestId('totalPriceEstimated', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="serviceCenterId"
                      type="choice"
                      options={filteredServiceCentresOptions}
                      isLoading={isLoading}
                      label={i18n.t('entity.order.labels.serviceCenter')}
                      isDisabled={
                        props.isReadOnly || orderVariantAccounting?.isDefaultServiceCenterLocked
                      }
                      isRequired={isFieldRequired('serviceCenterId')}
                      isNotClearable
                      data-testid={suffixTestId('serviceCenter', props)}
                    />
                  </Box>
                </HStack>
                <Grid columns={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="serviceOrderGroupId"
                      type="choice"
                      options={filteredOrderGroupsOptions}
                      isLoading={isLoading}
                      label={i18n.t('entity.order.labels.serviceOrderGroup')}
                      isDisabled={
                        props.isReadOnly || orderVariantAccounting?.isDefaultServiceOrderGroupLocked
                      }
                      isRequired={isFieldRequired('serviceOrderGroupId')}
                      isNotClearable
                      data-testid={suffixTestId('serviceOrderGroup', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      label={i18n.t('entity.vehicle.labels.actualMileage')}
                      name="mileage"
                      type="number"
                      control={control}
                      minStepperValue={0}
                      isDisabled={props.isReadOnly}
                      isRequired={isFieldRequired('mileage')}
                      data-testid={suffixTestId('mileage', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      label={i18n.t('entity.workshop.labels.fuelTank')}
                      name="fuelTank"
                      type="slider"
                      control={control}
                      max={1}
                      min={0}
                      step={0.25}
                      ticks={sliderTicks}
                      isDisabled={props.isReadOnly}
                      isRequired={isFieldRequired('fuelTank')}
                      data-testid={suffixTestId('fuelTank', props)}
                    />
                  </Box>
                </Grid>
              </VStack>
              <Separator />
              <VStack spacing={4}>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="insuranceCompany"
                      type="choice"
                      label={i18n.t('entity.order.labels.insuranceCompany')}
                      isDisabled={props.isReadOnly}
                      options={insuranceCompaniesOptions}
                      isLoading={isInsuranceCompaniesLoading}
                      isRequired={isFieldRequired('insuranceCompany')}
                      data-testid={suffixTestId('insuranceCompany', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="insuranceType"
                      type="choice"
                      label={i18n.t('entity.order.labels.insuranceType')}
                      isDisabled={props.isReadOnly}
                      options={insuranceTypesOptions}
                      isLoading={isInsuranceTypesLoading}
                      isRequired={isFieldRequired('insuranceType')}
                      data-testid={suffixTestId('insuranceType', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="insuranceEventNumber"
                      type="text"
                      label={i18n.t('entity.order.labels.insuranceEventNumber')}
                      isDisabled={props.isReadOnly}
                      isRequired={isFieldRequired('insuranceEventNumber')}
                      data-testid={suffixTestId('insuranceEventNumber', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="insuranceClaimNumber"
                      type="text"
                      label={i18n.t('entity.order.labels.insuranceClaimNumber')}
                      isDisabled={props.isReadOnly}
                      isRequired={isFieldRequired('insuranceClaimNumber')}
                      data-testid={suffixTestId('insuranceClaimNumber', props)}
                    />
                  </Box>
                </HStack>
                <HStack spacing={4}>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="initialInspectionPerformed"
                      type="checkbox"
                      label={i18n.t('entity.order.labels.initialInspectionPerformed')}
                      isDisabled={props.isReadOnly}
                      data-testid={suffixTestId('initialInspectionPerformed', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="vatDeducatible"
                      type="checkbox"
                      label={i18n.t('entity.order.labels.vatDeducatible')}
                      isDisabled={props.isReadOnly}
                      data-testid={suffixTestId('vatDeducatible', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="unblockingOfPayment"
                      type="checkbox"
                      label={i18n.t('entity.order.labels.unblockingOfPayment')}
                      isDisabled={props.isReadOnly}
                      data-testid={suffixTestId('unblockingOfPayment', props)}
                    />
                  </Box>
                  <Box flex={1}>
                    <FormField
                      control={control}
                      name="coverLetter"
                      type="checkbox"
                      label={i18n.t('entity.order.labels.coverLetter')}
                      isDisabled={props.isReadOnly}
                      data-testid={suffixTestId('coverLetter', props)}
                    />
                  </Box>
                </HStack>
                <HStack spacing={4}>
                  <Box>
                    <FormField
                      control={control}
                      name="returnUsedPart"
                      type="checkbox"
                      label={i18n.t('entity.order.labels.returnUsedParts')}
                      isDisabled={props.isReadOnly}
                      data-testid={suffixTestId('returnUsedPart', props)}
                    />
                  </Box>
                </HStack>
              </VStack>
              <Separator />
              <VStack spacing={1}>
                <HStack justify="space-between" align="flex-end">
                  <Label>{i18n.t('general.labels.note')}</Label>
                  <PredefinedNotes
                    note={formApi.watch('note') ?? null}
                    onPrefill={(note) => formApi.setValue('note', note)}
                    resource="SERVICE_CASE"
                    context="service_order"
                    isLinkVariant
                    data-testid={suffixTestId('predefinedNotes', props)}
                  />
                </HStack>
                <FormField
                  control={control}
                  name="note"
                  type="textarea"
                  isDisabled={props.isReadOnly}
                  data-testid={suffixTestId('note', props)}
                />
              </VStack>
              <Separator />
              <Heading size={4}>{i18n.t('entity.person.labels.contactInformation')}</Heading>
              <Space vertical={4} />
              <HStack spacing={4}>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="contactPerson"
                    type="choice"
                    isNotClearable
                    label={i18n.t('entity.businessCase.labels.contactPerson')}
                    options={contactPersons}
                    isLoading={isContactPersonsLoading}
                    onChange={(value) => {
                      const contactPerson = contactPersons?.find((c) => c.value === value);
                      formApi.setValue('email', contactPerson?.emails?.[0]?.value);
                      formApi.setValue('phone', contactPerson?.phoneNumbers?.[0]?.value);
                    }}
                    isDisabled={props.isReadOnly}
                    data-testid={suffixTestId('contactPerson', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="email"
                    type="choice"
                    isNotClearable
                    label={i18n.t('general.labels.emailAddress')}
                    options={contactEmailOptions}
                    isLoading={isContactPersonsLoading}
                    isDisabled={props.isReadOnly}
                    data-testid={suffixTestId('email', props)}
                  />
                </Box>
                <Box flex={1}>
                  <FormField
                    control={control}
                    name="phone"
                    type="choice"
                    isNotClearable
                    label={i18n.t('entity.person.labels.phoneNumber')}
                    options={contactPhoneOptions}
                    isLoading={isContactPersonsLoading}
                    isDisabled={props.isReadOnly}
                    data-testid={suffixTestId('phoneNumber', props)}
                  />
                </Box>
                <Box flexGrow={2} />
              </HStack>
              <ButtonGroup align="right">
                <FormButton
                  control={control}
                  type="submit"
                  title={i18n.t('general.actions.saveChanges')}
                  isDisabled={props.isReadOnly}
                  data-testid={suffixTestId('save', props)}
                />
              </ButtonGroup>
            </>
          );
        }}
      </Form>
    </DataStatus>
  );
}

const formSchema = (isFieldRequired: (name: string) => boolean) =>
  object({
    completionAtDate: yupDate[isFieldRequired('completionAtDate') ? 'required' : 'nullable'](),
    completionAtTime: yupString[isFieldRequired('completionAtTime') ? 'required' : 'nullable'](),
    totalPriceEstimated:
      yupNumber[isFieldRequired('totalPriceEstimated') ? 'required' : 'nullable'](),
    note: yupString.nullable(),
    insuranceCompany: yupString[isFieldRequired('insuranceCompany') ? 'required' : 'nullable'](),
    insuranceType: yupString[isFieldRequired('insuranceType') ? 'required' : 'nullable'](),
    insuranceEventNumber:
      yupString[isFieldRequired('insuranceEventNumber') ? 'required' : 'nullable'](),
    insuranceClaimNumber:
      yupString[isFieldRequired('insuranceClaimNumber') ? 'required' : 'nullable'](),
    mileage: yupNumber[isFieldRequired('mileage') ? 'required' : 'nullable'](),
    fuelTank: yupNumber[isFieldRequired('fuelTank') ? 'required' : 'nullable'](),
    initialInspectionPerformed: boolean().nullable(),
    vatDeducatible: boolean().nullable(),
    unblockingOfPayment: boolean().nullable(),
    coverLetter: boolean().nullable(),
    returnUsedPart: boolean().nullable(),
    serviceOrderGroupId:
      yupString[isFieldRequired('serviceOrderGroupId') ? 'required' : 'nullable'](),
    serviceCenterId: yupString[isFieldRequired('serviceCenterId') ? 'required' : 'nullable'](),
  });
