import {FormControl} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';

import {Path} from 'react-hook-form';

import i18n from '@omnetic-dms/i18n';

import {Nullish, RequiredTestIdProps, suffixTestId} from 'shared';

import {ReceiveNoteCorrectionItemForm} from '../types/ReceiveNoteCorrectionItemForm';
import {ReceiveNoteCorrectionItemAfterRow} from './ReceiveNoteCorrectionItemAfterRow';
import {ReceiveNoteCorrectionItemBeforeRow} from './ReceiveNoteCorrectionItemBeforeRow';
import {ReceiveNoteCorrectionItemDifferenceRow} from './ReceiveNoteCorrectionItemDifferenceRow';

interface DeliveryNoteItemFormProps extends RequiredTestIdProps {
  control: FormControl<ReceiveNoteCorrectionItemForm>;
  deliveryUnit: string;
  currency: string | Nullish;
  onQuantityChange: (
    value: number | null,
    changedField: Path<ReceiveNoteCorrectionItemForm>
  ) => boolean;
}

export function DeliveryNoteItemForm(props: DeliveryNoteItemFormProps) {
  return (
    <VStack spacing={4} data-testid={props['data-testid']}>
      <Heading size={5}>{i18n.t('entity.warehouse.labels.deliveryNoteItem')}</Heading>
      <VStack spacing={4}>
        <ReceiveNoteCorrectionItemDifferenceRow
          control={props.control}
          formContext="deliveryItem"
          unit={props.deliveryUnit}
          currency={props.currency}
          data-testid={suffixTestId('rows.difference', props)}
        />
        <ReceiveNoteCorrectionItemAfterRow
          control={props.control}
          formContext="deliveryItem"
          unit={props.deliveryUnit}
          currency={props.currency}
          onQuantityChange={props.onQuantityChange}
          data-testid={suffixTestId('rows.afterCorrection', props)}
        />
        <ReceiveNoteCorrectionItemBeforeRow
          control={props.control}
          formContext="deliveryItem"
          unit={props.deliveryUnit}
          currency={props.currency}
          data-testid={suffixTestId('rows.beforeCorrection', props)}
        />
      </VStack>
    </VStack>
  );
}
