import i18n from 'i18next';
import {DataStatus, Dialog, DialogProps, Separator} from 'platform/components';
import {Box, Show} from 'platform/foundation';
import styled from 'styled-components';

import {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {split} from 'ramda';

import {TemplateContextEnum} from '@omnetic-dms/api';
import {testIds} from '@omnetic-dms/routes';

import {getRandomId, Nullish} from 'shared';

import {useRenderTemplateExample} from '../hooks/useRenderTemplateExample';
import {MessageTemplateFormValues} from '../types/MessageTemplateFormValues';

type Props = Omit<DialogProps, 'children'> & {
  templateType: 'sms' | 'email';
  formApi: UseFormReturn<MessageTemplateFormValues>;
};

const LineWrapper = styled.div`
  white-space: pre;
  min-height: 1em;
`;

export const MessagePreviewDialog = (props: Props) => {
  const context: TemplateContextEnum | undefined = props.formApi.watch('context');

  const {renderSms, renderEmail, smsData, emailData, isLoading, isError} =
    useRenderTemplateExample(context);

  const renderMultiLine = (value: string | Nullish) => {
    if (!value) {
      return '';
    }
    return split('\n', value).map((line) => (
      <LineWrapper key={`${getRandomId()}_${value}`}>{line}</LineWrapper>
    ));
  };

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }
    const {body, subject} = props.formApi.getValues();
    if (props.templateType === 'sms') {
      renderSms?.({body: {template: body}});
    } else {
      renderEmail?.({
        body: {
          body,
          subject,
        },
      });
    }
  }, [props.isOpen, props.formApi, props.templateType, renderEmail, renderSms]);

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={i18n.t('page.messageTemplates.labels.previewDialog')}
      data-testid={testIds.settings.messageTemplates('previewDialog')}
    >
      <DataStatus isLoading={isLoading} isError={isError}>
        <Show when={props.templateType === 'sms'}>
          <Box data-testid={testIds.settings.messageTemplates('previewDialog-smsBody')}>
            {renderMultiLine(smsData?.body)}
          </Box>
        </Show>
        <Show when={props.templateType === 'email'}>
          <Box data-testid={testIds.settings.messageTemplates('previewDialog-emailSubject')}>
            {renderMultiLine(emailData?.subject)}
          </Box>
          <Separator spacing={3} />
          <Box data-testid={testIds.settings.messageTemplates('previewDialog-emailBody')}>
            {renderMultiLine(emailData?.body)}
          </Box>
        </Show>
      </DataStatus>
    </Dialog>
  );
};
