import {match} from 'ts-pattern';

import {always} from 'ramda';

import {RedirectModule} from '../types/RedirectModule';

export const getModuleFromQueryString = (query: string): RedirectModule | null =>
  match<string | null, RedirectModule | null>(new URLSearchParams(query).get('module'))
    .with('businessCase', always('businessCase'))
    .with('customer', always('customer'))
    .with('interest', always('interest'))
    .with('vehicle', always('vehicle'))
    .with('serviceCase', always('serviceCase'))
    .with('notification', always('notification'))
    .otherwise(always(null));
