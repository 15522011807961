import {captureMessage} from '@sentry/browser';
import {DeleteDialog, showNotification, useDialog} from 'platform/components';
import {InternalLightboxControls, Lightbox, LightboxVideo} from 'platform/lightbox';

import {isNil} from 'ramda';

import {AuditDataVideoAssetsFilesBody} from '@omnetic-dms/api';
import {i18n} from '@omnetic-dms/i18n';
import {downloadFile} from '@omnetic-dms/shared';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {isVideoPlayable} from '../utils/isVideoPlayable';

interface AuditVideoLightboxProps extends TestIdProps {
  videoAssets: AuditDataVideoAssetsFilesBody[];
  controls: InternalLightboxControls;
  onDeleteVideo: (video: LightboxVideo) => void;
}

export function AuditVideoLightbox(props: AuditVideoLightboxProps) {
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog, {storage: videoToDelete}] =
    useDialog<LightboxVideo>();

  const lightboxVideoAssets: LightboxVideo[] | Nullish = props.videoAssets
    .filter(isVideoPlayable)
    .map((asset) => ({
      ...asset,
      actions: [
        {
          id: 'deleteBtn',
          icon: 'action/delete',
          onClick: () => openDeleteDialog(asset),
        },
        {
          id: 'downloadBtn',
          icon: 'file/download',
          onClick: () => {
            if (isNil(asset.originUrl)) {
              showNotification.error(i18n.t('entity.inspection.errors.downloadFailed'));
              captureMessage('Error downloading video asset – originUrl is not defined', (scope) =>
                scope.setExtras({asset})
              );
            } else {
              downloadFile(asset.originUrl);
            }
          },
        },
      ],
    }));

  return (
    <>
      <Lightbox
        data-testid={suffixTestId('audit-uploadVideosGallery', props)}
        controls={props.controls}
        videos={lightboxVideoAssets}
      />
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        onConfirm={() => props.onDeleteVideo(videoToDelete!)}
        isInLightbox
      />
    </>
  );
}
