import {AdvertisingPlatformCodeEnum} from './AdvertisingPlatformCodeEnum';

export enum EPlatformUpdateTypes {
  switchOff, // type for platforms `ENABLE` || `PUBLISH`
  switchOn, // type for platforms `DISABLE` || `UNPUBLISH`
}

export type TUpdatePlatformStatusRequest = {
  vehicleId: string;
  platformCode?: `${AdvertisingPlatformCodeEnum}`;
  option: EPlatformUpdateTypes;
};
