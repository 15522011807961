import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import {environment} from '@omnetic-dms/environment';

import {
  ChangePasswordApiArg,
  ChangePasswordApiResponse,
  ExternalLoginApiArg,
  ExternalLoginApiResponse,
  GetAvailableLanguagesListApiArg,
  GetAvailableLanguagesListApiResponse,
  LoginApiArg,
  LoginApiResponse,
  PostLogoutApiArg,
  RefreshTokenApiArg,
  RefreshTokenApiResponse,
  RequestPasswordResetApiArg,
  RequestPasswordResetApiResponse,
  VerifyTokenApiArg,
  VerifyTokenApiResponse,
  WorkspaceInfoApiArg,
  WorkspaceInfoApiResponse,
  WorkspacesApiArg,
  WorkspacesApiResponse,
} from '../types/api';

export const publicApi = createApi({
  reducerPath: 'rtk_publicApi',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.API_URL,
  }),
  endpoints: (build) => ({
    externalLogin: build.mutation<ExternalLoginApiResponse, ExternalLoginApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/auth/login-external`,
        method: 'POST',
        body: queryArg.externalLoginRequestBody,
        headers: {'X-Workspace': queryArg.workspace},
      }),
    }),
    login: build.mutation<LoginApiResponse, LoginApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/auth/login`,
        method: 'POST',
        body: queryArg.loginRequestBody,
        headers: {'X-Workspace': queryArg.workspace},
      }),
    }),
    changePassword: build.mutation<ChangePasswordApiResponse, ChangePasswordApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/auth/change-password-by-token`,
        method: 'PUT',
        body: queryArg.changePasswordRequestBody,
        headers: {'X-Workspace': queryArg.workspace},
      }),
    }),
    requestPasswordReset: build.mutation<
      RequestPasswordResetApiResponse,
      RequestPasswordResetApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/auth/request-password-reset`,
        method: 'PUT',
        body: queryArg.requestPasswordResetRequestBody,
        headers: {'X-Workspace': queryArg.workspace},
      }),
    }),
    verifyToken: build.mutation<VerifyTokenApiResponse, VerifyTokenApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/auth/verify-password-reset-token`,
        method: 'PUT',
        body: queryArg.verifyTokenRequestBody,
        headers: {'X-Workspace': queryArg.workspace},
      }),
    }),
    refreshToken: build.mutation<RefreshTokenApiResponse, RefreshTokenApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/auth/token/refresh`,
        method: 'POST',
        body: queryArg.refreshTokenRequestBody,
        headers: {'X-Workspace': queryArg.workspace},
      }),
    }),
    workspaceInfo: build.query<WorkspaceInfoApiResponse, WorkspaceInfoApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/workspace/info`,
        method: 'GET',
        headers: {'X-Workspace': queryArg.workspace},
      }),
    }),
    workspaces: build.query<WorkspacesApiResponse, WorkspacesApiArg>({
      query: (queryArg) => ({
        url: `/dms/v1/workspaces`,
        headers: {'X-Workspace': queryArg.workspace},
        params: {email: queryArg.email},
      }),
    }),
    getAvailableLanguagesList: build.query<
      GetAvailableLanguagesListApiResponse,
      GetAvailableLanguagesListApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/available-languages`,
        headers: {'X-Workspace': queryArg.workspace},
      }),
    }),
    logout: build.mutation<void, PostLogoutApiArg>({
      query: ({accessToken}) => ({
        url: `/dms/v1/auth/logout`,
        method: 'POST',
        headers: {Authorization: `Bearer ${accessToken}`},
      }),
    }),
  }),
});

export const {
  useLogoutMutation,
  useWorkspacesQuery,
  useChangePasswordMutation,
  useExternalLoginMutation,
  useGetAvailableLanguagesListQuery,
  useLoginMutation,
  useWorkspaceInfoQuery,
  useLazyWorkspaceInfoQuery,
  useVerifyTokenMutation,
  useRequestPasswordResetMutation,
  useRefreshTokenMutation,
} = publicApi;
