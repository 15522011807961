import {
  Button,
  ButtonGroup,
  Card,
  DataStatus,
  Form,
  FormButton,
  FormField,
  FormSubmitHandler,
  showNotification,
} from 'platform/components';
import {Space} from 'platform/foundation';
import * as Yup from 'yup';

import {useNavigate} from 'react-router-dom';

import {path} from 'ramda';

import {
  GetWindshieldApiRes,
  useGetWindshieldUrlQuery,
  usePutWindshieldUrlMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {settingsRoutes} from '@omnetic-dms/routes';
import {handleApiError} from '@omnetic-dms/shared';

import {useBranchId} from '../../../hooks/useBranchId';

// eslint-disable-next-line no-template-curly-in-string
const SUPPORTED_VARIABLES = ['${PublicID}'];
const DEFAULT_WINDSHIELD_FORM_VALUES: GetWindshieldApiRes = {
  url: `${window.location.origin}/${SUPPORTED_VARIABLES[0]}`,
  isEnabled: false,
};

export function QrCodeDefinitionForm() {
  const branchId = useBranchId();
  const navigate = useNavigate();

  const {data, isFetching, error} = useGetWindshieldUrlQuery({branchId});

  const windshieldUrl = path(['status'], error) === 404 ? DEFAULT_WINDSHIELD_FORM_VALUES : data;

  const [updateWindshieldUrl] = usePutWindshieldUrlMutation();

  const handleSubmit: FormSubmitHandler<GetWindshieldApiRes> = async (windshieldData) =>
    await updateWindshieldUrl({body: windshieldData, branchId})
      .unwrap()
      .then(() => showNotification.success())
      .catch(handleApiError);

  const handleDiscard = () => navigate(settingsRoutes.advertising);

  return (
    <DataStatus isLoading={isFetching}>
      <Form<GetWindshieldApiRes>
        onSubmit={handleSubmit}
        schema={QrDefinitionFormSchema}
        defaultValues={DEFAULT_WINDSHIELD_FORM_VALUES}
        values={windshieldUrl}
      >
        {(control) => (
          <Card title={i18n.t('page.salesSettings.labels.qrCodeDefinitionTitle')}>
            <FormField
              type="text"
              name="url"
              control={control}
              helperText={`${i18n.t(
                'page.salesSettings.labels.qrCodeDefinitionHelper'
              )}: ${SUPPORTED_VARIABLES.join(', ')}`}
            />
            <Space vertical={4} />
            <FormField
              type="checkbox"
              name="isEnabled"
              label={i18n.t('page.salesSettings.labels.qrCodeCheckbox')}
              control={control}
            />
            <Space vertical={4} />
            <ButtonGroup align="right">
              <Button
                variant="secondary"
                onClick={handleDiscard}
                title={i18n.t('general.actions.discard')}
              />
              <FormButton type="submit" title={i18n.t('general.actions.save')} control={control} />
            </ButtonGroup>
          </Card>
        )}
      </Form>
    </DataStatus>
  );
}

const QrDefinitionFormSchema = Yup.object({
  url: Yup.string().nullable(),
  isEnabled: Yup.boolean().nullable().default(false),
});
