import {openDropdown} from 'platform/components';

import {MouseEvent} from 'react';

import {PredefinedNotesDropdownBody} from '../PredefinedNotesDropdownBody';
import {PredefinedNotesProps} from '../types/PredefinedNotesProps';

export function openPredefinedNotesDropdown(event: MouseEvent, props: PredefinedNotesProps) {
  openDropdown(
    event,
    <PredefinedNotesDropdownBody
      note={props.note}
      resource={props.resource}
      context={props.context}
      onPrefill={props.onPrefill}
      isLinkVariant={props.isLinkVariant}
      data-testid={props['data-testid']}
    />
  );
}
