import {Tooltip} from 'platform/components';
import {useFormatNumber} from 'platform/locale';

import {forwardRef, Ref, useImperativeHandle, useState} from 'react';

import {isNotNil} from 'ramda-adjunct';

import {useTooltipProps} from '../../hooks/useTooltipProps';
import {ICellRenderer, ICellRendererParams} from '../../types/AgGridTypes';
import {TooltipGetter} from '../../types/TooltipGetter';
import {getCellClassName} from '../../utils/getCellClassName';
import {getValueToRender} from '../../utils/getValueToRender';

export interface IntegerCellRendererProps extends ICellRendererParams {
  getTooltipProps?: TooltipGetter;
  decimalPlaces?: number;
}

function IntegerCellRendererComponent(props: IntegerCellRendererProps, ref: Ref<ICellRenderer>) {
  const {getTooltipProps, data, node, colDef, decimalPlaces} = props;
  const className = getCellClassName(colDef);
  const [cellValue, setCellValue] = useState<number>(() => getValueToRender(props));
  const formatNumber = useFormatNumber();

  useImperativeHandle(ref, () => ({
    refresh: (refreshProps) => {
      const newCellValue = getValueToRender(refreshProps);
      if (newCellValue !== cellValue) {
        setCellValue(newCellValue);
      }
      return true;
    },
  }));

  const tooltipProps = useTooltipProps(data, node, getTooltipProps);

  const value = isNotNil(cellValue) ? formatNumber(Number(cellValue), decimalPlaces ?? 0) : '';

  return (
    <Tooltip {...tooltipProps} label={value}>
      {/* eslint-disable-next-line react/forbid-dom-props */}
      <div className={className}>{value}</div>
    </Tooltip>
  );
}

export const IntegerCellRenderer = forwardRef<ICellRenderer, IntegerCellRendererProps>(
  IntegerCellRendererComponent
);
