import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  DataStatus,
  Dropdown,
  DropdownItem,
  Separator,
  Form,
  FormButton,
  FormSubmitHandler,
  EmptyStatus,
} from 'platform/components';
import {Box, Heading, Hide, HStack, Icon, Show, VStack} from 'platform/foundation';

import {isNilOrEmpty} from 'ramda-adjunct';

import {
  useLazyGetServiceCaseIntegrationsXentryOrderSynchronizationApiQuery,
  usePostServiceCaseIntegrationsXentryOrderSynchronizationApiMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';
import {handleApiError} from '@omnetic-dms/shared';

import {suffixTestId, RequiredTestIdProps, useOnMount} from 'shared';

import {DataCard} from './components/DataCard';
import {SOURCES} from './constants/Sources';
import {FormSchema} from './types/FormSchema';

interface SyncXentryProps extends RequiredTestIdProps {
  serviceCaseId: string;
}

export function SyncXentry(props: SyncXentryProps) {
  const [getServiceCaseIntegrationsXentryOrderSynchronization, {data, isFetching, isError}] =
    useLazyGetServiceCaseIntegrationsXentryOrderSynchronizationApiQuery();

  // Needs to be called on mount as lazy due duplicated calls after submit
  useOnMount(() => {
    getServiceCaseIntegrationsXentryOrderSynchronization({serviceCaseId: props.serviceCaseId});
  });

  const [postServiceCaseIntegrationsXentryOrderSynchronization, {isLoading}] =
    usePostServiceCaseIntegrationsXentryOrderSynchronizationApiMutation();

  const handleSubmit: FormSubmitHandler<FormSchema> = (data) =>
    postServiceCaseIntegrationsXentryOrderSynchronization({
      body: {groups: data.groups},
      serviceCaseId: props.serviceCaseId,
    })
      .unwrap()
      .then(closeCurrentDialog)
      .catch(handleApiError);

  let hasSelectedSource = false;

  const mappedGroups =
    data?.groups?.map((group) => {
      if (group?.selectedSource && group?.selectedSource !== 'USER_DECISION') {
        hasSelectedSource = true;
      }
      return {
        type: group?.type ?? '',
        selectedSource:
          group?.selectedSource && group?.selectedSource !== 'USER_DECISION'
            ? group?.selectedSource
            : SOURCES.OMNETIC,
      };
    }) || [];

  const defaultFormValues: FormSchema = {
    groups: mappedGroups,
  };

  return (
    <DataStatus isLoading={isFetching} isError={isError}>
      <Form<FormSchema> defaultValues={defaultFormValues} onSubmit={handleSubmit}>
        {(control, formApi) => {
          const selectAll = (source: string) =>
            formApi.setValue(
              'groups',
              mappedGroups.map((group) => ({...group, selectedSource: source}))
            );
          return (
            <VStack spacing={1}>
              <Box>
                <HStack spacing={2}>
                  <Dropdown
                    isDisabled={hasSelectedSource}
                    dropdownControl={
                      <Button
                        isDisabled={hasSelectedSource}
                        variant="link"
                        leftIcon="action/done_all"
                        title={i18n.t('general.actions.selectAll')}
                      />
                    }
                  >
                    <DropdownItem
                      label={i18n.t('entity.integration.labels.omnetic')}
                      onClick={() => selectAll(SOURCES.OMNETIC)}
                    />
                    <DropdownItem
                      label={i18n.t('entity.integration.labels.xentry')}
                      onClick={() => selectAll(SOURCES.XENTRY)}
                    />
                  </Dropdown>
                  <Button
                    variant="link"
                    leftIcon="navigation/refresh"
                    onClick={() =>
                      getServiceCaseIntegrationsXentryOrderSynchronization({
                        serviceCaseId: props.serviceCaseId,
                      })
                    }
                    title={i18n.t('general.actions.refresh')}
                  />
                </HStack>
                <Separator />
              </Box>
              <Show when={isNilOrEmpty(data?.groups)}>
                <EmptyStatus headline={i18n.t('entity.integration.notifications.noData')} />
              </Show>
              <Hide when={isNilOrEmpty(data?.groups)}>
                <VStack spacing={4}>
                  {data?.groups?.map((group, index) => (
                    <Show when={group?.isVisibleForUser} key={group?.type}>
                      <HStack align="center" spacing={4}>
                        <Box
                          width={10}
                          height={10}
                          borderRadius="medium"
                          backgroundColor="palettes.blue.20.100"
                          padding={2}
                        >
                          <Icon
                            value={`navigation/arrow_${group?.type === 'checkIn' ? 'upward' : 'downward'}`}
                            color="palettes.blue.80.100"
                          />
                        </Box>
                        <Heading size={4}>
                          {i18n.t(`entity.integration.labels.${group?.type}`)}
                        </Heading>
                      </HStack>
                      <HStack spacing={4}>
                        <DataCard
                          isOmnetic
                          hasSelectedSource={hasSelectedSource}
                          index={index}
                          formApi={formApi}
                          control={control}
                          serviceCaseId={props.serviceCaseId}
                          group={group!}
                          data-testid={suffixTestId('omnetic-card', props)}
                        />
                        <DataCard
                          index={index}
                          hasSelectedSource={hasSelectedSource}
                          formApi={formApi}
                          control={control}
                          serviceCaseId={props.serviceCaseId}
                          group={group!}
                          data-testid={suffixTestId('xentry-card', props)}
                        />
                      </HStack>
                    </Show>
                  ))}
                </VStack>
              </Hide>
              <Separator />
              <ButtonGroup align="right">
                <Button
                  title={i18n.t('general.actions.discard')}
                  onClick={closeCurrentDialog}
                  variant="secondary"
                  data-testid={suffixTestId('close', props)}
                />
                <FormButton
                  type="submit"
                  isLoading={isLoading}
                  control={control}
                  title={i18n.t('entity.integration.actions.syncNow')}
                  isDisabled={isNilOrEmpty(data?.groups)}
                  variant="primary"
                  data-testid={suffixTestId('link', props)}
                />
              </ButtonGroup>
            </VStack>
          );
        }}
      </Form>
    </DataStatus>
  );
}
