import {z} from 'zod';

import {AbortSignalSchema, BaseVoidResponseSchema} from './base';
import {GetArticleResponseSchema} from './metadaWarehouseArticle';

/**
 * Base
 */
const ReceiveNoteItemChangedFieldSchema = z.enum([
  'quantity',
  'purchasePrice',
  'quantityVerified',
  'unitPrice',
  'supplierQuantity',
  'warehouseQuantity',
  'totalPrice',
  'totalPriceDelivery',
]);
export type ReceiveNoteItemChangedField = z.infer<typeof ReceiveNoteItemChangedFieldSchema>;

/**
 * GET
 */
export const GetReceiveNoteItemRequestSchema = z.object({
  receiveNoteId: z.string(),
  itemId: z.string(),
});
export type GetReceiveNoteItemRequest = z.infer<typeof GetReceiveNoteItemRequestSchema>;

export const GetReceiveNoteItemResponseSchema = z.object({
  receiveNote: z.object({
    receiveNoteId: z.string(),
  }),
  deliveryNote: z.object({
    deliveryNoteId: z.string(),
    supplierId: z.string(),
  }),
  receiveNoteItem: z.object({
    receiveNoteItemId: z.string(),
    quantity: z.number(),
    purchasePrice: z.number(),
    purchaseValue: z.number(),
    vatType: z.string(),
    requestItemIds: z.array(z.string()),
  }),
  supplierArticle: z.object({
    supplierQuantity: z.number(),
    supplierUnitId: z.string(),
    warehouseQuantity: z.number(),
    supplierOrderingNumber: z.string().optional(),
    supplierBulkPackageQuantity: z.number().optional(),
    supplierId: z.string(),
  }),
  deliveryNoteItem: z.object({
    deliveryNoteItemId: z.string(),
    manufacturerNumber: z.string(),
    manufacturerId: z.string(),
    supplierNumber: z.string().optional(),
    name: z.string(),
    description: z.string().optional(),
    quantity: z.number(),
    quantityVerified: z.number(),
    purchasePrice: z.number(),
    purchaseValue: z.number(),
    vatType: z.string(),
  }),
  article: GetArticleResponseSchema,
});
export type GetReceiveNoteItemResponse = z.infer<typeof GetReceiveNoteItemResponseSchema>;

export const GetReceiveNoteItemNewRequestSchema = z.object({
  receiveNoteId: z.string(),
  articleId: z.string(),
});
export type GetReceiveNoteItemNewRequest = z.infer<typeof GetReceiveNoteItemNewRequestSchema>;

export const GetReceiveNoteItemNewResponseSchema = z.object({
  receiveNote: z.object({
    receiveNoteId: z.string(),
  }),
  deliveryNote: z.object({
    deliveryNoteId: z.string(),
    supplierId: z.string(),
  }),
  receiveNoteItem: z.object({
    quantity: z.number(),
    purchasePrice: z.number(),
    purchaseValue: z.number(),
    vatType: z.string(),
  }),
  supplierArticle: z.object({
    supplierQuantity: z.number(),
    supplierUnitId: z.string(),
    warehouseQuantity: z.number(),
    supplierOrderingNumber: z.string().optional(),
    supplierBulkPackageQuantity: z.number().optional(),
    supplierId: z.string(),
  }),
  deliveryNoteItem: z.object({
    manufacturerNumber: z.string(),
    manufacturerId: z.string(),
    supplierNumber: z.string().optional(),
    name: z.string(),
    description: z.string().optional(),
    quantity: z.number(),
    quantityVerified: z.number(),
    purchasePrice: z.number(),
    purchaseValue: z.number(),
    vatType: z.string(),
  }),
  article: GetArticleResponseSchema,
});
export type GetReceiveNoteItemNewResponse = z.infer<typeof GetReceiveNoteItemNewResponseSchema>;

export const GetReceiveNoteItemRefreshedRequestSchema = z.object({
  receiveNoteId: z.string(),
  articleId: z.string(),
});
export type GetReceiveNoteItemRefreshedRequest = z.infer<
  typeof GetReceiveNoteItemRefreshedRequestSchema
>;

export const GetReceiveNoteItemRefreshedResponseSchema = z.object({
  deliveryNoteItem: z.object({
    manufacturerNumber: z.string(),
    manufacturerId: z.string(),
    supplierNumber: z.string(),
    name: z.string(),
    description: z.string(),
  }),
  article: GetArticleResponseSchema,
});
export type GetReceiveNoteItemRefreshedResponse = z.infer<
  typeof GetReceiveNoteItemRefreshedResponseSchema
>;

export const GetReceiveNoteItemsCountRequestSchema = z.object({
  receiveNoteId: z.string(),
});
export type GetReceiveNoteItemsCountRequest = z.infer<typeof GetReceiveNoteItemsCountRequestSchema>;

export const GetReceiveNoteItemsCountResponseSchema = z.object({
  count: z.number(),
});
export type GetReceiveNoteItemsCountResponse = z.infer<
  typeof GetReceiveNoteItemsCountResponseSchema
>;

export const GetReceiveNoteItemsTotalPriceRequestSchema = z.object({
  receiveNoteId: z.string(),
});
export type GetReceiveNoteItemsTotalPriceRequest = z.infer<
  typeof GetReceiveNoteItemsTotalPriceRequestSchema
>;

export const GetReceiveNoteItemsTotalPriceResponseSchema = z.union([
  z.object({
    totalPriceWithoutVat: z.number(),
    totalPriceWithVat: z.number(),
  }),
  BaseVoidResponseSchema,
]);
export type GetReceiveNoteItemsTotalPriceResponse = z.infer<
  typeof GetReceiveNoteItemsTotalPriceResponseSchema
>;

/**
 * POST
 */
export const PostReceiveNoteItemManualRequestSchema = z.object({
  receiveNoteId: z.string(),
  articleId: z.string(),
  quantity: z.number(),
  purchasePrice: z.number(),
  stockValue: z.number(),
  vatType: z.string(),
  deliveryItem: z.object({
    quantityVerified: z.number(),
    unitPrice: z.number(),
    stockValue: z.number(),
    vatType: z.string(),
  }),
  supplierArticle: z.object({
    supplierQuantity: z.number(),
    supplierUnitId: z.string(),
    warehouseQuantity: z.number(),
    supplierOrderingNumber: z.string().optional(),
    supplierBulkPackageQuantity: z.number().optional(),
    isUpdateMapping: z.boolean(),
  }),
});
export type PostReceiveNoteItemManualRequest = z.infer<
  typeof PostReceiveNoteItemManualRequestSchema
>;

export const PostReceiveNoteItemManualResponseSchema = z.object({
  receiveNoteId: z.string(),
  receiveNoteItemId: z.string(),
});
export type PostReceiveNoteItemManualResponse = z.infer<
  typeof PostReceiveNoteItemManualResponseSchema
>;

export const PostReceiveNoteItemsDeleteRequestSchema = z.object({
  receiveNoteId: z.string(),
  body: z.object({
    receiveNoteItemId: z.array(z.string()),
  }),
});
export type PostReceiveNoteItemsDeleteRequest = z.infer<
  typeof PostReceiveNoteItemsDeleteRequestSchema
>;

export const PostReceiveNoteItemsDeleteResponseSchema = BaseVoidResponseSchema;
export type PostReceiveNoteItemsDeleteResponse = z.infer<
  typeof PostReceiveNoteItemsDeleteResponseSchema
>;

/**
 * PATCH
 */
export const PatchReceiveNoteItemManualRequestSchema = z.object({
  receiveNoteItemId: z.string(),
  body: z.object({
    quantity: z.number(),
    purchasePrice: z.number(),
    stockValue: z.number(),
    vatType: z.string(),
    deliveryItem: z.object({
      quantityVerified: z.number(),
      unitPrice: z.number(),
      stockValue: z.number(),
      vatType: z.string(),
    }),
    supplierArticle: z.object({
      supplierQuantity: z.number(),
      supplierUnitId: z.string(),
      warehouseQuantity: z.number(),
      supplierOrderingNumber: z.string().optional(),
      supplierBulkPackageQuantity: z.number().optional(),
      isUpdateMapping: z.boolean(),
    }),
  }),
});
export type PatchReceiveNoteItemManualRequest = z.infer<
  typeof PatchReceiveNoteItemManualRequestSchema
>;

export const PatchReceiveNoteItemManualResponseSchema = BaseVoidResponseSchema;
export type PatchReceiveNoteItemManualResponse = z.infer<
  typeof PatchReceiveNoteItemManualResponseSchema
>;

/**
 * PUT
 */
export const PutReceiveNoteItemCalculationRequestSchema = z.object({
  body: z.object({
    changedFieldValue: z.object({
      field: ReceiveNoteItemChangedFieldSchema,
    }),
    currentFeFieldsValues: z.object({
      quantity: z.number().optional(),
      purchasePrice: z.number().optional(),
      totalPrice: z.number().optional(),
      deliveryItem: z.object({
        quantityVerified: z.number().optional(),
        unitPrice: z.number().optional(),
        totalPriceDelivery: z.number().optional(),
      }),
      supplierArticle: z.object({
        supplierQuantity: z.number().optional(),
        warehouseQuantity: z.number().optional(),
      }),
    }),
  }),
  signal: AbortSignalSchema,
});
export type PutReceiveNoteItemCalculationRequest = z.infer<
  typeof PutReceiveNoteItemCalculationRequestSchema
>;

export const PutReceiveNoteItemCalculationResponseSchema = z.object({
  receiveNoteItem: z.object({
    quantity: z.number(),
    purchasePrice: z.number(),
    purchaseValue: z.number(),
  }),
  deliveryNoteItem: z.object({
    quantityVerified: z.number(),
    purchasePrice: z.number(),
    purchaseValue: z.number(),
  }),
  supplierArticle: z.object({
    supplierQuantity: z.number(),
    warehouseQuantity: z.number(),
  }),
});
export type PutReceiveNoteItemCalculationResponse = z.infer<
  typeof PutReceiveNoteItemCalculationResponseSchema
>;
