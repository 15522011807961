import {BreadcrumbType, DataStatus} from 'platform/components';
import {Box, HStack, Show, Space, VStack} from 'platform/foundation';

import {ReactNode} from 'react';

import {SectionTab} from '@omnetic-dms/shared';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {MyProfileHeader} from './components/MyProfileHeader';
import {MyProfileNavigation} from './components/MyProfileNavigation';

interface MyProfileHeaderData {
  title: string;
  breadcrumbs: BreadcrumbType[];
}

interface MyProfileTemplateProps extends RequiredTestIdProps {
  isLoading?: boolean;
  isError?: boolean;
  tabs?: SectionTab[];
  description?: string;
  children: ReactNode;
  header: MyProfileHeaderData;
  isCreating?: boolean;
}

export function MyProfileTemplate(props: MyProfileTemplateProps) {
  return (
    <HStack height="100%">
      <Box
        position="fixed"
        overflowY="auto"
        width={80}
        top={12}
        bottom={0}
        padding={4}
        borderRight="1px solid"
        borderColor="general.separator"
        backgroundColor="general.white"
      >
        <MyProfileNavigation />
      </Box>
      <Box paddingLeft={80} width="100%" backgroundColor="general.white">
        <DataStatus isLoading={props.isLoading} isError={props.isError} minHeight={60}>
          <VStack height="100%">
            <MyProfileHeader
              title={props.header.title}
              breadcrumbs={props.header.breadcrumbs}
              data-testid={suffixTestId('detail', props)}
            />
            <Box padding={6} flex={1} position="relative" backgroundColor="general.white">
              <VStack height="100%">
                <Show when={props.description}>
                  <Space vertical={6} />
                </Show>
                <Box flex={1}>{props.children}</Box>
              </VStack>
            </Box>
          </VStack>
        </DataStatus>
      </Box>
    </HStack>
  );
}
