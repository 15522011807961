import {Alpha3CountryCode, ColorSchemeType} from 'platform/components';
import {Optional} from 'utility-types';
import {z} from 'zod';

import {CurrencyCodeType, Nullish, onProgressType} from 'shared';

import {CountryAlpha3CodeEnum} from '../types/cached/Countries';
import {Alpha3} from '../types/common/Alpha3';
import {TRANSLATED_LANGUAGES} from '../types/common/Language';
import {Money} from '../types/common/Money';
import {PhoneNumber, PhoneNumberSchema} from '../types/common/PhoneNumber';
import {BigDecimal, Price} from '../types/common/Price';
import {VatRateType} from '../types/common/VatRateType';
import {VatTypeEnum} from '../types/common/VatTypeEnum';
import {PaymentType} from './base';
import {CebiaAutotracerReportResponseBody} from './CebiaAutotracerReportResponseBody';
import {AdvertisingPlatformCodeEnum} from './salesPublishing';
import {VatPrice} from './upsellCatalog';
import {ElectricVehicleBattery} from './vehicle/ElectricVehicleBattery';
import {SaleStateEnum} from './vehicle/SaleStateEnum';
import {SaleTypeEnum} from './vehicle/SaleTypeEnum';

// ------------------------- MANUAL TYPES -------------------------

// ------------------------- WAREHOUSE TYPES -------------------------

export type GetEnumsApiResponse = {
  listItems: string[];
};

// ------------------------- ERRORS -------------------------
export type ApiException = {
  error: {
    message: string;
    data: string[];
  };
  errors: {
    message: string;
    code: string;
  }[];
  validationErrors: ValidationError[];
};

export const ErrorSchema = z.object({
  error: z
    .object({
      message: z.string().optional(),
      data: z.record(z.string(), z.string()).optional(),
    })
    .optional(),
  errors: z
    .array(
      z.object({
        message: z.string().optional(),
        code: z.string().optional(),
      })
    )
    .optional(),
  validationErrors: z
    .array(
      z.object({
        message: z.string().optional(),
        field: z.string().optional(),
      })
    )
    .optional(),
});

export type ValidationError = {
  field: string;
  message: string;
};

export type ErrorData = {
  error?: {
    message?: string;
    data?: {
      [key: string]: string;
    };
  };
  errors?: {
    message?: string;
    code?: string;
  }[];
  validationErrors?: {
    message?: string;
    field?: string;
  }[];
};

export type Error = {
  status: number;
  data?: ErrorData;
};

// ------------------------- ACCESS CONTROL API -------------------------
export type GetPermissionDefinitionsApiResponse = /** status 200  */ {
  [key: string]: PermissionDefinitionResponseBody;
};
export type GetPermissionDefinitionsApiArg = {
  authorization?: string;
};
export type GetResourceGroupsApiResponse = /** status 200  */ RootResourceGroupResponseBody;
export type GetResourceGroupsApiArg = {
  authorization?: string;
};
export type AssignLicenseToRoleApiResponse = unknown;
export type AssignLicenseToRoleApiArg = {
  roleId: string;
  authorization?: string;
  assignLicenseToRoleRequestBody: AssignLicenseToRoleRequestBody;
};
export type CreateRoleApiResponse = /** status 201  */ FullRoleResponseBody;
export type CreateRoleApiArg = {
  authorization?: string;
  createRoleRequestBody: CreateRoleRequestBody;
};
export type GetRolesApiResponse = /** status 200  */ ShortRoleResponseBody[];
export type GetRolesApiArg = {
  authorization?: string;
};
export type DeleteRoleApiResponse = unknown;
export type DeleteRoleApiArg = {
  roleId: string;
  authorization?: string;
};
export type GetRoleApiResponse = /** status 200  */ FullRoleResponseBody;
export type GetRoleApiArg = {
  roleId: string;
  authorization?: string;
};
export type PatchRoleApiResponse = /** status 200  */ FullRoleResponseBody;
export type PatchRoleApiArg = {
  roleId: string;
  patchRoleRequestBody: PatchRoleRequestBody;
};

export type GetRolesForRoleManagementApiResponse =
  /** status 200  */ RoleForRoleManagementResponseBody[];
export type GetRolesForRoleManagementApiArg = {
  authorization?: string;
};
export type RemoveLicenseFromRoleApiResponse = unknown;
export type RemoveLicenseFromRoleApiArg = {
  roleId: string;
  licenseId: string;
  authorization?: string;
};
export type PermissionDefinitionResponseBody = {
  title: string;
  description: string;
  actions: ('create' | 'read' | 'update' | 'delete' | 'execute')[];
};
export type ResourceGroupResponseBody = {
  title: string;
  description: string | null;
  groups: ResourceGroupResponseBody[] | null;
  resources: string[] | null;
};
export type RootResourceGroupResponseBody = {
  groups: ResourceGroupResponseBody[] | null;
  resources: string[] | null;
};
export type AssignLicenseToRoleRequestBody = {
  licenseId: string;
};
export type RoleResourcePermissionSetResponseBody = {
  create?: boolean;
  read?: boolean;
  update?: boolean;
  delete?: boolean;
  execute?: boolean;
};
export type RoleLicenseResponseBody = {
  id: string;
  name: string;
};
export type FullRoleResponseBody = {
  id: string;
  title: string;
  permissions: {
    [key: string]: RoleResourcePermissionSetResponseBody;
  };
  system: boolean;
  licenses: RoleLicenseResponseBody[];
};
export type CreateRoleRequestBody = {
  title: string;
  baseRole?: string | null;
};
export type ShortRoleResponseBody = {
  id: string;
  title: string;
  system: boolean;
  licenses: RoleLicenseResponseBody[];
};
export type RoleResourcePermissionSetRequestBody = {
  create?: boolean;
  read?: boolean;
  update?: boolean;
  delete?: boolean;
  execute?: boolean;
};
export type PatchRoleRequestBody = {
  title?: string | null;
  permissions?: {
    [key: string]: RoleResourcePermissionSetRequestBody;
  } | null;
};
export type RoleForRoleManagementResponseBody = {
  role: FullRoleResponseBody;
  assignedUsers: number;
};

export type GetCentralizedPricingRes = Array<{
  tenantId: string;
  value: boolean;
  force: false;
  recordId: null;
}>;
export type GetCentralizedPricingArg = void;

export type PostCentralizedPricingRes = GetCentralizedPricingRes;
export type PostCentralizedPricingArg = {value: boolean};

// ------------------------- LICENSING API -------------------------
export type GetLicensingLogApiResponse = /** status 200  */ LicensingLogResponseBody;
export type GetLicensingLogApiArg = {
  from: string;
  to: string;
  /** Identify tenant with his workspace name */
  'X-Workspace': string;
};
export type ListTenantLicensesApiResponse = /** status 200  */ LicenseResponseBody[];
export type ListTenantLicensesApiArg = void;
export type GetUseCaseListApiResponse = /** status 200  */ UseCaseResponseBody[];
export type GetUseCaseListApiArg = void;
export type UseCaseLogResponseBody = {
  userId: string;
  useCaseId: string;
  time: string;
  type: string;
  message: string | null;
};
export type GrantedAccessLogResponseBody = {
  licenseId: string;
  userId: string;
  from: string;
  to: string;
};
export type LicensingLogResponseBody = {
  useCaseLogs: UseCaseLogResponseBody[];
  grantedAccessLogs: GrantedAccessLogResponseBody[];
};
export type LicenseResponseBody = {
  id: string;
  name: string;
  module: string;
  project: string;
  useCaseIds: string[];
};
export type UseCaseResponseBody = {
  id: string;
};
// ------------------------- AUTH API -------------------------

export type GetDocument360RedirectUrlApiResponse =
  /** status 200  */ Document360RedirectResponseBody;
export type ExternalLoginApiResponse = /** status 200  */ AccessTokenResponseBody;
export type ExternalLoginApiArg = {
  /** Identify tenant with his workspace name */
  workspace: string;
  externalLoginRequestBody: ExternalLoginRequestBody;
};
export type LoginApiResponse = /** status 200  */ AccessTokenResponseBody;
export type LoginApiArg = {
  /** Identify tenant with his workspace name */
  workspace: string;
  loginRequestBody: LoginRequestBody;
};
export type ChangePasswordApiResponse = unknown;
export type ChangePasswordApiArg = {
  /** Identify tenant with his workspace name */
  workspace: string;
  changePasswordRequestBody: ChangePasswordRequestBody;
};
export type RequestPasswordResetApiResponse = unknown;
export type RequestPasswordResetApiArg = {
  /** Identify tenant with his workspace name */
  workspace: string;
  requestPasswordResetRequestBody: RequestPasswordResetRequestBody;
};
export type VerifyTokenApiResponse = unknown;
export type VerifyTokenApiArg = {
  /** Identify tenant with his workspace name */
  workspace: string;
  verifyTokenRequestBody: VerifyTokenRequestBody;
};
export type RefreshTokenApiResponse = /** status 200  */ AccessTokenResponseBody;
export type RefreshTokenApiArg = {
  /** Identify tenant with his workspace name */
  workspace: string;
  refreshTokenRequestBody: RefreshTokenRequestBody;
};
export type SAmlApiResponse = /** status 200  */ SamlResponseBody;
export type SAmlApiArg = {
  samlRequestBody: SamlRequestBody;
};
export type Document360RedirectResponseBody = {
  redirectUrl: string;
};
export type AccessTokenResponseBody = {
  token: string;
  refreshToken: string;
};
export type ExternalLoginRequestBody = {
  email: string;
};
export type LoginRequestBody = {
  email: string;
  password: string;
};
export type ChangePasswordRequestBody = {
  token: string;
  password: string;
};
export type RequestPasswordResetRequestBody = {
  email: string;
};
export type VerifyTokenRequestBody = {
  token: string;
};
export type RefreshTokenRequestBody = {
  refreshToken: string;
};
export type SamlResponseBody = {
  redirectUrl: string;
  samlResponse: string;
};
export type SamlRequestBody = {
  samlRequest: string;
  sigAlg: string;
  signature: string;
};

export type UpdateVatCalculationDefinitionRequestBody = {
  branchId: string | null;
  calculationType: VatCalculationDefinitionCalculationTypeEnum;
};

export type UpdateVatCalculationDefinitionApiArg = {
  id: string;
  updateVatCalculationDefinitionRequestBody: UpdateVatCalculationDefinitionRequestBody;
};

export type UpdateVatCalculationDefinitionApiResponse =
  /** status 200  */ VatCalculationDefinitionResponseBody;

export type DeleteVatCalculationDefinitionApiResponse = unknown;
export type DeleteVatCalculationDefinitionApiArg = string;

export type GetVatCalculationDefinitionApiResponse =
  /** status 200  */ VatCalculationDefinitionResponseBody;
export type GetVatCalculationDefinitionApiArg = string;

export type GetVatCalculationDefinitionListApiResponse =
  /** status 200  */ VatCalculationDefinitionResponseBody[];

export type GetVatCalculationDefinitionListApiArg = {
  authorization?: string;
};

export type CreateBillingInformationApiResponse = /** status 201  */ BillingInformationResponseBody;
export type CreateBillingInformationApiArg = {
  createBillingInformationRequestBody: CreateBillingInformationRequestBody;
};
export type GetBillingInformationListApiResponse =
  /** status 200  */ BillingInformationListResponseBody;

export type PutBillableUserApiArg = {'x-tenant': string; userId: string; billable: boolean};
export type PutBillableUserApiRes = void;
export type GetUsersApiArg = {'x-tenant': string};
export type GetUsersApiRes = UserResponseBody[];

export type GetBillingInformationApiResponse = /** status 200  */ BillingInformationResponseBody;
export type GetBillingInformationApiArg = {
  billingInformationId: string;
};
export type PutBillingInformationApiResponse = /** status 200  */ BillingInformationResponseBody;
export type PutBillingInformationApiArg = {
  billingInformationId: string;
  putBillingInformationRequestBody: PutBillingInformationRequestBody;
};
export type CreateBranchApiResponse = /** status 201  */ BranchResponseBody;
export type CreateBranchApiArg = {
  createBranchRequestBody: CreateBranchRequestBody;
};
export type GetBranchListApiResponse = /** status 200  */ BranchListResponseBody;

export type GetBranchApiResponse = /** status 200  */ BranchResponseBody;
export type GetBranchApiArg = {
  branchId: string;
};
export type PutBranchApiResponse = /** status 200  */ BranchResponseBody;
export type PutBranchApiArg = {
  branchId: string;
  putBranchRequestBody: PutBranchRequestBody;
};
export type GetTenantApiResponse = /** status 200  */ TenantResponseBody;
export type PutTenantApiResponse = /** status 200  */ TenantResponseBody;
export type PutTenantApiArg = {
  putTenantRequestBody: PutTenantRequestBody;
};
export type GetCebiaAutotracerAndReportConfigApiResponse =
  /** status 200  */ CebiaAutotracerAndReportConfigResponseBody;

export type GetEsignoServiceApiResponse = {
  enabled: boolean;
  credentials: {
    apiKey: 'string';
  } | null;
};
export type PatchEsignoServiceApiResponse = {
  enabled: boolean;
  credentials: {
    apiKey: 'string';
  } | null;
};
export type PatchEsignoServiceApiArg = {
  enabled?: boolean | null;
  credentials?: {
    apiKey: 'string';
  } | null;
};
export type GetEsignoServiceApiArg = void;
export type PatchCebiaAutotracerAndReportConfigApiResponse = unknown;
export type PatchCebiaAutotracerAndReportConfigApiArg = {
  cebiaAutotracerAndReportConfigRequestBody: CebiaAutotracerAndReportConfigRequestBody;
};
export type GetCebiaFeatureDecoderConfigApiResponse =
  /** status 200  */ CebiaFeatureDecoderConfigResponseBody;
export type PatchCebiaFeatureDecoderConfigApiResponse = unknown;
export type PatchCebiaFeatureDecoderConfigApiArg = {
  cebiaFeatureDecoderConfigRequestBody: CebiaFeatureDecoderConfigRequestBody;
};
export type GetCebiaVinDecoderConfigApiResponse =
  /** status 200  */ CebiaVinDecoderConfigResponseBody;

export type PatchCebiaVinDecoderConfigApiResponse = unknown;
export type PatchCebiaVinDecoderConfigApiArg = {
  body: CebiaVinDecoderConfigRequestBody;
};
export type GetOmneticVinDecoderConfigApiResponse =
  /** status 200  */ OmneticVinDecoderConfigResponseBody;

export type PatchOmneticVinDecoderConfigApiResponse = unknown;
export type PatchOmneticVinDecoderConfigApiArg = {
  body: OmneticVinDecoderConfigRequestBody;
};
export type GetKonzultaConfigApiResponse = /** status 200  */ KonzultaConfigResponseBody;
export type GetEmailConfigApiResponse = /** status 200 */ EmailConfigResponseBody;
export type PatchKonzultaConfigApiResponse = unknown;
export type PatchKonzultaConfigApiArg = {
  konzultaConfigRequestBody: KonzultaConfigRequestBody;
};
export type PostKonzultaSmsApiResponse = unknown;
export type PostKonzultaSmsApiArg = {
  konzultaSmsSenderRequestBody: KonzultaSmsSenderRequestBody;
};
export type WorkspaceInfoApiResponse = /** status 200  */ WorkspaceInfoResponseBody;
export type WorkspaceInfoApiArg = {
  /** Identify tenant with his workspace name */
  workspace: string;
};
export type WorkspacesApiResponse = /** status 200  */ WorkspaceInfoResponseBody[];
export type WorkspacesApiArg = {
  email?: string;
  /** Identify tenant with his workspace name */
  workspace: string;
};
export const AuthAddressResponseBodySchema = z.object({
  street: z.string(),
  city: z.string(),
  zipCode: z.string(),
  state: z.string().nullable(),
  country: z.string(),
});
export type AuthAddressResponseBody = z.infer<typeof AuthAddressResponseBodySchema>;
export const SelfEmployedInformationResponseBodySchema = z
  .object({
    titleBefore: z.string().nullable(),
    firstName: z.string(),
    lastName: z.string(),
    titleAfter: z.string().nullable(),
    phoneNumber: PhoneNumberSchema.nullable(),
    emailAddress: z.string().nullable(),
  })
  .nullable();
export type SelfEmployedInformationResponseBody = z.infer<
  typeof SelfEmployedInformationResponseBodySchema
>;
export const ContactInformationResponseBodySchema = z.object({
  registrationNumber: z.string(),
  vatNumber: z.string().nullable(),
  companyName: z.string(),
  fileNumber: z.string().nullable(),
  address: AuthAddressResponseBodySchema.nullable(),
  selfEmployedInformation: SelfEmployedInformationResponseBodySchema.nullable(),
});
export type ContactInformationResponseBody = z.infer<typeof ContactInformationResponseBodySchema>;
export const DeputyPersonResponseBodySchema = z.object({
  isPrimary: z.boolean(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  dateOfBirth: z.string().nullable(),
  identificationNumber: z.string().nullable(),
  personalIdType: z.string().nullable(),
  personalIdNumber: z.string().nullable(),
  validUntil: z.string().nullable(),
  issuedBy: z.string().nullable(),
});
export type DeputyPersonResponseBody = z.infer<typeof DeputyPersonResponseBodySchema>;
export const BankAccountResponseBodySchema = z.object({
  bankName: z.string().nullable(),
  accountName: z.string().nullable(),
  accountNumber: z.string().nullable(),
  isPrimary: z.boolean().nullable(),
  currency: z.string().nullable(),
  swift: z.string().nullable(),
  iban: z.string().nullable(),
});
export type BankAccountResponseBody = z.infer<typeof BankAccountResponseBodySchema>;
export const BillingInformationResponseBodySchema = z.object({
  id: z.string(),
  billingName: z.string().nullable(),
  isPrimary: z.boolean(),
  contactInformation: ContactInformationResponseBodySchema.nullable(),
  deputyPersons: z.array(DeputyPersonResponseBodySchema),
  bankAccounts: z.array(BankAccountResponseBodySchema),
});
export type BillingInformationResponseBody = z.infer<typeof BillingInformationResponseBodySchema>;
export type AddressRequestBody = {
  street: string;
  city: string;
  zipCode: string;
  state: string | null;
  country: string;
};
export type SelfEmployedInformationRequestBody = {
  titleBefore: string | null;
  firstName: string;
  lastName: string;
  titleAfter: string | null;
  phoneNumber: PhoneNumber | null;
  emailAddress: string | null;
};
export type ContactInformationRequestBody = {
  registrationNumber: string;
  vatNumber: string;
  companyName: string;
  fileNumber: string | null;
  address: AddressRequestBody;
  selfEmployedInformation?: SelfEmployedInformationRequestBody | null;
};
export type DeputyPersonRequestBody = {
  isPrimary: boolean;
  firstName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  identificationNumber: string | null;
  personalIdType:
    | ('ID_TYPE_ID' | 'ID_TYPE_PASSPORT' | 'ID_TYPE_DRIVING_LICENSE' | 'ID_TYPE_OTHER')
    | null;
  personalIdNumber: string | null;
  validUntil: string | null;
  issuedBy: string | null;
};
export type BankAccountRequestBody = {
  bankName: string;
  accountName: string;
  accountNumber: string;
  isPrimary: boolean | null;
  currency: string | null;
  swift: string | null;
  iban: string | null;
};
export type CreateBillingInformationRequestBody = {
  billingName: string | null;
  contactInformation: ContactInformationRequestBody;
  deputyPersons: DeputyPersonRequestBody[];
  bankAccounts: BankAccountRequestBody[];
};
export type BillingInformationListItemResponseBody = {
  id: string;
  billingName: string | null;
  companyName: string | null;
  city: string | null;
  street: string | null;
  registrationNumber: string | null;
};
export type BillingInformationListResponseBody = {
  billingInformationListItems: BillingInformationListItemResponseBody[];
};
export type BillingContactInformationRequestBody = {
  registrationNumber: string;
  vatNumber: string;
  companyName: string;
  fileNumber: string | null;
  address: AddressRequestBody;
};
export type PutBillingInformationRequestBody = {
  billingName: string | null;
  contactInformation: BillingContactInformationRequestBody;
  deputyPersons: DeputyPersonRequestBody[];
  bankAccounts: BankAccountRequestBody[];
};
export const PublicContactsResponseBodySchema = z.object({
  phoneNumber: PhoneNumberSchema.nullable(),
  emailAddress: z.string().nullable(),
  website: z.string().nullable(),
});
export type PublicContactsResponseBody = z.infer<typeof PublicContactsResponseBodySchema>;
export const ContactPersonResponseBodySchema = z.object({
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  role: z.string().nullable(),
  isPrimary: z.boolean().nullable(),
  phoneNumber: PhoneNumberSchema.nullable(),
  emailAddress: z.string().nullable(),
});
export type ContactPersonResponseBody = z.infer<typeof ContactPersonResponseBodySchema>;
export const BranchResponseBodySchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  name: z.string(),
  code: z.string().nullable(),
  registrationNumber: z.string().nullable(),
  fileNumber: z.string().nullable(),
  vatNumber: z.string().nullable(),
  companyName: z.string().nullable(),
  address: AuthAddressResponseBodySchema.nullable(),
  publicContacts: PublicContactsResponseBodySchema.nullable(),
  contactPersons: z.array(ContactPersonResponseBodySchema).nullable(),
  billingInformation: BillingInformationResponseBodySchema.nullable(),
  accountingExternalId: z.string().nullable(),
});
export type BranchResponseBody = z.infer<typeof BranchResponseBodySchema>;
export type PublicContactsRequestBody = {
  phoneNumber: PhoneNumber | null;
  emailAddress: string | null;
  website: string | null;
};
export type ContactPersonRequestBody = {
  firstName: string | null;
  lastName: string | null;
  role: string | null;
  isPrimary: boolean | null;
  phoneNumber: PhoneNumber | null;
  emailAddress: string | null;
};
export type CreateBranchRequestBody = {
  billingInformationId: string;
  name: string;
  address: AddressRequestBody;
  publicContacts: PublicContactsRequestBody | null;
  contactPersons: ContactPersonRequestBody[] | null;
  accountingExternalId: string | null;
};
export type BranchListItemResponseBody = {
  id: string;
  marketingName: string | null;
  companyName: string | null;
  city: string | null;
  street: string | null;
  usersCount: number | null;
};
export type BranchListResponseBody = {
  branchListItems: BranchListItemResponseBody[];
};
export type PutBranchRequestBody = {
  billingInformationId: string;
  name: string;
  address: AddressRequestBody;
  publicContacts: PublicContactsRequestBody | null;
  contactPersons: ContactPersonRequestBody[] | null;
  accountingExternalId: string | null;
};
export type TenantResponseBody = {
  id: string;
  country: CountryAlpha3CodeEnum;
  currency: CurrencyCodeType;
  type: string | null;
  contactInformation: ContactInformationResponseBody | null;
  publicContacts: PublicContactsResponseBody | null;
  contactPersons: ContactPersonResponseBody[] | null;
  billingInformation: BillingInformationResponseBody[];
};
export type PutTenantRequestBody = {
  type: string | null;
  contactInformation: ContactInformationRequestBody | null;
  publicContacts: PublicContactsRequestBody | null;
  contactPersons: ContactPersonRequestBody[] | null;
};
export type CebiaAutotracerAndReportCredentials = {
  username: string;
  password: string;
};
export type CebiaAutotracerAndReportConfigResponseBody = {
  enabled: boolean;
  credentials: CebiaAutotracerAndReportCredentials | null;
};
export type CebiaAutotracerAndReportConfigRequestBody = {
  enabled?: boolean | null;
  credentials?: CebiaAutotracerAndReportCredentials | null;
};
export type CebiaEquipmentCredentials = {
  username: string;
  password: string;
};
export type CebiaFeatureDecoderConfigResponseBody = {
  enabled: boolean;
  credentials: CebiaEquipmentCredentials | null;
};
export type CebiaFeatureDecoderConfigRequestBody = {
  enabled?: boolean | null;
  credentials?: CebiaEquipmentCredentials | null;
};
export type CebiaVinDecoderConfigResponseBody = {
  enabled: boolean;
};
export type CebiaVinDecoderConfigRequestBody = {
  enabled?: boolean | null;
};
export type OmneticVinDecoderConfigResponseBody = {
  enabled: boolean;
};
export type OmneticVinDecoderConfigRequestBody = {
  enabled?: boolean | null;
};
export type KonzultaCredentials = {
  username?: string | null;
  password?: string | null;
};
export type KonzultaConfigResponseBody = {
  enabled?: boolean | null;
  credentials?: KonzultaCredentials | null;
};
export interface EmailConfigResponseBody {
  enabled: boolean;
  credentials: EmailCredentials;
}

export interface EmailCredentials {
  host: string;
  port: number;
  user: string;
  password: string;
  startTls: boolean;
  defaultEmail: string;
}
export type KonzultaConfigRequestBody = {
  enabled?: boolean | null;
  credentials?: KonzultaCredentials | null;
};
export type KonzultaSmsSenderRequestBody = {
  phone?: string;
  text?: string;
};
export type WorkspaceInfoResponseBody = {
  workspace: string;
  tenantName: string;
  name?: string;
};

// ------------------------- TENANT API -------------------------

export type FlagSmithJWT = {
  wsp?: string;
};

export type UserBranchResponseBody = {
  id: string;
  name: string;
  street: string;
  number: string;
  city: string;
  zip: string;
  country: string;
};

export type GetAutostacjaSvConnectorConfigApiResponse =
  /** status 200  */ AutostacjaSvConnectorConfigResponseBody;
export type PatchAutostacjaSvConnectorConfigApiResponse = unknown;
export type PatchAutostacjaSvConnectorConfigApiArg = {
  /** User JWT tokens are allowed */
  body: AutostacjaSvConnectorConfigRequestBody;
};
export type AutostacjaSvConnectorCredentials = {
  subscriptionKey: string;
};
export type AutostacjaSvConnectorConfigResponseBody = {
  enabled: boolean;
  credentials: AutostacjaSvConnectorCredentials | null;
};
export type AutostacjaSvConnectorConfigRequestBody = {
  enabled?: boolean | null;
  credentials?: AutostacjaSvConnectorCredentials | null;
};

// ------------------- User API ------------------

export type GetCurrentUserInfoApiResponse = /** status 200  */ UserResponseBody & {
  settings: UserSettingsType;
};

export type UserSettingsType = {
  branch?: string;
  country?: Alpha3CountryCode;
  currency?: string;
  language?: TRANSLATED_LANGUAGES;
  inactivityPeriod?: InactivityPeriodType;
  insurerNumber?: string;
  customId?: string;
};

export type InactivityPeriodType =
  | 'INACTIVITY_PERIOD_NEVER'
  | `INACTIVITY_PERIOD_15_MINUTES`
  | `INACTIVITY_PERIOD_30_MINUTES`
  | `INACTIVITY_PERIOD_1_HOUR`
  | `INACTIVITY_PERIOD_2_HOURS`
  | `INACTIVITY_PERIOD_6_HOURS`
  | `INACTIVITY_PERIOD_24_HOURS`
  | `INACTIVITY_PERIOD_48_HOURS`
  | null;

export type GetUserAuctionStatsApiResponse = /** status 200  */ UserStatsResponseBody;
export type GetUserAuctionStatsApiArg = {
  branch: string;
};
export type GetUserStatsApiResponse = /** status 200  */ UserStatsResponseBody;
export type GetUserStatsApiArg = {
  branch: string;
};
export type GetUserStats = {
  userAuctionStats: GetUserAuctionStatsApiResponse;
  userStats: GetUserStatsApiResponse;
};
export type GetUserSettingsApiResponse = /** status 200  */ {
  [key: string]: UserSettingsResponseBody;
};
export type CreateExternalUserApiResponse = /** status 201  */ UserResponseBody;
export type CreateExternalUserApiArg = {
  createExternalUserRequestBody: CreateExternalUserRequestBody;
};
export type GetAvailableLanguagesListApiResponse =
  /** status 200  */ AvailableLanguagesListResponseBody;
export type GetAvailableLanguagesListApiArg = {
  /** Identify tenant with his workspace name */
  workspace: string;
};

export type PostLogoutApiArg = {
  accessToken: string;
};

export type SetUserSettingsItemApiResponse = /** status 200  */ undefined;
export type SetUserSettingsItemApiArg = {
  settingKey: string;
  userSettingRequestBody: UserSettingRequestBody;
};
export type BlockUserApiResponse = /** status 200  */ UserResponseBody;
export type BlockUserApiArg = {
  userId: string;
};
export type ChangeCurrentUserPasswordApiResponse = unknown;
export type ChangeCurrentUserPasswordApiArg = {
  changeCurrentUserPasswordRequestBody: ChangeCurrentUserPasswordRequestBody;
};
export type CreateUserApiResponse = /** status 201  */ UserResponseBody;
export type CreateUserApiArg = {
  createUserRequestBody: CreateUserRequestBody;
};
export type GetUsersApiResponse = /** status 200  */ UserResponseBody[];
export type GetUserApiResponse = /** status 200  */ UserResponseBody;
export type GetUserApiArg = {
  id: string;
};
export type PatchCurrentUserApiResponse = /** status 200  */ UserResponseBody;
export type PatchCurrentUserApiArg = {
  updateCurrentUserRequestBody: UpdateCurrentUserRequestBody;
};
export type PatchUserApiResponse = /** status 200  */ UserResponseBody;
export type PatchUserApiArg = {
  userId: string;
  updateUserRequestBody: UpdateUserRequestBody;
};
export type GetOtherUserSettingsApiResponse = /** status 200  */ {
  [key: string]: UserSettingsResponseBody;
};
export type GetOtherUserSettingsApiArg = {
  userId: string;
};
export type SetOtherUserSettingsItemApiResponse = /** status 204  */ void;
export type SetOtherUserSettingsItemApiArg = {
  userId: string;
  settingKey: string;
  body: UserSettingRequestBody;
};
export type UnblockUserApiResponse = /** status 200  */ UserResponseBody;
export type UnblockUserApiArg = {
  userId: string;
};
export type GetUserMenuPinsResponse = /** status 200  */ string[];
export type GetUserMenuPinsArg = void;
export type SetUserMenuPinsArg = {items: Required<MenuItemPin[]>};
export type SetUserMenuPinsResponse = /** status 204  */ void;

export const RoleResponseBodySchema = z.object({
  id: z.string(),
  title: z.string(),
});
export type RoleResponseBody = z.infer<typeof RoleResponseBodySchema>;
export const UserResponseBodySchema = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phoneNumber: PhoneNumberSchema.nullish(),
  defaultBranch: BranchResponseBodySchema.optional(),
  branches: z.array(BranchResponseBodySchema).optional(),
  roles: z.array(RoleResponseBodySchema).optional(),
  blocked: z.boolean().optional(),
  external: z.boolean().optional(),
  billable: z.boolean().optional(),
});
export type UserResponseBody = z.infer<typeof UserResponseBodySchema>;
export type UserStatsResponseBody = {
  toBuyCount: number;
  favouriteCount: number;
  hiddenCount: number;
  comparisonCount: number;
};
export type UserSettingsResponseBody = {
  key: string;
  value: (string | number | boolean | object) | null;
};
export type CreateExternalUserRequestBody = {
  email: string;
};
export type PermissionSetResponseBody = {
  create?: boolean;
  read?: boolean;
  update?: boolean;
  delete?: boolean;
  execute?: boolean;
};
export type AvailableLanguagesListResponseBody = {
  default: string;
  languages: {
    [key: string]: string;
  };
};
export type UserSettingRequestBody = {
  value: string;
};
export type ChangeCurrentUserPasswordRequestBody = {
  currentPassword: string;
  newPassword: string;
};
export type CreateUserRequestBody = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  roles: string[];
  defaultBranchId: string;
  branchIds: string[];
  pins: string[];
};
export type UpdateCurrentUserRequestBody = {
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: PhoneNumber;
};
export type UpdateUserRequestBody = {
  firstName?: string;
  lastName?: string;
  password?: string;
  roles?: string[];
  defaultBranchId?: string;
  branchIds?: string[];
};
export type MenuItemPin = {
  item?: string;
};

// ------------------- Accounting API ------------------

export type GetCashRegisterDocumentKindOfPaymentListApiResponse =
  /** status 200  */ CashRegisterDocumentKindOfPaymentResponseBody[];
export type GetCashRegisterDocumentKindOfPaymentListApiArg = {
  authorization?: string;
};
export type GetCashRegisterDocumentStateListApiResponse =
  /** status 200  */ CashRegisterDocumentStateResponseBody[];
export type GetCashRegisterDocumentStateListApiArg = {
  authorization?: string;
};
export type GetCashRegisterDocumentTypeListApiResponse =
  /** status 200  */ CashRegisterDocumentTypeResponseBody[];
export type GetCashRegisterDocumentTypeListApiArg = {
  authorization?: string;
};

export type CreateCashRegisterApiResponse = /** status 201  */ CashRegisterResponseBody;
export type CreateCashRegisterApiArg = {
  authorization?: string;
  createCashRegisterRequestBody: CreateCashRegisterRequestBody;
};
export type GetCashRegisterListApiResponse = /** status 200  */ CashRegisterResponseBody[];
export type GetCashRegisterListApiArg = {
  inactive?: boolean | null;
  currency?: string | null;
  withValidIncomeSeries?: boolean | null;
};
export type GetCashRegisterApiResponse = /** status 200  */ CashRegisterResponseBody;
export type GetCashRegisterApiArg = {
  cashRegisterId: string;
  authorization?: string;
  'X-Branch'?: string;
};
export type PatchCashRegisterApiResponse = /** status 200  */ CashRegisterResponseBody;
export type PatchCashRegisterApiArg = {
  cashRegisterId: string;
  authorization?: string;
  patchCashRegisterRequestBody: PatchCashRegisterRequestBody;
};
export type InactivateCashRegisterApiResponse = /** status 200  */ CashRegisterResponseBody;
export type InactivateCashRegisterApiArg = {
  cashRegisterId: string;
  authorization?: string;
};
export type CancelCashRegisterDocumentApiResponse =
  /** status 201  */ CashRegisterDocumentResponseBody;
export type CancelCashRegisterDocumentApiArg = {
  cashRegisterDocumentId: string;
  authorization?: string;
  cancelCashRegisterDocumentRequestBody: CancelCashRegisterDocumentRequestBody;
};
export type CreateCashRegisterDocumentApiResponse =
  /** status 201  */ CashRegisterDocumentResponseBody;
export type CreateCashRegisterDocumentApiArg = {
  authorization?: string;
  createCashRegisterDocumentRequestBody: CreateCashRegisterDocumentRequestBody;
};
export type GetCashRegisterDocumentApiResponse =
  /** status 200  */ CashRegisterDocumentResponseBody;
export type GetCashRegisterDocumentApiArg = {
  cashRegisterDocumentId: string;
  authorization?: string;
};
export type CreateDocumentLimitDefinitionApiResponse =
  /** status 201  */ DocumentLimitDefinitionResponseBody;
export type CreateDocumentLimitDefinitionApiArg = {
  authorization?: string;
  createDocumentLimitDefinitionRequestBody: CreateDocumentLimitDefinitionRequestBody;
};
export type GetDocumentLimitDefinitionListApiResponse =
  /** status 200  */ DocumentLimitDefinitionResponseBody[];
export type GetDocumentLimitDefinitionListApiArg = {
  authorization?: string;
};
export type GetDocumentLimitDefinitionApiResponse =
  /** status 200  */ DocumentLimitDefinitionResponseBody;
export type GetDocumentLimitDefinitionApiArg = {
  id: string;
  authorization?: string;
};
export type RemoveDocumentLimitDefinitionApiResponse = unknown;
export type RemoveDocumentLimitDefinitionApiArg = {
  id: string;
  authorization?: string;
};
export type UpdateDocumentLimitDefinitionApiResponse =
  /** status 200  */ DocumentLimitDefinitionResponseBody;
export type UpdateDocumentLimitDefinitionApiArg = {
  id: string;
  authorization?: string;
  updateDocumentLimitDefinitionRequestBody: UpdateDocumentLimitDefinitionRequestBody;
};
export type GetDocumentRestrictionApiResponse = /** status 200  */ DocumentRestrictionResponseBody;
export type GetDocumentRestrictionApiArg = {
  /** Find restriction by given branchId. If null is given, then you are trying to find it for Tenant. */
  branchId?: string | null;
  /** Find restriction by given documentType. */
  documentType?: CashRegisterDocumentTypeEnum;
  authorization?: string;
  currency?: string;
};

export type ValidityRequestBody = {
  validFrom: string;
  roundType: 'UP' | 'DOWN' | 'ALGEBRAIC';
  roundStep: RoundStep;
  taxRounding: boolean;
};
export type CreateRoundingDefinitionRequestBody = {
  branchId: string;
  paymentType: PaymentType;
  validities: ValidityRequestBody[];
};
export type UpdateRoundingDefinitionRequestBody = {
  validities: ValidityRequestBody[];
};
export type FindRoundingDefinitionRequestBody = {
  branchId: string | null;
  paymentType: PaymentType;
  taxableSupplyDate: string;
};
export type RoundStepResponseBody = {
  key: string;
  value: string;
};
export type CancelCorrectiveTaxDocumentApiResponse = unknown;
export type CancelCorrectiveTaxDocumentApiArg = {
  correctiveTaxDocumentId: string;
  authorization?: string;
};

export type CreateRoundingDefinitionApiResponse = /** status 201  */ RoundingDefinitionResponseBody;
export type CreateRoundingDefinitionApiArg = {
  authorization?: string;
  createRoundingDefinitionRequestBody: CreateRoundingDefinitionRequestBody;
};
export type GetRoundingDefinitionListApiResponse =
  /** status 200  */ RoundingDefinitionResponseBody[];
export type GetRoundingDefinitionListApiArg = {
  authorization?: string;
};
export type DeleteRoundingDefinitionApiResponse = unknown;
export type DeleteRoundingDefinitionApiArg = {
  id: string;
  authorization?: string;
};
export type GetRoundingDefinitionApiResponse = /** status 200  */ RoundingDefinitionResponseBody;
export type GetRoundingDefinitionApiArg = {
  id: string;
  authorization?: string;
};
export type UpdateRoundingDefinitionApiResponse = /** status 200  */ RoundingDefinitionResponseBody;
export type UpdateRoundingDefinitionApiArg = {
  id: string;
  authorization?: string;
  updateRoundingDefinitionRequestBody: UpdateRoundingDefinitionRequestBody;
};
export type FindRoundingDefinitionValidityApiResponse =
  /** status 200  */ RoundingDefinitionAndValidityResponseBody;
export type FindRoundingDefinitionValidityApiArg = {
  authorization?: string;
  findRoundingDefinitionRequestBody: FindRoundingDefinitionRequestBody;
};

export type GetRoundStepApiResponse = /** status 200  */ RoundStepResponseBody[];
export type GetRoundStepApiArg = {
  authorization?: string;
};
export type TaxDocumentCalculationApiResponse =
  /** status 200  */ TaxDocumentCalculationResponseBody;
export type TaxDocumentCalculationV2ApiResponse =
  /** status 200  */ TaxDocumentCalculationV2ResponseBody;
export type GetTaxDocumentForPaymentApiArg = {
  id: string;
  authorization?: string;
};
export type CashRegisterDocumentKindOfPaymentEnum =
  | 'income/current_payment'
  | 'income/deposit_payment'
  | 'income/invoice_payment'
  | 'income/cash_register_income'
  | 'income/cash_register_transfer'
  | 'expense/current_payment'
  | 'expense/deposit_payment_return'
  | 'expense/cash_register_expense'
  | 'expense/cash_register_transfer';
export type CashRegisterDocumentTypeEnum = 'income' | 'expense';
export type CashRegisterDocumentKindOfPaymentResponseBody = {
  code: CashRegisterDocumentKindOfPaymentEnum;
  type: CashRegisterDocumentTypeEnum;
  name: string;
};
export type CashRegisterDocumentStateEnum = 'taken' | 'cancelled' | 'cancelling';
export type CashRegisterDocumentStateResponseBody = {
  code: CashRegisterDocumentStateEnum;
  color: string;
};
export type CashRegisterDocumentTypeResponseBody = {
  code: CashRegisterDocumentTypeEnum;
  color: string;
};
export type SupplierResponseBody = {
  id: string;
  name: string | null;
  tradeName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  zip: string | null;
  country: Alpha3 | null;
  cin: string | null;
  tin: string | null;
  fileNumber: string | null;
};
export type AccountingCustomerResponseBody = {
  id: string;
  name: string | null;
  tradeName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  zip: string | null;
  country: Alpha3 | null;
  cin: string | null;
  tin: string | null;
  printLanguage: string | null;
};
export type PaymentInfoResponseBody = {
  paymentMethod: 'BANK_TRANSFER' | 'CARD' | 'CASH' | 'OFFSET';
  bankAccount: string | null;
  bankName: string | null;
  iban: string | null;
  swift: string | null;
  variableSymbol: string | null;
  constantSymbol: string | null;
  specificSymbol: string | null;
};
export type Decimal = string;
export type MoneyResponseBody = {
  amount: Decimal;
  currency: Alpha3;
};
export const VatTypeSchema = z.enum([
  VatTypeEnum.S,
  VatTypeEnum.R,
  VatTypeEnum.MR,
  VatTypeEnum.E,
  VatTypeEnum.Z,
]);
export type VatType = z.infer<typeof VatTypeSchema>;
export type PriceResponseBody = {
  withoutVat: MoneyResponseBody | null;
  withVat: MoneyResponseBody | null;
  vatRate: string | null;
  vatCode?: string | null;
};
export type VatTypeSummaryResponseBody = {
  groupSummary: {
    priceWithVat: Money;
    priceWithoutVat: Money;
    vat: Money;
  };
  additionalCalculation: PriceResponseBody | null;
  vatRate: string;
};

export type VatTypeSummaryV2ResponseBody = {
  groupSummary: {
    priceWithVat: Money;
    priceWithoutVat: Money;
    vat: Money;
  };
  additionalCalculation: {
    priceWithVat: Money;
    priceWithoutVat: Money;
    vat: Money;
  } | null;
  vatRate: string;
};
export type TaxDocumentItemType = 'standard' | 'additionalCalculation';
export type TaxDocumentCalculationItemResponseBody = {
  reference: string;
  quantity: string;
  relatedItemId?: string;
  vatType: VatType;
  unitPrice: MoneyResponseBody;
  isUnitPriceWithVat: boolean;
  totalPriceWithoutVat: MoneyResponseBody;
  totalPriceWithVat: MoneyResponseBody;
  type: TaxDocumentItemType;
};
export type TaxDocumentSummaryItemType =
  | 'documentPriceWithVatRounding'
  | 'documentPriceWithVatRoundingVat'
  | 'documentPriceWithVatRoundingWithoutVat';
export type TaxDocumentCalculationSummaryItemResponseBody = {
  reference: string;
  type: TaxDocumentSummaryItemType;
  amount: MoneyResponseBody;
  vatRate: string | null;
};
export type RoundStep = '0.01' | '0.05' | '0.1' | '0.5' | '1' | '5' | '10' | '100';
export type ValidityResponseBody = {
  validFrom: string;
  roundType: 'UP' | 'DOWN' | 'ALGEBRAIC';
  roundStep: RoundStep;
  taxRounding: boolean;
};
export type RoundingDefinitionResponseBody = {
  id: string;
  branchId: string | null;
  paymentType: PaymentType;
  validities: ValidityResponseBody[];
  isSystem: boolean;
};
export type RoundingDefinitionAndValidityResponseBody = {
  definition: RoundingDefinitionResponseBody;
  validity: ValidityResponseBody;
};
export type TaxDocumentCalculationResponseBody = {
  taxableSupplyDate: string;
  vatTypes: VatType[];
  vatTypesSummary: {
    [key: string]: VatTypeSummaryResponseBody;
  };
  priceWithVat: MoneyResponseBody;
  priceWithoutVat: MoneyResponseBody;
  priceWithVatBeforeRounding: MoneyResponseBody;
  vat: MoneyResponseBody;
  items: TaxDocumentCalculationItemResponseBody[];
  summaryItems: TaxDocumentCalculationSummaryItemResponseBody[];
  usedRoundingDefinitionAndValidity: RoundingDefinitionAndValidityResponseBody;
};

export type TaxDocumentCalculationV2Response = {
  taxableSupplyDate: string;
  vatTypes: VatType[];
  vatTypesSummary: {
    [key: string]: VatTypeSummaryV2ResponseBody;
  };
  priceWithVat: MoneyResponseBody;
  priceWithoutVat: MoneyResponseBody;
  priceWithVatBeforeRounding: MoneyResponseBody;
  vat: MoneyResponseBody;
  items: TaxDocumentCalculationItemResponseBody[];
  summaryItems: TaxDocumentCalculationSummaryItemResponseBody[];
  usedRoundingDefinitionAndValidity: RoundingDefinitionAndValidityResponseBody;
};

export type TaxDocumentCalculationV2ResponseBody = {
  calculation: TaxDocumentCalculationV2Response;
  exchangeRateRatioCalculation: TaxDocumentCalculationV2Response;
};

export type CashRegisterResponseBody = {
  id: string;
  name: string;
  code: string;
  currency: string;
  expenseSeriesId: string;
  incomeSeriesId: string;
  branchId: string;
  activeFrom: string;
  eligibleForCardPayments: boolean;
  cashRegisterAccount: string | null;
  currentBalance: Money;
  initialBalance: Money;
  active: boolean;
  system: boolean;
  eetCode: string | null;
  closedAt: string | null;
  documentCount: number;
  createdBy: string | null;
  createdAt: string | null;
};
export type CreateCashRegisterRequestBody = {
  name: string;
  code: string;
  currency: Alpha3;
  expenseSeriesId: string;
  incomeSeriesId: string;
  branchId: string;
  activeFrom: string;
  cashRegisterAccount: string | null;
  eligibleForCardPayments: boolean | null;
  eetCode?: string | null;
};
export type PatchCashRegisterRequestBody = {
  name?: string | null;
  code?: string | null;
  currency?: Alpha3 | null;
  expenseSeriesId?: string | null;
  incomeSeriesId?: string | null;
  branchId?: string | null;
  activeFrom?: string | null;
  active?: boolean | null;
  cashRegisterAccount: string | null;
  eligibleForCardPayments: boolean | null;
  eetCode?: string | null;
};
export type CashRegisterDocumentItemResponseBody = {
  id: string;
  description: string;
  unitPrice: Money;
  unit: string;
  withVat: boolean;
  vatRate: BigDecimal;
  vatType: VatType;
  quantity: BigDecimal;
  totalPriceWithoutVat: Money;
  totalPriceWithVat: Money;
};
export type IssuerResponseBody = {
  name: string;
  country: string;
  city: string;
  street: string;
  zip: string;
  vatNumber: string;
  registrationNumber: string;
};
export type InvoiceDocumentTypes =
  | 'balance_invoice'
  | 'invoice'
  | 'invoice_proforma'
  | 'tax_document_for_payment'
  | 'corrective_tax_document';
export type CashRegisterDocumentResponseBody = {
  id: string;
  number: string;
  createdAt: string;
  createdById: string;
  cashRegisterId: string;
  paymentTransactionId: string | null;
  type: CashRegisterDocumentTypeEnum;
  kindOfPayment: CashRegisterDocumentKindOfPaymentEnum;
  state: CashRegisterDocumentStateEnum;
  issuedAt: string;
  isTaxDocument: boolean;
  cancelledDocumentId: string | null;
  invoiceNumber: string | null;
  relatedInvoicingDocumentId?: string;
  relatedInvoicingDocumentNumber?: string;
  relatedInvoicingDocumentType?: InvoiceDocumentTypes;
  variableSymbol: string | null;
  customerId: string | null;
  purpose: string | null;
  note: string | null;
  cashRegisterBalanceBefore: Money | null;
  cashRegisterBalanceAfter: Money | null;
  totalPriceWithoutVat: Money;
  totalPriceWithVat: Money;
  items: CashRegisterDocumentItemResponseBody[];
  issuer: IssuerResponseBody;
  cashReceiptSummary: TaxDocumentCalculationResponseBody;
  exchangeRateRatioCashReceiptSummary: TaxDocumentCalculationResponseBody;
  paymentMethod: 'BANK_TRANSFER' | 'CARD' | 'CASH' | 'CASH_ON_DELIVERY';
  exchangeRateRatio:
    | {
        amount: number;
        ratio: string;
        currency: string;
      }
    | Nullish;
};
export type CancelCashRegisterDocumentRequestBody = {
  issuedAt: string;
};
export type CreateCashRegisterDocumentItemRequestBody = {
  description: string;
  unitPrice: Money;
  unit: string;
  withVat: boolean;
  vatType: VatType;
  quantity: BigDecimal;
};
export type CreateCashRegisterDocumentRequestBody = {
  cashRegisterId: string;
  billingInformationId: string;
  type: CashRegisterDocumentTypeEnum;
  kindOfPayment: CashRegisterDocumentKindOfPaymentEnum;
  issuedAt: string;
  variableSymbol?: string | null;
  customerId?: string | null;
  purpose?: string | null;
  note?: string | null;
  paidByCard: boolean;
  relatedInvoicingDocumentId: string | null;
  relatedInvoicingDocumentType: InvoiceDocumentTypes | null;
  items: CreateCashRegisterDocumentItemRequestBody[];
  branchId: string | null;
  exchangeRateRatio:
    | {
        amount: number;
        ratio: string;
        currency: string;
      }
    | Nullish;
};
export type DocumentRestrictionTypeEnum = 'off' | 'disallow' | 'notice';
export type DocumentRestrictionResponseBody = {
  amount: Money;
  type: DocumentRestrictionTypeEnum;
  validFrom: string;
  documentTypes: CashRegisterDocumentTypeEnum[];
};
export type DocumentLimitDefinitionResponseBody = {
  id: string;
  branchId: string | null;
  restrictions: DocumentRestrictionResponseBody[];
  system: boolean;
  currency: string | null;
};
export type CreateDocumentRestrictionRequestBody = {
  amount: Money;
  type: DocumentRestrictionTypeEnum;
  validFrom: string;
  documentTypes: CashRegisterDocumentTypeEnum[];
};
export type CreateDocumentLimitDefinitionRequestBody = {
  branchId: string | null;
  restrictions: CreateDocumentRestrictionRequestBody[];
};
export type UpdateDocumentRestrictionRequestBody = {
  amount: Money;
  type: DocumentRestrictionTypeEnum;
  validFrom: string;
  documentTypes: CashRegisterDocumentTypeEnum[];
};
export type UpdateDocumentLimitDefinitionRequestBody = {
  restrictions: UpdateDocumentRestrictionRequestBody[];
};

export type VatCheckTotalsDefinitionDocumentTypeEnum = 'cash-receipts' | 'invoices';
export type VatCheckTotalsDefinitionResponseBody = {
  id: string;
  branchId: string | null;
  documentType: VatCheckTotalsDefinitionDocumentTypeEnum;
  isEnabled: boolean;
  isSystem: boolean;
};
export type VatCheckTotalsDefinitionRequestBody = {
  branchId: string;
  documentType: VatCheckTotalsDefinitionDocumentTypeEnum;
  isEnabled: boolean;
};

export type CreateVatCalculationDefinitionRequestBody = {
  branchId: string;
  calculationType: VatCalculationDefinitionCalculationTypeEnum;
};

export type CreateVatCalculationDefinitionApiArg = CreateVatCalculationDefinitionRequestBody;

export type VatCalculationDefinitionCalculationTypeEnum = 'from-above' | 'from-below' | 'sum';

export type VatCalculationDefinitionResponseBody = {
  id: string;
  branchId: string | null;
  calculationType: VatCalculationDefinitionCalculationTypeEnum;
  isSystem: boolean;
};

export type PostNegativeDocumentCancellationApiArg = {
  isActive: boolean;
  branchId: string;
};
export type UpdateNegativeDocumentCancellationApiArg = {
  isActive: boolean;
  branchId: string;
  id: string;
};
export type UpdateNegativeDocumentCancellationApiRes = {
  id: string;
};
export type GetNegativeDocumentCancellationApiRes = {
  isActive: boolean;
  branchId: string;
  isSystem?: boolean;
};
export type GetNegativeDocumentCancellationApiArg = {
  id: string;
};
export type PostNegativeDocumentCancellationApiRes = {
  id: string;
};

export type DeleteNegativeDocumentCancellationApiArg = {
  id: string;
};

export type DeleteNegativeDocumentCancellationApiRes = unknown;

export type CreateInvoiceMarginApiRes = unknown;
export type CreateInvoiceMarginApiArg = {
  branchId: string;
  buyNotVatDeductible: boolean;
  buyVatDeductible: boolean;
  brokerageNotVatDeductible: boolean;
  hideStandardInvoicesWarehouseNotVatDeductible: boolean;
  hideStandardInvoicesWarehouseVatDeductible: boolean;
  hideStandardInvoicesBrokerageNotVatDeductible: boolean;
};

export type EditInvoiceMarginApiRes = unknown;
export type EditInvoiceMarginApiArg = {
  id: string;
  branchId: string;
  buyNotVatDeductible: boolean;
  buyVatDeductible: boolean;
  brokerageNotVatDeductible: boolean;
  hideStandardInvoicesWarehouseNotVatDeductible: boolean;
  hideStandardInvoicesWarehouseVatDeductible: boolean;
  hideStandardInvoicesBrokerageNotVatDeductible: boolean;
};

export type DeleteInvoiceMarginApiRes = unknown;
export type DeleteInvoiceMarginApiArg = {
  id: string;
};

export type PostProformaGenerationApiArg = {branchId: string; unify: boolean};
export type GetProformaGenerationListApiArg = void;
export type GetProformaGenerationListApiRes = PostProformaGenerationApiRes[];
export type PostProformaGenerationApiRes = {
  branchId: string;
  unify: boolean;
  isSystem: boolean;
  id: string;
};
export type GetProformaGenerationApiArg = {id: string};
export type GetProformaGenerationApiRes = {
  branchId: string;
  unify: boolean;
  isSystem: boolean;
  id: string;
};
export type DeleteProformaGenerationApiArg = {id: string};
export type DeleteProformaGenerationApiRes = unknown;
export type PutProformaGenerationApiArg = {id: string; branchId: string; unify: boolean};
export type PutProformaGenerationApiRes = {
  branchId: string;
  unify: boolean;
  isSystem: boolean;
  id: string;
};

export type GetInvoiceMarginsApiRes = GetInvoiceMarginApiRes[];
export type GetInvoiceMarginApiRes = {
  id: string;
  branchId: string;
  buyNotVatDeductible: boolean;
  buyVatDeductible: boolean;
  brokerageNotVatDeductible: boolean;
  hideStandardInvoicesWarehouseNotVatDeductible: boolean;
  hideStandardInvoicesWarehouseVatDeductible: boolean;
  hideStandardInvoicesBrokerageNotVatDeductible: boolean;
  isSystem: boolean;
};
export type GetInvoiceMarginApiArg = {
  id: string;
};

export type CreateVatCalculationDefinitionApiResponse =
  /** status 201  */ VatCalculationDefinitionResponseBody;

export type CreateVatCheckTotalsDefinitionApiResponse =
  /** status 201  */ VatCheckTotalsDefinitionResponseBody;
export type CreateVatCheckTotalsDefinitionApiArg = {
  vatCheckTotalsDefinitionRequestBody: VatCheckTotalsDefinitionRequestBody;
};
export type GetVatCheckTotalsDefinitionListApiResponse =
  /** status 200  */ VatCheckTotalsDefinitionResponseBody[];
export type GetVatCheckTotalsDefinitionListApiArg = void;
export type DeleteVatCheckTotalsDefinitionApiResponse = unknown;
export type DeleteVatCheckTotalsDefinitionApiArg = {
  id: string;
};
export type UpdateVatCheckTotalsDefinitionApiResponse =
  /** status 200  */ VatCheckTotalsDefinitionResponseBody;
export type UpdateVatCheckTotalsDefinitionApiArg = {
  id: string;
  vatCheckTotalsDefinitionRequestBody: VatCheckTotalsDefinitionRequestBody;
};
export type GetVatCheckTotalsDefinitionApiResponse =
  /** status 200  */ VatCheckTotalsDefinitionResponseBody;
export type GetVatCheckTotalsDefinitionApiArg = string;

// ------------------- File storage API ------------------

export type UploadFileRequestArg = {
  file: File;
  onProgress?: onProgressType;
};

export type FileInfoResponse = {
  fileId: string;
  uploadUri: string;
};

export type FileUriResponse = {
  originalUri: string;
};

export type UploadFileResponse = {
  fileId: string;
  fileUri: string;
  signedUrl: string;
  file: File;
};

// ------------------- Gdpr API ------------------
export type CreateConsentTypeApiResponse = /** status 201  */ ConsentType;
export type CreateConsentTypeApiArg = {
  createConsentTypeRequestBody: CreateConsentTypeRequestBody;
};
export type GetConsentTypeApiArg = {
  consentTypeId: string;
};
export type GetConsentTypesApiResponse = /** status 200  */ ConsentType[];
export type GetConsentTypeApiResponse = /** status 200  */ ConsentType;

export enum ConsentSubject {
  TENANT = 'tenant',
  BRANCH = 'branch',
}

export enum ConsentDuration {
  LIFETIME = 'lifetime',
  SIX_MONTHS = 'six_months',
  ONE_YEAR = 'one_year',
  TWO_YEARS = 'two_years',
}

export enum ConsentChannel {
  PHONE = 'phone',
  SMS = 'sms',
  EMAIL = 'email',
  MAIL = 'mail',
}

export enum ConsentStatus {
  AGREED = 'agreed',
  EXPIRED = 'expired',
  REJECTED = 'rejected',
  WITHDRAWN = 'withdrawn',
}

export type ConsentType = {
  id: string;
  name: string;
  subject: ConsentSubject;
  branchId: string | null;
  duration: ConsentDuration;
  channels: ConsentChannel[];
  templates: ConsentTemplate[];
};

export type ConsentTemplate = {
  title: string;
  isPrimary: boolean;
  fileUri: string;
  fileName: string;
  fileId?: string;
};

export type CreateTemplateRequestBody = {
  title: string;
  isPrimary: boolean;
  fileId: string;
};

export type CreateConsentTypeRequestBody = {
  name: string;
  subject: ConsentSubject;
  branchId: string | null;
  duration: ConsentDuration;
  channels: ConsentChannel[];
  templates: CreateTemplateRequestBody[];
};

export type EditTemplateRequestBody = {
  title: string;
  fileId: string;
  isPrimary: boolean;
};
export type EditConsentTypeRequestBody = {
  name: string;
  subject: ConsentSubject;
  branchId: string | null;
  duration: ConsentDuration;
  channels: ConsentChannel[];
  templates: EditTemplateRequestBody[];
};
export type EditConsentTypeApiResponse = unknown;
export type EditConsentTypeApiArg = {
  consentTypeId: string;
  editConsentTypeRequestBody: EditConsentTypeRequestBody;
};

export type DeleteConsentTypeApiResponse = unknown;
export type DeleteConsentTypeApiArg = {
  consentTypeId: string;
};

export type ConsentResponseBody = {
  id: string;
  name: string;
  status: ConsentStatus;
  validUntil: string | null;
  channels: ConsentChannel[];
  customerId: string;
  fileName: string;
  fileUri: string;
  createdAt: string;
  createdBy: string;
};
export type GetConsentListApiResponse = /** status 200  */ ConsentResponseBody[];
export type GetConsentListApiArg = {
  customerId?: string;
};

export type DeleteConsentApiResponse = unknown;
export type DeleteConsentApiArg = {
  consentId: string;
  customerId: string;
};

export type CreateConsentRequestBody = {
  customerContractInformationId: string;
  consentTypeId: string;
  templateTitle: string;
  templateFileId: string;
  customerId: string;
  businessCaseId: string | null;
};
export type BatchCreateConsentApiResponse = /** status 201  */ ConsentResponseBody[];
export type BatchCreateConsentApiArg = {
  body: CreateConsentRequestBody[];
  customerId: string;
};

export type AgreeConsentApiResponse = unknown;
export type AgreeConsentApiArg = {
  consentId: string;
  customerId: string;
};
export type RejectConsentApiResponse = unknown;
export type RejectConsentApiArg = {
  consentId: string;
  customerId: string;
};
export type WithdrawConsentApiResponse = unknown;
export type WithdrawConsentApiArg = {
  consentId: string;
  customerId: string;
};

type ParticipationUserType = {type: string; userId: string; organizationalUnitId?: string};
export type GetParticipationApiResponse = {
  assignees: ParticipationUserType[];
  owner: ParticipationUserType;
  author: Omit<ParticipationUserType, 'organizationalUnitId'>;
};

export enum EntityResourceIds {
  vehicle = 'VEHICLE',
  businessCase = 'BUSINESS_CASE',
  customer = 'CUSTOMER',
  interest = 'INTEREST',
  serviceCase = 'SERVICE_CASE',
  serviceOrder = 'SERVICE_ORDER',
  tireOrder = 'TIRE_ORDER',
  directSales = 'DIRECT_SALES',
  serviceOrderIssues = 'SERVICE_ORDER_ISSUES',
  serviceOrderReturn = 'SERVICE_ORDER_RETURN',
  checkout = 'CHECKOUT',
  receiveNote = 'RECEIVE_NOTE',
  receiveNoteCorrection = 'RECEIVE_NOTE_CORRECTION',
  deliveryNote = 'DELIVERY_NOTE',
  employee = 'EMPLOYEE',
  task = 'TASK',
}

export type GetParticipationApiArg = {
  resourceId: EntityResourceIds;
  recordId: string;
};

export type DefaultAssignmentApiArg = {
  participationId: string;
  userId: string;
};

// ------------------- Cebia API ------------------

export type GetAutoTracerUrlApiResponse = /** status 200  */ CebiaUrlResponseBody;
export type GetAutoTracerUrlApiArg = {
  vin?: string;
  vehicleMark?: string;
  vehicleModel?: string;
  mileage?: number;
  makeYear?: number;
  authorization?: string;
};

export type GetLastAutotracerReportApiResponse =
  /** status 200  */ CebiaAutotracerReportResponseBody;
export type GetLastAutotracerReportApiArg = {
  vehicleId: string;
};

export type GetCebiaReportsApiResponse = /** status 200  */ CebiaReportResponseBody[];
export type GetCebiaReportsApiArg = {
  vehicleId: string;
};

export type GetManufactureYearApiResponse = /** status 200  */ ManufactureYearResponseBody;
export type GetManufactureYearApiArg = {
  vin: string;
  authorization?: string;
};

export type CebiaUrlResponseBody = {
  url: string;
};

export type CebiaReportResponseBody = {
  id: string;
  url: string;
  isOverdue: boolean;
  createdAt: string;
  createdBy: string | null;
};

export type ManufactureYearResponseBody = {
  manufacturedOnMonth: number | null;
  manufacturedOnYear: number | null;
};

export type CebiaRokvyCredentials = {
  clientId: string;
  clientSecret: string;
  username: string;
  password: string;
};
export type GetCebiaRokvyConfigApiResponse = /** status 200  */ CebiaRokvyConfigResponseBody;

export enum PatchRegisterRouteRequestType {
  documentRegister = 'invalid-document-register',
  euVies = 'eu-vies',
  distraintRegister = 'distraint-register',
  insolvencyRegister = 'insolvency-register',
  unreliableVatPayerRegister = 'unreliable-vat-payer-register',
}

export type GetRegisterApiResponse = {
  enabled: boolean;
};

export type GetDistraintRegisterApiResponse = GetRegisterApiResponse & {
  credentials: {
    apiKey: string;
  };
};

export type DistraintRegisterCredentials = {
  apiKey: string;
};

export type DistraintRegisterConfigRequestBody = {
  enabled?: boolean;
  credentials?: DistraintRegisterCredentials;
};

export type PatchDistraintRegisterConfigApiArg = {
  body: DistraintRegisterConfigRequestBody;
};

export type EuViesConfigRequestBody = {
  enabled?: boolean;
};

export type PatchEuViesConfigApiArg = {
  body: EuViesConfigRequestBody;
};

export type InsolvencyRegisterConfigRequestBody = {
  enabled?: boolean;
};

export type PatchInsolvencyRegisterConfigApiArg = {
  body: InsolvencyRegisterConfigRequestBody;
};

export type InvalidDocumentRegisterConfigRequestBody = {
  enabled?: boolean;
};

export type PatchInvalidDocumentRegisterConfigApiArg = {
  body: InvalidDocumentRegisterConfigRequestBody;
};

export type UnreliableVatPayerRegisterConfigRequestBody = {
  enabled?: boolean;
};

export type PatchUnreliableVatPayerRegisterConfigApiArg = {
  body: UnreliableVatPayerRegisterConfigRequestBody;
};

export type AttachmentFileRequestBody = {
  fileId: string;
  sendAsPdf?: boolean;
};
export type SendEmailRequestBody = {
  to?: string[];
  from?: string | null;
  subject: string;
  body: string;
  cc?: string[];
  bcc?: string[];
  attachmentFiles?: (AttachmentFileRequestBody | null)[];
};

export type GetJbrConfigApiResponse = /** status 200  */ JbrConfigResponseBody;
export type PatchJbrConfigApiResponse = unknown;
export type PatchJbrConfigApiArg = {
  jbrConfigRequestBody: JbrConfigRequestBody;
};
export type JbrCredentials = {
  username: string;
  password: string;
  url: string;
};
export type JbrConfigResponseBody = {
  enabled: boolean;
  credentials: JbrCredentials | null;
};
export type JbrConfigRequestBody = {
  enabled?: boolean | null;
  credentials?: JbrCredentials | null;
};

export type GetEmployeePhotoApiResponse = /** status 200  */ EmployeePhotoResponseBody;
export type GetEmployeePhotoApiArg = {
  employeeId: string;
  /** User JWT tokens are allowed */
  authorization?: string;
};
export type GetEmployeeAvatarApiResponse = string;
export type GetEmployeeAvatarApiArg = {employeeId: string};

export type SetEmployeePhotoApiResponse = unknown;
export type SetEmployeePhotoApiArg = {
  employeeId: string;
  /** User JWT tokens are allowed */
  authorization?: string;
  setEmployeePhotoRequestBody: SetEmployeePhotoRequestBody;
};
export type EmployeePhotoResponseBody = {
  fileId: string;
  filename: string;
  uri: string;
};

export type SetEmployeePhotoRequestBody = {
  fileId: string | null;
};

export type PatchCebiaRokvyConfigApiResponse = unknown;
export type PatchCebiaRokvyConfigApiArg = {
  cebiaRokvyConfigRequestBody: CebiaRokvyConfigRequestBody;
};
export type CebiaRokvyConfigResponseBody = {
  enabled: boolean;
  credentials: CebiaRokvyCredentials | null;
};
export type CebiaRokvyConfigRequestBody = {
  enabled?: boolean | null;
  credentials?: CebiaRokvyCredentials | null;
};

// ------------------- Promotional photo API ------------------

export type CreatePromotionalPhotoApiResponse = /** status 200  */ PromotionalPhotoResponseBody;
export type CreatePromotionalPhotoApiArg = {
  createPromotionalPhotoRequestBody: CreatePromotionalPhotoRequestBody;
};
export type DeletePromotionalPhotoApiResponse = unknown;
export type DeletePromotionalPhotoApiArg = {
  deletePromotionalPhotoRequestBody: DeletePromotionalPhotoRequestBody;
};
export type EditPromotionalPhotoApiResponse = /** status 200  */ PromotionalPhotoResponseBody;
export type EditPromotionalPhotoApiArg = {
  promotionalPhotoId: string;
  editPromotionalPhotoRequestBody: EditPromotionalPhotoRequestBody;
};
export type GetPromotionalPhotoApiResponse = /** status 200  */ PromotionalPhotoResponseBody;
export type GetPromotionalPhotoApiArg = {
  promotionalPhotoId: string;
};
export type GetSupportedPlatformsApiResponse =
  /** status 200  */ PromotionalPhotoSupportedPlatformsResponseBody;
export type AddToAllVehicleApiResponse = unknown;
export type AddToAllVehicleApiArg = {
  addToAllVehiclesRequestBody: AddToAllVehiclesRequestBody;
};
export type AddPromoPhotosToVehicleApiResponse = /** status 200  */ VehiclePromoPhotoResponseBody[];
export type AddPromoPhotosToVehicleApiArg = {
  addToVehicleRequestBody: AddToVehicleRequestBody;
};
export type GetPromoPhotoForVehicleApiResponse = /** status 200  */ VehiclePromoPhotoResponseBody[];
export type GetPromoPhotoForVehicleApiArg = {
  vehicleId: string;
};
export type RemoveInAllVehiclesApiResponse = unknown;
export type RemoveInAllVehiclesApiArg = {
  removeInAllVehiclesRequestBody: RemoveInAllVehiclesRequestBody;
};
export type RemovePromoPhotoInVehicleApiResponse = unknown;
export type RemovePromoPhotoInVehicleApiArg = {
  vehicleId: string;
  removeInVehicleRequestBody: RemoveInVehicleRequestBody;
};
export type ResetPositionsInVehicleApiResponse = /** status 200  */ VehiclePromoPhotoResponseBody[];
export type ResetPositionsInVehicleApiArg = {
  resetPositionsInVehicleRequestBody: ResetPositionsInVehicleRequestBody;
};
export type ResetPositionsToAllVehiclesApiResponse = unknown;
export type ResetPositionsToAllVehiclesApiArg = {
  resetPositionsToAllVehiclesRequestBody: ResetPositionsToAllVehiclesRequestBody;
};
export type UpdatePositionsInVehicleApiResponse = unknown;
export type UpdatePositionsInVehicleApiArg = {
  vehicleId: string;
  updatePositionsInVehicleRequestBody: UpdatePositionsInVehicleRequestBody;
};
export type PromotionalPhotoResponseBody = {
  id: string;
  title: string;
  position: number;
  fileId: string;
  remoteFileId: string;
  url: string;
};
export type CreatePromotionalPhotoRequestBody = {
  title: string;
  position: number;
  fileId: string;
};
export type ListThemeApiResponse = /** status 200  */ ThemeResponseBody[];
export type LogoResponseBody = {
  imageId: string;
  url: string;
  resizeUrl: string;
};
export type ThemeResponseBody = {
  themeId: string;
  name: string;
  logo: LogoResponseBody;
  style: string;
};
export type DeletePromotionalPhotoRequestBody = {
  promotionalPhotoIds: string[];
};
export type EditPromotionalPhotoRequestBody = {
  title: string;
  position: number;
  fileId: string;
};
export type PromotionalPhotoSupportedPlatformsResponseBody = {
  codes: AdvertisingPlatformCodeEnum[];
};
export type AddToAllVehiclesRequestBody = {
  promotionalPhotoIds: string[];
};
export type GetPromotionalPhotosApiResponse = /** status 200  */ PromotionalPhotoResponseBody[];
export type VehiclePromoPhotoResponseBody = {
  id: string;
  position: number;
  positionInSettings: number;
  fileId: string;
  remoteFileId: string;
  url: string;
};
export type AddToVehicleRequestBody = {
  vehicleId: string;
};
export type RemoveInAllVehiclesRequestBody = {
  promotionalPhotoIds: string[];
};
export type RemoveInVehicleRequestBody = {
  vehiclePromoPhotoId: string;
};
export type ResetPositionsInVehicleRequestBody = {
  vehicleId: string;
};
export type ResetPositionsToAllVehiclesRequestBody = {
  promotionalPhotoIds: string[];
};
export type UpdatePositionInVehicleRequestBody = {
  vehiclePromoPhotoId: string;
  position: number;
};
export type UpdatePositionsInVehicleRequestBody = {
  positions: UpdatePositionInVehicleRequestBody[];
};

// ------------------- Vehicle API ------------------
export type DimensionsSpecificationResponseBody = {
  width?: number | null;
  height?: number | null;
  length?: number | null;
};

export type DimensionsResponseBody = {
  dimensions: DimensionsSpecificationResponseBody | null;
  operatingWeight: number | null;
  weight: number | null;
  maximalLoadCapacity: number | null;
  wheelBase: number | null;
  trunkSpace: number | null;
  height?: string | null;
  width?: string | null;
  length?: string | null;
  loadingArea?: string | null;
  dimensionsLoadingSpace?: string | null;
  lengthCategory?: string | null;
  heightCategory?: string | null;
};

export type EngineResponseBody = {
  engineName: string | null;
  engineCode: string | null;
  engineNumber: string | null;
  engineVolume: number | null;
  cylinderCount: number | null;
  gearCount: number | null;
  hasDpf: boolean | null;
  fuelConsumptionCombined: number | null;
  fuelConsumptionUrban: number | null;
  fuelConsumptionExtraUrban: number | null;
  emissionClass: string | null;
  carbonDioxideEmission: number | null;
  maxTorque: string | null;
  powerConsumptionCombined: number | null;
  powerCombustionEngine: number | null;
  powerElectricEngine: number | null;
  electricEngineNote: string | null;
  maximalPower?: number | null;
};

export type ModelSpecificationResponseBody = {
  seatCount: number | null;
  originCountry: string | null;
  firstRegistrationCountry: string | null;
  lastRegistrationCountry: string | null;
  warrantyDate: string | null;
  warrantyMileage: number | null;
  isRegistered: boolean | null;
  serviceContractUntil: string | null;
  serviceContractMileageLimit: number | null;
  serviceContractNote: string | null;
};

export type FullStateResponseBody = {
  mileage: number | null;
  ownerCount: number | null;
  primaryKeyCount: number | null;
  technicalInspectionValidUntil: string | null;
  gasRevisionValidUntil: string | null;
  pressureVesselValidUntil: string | null;
  technicalInspectionNote: string | null;
  registrationPlate: string | null;
  condition: string | null;
  isAcceptableForTechnicalInspection: boolean | null;
  hasCoc: boolean | null;
  realMileage: number | null;
  vehicleRegistrationNumber: string | null;
  registrationCertificateNumber: string | null;
  serviceBookType: string | null;
  serviceBookState: string | null;
  lastServiceDate: string | null;
  lastServiceMileage: number | null;
  nextServiceDate: string | null;
  nextServiceMileage: number | null;
  otherRecords: string | null;
};

export type AdditionalInformationResponseBody = {
  exteriorColor?: string | null;
  exteriorColorSpecification?: string | null;
  exteriorColorFactoryName?: string | null;
  interiorColor?: string | null;
  interiorMaterial?: string | null;
  manufacturerColorCode?: string | null;
  manufacturerColorName?: string | null;
};

export type VehicleFeatureResponseBody = {
  id: string;
  translation: string;
  key: string;
  isHighlighted: boolean;
  isPremium: boolean;
};

export type BatteryResponseBody = {
  chargingAC: number | null;
  chargingTimeAC: number | null;
  chargingDC: number | null;
  chargingTimeDC: number | null;
  batteryType: string | null;
  batteryCapacityWh: number | null;
  batteryCapacityAh: number | null;
  batteryVoltage: number | null;
  batteryCount: number | null;
  chargingConnectorType: string | null;
  electricRange: number | null;
  batteryMileageLimit: number | null;
  batteryWarrantyUntil: string | null;
  batteryOwnershipType: string | null;
  hasRangeExtender: boolean | null;
};

export type ElectricVehicleBatteryResponseBody = Optional<ElectricVehicleBattery>;

export type VehicleDataResponseBody = {
  bodyStyle: string | null;
  carbonDioxideEmission: VehicleDataDecimal | null;
  condition: string | null;
  customMake: string | null;
  customModelFamily: string | null;
  cylinderCount: number | null;
  dimensions: DimensionsResponseBody | null;
  doorCountCategory: string | null;
  serviceBookType: string | null;
  serviceBookState: string | null;
  handDriveType: string | null;
  secondaryFuelType: string | null;
  drive: string | null;
  emissionClass: string | null;
  engineCode: string | null;
  engineName: string | null;
  engineNumber: string | null;
  engineVolume: number | null;
  exteriorColor: ColorResponseBody | null;
  exteriorColorFactoryName: string | null;
  exteriorColorSpecification: string | null;
  features: VehicleFeatureResponseBody[] | null;
  featuresByManufacturer: string | null;
  firstRegistrationCountry: string | null;
  firstRegistrationOn: string | null;
  fuelConsumptionCombined: VehicleDataDecimal | null;
  fuelConsumptionExtraUrban: VehicleDataDecimal | null;
  fuelConsumptionUrban: VehicleDataDecimal | null;
  fuelType: string | null;
  hybridType: string | null;
  pressureVesselValidUntil: string | null;
  gearCount: number | null;
  hasCoc: boolean | null;
  hasDpf: boolean | null;
  hasFullServiceHistory: boolean | null;
  interiorColor: ColorResponseBody | null;
  interiorMaterial: string | null;
  isAcceptableForTechnicalInspection: boolean | null;
  isHybrid: boolean | null;
  isMovable: boolean | null;
  isPluginHybrid: boolean | null;
  isRegistered: boolean | null;
  lastRegistrationCountry: string | null;
  gasRevisionValidUntil: string | null;
  make: string | null;
  manufacturedOn: string | null;
  maxTorque: string | null;
  manufacturerColorCode: string | null;
  manufacturerColorName: string | null;
  maximalLoadCapacity: number | null;
  mileage: number | null;
  modelFamily: string | null;
  modelFamilyGroup: string | null;
  operatingWeight: number | null;
  originCountry: string | null;
  ownerCount: number | null;
  photos?: Array<VehiclePhoto>;
  power: number | null;
  powerConsumptionCombined: VehicleDataDecimal | null;
  primaryKeyCount: number | null;
  realMileage: number | null;
  registrationCertificateNumber: string | null;
  registrationCertificate1Number: string | null;
  registrationCertificate2Number: string | null;
  serviceContractNote: string | null;
  vehicleRegistrationNumber: string | null;
  registrationPlate: string | null;
  seatCount: number | null;
  technicalInspectionNote: string | null;
  technicalInspectionValidSince: string | null;
  technicalInspectionValidUntil: string | null;
  transmission: string | null;
  trim: string | null;
  type: string | null;
  variant: string | null;
  vehicle_type: string | null;
  vin: string | null;
  warrantyDate: string | null;
  lastServiceDate: string | null;
  nextServiceDate: string | null;
  serviceContractUntil: string | null;
  warrantyMileage: number | null;
  weight: number | null;
  lastServiceMileage: number | null;
  nextServiceMileage: number | null;
  serviceContractMileageLimit: number | null;
  wheelBase: number | null;
  trunkSpace: number | null;
  battery: BatteryResponseBody | null;
  otherRecords: string | null;
  electricVehicleBattery: ElectricVehicleBatteryResponseBody | null;
};

export type EquipmentResponseBody = {
  manufacturerCode?: string | null;
  description?: string | null;
  dmsCodes?: string[] | null;
};

export type VehiclePhoto = {
  id: string;
  weight: number;
  fileId: string;
  remoteFileId: string;
  albumId: string;
  isCoverPhoto: boolean;
  url: string;
};

export type VehicleTypeEnum =
  | 'VEHICLETYPE_CARAVAN'
  | 'VEHICLETYPE_PASSENGER_CAR'
  | 'VEHICLETYPE_VAN'
  | 'VEHICLETYPE_MOTORCYCLE'
  | 'VEHICLETYPE_TRUCK'
  | 'VEHICLETYPE_SEMI_TRAILER'
  | 'VEHICLETYPE_TRAILER'
  | 'VEHICLETYPE_BUS'
  | 'VEHICLETYPE_OTHER'
  | 'VEHICLETYPE_AGRICULTURAL_MACHINE'
  | 'VEHICLETYPE_CONSTRUCTION_MACHINE';

export type PatchModelSpecificationRequestBody = {
  seatCount?: number | null;
  originCountry?: string | null;
  firstRegistrationCountry?: string | null;
  lastRegistrationCountry?: string | null;
  warrantyDate?: string | null;
  warrantyMileage?: number | null;
  isRegistered?: boolean | null;
  serviceContractUntil?: string | null;
  serviceContractMileageLimit?: number | null;
  serviceContractNote?: string | null;
};
export type PatchStateRequestBody = {
  mileage?: number | null;
  ownerCount?: number | null;
  primaryKeyCount?: number | null;
  technicalInspectionValidUntil?: string | null;
  gasRevisionValidUntil?: string | null;
  pressureVesselValidUntil?: string | null;
  technicalInspectionNote?: string | null;
  registrationPlate?: string | null;
  condition?: string | null;
  isAcceptableForTechnicalInspection?: boolean | null;
  hasCoc?: boolean | null;
  realMileage?: number | null;
  vehicleRegistrationNumber?: string | null;
  registrationCertificateNumber?: string | null;
  serviceBookType?: string | null;
  serviceBookState?: string | null;
  lastServiceDate?: string | null;
  lastServiceMileage?: number | null;
  nextServiceDate?: string | null;
  nextServiceMileage?: number | null;
};
export type PatchEngineRequestBody = {
  engineNumber?: string | null;
  engineCode?: string | null;
  engineName?: string | null;
  engineVolume?: number | null;
  cylinderCount?: number | null;
  gearCount?: number | null;
  hasDpf?: boolean | null;
  fuelConsumptionCombined?: number | null;
  fuelConsumptionUrban?: number | null;
  fuelConsumptionExtraUrban?: number | null;
  emissionClass?: string | null;
  carbonDioxideEmission?: number | null;
  maxTorque?: string | null;
  electricEngineNote?: string | null;
  maximalPower?: number | null;
};
export type DimensionsSpecificationRequestBody = {
  width?: number | null;
  height?: number | null;
  length?: number | null;
};
export type PatchDimensionsRequestBody = {
  dimensions?: DimensionsSpecificationRequestBody | null;
  operatingWeight?: number | null;
  weight?: number | null;
  maximalLoadCapacity?: number | null;
  wheelBase?: number | null;
  trunkSpace?: number | null;
};
export type PatchAdditionalInformationRequestBody = {
  exteriorColor?: string | null;
  exteriorColorSpecification?: string | null;
  exteriorColorFactoryName?: string | null;
  interiorColor?: string | null;
  interiorMaterial?: string | null;
  manufacturerColorCode?: string | null;
};
export type PatchVehicleRequestBody = {
  modelSpecification?: PatchModelSpecificationRequestBody;
  state?: PatchStateRequestBody;
  engine?: PatchEngineRequestBody;
  dimensions?: PatchDimensionsRequestBody;
  additionalInformation?: PatchAdditionalInformationRequestBody;
  vin?: string | null;
  manufacturedOnMonth?: number | null;
  manufacturedOnYear?: number | null;
  sellingPrice?: string | null;
  sellingPriceWithVat?: string | null;
  sellingPriceVatRate?: string | null;
  purchasePrice?: string | null;
  purchasePriceWithVat?: string | null;
  purchasePriceVatRate?: string | null;
  currencyCode?: string | null;
  vatCode?: string | null;
  isVatDeductible?: boolean | null;
  isArchived?: boolean | null;
  trim?: string | null;
  variant?: string | null;
  keyLocation?: string | null;
};

export type SaleType = 'IN_STOCK' | 'BROKERAGE';

export type VehicleDataDecimal = string;
export type ColorResponseBody = {
  value: string | null;
  code: string | null;
  name: string | null;
};

export type ElectricVehicleBatteryV2ResponseBody = Optional<ElectricVehicleBattery>;

export type FullVehicleResponseBody = {
  id: string;
  externalId?: string | null;
  branchId: string;
  publicId?: string | null;
  dimensions?: DimensionsResponseBody | null;
  engine?: EngineResponseBody | null;
  ownerships?: object[];
  modelSpecification?: ModelSpecificationResponseBody | null;
  state?: FullStateResponseBody | null;
  additionalInformation?: AdditionalInformationResponseBody | null;
  features?: VehicleFeatureResponseBody[];
  featuresByManufacturer?: string | null;
  photos?: VehiclePhotoResponseBody[];
  albums?: VehicleAlbumResponseBody[];
  vin?: string | null;
  make: string;
  modelFamily: string;
  axleCount?: number | null;
  europalletStorageSpaces?: number | null;
  trim?: string | null;
  variant?: string | null;
  type?: VehicleTypeEnum;
  fuelType?: string | null;
  secondaryFuelType?: string | null;
  handDriveType?: string | null;
  transmission?: string | null;
  doorCountCategory?: string | null;
  bodyStyle?: string | null;
  drive?: string | null;
  power?: number | null;
  firstRegistrationOnDay?: number | null;
  firstRegistrationOnMonth?: number | null;
  firstRegistrationOnYear?: number | null;
  manufacturedOnMonth?: number | null;
  manufacturedOnYear?: number | null;
  saleState: SaleStateEnum | null;
  saleType: SaleTypeEnum | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isArchived?: boolean | null;
  sellingPrice: PriceResponseBody;
  purchasePrice: PriceResponseBody;
  customMake?: string | null;
  customModelFamily?: string | null;
  isVatDeductible?: boolean | null;
  vehicle: VehicleDataResponseBody;
  title: string;
  customId: string | null;
  keyLocation?: string | null;
  participationId: string;
  hybridType?: string | null;
  vehicleSourceCodeId?: string | null;
  electricVehicleBattery?: ElectricVehicleBatteryResponseBody | null;
};

export type VehicleType =
  | 'VEHICLETYPE_CARAVAN'
  | 'VEHICLETYPE_PASSENGER_CAR'
  | 'VEHICLETYPE_VAN'
  | 'VEHICLETYPE_MOTORCYCLE'
  | 'VEHICLETYPE_TRUCK'
  | 'VEHICLETYPE_SEMI_TRAILER'
  | 'VEHICLETYPE_TRAILER'
  | 'VEHICLETYPE_BUS'
  | 'VEHICLETYPE_OTHER'
  | 'VEHICLETYPE_AGRICULTURAL_MACHINE'
  | 'VEHICLETYPE_CONSTRUCTION_MACHINE';

export type GetVehicleApiResponse = /** status 200  */ FullVehicleResponseBody;
export type ColorRequestBody = {
  value: string | null;
  code: string | null;
  name: string | null;
};
export type FeatureRequestBody = {
  key: string;
  isHighlighted?: boolean | null;
  isPremium?: boolean | null;
};
export type ElectricVehicleBatteryV2RequestBody = Optional<ElectricVehicleBattery>;

export type LastActiveServiceCaseApiArg = {vehicleId: string};
export type LastActiveServiceCaseApiRes = {
  id: string | null;
  number: string | null;
  state: string | null;
  ordersState: string | null;
  name: string | null;
  vehicleId: string | null;
  customerId: string | null;
  description: string | null;
  markers: string[] | null;
  createdAt: string | null;
  createdBy: string | null;
  updatedAt: string | null;
  updatedBy: string | null;
  closedAt: string | null;
  closedBy: string | null;
};
export type CustomerLastActiveServiceCaseApiArg = {customerId: string};
export type CustomerLastActiveServiceCaseApiRes = {
  id: string | null;
  number: string | null;
  state?:
    | 'DEMAND'
    | 'DRAFT'
    | 'HANDED_OVER'
    | 'PLANNED'
    | 'CANCELLED'
    | 'CHECKED_IN'
    | 'CREATED'
    | null;
  ordersState?:
    | 'CANCELLED'
    | 'CLOSED'
    | 'IN_PROGRESS'
    | 'LOCKED'
    | 'OFFER'
    | 'OPEN'
    | 'PAUSED'
    | 'PLANNED'
    | 'REQUEST'
    | 'WORK_DONE'
    | 'DRAFT'
    | null;
  name: string | null;
  vehicleId: string | null;
  customerId: string | null;
  description: string | null;
  markers: string[] | null;
  createdAt: string | null;
  createdBy: string | null;
  updatedAt: string | null;
  updatedBy: string | null;
  closedAt: string | null;
  closedBy: string | null;
};

export type PatchAdvertisedVehicleInAdvertisementUpdatedAtApiResponse = unknown;
export type PatchAdvertisedVehicleInAdvertisementUpdatedAtApiArg = {
  vehicleId: string;
  patchAdvertisedVehicleInAdvertisementUpdatedAtRequestBody: PatchAdvertisedVehicleInAdvertisementUpdatedAtRequestBody;
};
export type PatchAdvertisedVehicleInAdvertisementUpdatedAtRequestBody = {
  inAdvertisementUpdatedAt: string;
};

export type CreateServiceVehicleApiArg = {vehicleId: string};
export type CreateServiceVehicleApiRes = {serviceVehicleId: string};
export type GetServiceVehicleApiArg = {vehicleId: string};
export type GetServiceVehicleApiRes = {serviceVehicleId: string};
export type CreateVehicleV2ApiArg = {
  externalId?: string | null;
  title?: string | null;
  vehicleId?: string | null;
  bodyStyle?: string | null;
  carbonDioxideEmission?: VehicleDataDecimal | null;
  condition?: string | null;
  customMake?: string | null;
  customModelFamily?: string | null;
  cylinderCount?: number | null;
  dimensions?: DimensionsRequestBody | null;
  doorCountCategory?: string | null;
  drive?: string | null;
  emissionClass?: string | null;
  engineCode?: string | null;
  engineName?: string | null;
  engineNumber?: string | null;
  engineVolume?: number | null;
  exteriorColor?: ColorRequestBody | null;
  serviceBookType?: string | null;
  serviceBookState?: string | null;
  handDriveType?: string | null;
  secondaryFuelType?: string | null;
  exteriorColorFactoryName?: string | null;
  exteriorColorSpecification?: string | null;
  firstRegistrationCountry?: string | null;
  firstRegistrationOn?: string | null;
  fuelConsumptionCombined?: VehicleDataDecimal | null;
  fuelConsumptionExtraUrban?: VehicleDataDecimal | null;
  fuelConsumptionUrban?: VehicleDataDecimal | null;
  fuelType?: string | null;
  hybridType?: string | null;
  pressureVesselValidUntil?: string | null;
  gearCount?: number | null;
  hasCoc?: boolean | null;
  hasDpf?: boolean | null;
  interiorColor?: ColorRequestBody | null;
  interiorMaterial?: string | null;
  isAcceptableForTechnicalInspection?: boolean | null;
  isRegistered?: boolean | null;
  lastRegistrationCountry?: string | null;
  gasRevisionValidUntil?: string | null;
  make: string;
  manufacturedOn?: string | null;
  maxTorque?: string | null;
  manufacturerColorCode?: string | null;
  manufacturerColorName?: string | null;
  maximalLoadCapacity?: number | null;
  mileage?: number | null;
  modelFamily: string;
  modelFamilyGroup?: string | null;
  operatingWeight?: number | null;
  originCountry?: string | null;
  ownerCount?: number | null;
  power?: number | null;
  powerConsumptionCombined?: VehicleDataDecimal | null;
  powerCombustionEngine?: VehicleDataDecimal | null;
  powerElectricEngine?: VehicleDataDecimal | null;
  primaryKeyCount?: number | null;
  realMileage?: number | null;
  registrationCertificateNumber?: string | null;
  otherRecords?: string | null;
  serviceContractNote?: string | null;
  vehicleRegistrationNumber?: string | null;
  registrationPlate?: string | null;
  seatCount?: number | null;
  standingPlaceCount?: number | null;
  bedCount?: number | null;
  axleCount?: number | null;
  europalletStorageSpaces?: number | null;
  lowDeck?: boolean | null;
  maximalAxleLoad?: number | null;
  maximalRoofLoad?: number | null;
  grossCombinationWeight?: number | null;
  dimensionsLoadingSpace?: DimensionsRequestBody | null;
  loadingCapacity?: number | null;
  technicalInspectionNote?: string | null;
  technicalInspectionValidSince?: string | null;
  technicalInspectionValidUntil?: string | null;
  transmission?: string | null;
  trim?: string | null;
  type: VehicleTypeEnum;
  variant?: string | null;
  vin?: string | null;
  warrantyDate?: string | null;
  lastServiceDate?: string | null;
  nextServiceDate?: string | null;
  serviceContractUntil?: string | null;
  warrantyMileage?: number | null;
  weight?: number | null;
  lastServiceMileage?: number | null;
  nextServiceMileage?: number | null;
  serviceContractMileageLimit?: number | null;
  wheelBase?: number | null;
  trunkSpace?: number | null;
  features?: FeatureRequestBody[] | null;
  chargingAC?: number | null;
  chargingTimeAC?: number | null;
  chargingDC?: number | null;
  chargingTimeDC?: number | null;
  batteryType?: string | null;
  batteryCapacityWh?: number | null;
  batteryCapacityAh?: number | null;
  batteryVoltage?: number | null;
  batteryCount?: number | null;
  chargingConnectorType?: string | null;
  electricRange?: number | null;
  batteryMileageLimit?: number | null;
  batteryWarrantyUntil?: string | null;
  batteryOwnershipType?: string | null;
  hasRangeExtender?: boolean | null;
  electricEngineNote?: string | null;
  maximalPower?: number | null;
  electricVehicleBattery?: ElectricVehicleBatteryV2RequestBody | null;
};
export type UpdateVehicleV2ApiArg = {
  id: string;
  externalId?: string | null;
  title?: string | null;
  vehicleId?: string | null;
  bodyStyle?: string | null;
  carbonDioxideEmission?: VehicleDataDecimal | null;
  condition?: string | null;
  customMake?: string | null;
  customModelFamily?: string | null;
  cylinderCount?: number | null;
  dimensions?: DimensionsRequestBody | null;
  doorCountCategory?: string | null;
  drive?: string | null;
  emissionClass?: string | null;
  engineCode?: string | null;
  engineName?: string | null;
  engineNumber?: string | null;
  engineVolume?: number | null;
  exteriorColor?: ColorRequestBody | null;
  serviceBookType?: string | null;
  serviceBookState?: string | null;
  handDriveType?: string | null;
  secondaryFuelType?: string | null;
  exteriorColorFactoryName?: string | null;
  exteriorColorSpecification?: string | null;
  firstRegistrationCountry?: string | null;
  firstRegistrationOn?: string | null;
  fuelConsumptionCombined?: VehicleDataDecimal | null;
  fuelConsumptionExtraUrban?: VehicleDataDecimal | null;
  fuelConsumptionUrban?: VehicleDataDecimal | null;
  fuelType?: string | null;
  hybridType?: string | null;
  pressureVesselValidUntil?: string | null;
  gearCount?: number | null;
  hasCoc?: boolean | null;
  hasDpf?: boolean | null;
  interiorColor?: ColorRequestBody | null;
  interiorMaterial?: string | null;
  isAcceptableForTechnicalInspection?: boolean | null;
  isRegistered?: boolean | null;
  lastRegistrationCountry?: string | null;
  gasRevisionValidUntil?: string | null;
  make: string;
  manufacturedOn?: string | null;
  maxTorque?: string | null;
  manufacturerColorCode?: string | null;
  manufacturerColorName?: string | null;
  maximalLoadCapacity?: number | null;
  mileage?: number | null;
  modelFamily: string;
  modelFamilyGroup?: string | null;
  operatingWeight?: number | null;
  originCountry?: string | null;
  ownerCount?: number | null;
  power?: number | null;
  powerConsumptionCombined?: VehicleDataDecimal | null;
  powerCombustionEngine?: VehicleDataDecimal | null;
  powerElectricEngine?: VehicleDataDecimal | null;
  primaryKeyCount?: number | null;
  realMileage?: number | null;
  registrationCertificateNumber?: string | null;
  otherRecords?: string | null;
  serviceContractNote?: string | null;
  vehicleRegistrationNumber?: string | null;
  registrationPlate?: string | null;
  seatCount?: number | null;
  standingPlaceCount?: number | null;
  bedCount?: number | null;
  axleCount?: number | null;
  europalletStorageSpaces?: number | null;
  lowDeck?: boolean | null;
  maximalAxleLoad?: number | null;
  maximalRoofLoad?: number | null;
  grossCombinationWeight?: number | null;
  dimensionsLoadingSpace?: DimensionsRequestBody | null;
  loadingCapacity?: number | null;
  technicalInspectionNote?: string | null;
  technicalInspectionValidSince?: string | null;
  technicalInspectionValidUntil?: string | null;
  transmission?: string | null;
  trim?: string | null;
  type: VehicleTypeEnum;
  variant?: string | null;
  vin?: string | null;
  warrantyDate?: string | null;
  lastServiceDate?: string | null;
  nextServiceDate?: string | null;
  serviceContractUntil?: string | null;
  warrantyMileage?: number | null;
  weight?: number | null;
  lastServiceMileage?: number | null;
  nextServiceMileage?: number | null;
  serviceContractMileageLimit?: number | null;
  wheelBase?: number | null;
  trunkSpace?: number | null;
  features?: FeatureRequestBody[] | null;
  chargingAC?: number | null;
  chargingTimeAC?: number | null;
  chargingDC?: number | null;
  chargingTimeDC?: number | null;
  batteryType?: string | null;
  batteryCapacityWh?: number | null;
  batteryCapacityAh?: number | null;
  batteryVoltage?: number | null;
  batteryCount?: number | null;
  chargingConnectorType?: string | null;
  electricRange?: number | null;
  batteryMileageLimit?: number | null;
  batteryWarrantyUntil?: string | null;
  batteryOwnershipType?: string | null;
  hasRangeExtender?: boolean | null;
  electricEngineNote?: string | null;
  maximalPower?: number | null;
  electricVehicleBattery?: ElectricVehicleBatteryV2RequestBody | null;
};
export type GetVehicleApiResponseV2 = {
  id: string;
  externalId?: string | null;
  publicId?: string | null;
  customId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isArchived?: boolean | null;
  title: string;
  keyLocation?: string | null;
  bodyStyle?: string | null;
  carbonDioxideEmission?: VehicleDataDecimal | null;
  condition?: string | null;
  customMake?: string | null;
  customModelFamily?: string | null;
  cylinderCount?: number | null;
  dimensions: DimensionsSpecificationResponseBody | null;
  doorCountCategory?: string | null;
  drive?: string | null;
  emissionClass?: string | null;
  engineCode?: string | null;
  engineName?: string | null;
  engineNumber?: string | null;
  engineVolume?: number | null;
  exteriorColor?: ColorResponseBody | null;
  serviceBookType?: string | null;
  serviceBookState?: string | null;
  handDriveType?: string | null;
  secondaryFuelType?: string | null;
  exteriorColorFactoryName?: string | null;
  exteriorColorSpecification?: string | null;
  firstRegistrationCountry?: string | null;
  firstRegistrationOn?: string | null;
  fuelConsumptionCombined?: VehicleDataDecimal | null;
  fuelConsumptionExtraUrban?: VehicleDataDecimal | null;
  fuelConsumptionUrban?: VehicleDataDecimal | null;
  fuelType?: string | null;
  hybridType?: string | null;
  pressureVesselValidUntil?: string | null;
  gearCount?: number | null;
  hasCoc?: boolean | null;
  hasDpf?: boolean | null;
  interiorColor?: ColorResponseBody | null;
  interiorMaterial?: string | null;
  isAcceptableForTechnicalInspection?: boolean | null;
  isRegistered?: boolean | null;
  lastRegistrationCountry?: string | null;
  gasRevisionValidUntil?: string | null;
  make?: string | null;
  manufacturedOn?: string | null;
  maxTorque?: string | null;
  manufacturerColorCode?: string | null;
  manufacturerColorName?: string | null;
  maximalLoadCapacity?: number | null;
  mileage?: number | null;
  modelFamily?: string | null;
  modelFamilyGroup?: string | null;
  operatingWeight?: number | null;
  originCountry?: string | null;
  ownerCount?: number | null;
  power?: number | null;
  powerConsumptionCombined?: VehicleDataDecimal | null;
  primaryKeyCount?: number | null;
  realMileage?: number | null;
  registrationCertificateNumber?: string | null;
  otherRecords?: string | null;
  serviceContractNote?: string | null;
  vehicleRegistrationNumber?: string | null;
  registrationPlate?: string | null;
  seatCount?: number | null;
  standingPlaceCount?: number | null;
  bedCount?: number | null;
  axleCount?: number | null;
  europalletStorageSpaces?: number | null;
  lowDeck?: boolean | null;
  maximalAxleLoad?: number | null;
  maximalRoofLoad?: number | null;
  grossCombinationWeight?: number | null;
  dimensionsLoadingSpace?: DimensionsResponseBody | null;
  loadingCapacity?: number | null;
  technicalInspectionNote?: string | null;
  technicalInspectionValidSince?: string | null;
  technicalInspectionValidUntil?: string | null;
  transmission?: string | null;
  trim?: string | null;
  type?: VehicleTypeEnum;
  variant?: string | null;
  vehicle_type?: string | null;
  vin?: string | null;
  warrantyDate?: string | null;
  lastServiceDate?: string | null;
  nextServiceDate?: string | null;
  serviceContractUntil?: string | null;
  warrantyMileage?: number | null;
  weight?: number | null;
  lastServiceMileage?: number | null;
  nextServiceMileage?: number | null;
  serviceContractMileageLimit?: number | null;
  wheelBase?: number | null;
  trunkSpace?: number | null;
  features?: VehicleFeatureResponseBody[] | null;
  chargingAC?: number | null;
  chargingTimeAC?: number | null;
  chargingDC?: number | null;
  chargingTimeDC?: number | null;
  batteryType?: string | null;
  batteryCapacityWh?: number | null;
  batteryCapacityAh?: number | null;
  batteryVoltage?: number | null;
  batteryCount?: number | null;
  chargingConnectorType?: string | null;
  electricRange?: number | null;
  batteryMileageLimit?: number | null;
  batteryWarrantyUntil?: string | null;
  batteryOwnershipType?: string | null;
  hasRangeExtender?: boolean | null;
  lengthCategory?: string | null;
  heightCategory?: string | null;
  loadingArea?: string | null;
  electricEngineNote?: string | null;
  powerCombustionEngine?: string | null;
  powerElectricEngine?: string | null;
  serviceIntervals?: {
    serviceInspection: {
      lastServiceInspectionDate: string | null;
      lastServiceInspectionMileage: number | null;
      nextServiceInspectionDate: string | null;
      nextServiceInspectionMileage: number | null;
      replacementAfterMonths: number | null;
      replacementAfterMileage: number | null;
    };
    engineOil: {
      lastReplacementDate: string | null;
      lastReplacementMileage: number | null;
      nextReplacementDate: string | null;
      nextReplacementMileage: number | null;
      replacementAfterMonths: number | null;
      replacementAfterMileage: number | null;
    };
    gearboxOil: {
      lastReplacementDate: string | null;
      lastReplacementMileage: number | null;
      nextReplacementDate: string | null;
      nextReplacementMileage: number | null;
      replacementAfterMonths: number | null;
      replacementAfterMileage: number | null;
    };
    fourByFourOilDistributor: {
      lastReplacementDate: string | null;
      lastReplacementMileage: number | null;
      nextReplacementDate: string | null;
      nextReplacementMileage: number | null;
      replacementAfterMonths: number | null;
      replacementAfterMileage: number | null;
    };
    timingBelt: {
      lastReplacementDate: string | null;
      lastReplacementMileage: number | null;
      nextReplacementDate: string | null;
      nextReplacementMileage: number | null;
      replacementAfterMonths: number | null;
      replacementAfterMileage: number | null;
    };
    brakeFluid: {
      lastReplacementDate: string | null;
      lastReplacementMileage: number | null;
      nextReplacementDate: string | null;
      nextReplacementMileage: number | null;
    };
    coolantFluid: {
      lastReplacementDate: string | null;
      lastReplacementMileage: number | null;
      nextReplacementDate: string | null;
      nextReplacementMileage: number | null;
    };
    annualMileage: {
      calculatedFromServiceVisits: number | null;
    };
  } | null;
  maximalPower?: number | null;
  electricVehicleBattery?: ElectricVehicleBatteryV2ResponseBody | null;
};
export type GetVehicleApiArg = {
  vehicleId: string;
};
export type GetVehicleInsightsApiArg = {
  vehicleId: string;
};
export type GetVehicleInsightsApiRes = {
  daysInAdvertising: number | null;
  daysInStock: number | null;
  daysSinceReprice: number | null;
};

export type GetVehiclePhotosApiArg = {
  vehicleId: string;
};

export type GetVehiclePhotosApiRes = VehiclePhotoResponseBody[];

export type GetSimilarVehiclesApiResponse = /** status 200  */ SimilarVehicleResponseBody[];
export type GetSimilarVehiclesApiArg = {
  size?: number | null;
  offset?: number | null;
  vin?: string | null;
  vinContains?: string | null;
  registrationPlateContains?: string | null;
};
export type BulkDownloadApiArg = {eventId: string};
type BulkDownloadObjectType = {id: string; destinationPath: string};
export type BulkDownloadApiResponse = {
  state: 'PENDING' | 'DONE' | 'FAILED';
  data: {
    bulkObjectId: string;
    bulkObjectDownloadUrl: string;
    bulkStatusUrl: string;
    bulkName: string;
    objects: BulkDownloadObjectType[];
  };
  id: string;
  errorMessage: string;
};
export type VehiclePhotoResponseBody = {
  id: string;
  weight: number;
  fileId: string;
  remoteFileId: string;
  albumId: string;
  isCoverPhoto: boolean;
  url: string;
  latestFileOperation?: FileOperationResponseBody | null;
};
export type SimilarVehicleResponseBody = {
  id: string;
  make: string;
  title: string;
  makeName: string;
  modelFamily: string;
  modelFamilyName: string;
  customMake: string | null;
  customModelFamily: string | null;
  vin: string | null;
  registrationPlate: string | null;
  isArchived: boolean | null;
  photos: VehiclePhotoResponseBody[];
  type?:
    | (
        | 'VEHICLETYPE_PASSENGER_CAR'
        | 'VEHICLETYPE_VAN'
        | 'VEHICLETYPE_MOTORCYCLE'
        | 'VEHICLETYPE_TRUCK'
        | 'VEHICLETYPE_SEMI_TRAILER'
        | 'VEHICLETYPE_TRAILER'
        | 'VEHICLETYPE_BUS'
        | 'VEHICLETYPE_OTHER'
        | 'VEHICLETYPE_CARAVAN'
        | 'VEHICLETYPE_AGRICULTURAL_MACHINE'
        | 'VEHICLETYPE_CONSTRUCTION_MACHINE'
      )
    | null;
  trim?: string | null;
  variant?: string | null;
  fuelType?: string | null;
  transmission?: string | null;
  bodyStyle?: string | null;
  drive?: string | null;
  firstRegistration?: string | null;
  realProductionYear?: string | null;
  mileage?: number | null;
  secondaryFuelType?: string | null;
  features?: any | null;
  power?: number | null;
  sellingState:
    | 'not-set'
    | 'for-sale'
    | 'selling'
    | 'not-for-sale'
    | 'sold'
    | 'not-sold'
    | 'cannot-be-sold';
  buyingState:
    | 'not-set'
    | 'to-buy'
    | 'buying'
    | 'for-pricing'
    | 'priced'
    | 'not-bought'
    | 'bought'
    | 'returned-to-customer';
  isAvailable: boolean;
};

export type VehicleAlbumResponseBody = {
  translation: string;
  id: string;
  name: string;
  serviceCaseId: string | null;
  createdAt: string;
};

export type ListVehicleAlbumsApiResponse = /** status 200  */ VehicleAlbumResponseBody[];

export type ListVehicleAlbumsApiArg = {
  vehicleId?: string;
  serviceCaseId?: string;
};

export type VehiclePhotoResponse = {
  id: string;
  fileId: string;
  albumId: string;
  weight: number;
  isCoverPhoto: boolean;
  coverPhoto?: boolean;
  url: string;
};

export type AddFilesToVehicleAlbumResponse = {
  photos: VehiclePhotoResponse[];
};

export type AddFilesToVehicleAlbumRequestBody = {
  fileIds: string[];
  withEmptyUrl?: boolean;
};

export type AddFilesToVehicleAlbumApiResponse = /** status 201  */ AddFilesToVehicleAlbumResponse;

export type AddFilesToVehicleAlbumApiArg = {
  vehicleAlbumId: string;
  vehicleId: string;
  addFilesToVehicleAlbumRequestBody: AddFilesToVehicleAlbumRequestBody;
};

export type UnassignTagFromEntityArg = {
  recordId: string;
  resourceId: EntityResourceIds;
  tagId: string;

  tagValueId: string;
};
export type UnassignTagFromEntityRes = void;

export type AssignTagFromEntityArg = {
  recordId: string;
  resourceId: EntityResourceIds;
  tagId: string;

  tagValueId: string;
};
export type AssignTagFromEntityRes = void;

export type EditTagArg = {
  tagId: string;
  tagValueId: string;
  color: ColorSchemeType;
  name: string;
};

export type ExpireTagArg = {
  tagValueId: string;
  expiresAt: string;
  tagId: string;
};

export type RestoreTagArg = {
  tagValueId: string;
  tagId: string;
};

export type CreateTagRes = {
  id: string;
  color: ColorSchemeType;
  name: string;
};

export type ExpireTagRes = CreateTagRes;
export type EditTagRes = CreateTagRes;
export type RestoreTagRes = CreateTagRes;

export type CreateTagArg = {
  tagId: string;
  color: ColorSchemeType;
  name: string;
};
export type GetTagArg = {
  tagId: string;

  tagValueId: string;
};
export type GetTagsArg = {
  resourceId: EntityResourceIds;
};

export type GetTagResBody = {
  id: string;
  name: string;
  values: GetTagRes[];
  allowedResources: Array<string>;
};
export type GetTagsRes = Array<GetTagResBody>;
export type GetTagRes = {
  id: string;
  color: ColorSchemeType;
  name: string;
  expiresAt: string;
};

export type GetTagsForEntityArg = {
  recordId: string;
  resourceId: EntityResourceIds;
};

export type EntityTag = {
  tagId: string;
  tagName: string;
  valueColor: ColorSchemeType;
  valueId: string;
  valueName: string;
};
export type GetTagsForEntityRes = Array<EntityTag>;

export type GetCommissionRuleArg = {
  commissionRuleId: string;
};

export type GetCommissionRuleRes = {
  id: string;
  percentage: string;
  priceUpTo: Price;
  minimumAmount: VatPrice;
  fixedAmount: VatPrice;
};

export type GetCommissionRulesRes = GetCommissionRuleRes[];
export type GetCommissionRulesArg = {amount: string; currency: string} | undefined;

export type UpdateCommissionRuleArg = GetCommissionRuleRes;
export type UpdateCommissionRuleRes = GetCommissionRuleRes;

export type CreateCommissionRuleArg = Omit<GetCommissionRuleRes, 'id'>;
export type CreateCommissionRuleRes = GetCommissionRuleRes;

export type DeleteCommissionRuleArg = {id: string};
export type DeleteCommissionRuleRes = void;

export enum PartialVehiclePhotosRoutes {
  vehicle = 'vehicle',
  condition = 'condition',
  inspections = 'inspection',
}

export type BatchDownloadEventIdArg = {
  vehicleId: string;
  vehiclePhotoLocation: PartialVehiclePhotosRoutes;
  auditId?: string;
  albumId?: string;
};

export type BatchDownloadSaleVehicleEventIdArg = {
  saleVehicleId: string;
  albumId?: string;
};

export type BatchDownloadServiceGalleryEventIdArg = {
  vehicleId: string;
  serviceAlbumId: string;
};

export type BatchDownloadEventIdResponse = {
  eventId: string;
};

export type GetPriceReportApiArg = {
  vehicleId: string;
};
export type PartialUpdateVehicleApiResponse = unknown;
export type PartialUpdateVehicleApiArg = {
  vehicleId: string;
  patchVehicleRequestBody: PatchVehicleRequestBody;
};

export type DimensionsRequestBody = {
  dimensions?: DimensionsSpecificationRequestBody | null;
  operatingWeight?: number | null;
  weight?: number | null;
  maximalLoadCapacity?: number | null;
  wheelBase?: number | null;
  trunkSpace?: number | null;
};
export type TagResponseBody = {
  id: string;
  title: string;
};
export type TagRequestBody = {
  text: string;
};
export type ShortcomingBulkRequestBody = {
  shortcomings: TagRequestBody[];
};
export type HighlightBulkRequestBody = {
  highlights: TagRequestBody[];
};
export type BulkCreateHighlightsApiResponse = /** status 201  */ TagResponseBody[];
export type BulkCreateHighlightsApiArg = {
  vehicleId: string;
  highlightBulkRequestBody: HighlightBulkRequestBody;
};
export type DeleteHighlightApiResponse = /** status 204  */ undefined;
export type DeleteHighlightApiArg = {
  vehicleId: string;
  highlightId: string;
  authorization?: string;
};
export type ListShortcomingsApiResponse = /** status 200  */ TagResponseBody[];
export type ListShortcomingsApiArg = {
  vehicleId: string;
};
export type ListHighlightsApiResponse = /** status 200  */ TagResponseBody[];
export type ListHighlightsApiArg = {
  vehicleId: string;
};
export type BulkCreateShortcomingsApiResponse = /** status 201  */ TagResponseBody[];
export type BulkCreateShortcomingsApiArg = {
  vehicleId: string;
  shortcomingBulkRequestBody: ShortcomingBulkRequestBody;
};
export type DeleteShortcomingApiResponse = unknown;
export type DeleteShortcomingApiArg = {
  vehicleId: string;
  shortcomingId: string;
};

export type EngineRequestBody = {
  engineNumber?: string | null;
  engineCode?: string | null;
  engineName?: string | null;
  engineVolume?: number | null;
  cylinderCount?: number | null;
  gearCount?: number | null;
  hasDpf?: boolean | null;
  fuelConsumptionCombined?: number | null;
  fuelConsumptionUrban?: number | null;
  fuelConsumptionExtraUrban?: number | null;
  emissionClass?: string | null;
  carbonDioxideEmission?: number | null;
  maxTorque?: string | null;
  powerConsumptionCombined?: number | null;
  powerCombustionEngine?: number | null;
  powerElectricEngine?: number | null;
  electricEngineNote?: string | null;
  maximalPower?: number | null;
};

export type ModelSpecificationRequestBody = {
  seatCount?: number | null;
  originCountry?: string | null;
  firstRegistrationCountry?: string | null;
  lastRegistrationCountry?: string | null;
  warrantyDate?: string | null;
  warrantyMileage?: number | null;
  isRegistered?: boolean | null;
  serviceContractUntil?: string | null;
  serviceContractMileageLimit?: number | null;
  serviceContractNote?: string | null;
};

export type StateRequestBody = {
  mileage?: number | null;
  ownerCount?: number | null;
  primaryKeyCount?: number | null;
  technicalInspectionValidUntil?: string | null;
  gasRevisionValidUntil?: string | null;
  pressureVesselValidUntil?: string | null;
  technicalInspectionNote?: string | null;
  registrationPlate?: string | null;
  condition?: string | null;
  isAcceptableForTechnicalInspection?: boolean | null;
  hasCoc?: boolean | null;
  realMileage?: number | null;
  vehicleRegistrationNumber?: string | null;
  registrationCertificateNumber?: string | null;
  serviceBookType?: string | null;
  serviceBookState?: string | null;
  lastServiceDate?: string | null;
  lastServiceMileage?: number | null;
  nextServiceDate?: string | null;
  nextServiceMileage?: number | null;
};

export type AdditionalInformationRequestBody = {
  exteriorColor?: string | null;
  exteriorColorSpecification?: string | null;
  exteriorColorFactoryName?: string | null;
  interiorColor?: string | null;
  interiorMaterial?: string | null;
  manufacturerColorCode?: string | null;
};

export type BatteryRequestBody = {
  chargingAC: number | null;
  chargingTimeAC: number | null;
  chargingDC: number | null;
  chargingTimeDC: number | null;
  batteryType: string | null;
  batteryCapacityWh: number | null;
  batteryCapacityAh: number | null;
  batteryVoltage: number | null;
  batteryCount: number | null;
  chargingConnectorType: string | null;
  electricRange: number | null;
  batteryMileageLimit: number | null;
  batteryWarrantyUntil: string | null;
  batteryOwnershipType: string | null;
  hasRangeExtender: boolean | null;
};

export type ElectricVehicleBatteryRequestBody = Optional<ElectricVehicleBattery>;

export type UpdateVehicleRequestBody = {
  dimensions: DimensionsRequestBody;
  engine: EngineRequestBody;
  modelSpecification: ModelSpecificationRequestBody;
  state: StateRequestBody;
  vin?: string | null;
  type: VehicleTypeEnum;
  make: string;
  modelFamily: string;
  customMake?: string | null;
  customModelFamily?: string | null;
  trim?: string | null;
  variant?: string | null;
  additionalInformation?: AdditionalInformationRequestBody | null;
  fuelType?: string | null;
  hybridType?: string | null;
  secondaryFuelType?: string | null;
  handDriveType?: string | null;
  transmission?: string | null;
  doorCountCategory?: string | null;
  bodyStyle?: string | null;
  drive?: string | null;
  power?: number | null;
  firstRegistrationOnDay?: number | null;
  firstRegistrationOnMonth?: number | null;
  firstRegistrationOnYear?: number | null;
  manufacturedOnMonth?: number | null;
  manufacturedOnYear?: number | null;
  isVatDeductible?: boolean | null;
  isArchived?: boolean | null;
  features?: string[];
  title?: string | null;
  keyLocation?: string | null;
  battery?: BatteryRequestBody | null;
  vehicleSourceCodeId?: string | null;
  electricVehicleBattery?: ElectricVehicleBatteryRequestBody | null;
};

export type VehiclePhotoRequestBody = {
  fileId: string;
};

export type CreateVehicleRequestBody = {
  dimensions: DimensionsRequestBody;
  engine: EngineRequestBody;
  modelSpecification: ModelSpecificationRequestBody;
  state: StateRequestBody;
  additionalInformation?: AdditionalInformationRequestBody | null;
  features?: string[];
  photos?: VehiclePhotoRequestBody[];
  vin?: string | null;
  make: string;
  modelFamily: string;
  externalId?: string | null;
  customMake?: string | null;
  customModelFamily?: string | null;
  trim?: string | null;
  variant?: string | null;
  fuelType?: string | null;
  hybridType?: string | null;
  secondaryFuelType?: string | null;
  handDriveType?: string | null;
  transmission?: string | null;
  doorCountCategory?: string | null;
  bodyStyle?: string | null;
  drive?: string | null;
  power?: number | null;
  firstRegistrationOnDay?: number | null;
  firstRegistrationOnMonth?: number | null;
  firstRegistrationOnYear?: number | null;
  manufacturedOnMonth?: number | null;
  manufacturedOnYear?: number | null;
  sellingPrice?: string | null;
  sellingPriceWithVat?: string | null;
  sellingPriceVatRate?: string | null;
  axleCount?: number | null;
  europalletStorageSpaces?: number | null;
  purchasePrice?: string | null;
  purchasePriceWithVat?: string | null;
  purchasePriceVatRate?: string | null;
  currencyCode?: string | null;
  isVatDeductible?: boolean | null;
  isArchived?: boolean | null;
  type: VehicleTypeEnum;
  source?: string | null;
  title?: string | null;
  battery?: BatteryRequestBody | null;
  vehicleId?: string | null;
  vehicleSourceCodeId?: string | null;
  electricVehicleBattery?: ElectricVehicleBatteryRequestBody | null;
};

export type CreateVehicleApiResponse = /** status 201  */ FullVehicleResponseBody;
export type CreateVehicleApiArg = {
  vehicleRequestBody: CreateVehicleRequestBody;
};

export type CreateVehicleToBuyApiResponse = /** status 201  */ FullVehicleResponseBody;
export type CreateVehicleToBuyApiArg = {
  vehicleRequestBody: CreateVehicleRequestBody;
};

export type UpdateVehicleApiResponse = /** status 200  */ FullVehicleResponseBody;
export type UpdateVehicleApiArg = {
  vehicleId: string;
  vehicleRequestBody: UpdateVehicleRequestBody;
};

export type VehicleFinancialInstitutionResponseBody = {
  vehicleId: string;
  isFinanced: boolean;
  institution: string;
};
export type GetVehicleFinancialInstitutionApiArg = {
  vehicleId: string;
};
export type GetVehicleFinancialInstitutionResponse = VehicleFinancialInstitutionResponseBody;
export type UpdateVehicleFinancialInstitutionResponse = VehicleFinancialInstitutionResponseBody;
export type UpdateVehicleFinancialInstitutionRequestBody = {
  isFinanced: boolean;
  institution: string | null;
};
export type UpdateVehicleFinancialInstitutionApiArg = {
  vehicleId: string;
  body: UpdateVehicleFinancialInstitutionRequestBody;
};

export type ArchiveVehicleApiResponse = /** status 204  */ undefined;
export type ArchiveVehicleApiArg = {
  vehicleId: string;
};

export type GetAdditionalNoteApiResponse = /** status 200  */ AdditionalNoteResponseBody;
export type GetAdditionalNoteApiArg = {
  vehicleId: string;
};
export type SetAdditionalNoteApiResponse = /** status 201  */ AdditionalNoteResponseBody;
export type SetAdditionalNoteApiArg = {
  vehicleId: string;
  additionalNoteRequestBody: AdditionalNoteRequestBody;
};
export type AdditionalNoteResponseBody = {
  id: string;
  author: UserResponseBody;
  createdAt: string;
  note: string;
};
export type AdditionalNoteRequestBody = {
  note: string;
};
// ------------------- Workshop API ------------------
export type CreateServiceCaseApiResponse = /** status 201  */ ServiceCaseResponseBody;
export type CreateServiceCaseApiArg = {
  createServiceCaseRequestBody: CreateServiceCaseRequestBody;
};
export type GetServiceCaseApiResponse = /** status 200  */ ServiceCaseResponseBody;
export type GetServiceCaseApiArg = {
  serviceCaseId: string;
};
export type ServiceCaseResponseBody = {
  id: string;
  vehicleId: string;
  customerId: string;
  createdAt: string;
  createdBy: string;
};
export type CreateServiceCaseRequestBody = {
  vehicleId: string;
  customerId: string;
};

// ------------------------- ALPHA CATALOGUE API -------------------------
export type ApiHealthCheckLivenessApiResponse = unknown;
export type ApiHealthCheckLivenessApiArg = void;
export type ApiHealthCheckReadinessApiResponse = unknown;
export type ApiHealthCheckReadinessApiArg = void;
export type ApiGetVersionApiResponse = /** status 200 Returns microservice version */ {
  version: string;
};
export type ApiGetVersionApiArg = void;
export type PingControllerApiResponse = /** status 200 Ping and pong. */ {
  detail: string;
};
export type PingControllerApiArg = void;
export type PutApiCatalogueCarsApiResponse =
  /** status 200 The request is successfully performed. */ string[];
export type PutApiCatalogueCarsApiArg = {
  body: {
    car_style: string;
    co2_emission?: number;
    cubic_capacity: number;
    door_count: number;
    drive: string;
    emission_class?: string;
    fuel_consumption_combined: number;
    fuel_consumption_extra_urban?: number;
    fuel_consumption_urban?: number;
    fuel_type: string;
    make: string;
    manufactured_from: string;
    manufactured_to?: string;
    model: string;
    power: number;
    transmission: string;
    year: number;
  }[];
};
export type DeleteApiEnumApiResponse = unknown;
export type DeleteApiEnumApiArg = {
  body: string[];
};
export type ApiEnumKey = {
  categories?: string[];
  const_key: string;
  const_key_old: string;
  labels: {
    label: string;
    label_short?: string | null;
    language: string;
  }[];
  parent: string | null;
  properties?: {
    name: string;
    value: any;
  }[];
};
export type GetAllApiEnumsApiResponse = /** status 200 The request is successfully performed. */ {
  keys: {
    categories?: string[];
    const_key: string;
    const_key_old: string;
    labels: {
      label: string;
      label_short?: string | null;
      language: string;
    }[];
    parent: string | null;
    properties?: {
      name: string;
      value: any;
    }[];
    sort_priority: number;
  }[];
  property_name: string;
  vehicle_type: VehicleTypeEnum | null;
}[];
export type GetAllApiEnumsApiArg = {
  /** List of ISO 693-1 languages of labels to retrieve. */
  lang?: string[];
  /** Field by which user wants to do ordering of keys inside each property. */
  orderBy?: ('label' | 'priority') | null;
  /** List of vehicle types to retrieve. */
  vehicleType?: VehicleTypeEnum[];
};
export type PostApiEnumApiResponse = unknown;
export type PostApiEnumApiArg = {
  body: {
    keys?: {
      categories?: {
        const_key: string;
      }[];
      const_key: string;
      const_key_old?: string | null;
      labels: {
        label: string;
        label_short?: string | null;
        language: string;
      }[];
      parent: string | null;
      properties?: {
        name: string;
        value: any;
      }[];
    }[];
    property_name: string;
    vehicle_type: string | null;
  }[];
};
export type PutApiEnumApiResponse =
  /** status 200 The request is successfully performed. */ string[];
export type PutApiEnumApiArg = {
  body: {
    keys?: {
      categories?: {
        const_key: string;
      }[];
      const_key: string;
      const_key_old?: string | null;
      labels: {
        label: string;
        label_short?: string | null;
        language: string;
      }[];
      parent: string | null;
      properties?: {
        name: string;
        value: any;
      }[];
    }[];
    property_name: string;
    vehicle_type: string | null;
  }[];
};
export type GetAllEnumsWithRemovedApiResponse =
  /** status 200 The request is successfully performed. */ {
    correct_id: string | null;
    enum_id: string;
    keys: {
      categories?: string[];
      const_key: string;
      const_key_old: string;
      labels: {
        label: string;
        label_short?: string | null;
        language: string;
      }[];
      parent: string | null;
      properties?: {
        name: string;
        value: any;
      }[];
    }[];
    property_name: string;
    vehicle_type: string | null;
  }[];
export type GetAllEnumsWithRemovedApiArg = {
  /** List of ISO 693-1 languages of labels to retrieve. */
  lang?: string[];
};
export type GetEnumAllPropertiesApiResponse =
  /** status 200 The request is successfully performed. */ {
    keys: {
      categories?: string[];
      const_key: string;
      const_key_old: string;
      labels: {
        label: string;
        label_short?: string | null;
        language: string;
      }[];
      parent: string | null;
      properties?: {
        name: string;
        value: any;
      }[];
    }[];
    property_name: string;
    vehicle_type: string | null;
  }[];
export type GetEnumAllPropertiesApiArg = void;
export type GetApiEnumsApiResponse = /** status 200 The request is successfully performed. */ {
  keys: {
    categories?: string[];
    const_key: string;
    const_key_old: string;
    labels: {
      label: string;
      label_short?: string | null;
      language: string;
    }[];
    parent: string | null;
    properties?: {
      name: string;
      value: any;
    }[];
  }[];
  property_name: string;
  vehicle_type: string | null;
}[];
export type GetApiEnumsApiArg = {
  /** List of names of enums to retrieve. */
  names: string[];
  /** List of ISO 693-1 languages of labels to retrieve. */
  lang?: string[];
};
export type GetApiGumtApiResponse = /** status 200 The request is successfully performed. */ {
  ignore_in_gumt: string;
  ignore_in_text_analysis: string;
  mapping_key_id: string;
  matching_condition: string;
  matching_pattern: string;
  output_type: string;
  pattern_language: string;
  source_attribute_name: string;
  source_id: string;
  source_type: string;
  standard_attribute_name: string;
  standard_attribute_value: string;
  text_matching_type: string;
}[];
export type GetApiGumtApiArg = void;
export type GetApiGumtServerApiResponse = /** status 200 The request is successfully performed. */ {
  ignore_in_gumt: string;
  ignore_in_text_analysis: string;
  mapping_key_id: string;
  matching_condition: string;
  matching_pattern: string;
  output_type: string;
  pattern_language: string;
  source_attribute_name: string;
  source_id: string;
  source_type: string;
  standard_attribute_name: string;
  standard_attribute_value: string;
  text_matching_type: string;
}[];
export type GetApiGumtServerApiArg = {
  /** Server id providing information (autoscout24, mobile_de,tipars...) */
  server: string;
};
export type GetApiGumtServerAttributeApiResponse =
  /** status 200 The request is successfully performed. */ {
    ignore_in_gumt: string;
    ignore_in_text_analysis: string;
    mapping_key_id: string;
    matching_condition: string;
    matching_pattern: string;
    output_type: string;
    pattern_language: string;
    source_attribute_name: string;
    source_id: string;
    source_type: string;
    standard_attribute_name: string;
    standard_attribute_value: string;
    text_matching_type: string;
  }[];
export type GetApiGumtServerAttributeApiArg = {
  /** Server id providing information (autoscout24, mobile_de,tipars...) */
  server: string;
  /** Value of source_attribute_name */
  attribute: string;
};
export type DeleteApiMakeModelApiResponse = unknown;
export type DeleteApiMakeModelApiArg = {
  body: string[];
};
export type PostApiMakeModelApiResponse = unknown;
export type PostApiMakeModelApiArg = {
  body: {
    default_label: string;
    labels?: {
      label: string;
      language: string;
    }[];
    make: string;
    models?: {
      default_label: string;
      labels?: {
        label: string;
        language: string;
      }[];
      model: string;
      model_group?: {
        default_label: string;
        group: string;
        labels?: {
          label: string;
          language: string;
        }[];
      } | null;
    }[];
    vehicle_type: string;
  }[];
};
export type PutApiMakeModelApiResponse =
  /** status 200 The request is successfully performed. */ string[];
export type PutApiMakeModelApiArg = {
  body: {
    default_label: string;
    labels?: {
      label: string;
      language: string;
    }[];
    make: string;
    models?: {
      default_label: string;
      labels?: {
        label: string;
        language: string;
      }[];
      model: string;
      model_group?: {
        default_label: string;
        group: string;
        labels?: {
          label: string;
          language: string;
        }[];
      } | null;
    }[];
    vehicle_type: string;
  }[];
};
export type GetApiMakeModelApiResponse = /** status 200 The request is successfully performed. */ {
  default_label: string;
  labels: {
    label: string;
    label_short?: string | null;
    language: string;
  }[];
  make: string;
  models?: {
    default_label: string;
    labels?: {
      label: string;
      language: string;
    }[];
    model: string;
    model_group?: {
      default_label: string;
      group: string;
      labels?: {
        label: string;
        language: string;
      }[];
    } | null;
  }[];
  vehicle_type: string;
}[];
export type GetApiMakeModelApiArg = {
  /** Vehicle type such as VEHICLETYPE_PASSENGER_CAR, VEHICLETYPE_MOTORCYCLE, etc. */
  vehicleType: string;
  /** List of ISO 693-1 languages of labels to retrieve. */
  lang?: string[];
  /** TBD */
  q?: string;
  /** Size of returned data. */
  size?: number;
};
export type GetApiMakeModelWithRemovedApiResponse =
  /** status 200 The request is successfully performed. */ {
    correct_id: string | null;
    default_label: string;
    labels: {
      label: string;
      label_short?: string | null;
      language: string;
    }[];
    make: string;
    make_id: string;
    models?: {
      default_label: string;
      labels?: {
        label: string;
        language: string;
      }[];
      model: string;
      model_group?: {
        default_label: string;
        group: string;
        labels?: {
          label: string;
          language: string;
        }[];
      } | null;
    }[];
    vehicle_type: string;
  }[];
export type GetApiMakeModelWithRemovedApiArg = {
  /** Vehicle type such as VEHICLETYPE_PASSENGER_CAR, VEHICLETYPE_MOTORCYCLE, etc. */
  vehicleType: string;
  /** List of ISO 693-1 languages of labels to retrieve. */
  lang?: string[];
};
export type VehicleModel = {
  default_label: string;
  labels?: {
    label: string;
    language: string;
  }[];
  model: string;
  model_group?: {
    default_label: string;
    group: string;
    labels?: {
      label: string;
      language: string;
    }[];
  } | null;
};
export type ApiMakeModelWithMake = {
  default_label: string;
  labels?: {
    label: string;
    label_short?: string | null;
    language: string;
  }[];
  make: string;
  models?: VehicleModel[];
  vehicle_type: string;
};
export type GetApiMakeModelWithMakeApiResponse =
  /** status 200 The request is successfully performed. */ ApiMakeModelWithMake[];
export type GetApiMakeModelWithMakeApiArg = {
  /** Vehicle type such as VEHICLETYPE_PASSENGER_CAR, VEHICLETYPE_MOTORCYCLE, etc. */
  vehicleType: string;
  /** Car make. If provided then the endpoint returns list of related models. If not provided then the endpoint returns the list of all possible makes.
   */
  make?: string;
  /** List of ISO 693-1 languages of labels to retrieve. */
  lang?: string[];
};

export type VehicleModelFamily = {
  [family: string]: VehicleModel[];
};

export type MakeModels = {
  [make: string]: VehicleModelFamily;
};

export type GetApiMakeModelsWithMakeApiArg = {
  vehicleType: string;
  makes: string[];
  lang?: string[];
};

export type GetAllMakeModelsApiArgs = {vehicleType: string; lang?: string[]};
export type Make = {name: string; label: string; models: string[]; modelFamilies: string[]};
export type Makes = {[key: string]: Make};
export type Model = {name: string; label: string; make: string; modelFamily?: string};
export type Models = {[key: string]: Model};
export type ModelFamily = {name: string; label: string; make: string; models: string[]};
export type ModelFamilies = {[key: string]: ModelFamily};
export type GetAllMakeModelsApiResponse = {
  makes: Makes;
  models: Models;
  modelFamilies: ModelFamilies;
};

// ------------------------- PARKING PLACES API -------------------------
export type CreateNewParkingSectorApiResponse = /** status 201  */ ParkingSectorResponseBody;
export type CreateNewParkingSectorApiArg = {
  createParkingSectorRequestBody: CreateParkingSectorRequestBody;
};
export type DeleteParkingSectorApiResponse = unknown;
export type DeleteParkingSectorApiArg = {
  deleteParkingSectorRequestBody: DeleteParkingSectorRequestBody;
};
export type UpdateParkingSectorApiResponse = /** status 200  */ ParkingSectorResponseBody;
export type UpdateParkingSectorApiArg = {
  updateParkingSectorRequestBody: UpdateParkingSectorRequestBody;
};
export type GetOccupiedParkingSpacesApiResponse =
  /** status 200  */ OccupiedParkingSpaceResponseBody[];
export type GetOccupiedParkingSpacesApiArg = {
  sectorId: string;
};
export type GetParkingSectorApiResponse = /** status 200  */ ParkingSectorResponseBody;
export type GetParkingSectorApiArg = {
  sectorId: string;
};
export type GetParkingSectorsApiResponse = /** status 200  */ ParkingSectorResponseBody[];
export type GetParkingSectorsApiArg = {
  branchId: string;
};
export type GetVehicleParkingSpaceApiResponse = /** status 200  */ VehicleParkingSpaceResponseBody;
export type GetVehicleParkingSpaceApiArg = {
  vehicleId: string;
};
export type UpdateVehicleParkingSpaceApiResponse = unknown;
export type UpdateVehicleParkingSpaceApiArg = {
  vehicleId: string;

  updateVehicleParkingSpaceRequestBody: UpdateVehicleParkingSpaceRequestBody;
};
export type ParkingSectorResponseBody = {
  sectorId: string;
  branchId: string;
  name: string;
  prefix: string;
  numberOfParkingSpaces: number;
};
export type CreateParkingSectorRequestBody = {
  branchId: string;
  name: string;
  prefix: string;
  numberOfParkingSpaces: number;
};
export type DeleteParkingSectorRequestBody = {
  sectorId: string;
};
export type UpdateParkingSectorRequestBody = {
  sectorId: string;
  branchId: string;
  name: string;
  prefix: string;
  numberOfParkingSpaces: number;
};
export type OccupiedParkingSpaceResponseBody = {
  sectorId: string;
  vehicleId: string;
  vehicleTitle: string | null;
  vehicleLicensePlate: string | null;
  make: string;
  model: string;
  parkingSpaceNumber: number;
};
export type VehicleParkingSpaceResponseBody = {
  sectorId: string | null;
  prefix: string | null;
  number: number | null;
};
export type UpdateVehicleParkingSpaceRequestBody = {
  sectorId: string | null;
  number: number | null;
};

// ------------------------- WAREHOUSE API -------------------------
export type GetWarehousesApiResponse /** status 200 Standard response */ =
  | ({
      warehouse_id?: string;
      name?: string;
      address?: string;
      currency?: string;
      created?: string;
      created_by?: string;
      updated?: string;
      updated_by?: string;
    } | null)[]
  | /** status 204 Standard response */ undefined;
export type GetWarehousesApiArg = void;

export type GetWarehouseApiResponse =
  | /** status 200 Standard response */ ({
      warehouse_id?: string;
      name?: string;
      address?: string;
      currency?: string;
      created?: string;
      created_by?: string;
      updated?: string;
      updated_by?: string;
    } | null)
  | /** status 204 Standard response */ undefined;
export type GetWarehouseApiArg = {
  id: string;
};

export type GetSuppliersApiResponse /** status 200 Standard response */ =
  | ({
      supplier_id?: string | null;
      name?: string | null;
      description?: string | null;
    } | null)[]
  | /** status 204 Standard response */ undefined;
export type GetSuppliersApiArg = void;

export type GetArticleApiResponse =
  | /** status 200 Standard response */ ({
      article_id?: string | null;
      warehouse_id_fk?: string | null;
      supplier_id_fk?: string | null;
      manufacturer_id_fk?: string | null;
      name?: string | null;
      description?: string | null;
      internal_number?: string | null;
      manufacturer_catalog_num?: string | null;
      measurement_unit?: string | null;
      vat_type?: string | null;
      location?: string | null;
      created?: string | null;
      created_by?: string | null;
      updated?: string | null;
      updated_by?: string | null;
    } | null)
  | /** status 204 Standard response */ undefined;
export type GetArticleApiArg = {
  warehouseId: string;
  id: string;
};
export type ArticleDataType = {
  supplier_id_fk?: string | null;
  manufacturer_id_fk?: string | null;
  name?: string | null;
  description?: string | null;
  internal_number?: string | null;
  manufacturer_catalog_num?: string | null;
  measurement_unit?: string | null;
  vat_type?: string | null;
  location?: string | null;
  purchase_price?: number | null;
  service_order_price?: number | null;
  direct_sale_price?: number | null;
};
export type PostArticleApiResponse =
  | /** status 200 Standard response */ ({
      article_id?: string | null;
    } | null)
  | /** status 204 Standard response */ undefined;
export type PostArticleApiArg = {
  warehouseId: string;
  body: ArticleDataType;
};
export type PatchArticleApiResponse =
  | /** status 200 Standard response */ ({
      article_id?: string | null;
    } | null)
  | /** status 204 Standard response */ undefined;

export type PostWarehouseApiResponse =
  | /** status 200 Standard response */ ({
      warehouse_id?: string | null;
    } | null)
  | /** status 204 Standard response */ undefined;
export type PostWarehouseApiArg = {
  body: {
    name?: string | null;
    address?: string | null;
    currency?: string | null;
  } | null;
};

export type PatchWarehouseApiResponse = unknown;
export type PatchWarehouseApiArg = {
  warehouseId: string | null;
  body: {
    name?: string | null;
    address?: string | null;
    currency?: string | null;
  } | null;
};

// ------------------------- VAT CALCULATION API -------------------------
export type CalculationRuleResponseBody = {
  formulaFromWithoutVat: 'STANDARD_FROM_BELOW';
  formulaFromWithVat: 'STANDARD_FROM_ABOVE';
  roundType: 'HALF_UP';
  roundScale: number;
  roundStep: number;
  activeFrom: string;
};

export type VatCalculationResponseBody = {
  withoutVat: string;
  withVat: string;
  vat: string;
  vatRate: string;
};
export type CalculateFromWithVatApiResponse = /** status 200  */ VatCalculationResponseBody;
export type CalculateFromWithVatApiArg = {
  countryCode: string;
  vatRateType: VatRateType;
  amount: string;
  date?: string;
};
export type CalculateFromWithoutVatApiResponse = /** status 200  */ VatCalculationResponseBody;
export type CalculateFromWithoutVatApiArg = {
  countryCode: string;
  vatRateType: VatRateType;
  amount: string;
  date?: string;
};

export type GetWindshieldApiArg = {
  branchId?: string;
};
export type GetWindshieldApiRes = {
  url: string;
  isEnabled: boolean;
};
export type PutWindshieldApiArg = {
  branchId?: string;
  body: {
    url: string;
    isEnabled: boolean;
  };
};

// ------------------------- WATERMARK -------------------------
export type GetWatermarkApiResponse = /** status 200  */ WatermarkResponseBody;
export type UpdateWatermarkApiResponse = /** status 200  */ WatermarkResponseBody;
export type UpdateWatermarkApiArg = {
  updateWatermarkRequestBody: UpdateWatermarkRequestBody;
};
export type GetPreviewApiResponse = /** status 200  */ GetPreviewResponseBody;
export type GetPreviewApiArg = {
  vehiclePhotoId: string;
};
export type GetPublicPreviewApiResponse = unknown;
export type GetPublicPreviewApiArg = {
  token?: string;
  remoteFileId: string;
};
export type WatermarkPositionEnum =
  | 'top_left'
  | 'top_center'
  | 'top_right'
  | 'center_left'
  | 'center_center'
  | 'center_right'
  | 'bottom_left'
  | 'bottom_center'
  | 'bottom_right';
export type WatermarkResponseBody = {
  fileId: string | null;
  imageUri: string | null;
  position: WatermarkPositionEnum;
  size: number;
  fitToImage: boolean;
  addOnlyForFirstPhoto: boolean;
};
export type UpdateWatermarkRequestBody = {
  fileId: string | null;
  position: WatermarkPositionEnum;
  size: number;
  fitToImage: boolean;
  addOnlyForFirstPhoto: boolean;
};
export type GetPreviewResponseBody = {
  content: string;
};
export type GetPreviewFromSettingsApiResponse = /** status 200  */ GetPreviewResponseBody;
export type GetPreviewFromSettingsApiArg = {
  fileId?: string | null;
  position?: WatermarkPositionEnum;
  size?: number;
  fitToImage?: boolean;
};

// ------------------------- PUBLISHING PLATFORM PHOTOS SETTINGS API -------------------------
export type GetPhotoSettingsApiResponse = /** status 200  */ PlatformPhotoSettingsResponseBody;
export type GetPhotoSettingsApiArg = {
  platformCode: 'sauto' | 'tipcars' | 'mobile.de' | 'myweb' | 'facebook' | 'autosoft' | 'autocaris';
  params: {branchId?: string};
};
export type UpdatePhotoSettingsApiResponse = /** status 200  */ PlatformPhotoSettingsResponseBody;
export type UpdatePhotoSettingsApiArg = {
  platformCode: 'sauto' | 'tipcars' | 'mobile.de' | 'myweb' | 'facebook' | 'autosoft' | 'autocaris';
  updatePlatformPhotoSettingsRequestBody: UpdatePlatformPhotoSettingsRequestBody;
  params: {branchId?: string};
};
export type PlatformPhotoSettingsResponseBody = {
  maxPhotoNumberSelected: number;
  maxPhotoNumberAllowed: number;
  promoPhotoAllowed: boolean;
  watermarkAllowed: boolean;
};
export type UpdatePlatformPhotoSettingsRequestBody = {
  maxPhotoNumberSelected: number;
  promoPhotoAllowed: boolean;
  watermarkAllowed: boolean;
};

// ------------------------- ARES BUSSINES INFO API -------------------------
export type GetBusinessInfoApiResponse = /** status 200  */ CompanyInformationListResponseBody;
export type GetBusinessInfoApiArg = {
  businessId: string;
  countryCode?: string | null;
};
export type CompanyInformationResponseBody = {
  businessId: string;
  businessName: string;
  address: string;
  city: string;
  stateProvince: string;
  postalZipCode: string;
  countryCode: string;
  taxId: string | null;
  fileNumber: string | null;
};
export type CompanyInformationListResponseBody = {
  data: CompanyInformationResponseBody[];
};

// ------------------------- NOTE API -------------------------
export type GetNoteApiResponse = /** status 200  */ NoteV2ResponseBody;
export type GetNoteApiArg = {
  resourceId: EntityResourceIds;
  recordId: string;
  context: string;
};
export type UpdateNoteApiResponse = unknown;
export type UpdateNoteApiArg = {
  resourceId: EntityResourceIds;
  recordId: string;
  context: string;
  updateNoteRequestBody: UpdateNoteRequestBody;
};
export type NoteV2ResponseBody = {
  updatedBy: string;
  updatedAt: string;
  text: string;
};
export type UpdateNoteRequestBody = {
  text: string;
};

// ------------------------- PARTICIPATION V2 API -------------------------
export type TransferOwnershipToUserV2ApiResponse = /** status 204  */ undefined;
export type TransferOwnershipToUserV2ApiArg = {
  resourceId: string;
  recordId: string;
  userIdToTransferOwnership: string;
};
export type AssignUserV2ApiResponse = /** status 204  */ undefined;
export type AssignUserV2ApiArg = {
  resourceId: string;
  recordId: string;
  userIdToAssign: string;
};
export type UnassignUserV2ApiResponse = /** status 204  */ undefined;
export type UnassignUserV2ApiArg = {
  resourceId: string;
  recordId: string;
  userIdToUnassign: string;
};

export const TemplateListItemResponseBodySchema = z.object({
  id: z.string(),
  title: z.string(),
  note: z.string().nullable(),
  fileName: z.string(),
  primary: z.boolean(),
});

export type TemplateListItemResponseBody = z.infer<typeof TemplateListItemResponseBodySchema>;
export const FileResponseBodySchema = z.object({
  id: z.string(),
  filename: z.string(),
  pdfFilename: z.string(),
  fileUri: z.string(),
  pdfUri: z.string(),
});

export type FileResponseBody = z.infer<typeof FileResponseBodySchema>;
export const FileOperationStateHttpBodySchema = z.enum(['IN_PROGRESS', 'SUCCESS', 'FAILED']);

export type FileOperationStateHttpBody = z.infer<typeof FileOperationStateHttpBodySchema>;
export const RecordIdHttpModelSchema = z.object({
  id: z.string(),
  resourceId: z.string(),
});
export type RecordIdHttpModel = z.infer<typeof RecordIdHttpModelSchema>;
export const UuidResponseBodySchema = z.object({
  id: z.string(),
});
export type UuidResponseBody = z.infer<typeof UuidResponseBodySchema>;
export const FileOperationResponseBodySchema = z.object({
  id: z.string(),
  batchId: z.string(),
  sourceFileId: z.string(),
  sourceRemoteFileId: z.string(),
  generatedRemoteFileId: z.string(),
  state: FileOperationStateHttpBodySchema,
  authorUserId: z.string().nullable(),
  createdAt: z.string(),
  type: z.enum(['REMOVE_BACKGROUND', 'ROTATE', 'COPY']),
  updatedAt: z.string().nullable(),
  recordId: RecordIdHttpModelSchema,
  batchRecordId: RecordIdHttpModelSchema,
  generatorId: z.string(),
  acknowledged: z.boolean(),
});
export type FileOperationResponseBody = z.infer<typeof FileOperationResponseBodySchema>;
export type GetUserDashboardSettingsApiResponse =
  /** status 200  */ UserDashboardSettingsResponseBody;
export type GetUserDashboardSettingsApiArg = void;
export type PutUserDashboardSettingsApiResponse = unknown;
export type PutUserDashboardSettingsApiArg = {
  salesFunnelInterests: boolean;
  salesFunnelBusinessCases: boolean;
  vehicleStock: boolean;
  latestVehicles: boolean;
  latestBusinessCases: boolean;
  latestInterests: boolean;
  notifications: boolean;
  myTasks: boolean;
};

export type GetUserAvatarApiResponse = string;
export type GetUserAvatarApiArg = {
  userId: string;
};

export type UserDashboardSettingsResponseBody = {
  salesFunnelInterests: boolean;
  salesFunnelBusinessCases: boolean;
  vehicleStock: boolean;
  latestVehicles: boolean;
  latestBusinessCases: boolean;
  latestInterests: boolean;
  notifications: boolean;
  myTasks: boolean;
};
export type UserDashboardSettingsRequestBody = {
  salesFunnelInterests: boolean;
  salesFunnelBusinessCases: boolean;
  vehicleStock: boolean;
  latestVehicles: boolean;
  latestBusinessCases: boolean;
  latestInterests: boolean;
  notifications: boolean;
  myTasks: boolean;
};
export type GetSimilarVehiclesV2ApiResponse = /** status 200  */ SimilarVehiclesV2ResponseBody;
export type GetSimilarVehiclesV2ApiArg = {
  size?: number | null;
  offset?: number | null;
  vin?: string | null;
  registrationPlateContains?: string | null;
  vinContains?: string | null;
};
export type SimilarVehiclesV2ResponseBody = {
  totalCount: number;
  vehicles: SimilarVehicleResponseBody[];
};

export type VehicleCoverPhotoResponseBody = {
  id: string;
  fileId: string;
  remoteFileId: string;
  uri: string;
};
export type GetVehicleCoverPhotoApiResponse = /** status 200  */ VehicleCoverPhotoResponseBody;
export type GetVehicleCoverPhotoApiArg = {
  vehicleId: string;
};
