import {Separator} from 'platform/components';
import {Box, VStack} from 'platform/foundation';

import i18n from '@omnetic-dms/i18n';
import {testIds} from '@omnetic-dms/routes';

import {MyProfileTemplate} from '../../components/MyProfileTemplate/MyProfileTemplate';
import {AutoLogoutForm} from './components/AutoLogoutForm';
import {ChangePasswordForm} from './components/ChangePasswordForm';

export function Security() {
  return (
    <MyProfileTemplate
      header={{
        title: i18n.t('entity.user.labels.security'),
        breadcrumbs: [
          {label: i18n.t('entity.user.labels.myProfile'), isCurrentPage: true},
          {label: i18n.t('entity.user.labels.security'), isCurrentPage: true},
        ],
      }}
      data-testid={testIds.myProfile.security('header')}
    >
      <Box maxWidth={200}>
        <VStack spacing={6}>
          <ChangePasswordForm />
          <Separator />
          <AutoLogoutForm />
        </VStack>
      </Box>
    </MyProfileTemplate>
  );
}
