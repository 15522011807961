import {z} from 'zod';

import {BaseVoidResponseSchema, CreatedAndUpdatedBySchema, PaymentTypeSchema} from './base';

/**
 * Base
 */
const ReceiveNoteStateSchema = z.enum(['PENDING', 'COMPLETED']);
export type ReceiveNoteState = z.infer<typeof ReceiveNoteStateSchema>;

const DeliveryNoteStateSchema = z.enum(['PENDING', 'RECEIVED', 'COMPLETED']);
export type DeliveryNoteState = z.infer<typeof DeliveryNoteStateSchema>;

/**
 * GET
 */
export const GetReceiveNoteRequestSchema = z.object({
  receiveNoteId: z.string(),
});
export type GetReceiveNoteRequest = z.infer<typeof GetReceiveNoteRequestSchema>;

export const GetReceiveNoteResponseSchema = z
  .object({
    receiveNoteId: z.string(),
    warehouseId: z.string(),
    deliveryNoteId: z.string(),
    supplierId: z.string(),
    receiveNoteNumber: z.string().optional(),
    state: ReceiveNoteStateSchema,
    paymentType: PaymentTypeSchema,
    currency: z.string().optional(),
    exchangeRate: z.number().optional(),
    ncConversion: z.string().optional(),
    note: z.string().optional(),
    authorizationProfileId: z.string(),
    invoiceNumber: z.string().optional(),
    isFromReceiveNote: z.boolean(),
    invoiceIssuedAt: z.string().optional(),
    invoiceIssueDate: z.string().optional(),
  })
  .merge(CreatedAndUpdatedBySchema);
export type GetReceiveNoteResponse = z.infer<typeof GetReceiveNoteResponseSchema>;

/**
 * POST
 */
export const PostReceiveNoteManualRequestSchema = z.object({
  warehouseId: z.string(),
  invoiceNumber: z.string().optional(),
  deliveryNoteNumber: z.string().optional(),
  supplierId: z.string(),
  paymentType: PaymentTypeSchema,
  orderNumber: z.string().optional(),
  exchangeRate: z.number().optional(),
  ncConversion: z.string().optional(),
  note: z.string().optional(),
  authorizationProfileId: z.string(),
  invoiceIssueDate: z.string().optional(),
  delivery: z
    .object({
      deliveryNoteId: z.string(),
    })
    .optional(),
});
export type PostReceiveNoteManualRequest = z.infer<typeof PostReceiveNoteManualRequestSchema>;

export const PostReceiveNoteManualResponseSchema = z.object({
  receiveNoteId: z.string(),
  receiveNoteItemId: z.string(),
});
export type PostReceiveNoteManualResponse = z.infer<typeof PostReceiveNoteManualResponseSchema>;

/**
 * PATCH
 */
export const PatchReceiveNoteManualRequestSchema = z.object({
  receiveNoteId: z.string(),
  body: z.object({
    deliveryNoteNumber: z.string().optional(),
    warehouseId: z.string(),
    supplierId: z.string(),
    invoiceNumber: z.string().optional(),
    paymentType: PaymentTypeSchema,
    exchangeRate: z.number().optional(),
    ncConversion: z.string().optional(),
    note: z.string().optional(),
    authorizationProfileId: z.string(),
    invoiceIssueDate: z.string().optional(),
  }),
});
export type PatchReceiveNoteManualRequest = z.infer<typeof PatchReceiveNoteManualRequestSchema>;

export const PatchReceiveNoteManualResponseSchema = z.object({
  deliveryNote: z
    .object({
      deliveryNoteId: z.string(),
      supplierId: z.string(),
      warehouseId: z.string(),
      authorizationProfileId: z.string(),
      state: DeliveryNoteStateSchema,
      deliveryNoteNumber: z.string(),
      invoiceNumber: z.string(),
      paymentType: PaymentTypeSchema,
      currency: z.string(),
      exchangeRate: z.number(),
      referenceNumber: z.string(),
      ncConversion: z.string(),
      issuedAt: z.string(),
      isFromReceiveNote: z.boolean(),
      invoiceIssueDate: z.string().optional(),
    })
    .merge(CreatedAndUpdatedBySchema),
  receiveNote: z
    .object({
      receiveNoteId: z.string(),
      warehouseId: z.string(),
      deliveryNoteId: z.string(),
      supplierId: z.string(),
      receiveNoteNumber: z.string(),
      state: ReceiveNoteStateSchema,
      paymentType: PaymentTypeSchema,
      currency: z.string(),
      exchangeRate: z.number(),
      ncConversion: z.string(),
      note: z.string(),
      authorizationProfileId: z.string(),
    })
    .merge(CreatedAndUpdatedBySchema),
});
export type PatchReceiveNoteManualResponse = z.infer<typeof PatchReceiveNoteManualResponseSchema>;

export const PatchReceiveNoteStateRequestSchema = z.object({
  receiveNoteId: z.string(),
  body: z.object({
    state: ReceiveNoteStateSchema,
  }),
});
export type PatchReceiveNoteStateRequest = z.infer<typeof PatchReceiveNoteStateRequestSchema>;

export const PatchReceiveNoteStateResponseSchema = BaseVoidResponseSchema;
export type PatchReceiveNoteStateResponse = z.infer<typeof PatchReceiveNoteStateResponseSchema>;

/**
 * DELETE
 */
export const BulkDeleteReceiveNoteRequestSchema = z.object({
  receiveNoteId: z.array(z.string()),
});
export type BulkDeleteReceiveNoteRequest = z.infer<typeof BulkDeleteReceiveNoteRequestSchema>;

export const BulkDeleteReceiveNoteResponseSchema = BaseVoidResponseSchema;
export type BulkDeleteReceiveNoteResponse = z.infer<typeof BulkDeleteReceiveNoteResponseSchema>;
