import {
  Alert,
  Attributes,
  AttributesRow,
  Button,
  Card,
  closeCurrentDialog,
  EmptyStatus,
  openDialog,
  Segment,
  Separator,
} from 'platform/components';
import {Box, Heading, HStack, Link, Right, Show, Text, VStack} from 'platform/foundation';
import {useDateTimeFormatter, useFormatCurrency} from 'platform/locale';
import {match} from 'ts-pattern';

import {useState} from 'react';

import {
  AftersalesPaymentMethod,
  Payment,
  usePostCheckoutPaymentCancelMutation,
} from '@omnetic-dms/api';
import i18n from '@omnetic-dms/i18n';

import {buildArray, openFile, parseDate, RequiredTestIdProps, suffixTestId} from 'shared';

import {EMPTY_PLACEHOLDER} from '../../../constants/placeholders';
import {useBank} from '../../../hooks/useBank';
import {useCashRegister} from '../../../hooks/useCashRegister';
import {handleApiError} from '../../../utils/handleApiError';
import {PayDialog} from './PayDialog';

interface PaymentOverviewProps extends RequiredTestIdProps {
  checkoutId: string;
  payment: Payment;
  disallowedPaymentMethods?: AftersalesPaymentMethod[];
}

export function PaymentOverview(props: PaymentOverviewProps) {
  const [selectedSegment, seSegment] = useState('DETAILS');

  const {getCashRegisterById} = useCashRegister();
  const {composeBankAccount} = useBank();
  const formatCurrency = useFormatCurrency();
  const formatDateTime = useDateTimeFormatter();

  const [postCheckoutPaymentCancel, {isLoading: isCancelingPaymnet}] =
    usePostCheckoutPaymentCancelMutation();

  const handleCancelPayment = () =>
    postCheckoutPaymentCancel({
      checkoutId: props.checkoutId,
      paymentId: props.payment.checkoutPaymentId,
    })
      .unwrap()
      .catch(handleApiError);

  const paymentMethodLabel = match(props.payment.method)
    .with('BANK_TRANSFER', () => i18n.t('entity.invoice.paymentMethod.bankTransfer'))
    .with('CARD', () => i18n.t('entity.invoice.paymentMethod.card'))
    .with('CASH', () => i18n.t('entity.invoice.paymentMethod.cash'))
    .with('INTERNAL', () => i18n.t('general.labels.internal'))
    .exhaustive();

  const rows = buildArray<AttributesRow>([
    {
      label: i18n.t('entity.checkout.labels.paymentMethod'),
      value: paymentMethodLabel,
      testId: 'paymentMethod',
    },
    {
      label: i18n.t('entity.checkout.labels.issuedOn'),
      value: props.payment.issuedOn
        ? formatDateTime('dateShort', parseDate(props.payment.issuedOn))
        : EMPTY_PLACEHOLDER,
      testId: 'issuedOn',
    },
    {
      label: i18n.t('entity.checkout.labels.dueDate'),
      value: props.payment.dueDate
        ? formatDateTime('dateShort', parseDate(props.payment.dueDate))
        : EMPTY_PLACEHOLDER,
      testId: 'dueDate',
    },
    {
      label: i18n.t('entity.accounting.labels.dateOfTaxableSupply'),
      value: props.payment.dateOfTaxableSupply
        ? formatDateTime('dateShort', parseDate(props.payment.dateOfTaxableSupply))
        : EMPTY_PLACEHOLDER,
      testId: 'dateOfTaxableSupply',
    },
  ])
    .when(props.payment.method === 'CASH', {
      label: i18n.t('entity.checkout.labels.cashRegister'),
      value: props.payment.cashRegisterId
        ? getCashRegisterById(props.payment.cashRegisterId)?.name
        : EMPTY_PLACEHOLDER,
    })
    .when(props.payment.method === 'BANK_TRANSFER', {
      label: i18n.t('general.labels.bankAccount'),
      value: props.payment.bankAccount
        ? composeBankAccount(props.payment.bankAccount)
        : EMPTY_PLACEHOLDER,
    })
    .add({
      label: i18n.t('general.labels.note'),
      value: props.payment.note,
    });
  rows.push(
    ...(props.payment.invoices?.map((invoice) => ({
      label: i18n.t('entity.checkout.labels.invoice'),
      content: (
        <HStack justify="space-between">
          <Link size="small" onClick={() => openFile(invoice.fileUrl)} title={invoice.name} />
          <Show when={props.payment.paymentState === 'PENDING'}>
            <Button
              size="small"
              isLoading={isCancelingPaymnet}
              variant="dangerLink"
              title={i18n.t('entity.invoice.actions.cancelInvoice')}
              onClick={handleCancelPayment}
            />
          </Show>
        </HStack>
      ),
      testId: 'invoice',
    })) ?? [])
  );

  const paymentAmount = props.payment.foreignCurrencyPaymentAmount ?? props.payment.paymentAmount;
  const remainingPaymentAmount =
    props.payment.remainingForeignCurrencyPaymentAmount ?? props.payment.remainingPaymentAmount;

  return (
    <VStack spacing={4}>
      <Card variant="inlineGrey">
        <HStack spacing={20}>
          <VStack spacing={1}>
            <Text size="xSmall" color="secondary">
              {i18n.t('general.labels.amount')}
            </Text>
            <Heading size={4} data-testid={suffixTestId('amount', props)}>
              {formatCurrency(paymentAmount.amount, paymentAmount.currency, 2)}
            </Heading>
          </VStack>
          <VStack spacing={1}>
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.invoice.labels.remaining')}
            </Text>
            <Heading size={4} data-testid={suffixTestId('remainingAmount', props)} color="danger">
              {formatCurrency(remainingPaymentAmount.amount, remainingPaymentAmount.currency, 2)}
            </Heading>
          </VStack>
        </HStack>
        <Separator />
        <VStack spacing={4} justify="flex-start">
          <Show when={props.payment.paymentState === 'PAID'}>
            <Alert title={i18n.t('entity.checkout.labels.allPaymentsPaid')} variant="success" />
          </Show>
          <Box width={75}>
            <Segment
              value={selectedSegment}
              onChange={seSegment}
              options={[
                {label: i18n.t('general.labels.details'), value: 'DETAILS'},
                {label: i18n.t('page.accounting.invoiceDetailPayments.title'), value: 'PAYMENTS'},
              ]}
              data-testid={props['data-testid']}
            />
          </Box>
          <Show when={selectedSegment === 'DETAILS'}>
            <Attributes size="quarter" rows={rows} data-testid={props['data-testid']} />
            <Show when={props.payment.paymentState === 'PENDING'}>
              <Separator spacing={0} />
              <Right>
                <Button
                  title={i18n.t('entity.order.actions.pay')}
                  onClick={() =>
                    openDialog(
                      <PayDialog
                        checkoutId={props.checkoutId}
                        payment={props.payment}
                        disallowedPaymentMethods={props.disallowedPaymentMethods}
                        onClose={closeCurrentDialog}
                        data-testid={props['data-testid']}
                      />,
                      {
                        title: i18n.t('entity.order.actions.pay'),
                      }
                    )
                  }
                />
              </Right>
            </Show>
          </Show>
          <Show when={selectedSegment === 'PAYMENTS'}>
            {/* TODO - T20-66974 */}
            <EmptyStatus subheadline="TODO - T20-66974" />
          </Show>
        </VStack>
      </Card>
    </VStack>
  );
}
