import {isFeatureEnabled} from 'feature-flags';
import {
  Button,
  ButtonGroup,
  closeCurrentDialog,
  closeDialog,
  openDialog,
} from 'platform/components';
import {Text, VStack} from 'platform/foundation';

import {isNil} from 'ramda';
import {isNilOrEmpty} from 'ramda-adjunct';

import {
  SaleVehicleAvailableWarehouseActionsResponseBody,
  FileResponseBody,
  useGetSaleVehicleAvailableWarehouseActionsQuery,
  useGetVehicleWarehouseManagementSettingsQuery,
} from '@omnetic-dms/api';
import {featureFlags} from '@omnetic-dms/feature-flags';
import i18n from '@omnetic-dms/i18n';

import {Nullish, suffixTestId, TestIdProps} from 'shared';

import {DocumentsDialog} from '../components/DocumentsDialog/DocumentsDialog';
import {VehicleWarehouseCancelStockIn} from '../components/VehicleWarehouse/components/VehicleWarehouseCancelStockIn';
import {VehicleWarehouseCancelStockOut} from '../components/VehicleWarehouse/components/VehicleWarehouseCancelStockOut';
import {VehicleWarehouseStockIn} from '../components/VehicleWarehouse/components/VehicleWarehouseStockIn';
import {VehicleWarehouseStockInStockOut} from '../components/VehicleWarehouse/components/VehicleWarehouseStockInStockOut';
import {VehicleWarehouseStockOut} from '../components/VehicleWarehouse/components/VehicleWarehouseStockOut';
import {VehicleWarehouseTransfer} from '../components/VehicleWarehouse/components/VehicleWarehouseTransfer';

const WAREHOUSE_DIALOG_ID = 'vehicleWarehouseDialog';

interface VehicleWarehouseHookProps extends TestIdProps {
  vehicleId: string;
  saleVehicleId?: string;
  vehicleWarehouseId?: string;
  customerId?: string | Nullish;
  onMovementComplete?: <T>(callback?: T) => void;
}

const defaultActions: SaleVehicleAvailableWarehouseActionsResponseBody = {
  cancelIssue: false,
  cancelReceipt: false,
  issue: false,
  receive: false,
  transfer: false,
};

export function useVehicleWarehouse(props: VehicleWarehouseHookProps) {
  const {data: settings} = useGetVehicleWarehouseManagementSettingsQuery();
  const {data: availableActions = defaultActions} = useGetSaleVehicleAvailableWarehouseActionsQuery(
    {
      vehicleId: props.vehicleId,
    },
    {
      skip: isNilOrEmpty(props.vehicleId),
    }
  );

  const isVehicleWarehouseEnabled =
    isFeatureEnabled(featureFlags.SALES_VEHICLE_WAREHOUSE_MANAGEMENT) && settings?.isEnabled;

  const handleStockInDocumentGenerated = (document: FileResponseBody) => {
    closeDialog(WAREHOUSE_DIALOG_ID);

    openDialog(
      <DocumentsDialog
        customerId={props.customerId}
        documents={[document]}
        text={i18n.t('entity.vehicleWarehouse.labels.stockInDocumentCreated')}
      />,
      {
        title: i18n.t('entity.document.labels.vehicleWarehouseStockInDocument'),
        buttons: [
          {
            variant: 'secondary',
            title: i18n.t('general.actions.close'),
            onClick: closeCurrentDialog,
          },
        ],
      }
    );
  };

  const handleStockOutDocumentGenerated = (document: FileResponseBody) => {
    closeDialog(WAREHOUSE_DIALOG_ID);

    openDialog(
      <DocumentsDialog
        customerId={props.customerId}
        documents={[document]}
        text={i18n.t('entity.vehicleWarehouse.labels.stockOutDocumentCreated')}
      />,
      {
        title: i18n.t('entity.document.labels.vehicleWarehouseStockOutDocument'),
        buttons: [
          {
            variant: 'secondary',
            title: i18n.t('general.actions.close'),
            onClick: closeCurrentDialog,
          },
        ],
      }
    );
  };

  const handleCancelStockInDocumentGenerated = (document: FileResponseBody) => {
    closeDialog(WAREHOUSE_DIALOG_ID);

    openDialog(
      <DocumentsDialog
        customerId={props.customerId}
        documents={[document]}
        text={i18n.t('entity.vehicleWarehouse.labels.cancelStockInDocumentCreated')}
      />,
      {
        title: i18n.t('entity.document.labels.vehicleWarehouseCancelStockInDocument'),
        buttons: [
          {
            variant: 'secondary',
            title: i18n.t('general.actions.close'),
            onClick: closeCurrentDialog,
          },
        ],
      }
    );
  };

  const handleCancelStockOutDocumentGenerated = (document: FileResponseBody) => {
    closeDialog(WAREHOUSE_DIALOG_ID);

    openDialog(
      <DocumentsDialog
        customerId={props.customerId}
        documents={[document]}
        text={i18n.t('entity.vehicleWarehouse.labels.cancelStockOutDocumentCreated')}
      />,
      {
        title: i18n.t('entity.document.labels.vehicleWarehouseCancelStockOutDocument'),
        buttons: [
          {
            variant: 'secondary',
            title: i18n.t('general.actions.close'),
            onClick: closeCurrentDialog,
          },
        ],
      }
    );
  };

  const handleTransferDocumentsGenerated = (documents: FileResponseBody[]) => {
    closeDialog(WAREHOUSE_DIALOG_ID);

    openDialog(
      <DocumentsDialog
        customerId={props.customerId}
        documents={documents}
        text={i18n.t('entity.vehicleWarehouse.labels.transferDocumentCreated')}
      />,
      {
        title: i18n.t('entity.document.labels.vehicleWarehouseTransferDocument'),
        buttons: [
          {
            variant: 'secondary',
            title: i18n.t('general.actions.close'),
            onClick: closeCurrentDialog,
          },
        ],
      }
    );
  };

  const openStockInDialog = () =>
    openDialog(
      <VehicleWarehouseStockIn
        vehicleId={props.vehicleId}
        onDocumentCreation={handleStockInDocumentGenerated}
        onMovementComplete={props.onMovementComplete}
        data-testid={suffixTestId('stockIn', props)}
      />,
      {
        id: WAREHOUSE_DIALOG_ID,
        title: i18n.t('entity.vehicleWarehouse.actions.warehouseStockIn'),
        scrollBehavior: 'outside',
      }
    );

  const openStockOutDialog = (invoiceId?: string) => {
    if (isNil(props.vehicleWarehouseId)) {
      throw new Error('useVehicleWarehouse openStockOutDialog missing vehicleWarehouseId');
    }

    openDialog(
      <VehicleWarehouseStockOut
        vehicleId={props.vehicleId}
        invoiceId={invoiceId}
        vehicleWarehouseId={props.vehicleWarehouseId}
        onDocumentCreation={handleStockOutDocumentGenerated}
        onMovementComplete={props.onMovementComplete}
        data-testid={suffixTestId('stockOut', props)}
      />,
      {
        id: WAREHOUSE_DIALOG_ID,
        title: i18n.t('entity.vehicleWarehouse.actions.warehouseStockOut'),
        scrollBehavior: 'outside',
      }
    );
  };

  const openCancelStockInDialog = () => {
    if (isNil(props.saleVehicleId)) {
      throw new Error('useVehicleWarehouse openCancelStockInDialog missing saleVehicleId');
    }

    openDialog(
      <VehicleWarehouseCancelStockIn
        vehicleId={props.vehicleId}
        saleVehicleId={props.saleVehicleId}
        onDocumentCreation={handleCancelStockInDocumentGenerated}
        onMovementComplete={props.onMovementComplete}
        data-testid={suffixTestId('cancelStockIn', props)}
      />,
      {
        id: WAREHOUSE_DIALOG_ID,
        title: i18n.t('entity.vehicleWarehouse.actions.warehouseCancelStockIn'),
        scrollBehavior: 'outside',
      }
    );
  };

  const openCancelStockOutDialog = () => {
    if (isNil(props.saleVehicleId)) {
      throw new Error('useVehicleWarehouse openCancelStockOutDialog missing saleVehicleId');
    }

    openDialog(
      <VehicleWarehouseCancelStockOut
        vehicleId={props.vehicleId}
        saleVehicleId={props.saleVehicleId}
        onDocumentCreation={handleCancelStockOutDocumentGenerated}
        onMovementComplete={props.onMovementComplete}
        data-testid={suffixTestId('cancelStockOut', props)}
      />,
      {
        id: WAREHOUSE_DIALOG_ID,
        title: i18n.t('entity.vehicleWarehouse.actions.warehouseCancelStockOut'),
        scrollBehavior: 'outside',
      }
    );
  };

  const openTransferDialog = (branchId?: string) => {
    if (isNil(props.saleVehicleId)) {
      throw new Error('useVehicleWarehouse openTransferDialog missing saleVehicleId');
    }

    openDialog(
      <VehicleWarehouseTransfer
        vehicleId={props.vehicleId}
        branchId={branchId}
        saleVehicleId={props.saleVehicleId}
        onDocumentCreation={handleTransferDocumentsGenerated}
        onMovementComplete={() => props.onMovementComplete?.(branchId)}
      />,
      {
        id: WAREHOUSE_DIALOG_ID,
        title: i18n.t('entity.vehicleWarehouse.actions.warehouseTransfer'),
        scrollBehavior: 'outside',
      }
    );
  };

  const openStockInStockOutDialog = (invoiceId: string, branchId?: string) => {
    if (isNil(props.vehicleId)) {
      throw new Error('useVehicleWarehouse openStockInStockOutDialog missing vehicleId');
    }
    return openDialog(
      <VehicleWarehouseStockInStockOut
        vehicleId={props.vehicleId}
        branchId={branchId}
        onStockInDocumentCreation={handleStockInDocumentGenerated}
        onStockOutDocumentCreation={handleStockOutDocumentGenerated}
        onMovementComplete={() => props.onMovementComplete?.(branchId)}
        invoiceId={invoiceId}
        data-testid={suffixTestId('stockInStockOut', props)}
      />,
      {
        id: WAREHOUSE_DIALOG_ID,
        title: i18n.t('entity.vehicleWarehouse.actions.warehouseStockInStockOut'),
        scrollBehavior: 'outside',
      }
    );
  };

  const openStockInAlertDialog = (invoiceId: string, branchId?: string) =>
    openDialog(
      <VStack spacing={4}>
        <Text size="base" color="primary">
          {i18n.t('entity.vehicleWarehouse.labels.stockInAlertDescription')}
        </Text>
        <ButtonGroup align="right">
          <Button
            title={i18n.t('general.actions.cancel')}
            variant="secondary"
            onClick={closeCurrentDialog}
            data-testid={suffixTestId('cancelAlert', props)}
          />
          <Button
            title={i18n.t('general.actions.confirm')}
            type="submit"
            onClick={() => {
              closeCurrentDialog();
              openStockInStockOutDialog(invoiceId, branchId);
            }}
            data-testid={suffixTestId('submitAlert', props)}
          />
        </ButtonGroup>
      </VStack>,
      {
        size: 'small',
      }
    );

  return {
    isVehicleWarehouseEnabled,
    availableActions,
    openStockInDialog,
    openStockOutDialog,
    openCancelStockInDialog,
    openCancelStockOutDialog,
    openTransferDialog,
    openStockInAlertDialog,
  };
}
