import {Separator} from 'platform/components';

import {RequiredTestIdProps, suffixTestId} from 'shared';

import {CustomerContractSection} from './CustomerContractSection';
import {DiscountSection} from './DiscountSection';

interface DiscountsProps extends RequiredTestIdProps {
  serviceCaseId: string;
  serviceOrderId: string;
  isReadOnly?: boolean;
}

export function Discounts(props: DiscountsProps) {
  return (
    <>
      <CustomerContractSection
        orderId={props.serviceOrderId}
        serviceCaseId={props.serviceCaseId}
        isReadOnly={props.isReadOnly}
        data-testid={suffixTestId('customerContract', props)}
      />
      <Separator />
      <DiscountSection
        serviceCaseId={props.serviceCaseId}
        serviceOrderId={props.serviceOrderId}
        isReadOnly={props.isReadOnly}
        data-testid={suffixTestId('discounts', props)}
      />
    </>
  );
}
