import {z} from 'zod';

import {BaseIdSchema, BaseVoidResponseSchema, CreatedAndUpdatedBySchema} from './base';

const WarehousePricingEnumSchema = z.enum(['AVG', 'FIFO']);
export type WarehousePricingEnum = z.infer<typeof WarehousePricingEnumSchema>;

const SalesPriceCalculationEnumSchema = z.enum([
  'MARGIN_ON_BASE',
  'MARKUP_ON_BASE',
  'PROFIT_ON_BASE',
  'MANUAL',
  'RECOMMENDED_PRICE',
  'MARKETING_CODE',
]);
export type SalesPriceCalculationEnum = z.infer<typeof SalesPriceCalculationEnumSchema>;

const BasePriceSourceEnumSchema = z.enum([
  'AVG_PURCHASE',
  'LAST_PURCHASE',
  'RECOMMENDED',
  'MANUAL',
]);
export type BasePriceSourceEnum = z.infer<typeof BasePriceSourceEnumSchema>;

const WarehouseSupplierSchema = z.object({
  id: z.string().nullable(),
});

export const WarehouseDetailSchema = z.object({
  name: z.string(),
  warehouseNumber: z.string().optional(),
  address: z.string().optional(),
  supportedMake: z.string().optional(),
  defaultSupplierId: z.string(),
  warehousePricing: WarehousePricingEnumSchema,
  defaultAccountId: z.string(),
  defaultOrderShipping: z.string().optional(),
  branchId: z.string(),
  companyBillingInfoId: z.string(),
  allowServiceOrderIssue: z.boolean(),
  allowedOrderVariants: z.array(z.string()).optional(),
  commercialOrderMarginMinimum: z.number().nullish(),
  stockNumberPrefix: z.string().optional(),
  receiveNoteSeriesId: z.string(),
  receiveNoteTemplateId: z.string(),
  issueNoteSeriesId: z.string(),
  issueNoteTemplateId: z.string(),
  salesPriceCalculation: SalesPriceCalculationEnumSchema,
  basePriceSource: BasePriceSourceEnumSchema,
  salePriceProfitWithoutVat: z.number().nullish(),
  salePriceMarginPercent: z.number().nullish(),
  salePriceMarkupPercent: z.number().nullish(),
  blockPricingSettingOnArticle: z.boolean(),
  unitSalesPriceWithVat: z.boolean(),
  dispatchToMinus: z.boolean(),
  dummyWarehouse: z.boolean(),
  allSuppliers: z.boolean(),
  suppliers: z.array(WarehouseSupplierSchema),
  allowDirectSaleIssue: z.boolean(),
  allowedDirectSaleVariants: z.array(z.string()).optional(),
  authorizationProfileId: z.string(),
  currency: z.string(),
  creditNoteSeriesId: z.string().nullish(),
  creditNoteTemplateId: z.string().nullish(),
  issueNoteCorrectionSeriesId: z.string().nullish(),
  issueNoteCorrectionTemplateId: z.string().nullish(),
});

/**
 * GET
 */
export const GetWarehousesRequestSchema = z.union([
  z.object({
    serviceOrderVariantId: z.string().optional(),
    directSaleVariantId: z.string().optional(),
    authorizationProfileId: z.string().optional(),
    supplierId: z.string().optional(),
  }),
  z.void(),
]);
export type GeWarehousesRequest = z.infer<typeof GetWarehousesRequestSchema>;

export const GetWarehousesResponseSchema = z.array(
  z.object({
    id: z.string(),
    warehouseNumber: z.string().optional(),
    name: z.string(),
    authorizationProfileId: z.string(),
  })
);
export type GetWarehousesResponse = z.infer<typeof GetWarehousesResponseSchema>;

export const GetWarehouseRequestSchema = z.object({
  warehouseId: z.string(),
});
export type GetWarehouseRequest = z.infer<typeof GetWarehouseRequestSchema>;

export const GetWarehouseResponseSchema =
  BaseIdSchema.merge(WarehouseDetailSchema).merge(CreatedAndUpdatedBySchema);
export type GetWarehouseResponse = z.infer<typeof GetWarehouseResponseSchema>;

/**
 * POST
 */
export const PostWarehouseRequestSchema = WarehouseDetailSchema.omit({currency: true});
export type PostWarehouseRequest = z.infer<typeof PostWarehouseRequestSchema>;

export const PostWarehouseResponseSchema = z.object({
  warehouseId: z.string(),
});
export type PostWarehouseResponse = z.infer<typeof PostWarehouseResponseSchema>;

/**
 * PATCH
 */
export const PatchWarehouseRequestSchema = z.object({
  warehouseId: z.string(),
  body: WarehouseDetailSchema.omit({currency: true}),
});
export type PatchWarehouseRequest = z.infer<typeof PatchWarehouseRequestSchema>;

export const PatchWarehouseResponseSchema = BaseVoidResponseSchema;
export type PatchWarehouseResponse = z.infer<typeof PatchWarehouseResponseSchema>;

/**
 * DELETE
 */
export const DeleteWarehouseRequestSchema = z.object({
  body: z.object({
    warehouseId: z.array(z.string()),
  }),
});
export type DeleteWarehouseRequest = z.infer<typeof DeleteWarehouseRequestSchema>;

export const DeleteWarehouseResponseSchema = BaseVoidResponseSchema;
export type DeleteWarehouseResponse = z.infer<typeof DeleteWarehouseResponseSchema>;
